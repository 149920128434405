// Custom components
import React, { useEffect, useState } from "react";

const Badge = ({ content, style }) => {
    return <div style={style} className="bg-grey-100 flex items-center justify-center h-full p-2 whitespace-no-wrap">{content}</div>
}

function PrefixPostfixInput(props) {
    const {
        register,
        isRequired,
        setValue,
        value,
        type,
        label,
        placeholder,
        id,
        name,
        state,
        extra,
        errors,
        variant,
        disabled,
        readOnly,
        onBlur,
        onFocus,
        inputStyle,
        prefix = '',
        postfix = '',
    } = props;

    const [fakeValue, setFakeValue] = useState('')

    useEffect(() => {
        setFakeValue(value?.replace(prefix, '')?.replace(postfix, ''))
    }, [value])

    return (
        <div className={`${extra}`} style={inputStyle}>
            {label && (
                <div className={`mb-2`}>
                    <label
                        htmlFor={id}
                        className={`${disabled
                            ? ""
                            : variant === "secondary"
                                ? "text-secondary-text"
                                : "text-primary-text"
                            } text-sm leading-5 font-semibold`}
                    >
                        {label}
                    </label>
                    {isRequired && <span className='font-semibold text-error'>*</span>}
                </div>
            )}

            <div className={`flex items-center rounded-lg overflow-hidden border text-14px font-medium leading-5 ${disabled
                ? "opacity-75 text-hint"
                : state === "error"
                    ? "text-error border-error"
                    : variant === "secondary"
                        ? "text-grey-atom-grey border-gray-primary"
                        : "text-dark-primary border-gray-primary"
                }`}
                style={{ height: 42 }}
            >
                {prefix && <Badge content={prefix} />}
                <input
                    onChange={(e) => {
                        const value =
                            name === "price"
                                ? e.target.value.toString().length > 13
                                    ? e.target.value.toString().substring(0, 13)
                                    : e.target.value
                                : e.target.value;

                        setValue(name, prefix + value + postfix);
                        if (type === 'percent') {
                            if (/^[0-9]\d*\.?\d*$/.test(e.target.value) || e.target.value === '') {
                                setFakeValue(e.target.value);
                                setValue(e.target.value, prefix + value + postfix);
                            } else {
                                setFakeValue(e.target.value?.slice(0, e.target.value.length - 1))
                                setValue(e.target.value?.slice(0, e.target.value.length - 1), prefix + value + postfix);
                            }
                        }
                    }}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    {...register(name, { required: isRequired })}
                    readOnly={readOnly}
                    disabled={disabled}
                    value={fakeValue}
                    type={type}
                    id={id}
                    placeholder={placeholder}
                    onKeyDown={(evt) => {
                        if (type === "text" && variant === "tax_no") {
                            const regexPattern = /^[0-9-]+$/;

                            // Allow certain keys like backspace
                            const isAllowedKey = evt.key === "Backspace" || regexPattern.test(evt.key);

                            // Prevent the default action if the key is not allowed
                            !isAllowedKey && evt.preventDefault();
                        } else if (type === "number") {
                            ["e", "E", "+", ".", ",", "-"].includes(evt.key) && evt.preventDefault();
                        }
                    }}
                    onPaste={e => {
                        const pastedContent = e.clipboardData.getData('Text')
                        type === 'percent' && !/^[0-9]\d*\.?\d*$/.test(pastedContent) && e.preventDefault()
                    }}
                    onWheel={(event) => type === "number" && event.currentTarget.blur()}
                    style={{ padding: "10px 16px" }}
                    className={`${readOnly && "opacity-50"} ${prefix && 'border-l'} ${postfix && 'border-r'} outline-none placeholder-hint font-medium`}
                />
                <input className="hidden" name={name} value={value} />
                {postfix && <Badge content={postfix} />}
            </div>
            {errors[name] && (
                <p className='text-xs font-normal mt-2 text-error'>{errors[name].message}</p>
            )}
        </div>
    );
}

export default PrefixPostfixInput;
