import React, { useEffect } from "react";
import { CustomToastMessage } from "@utils/customToastMsg";
import IcCopy from "@assets/images/payment/ic_copy.svg";

const PreviewScript = ({ appId, merchantId }) => {
  const codeString = `<script src="${process.env.REACT_APP_WIDGET_URL}/widget.js"></script>
  ${'\n'}<script>jQuery(document).ready(function($){var total=document.querySelector('.order-total .woocommerce-Price-amount.amount')
  ${'\n'}.textContent.replace(/\\D/g,'');var n=$('<div id="btn-widget"></div>');$('#payment').replaceWith(n);WidgetASM.config({
  ${'\n'}appID:"${appId}",order:{"merchant_id":${merchantId},"order_id":"1","amount":total}});WidgetASM.buttonWidget.init().render()})</script>${'\n'}`

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(codeString);
      CustomToastMessage.success('Đã sao chép')

    } catch (err) {
      CustomToastMessage.error('Sao chép thất bại')
    }
  }

  useEffect(() => {
    // if (1 || (orderId && total)) {
    // }
    window.WidgetASM.config({
      appID: appId,
      selector: "#widget-app",
      order: {
        "order_id": "test",
        "amount": 1000,
        "app_id": "fec4b00a-15c7-42d1-8a16-16658b43",
        "mobile": "0905505048",
        "gender": "M",
        "address": "266 nam ky khoi nghia",
        "email": "khanhln26169@gmail.com",
        "description": "",
        "last_name": "THANH PHU",
        "city": "Ho Chi Minh",
        "country": "VN",
        "district": "Quan 3",
        "first_name": "TRAN",
        "is_redirect": true
      }
    })
    window.WidgetASM.widgets.init().render()
  }, [])

  return (
    <div>
      <div className="text-black font-semibold leading-5">Script:</div>
      <div
        style={{ padding: "10px 16px", border: "1px solid #E8E8E8", background: "#1D242F" }}
        className={`flex rounded-lg relative`}
      >
        <button
          style={{
            padding: 6,
            background: '#F5F5F5',
            borderRadius: 6,
            right: 16
          }}
          className="absolute hover:opacity-75 cursor-pointer"
          onClick={handleCopy}
          type="button"
        >
          <img
            src={IcCopy}
            alt="IcCopy"
            style={{
              height: 20,
              width: "auto",
              transition: "all 0.3s linear",
              objectFit: "contain",
            }}
          />
        </button>

        <div className="w-full overflow-auto">
          <code style={{ background: 'transparent', color: 'white', textWrap: 'nowrap' }} className="p-0 whitespace-pre-line">{codeString}</code>
        </div>

        <div className="hidden">
          {/* <label>View</label> */}
          <div id="widget-app"></div>
        </div>
      </div>
    </div>
  );
};

export default PreviewScript;
