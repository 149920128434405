export const IS_COMING_SOON = true

export const PAYMENT_METHOD = {
  QR: 0,
  CASH: 1,
  CARD: 2,
  LINK: 3,
};

export const IMAGE_VALID_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

export const STATUS_CELL_COLOR = {
  BLUE: 'blue',
  RED: 'red',
  LIGHT_BLUE: 'lightBlue',
  TURQUOISE: 'turquoise',
  GRAY: 'gray',
}

export const LIMIT_UNFOCUS_TIME = 4 * 60 * 60 * 1000 // 4 hours
