import React, { useEffect, useState } from 'react'
import { SlArrowLeft } from 'react-icons/sl'
import Card from '@components/digitalbill/Card'
import CardPro from '@components/digitalbill/Card/Pro'
import CardStartup from '@components/digitalbill/Card/Startup'
import PurchaseNotification from '@components/notifications/PurchaseNoti'
import SectionTitle from '@components/section-title'
import { theme } from 'configTheme'
import { history } from '@helpers'
import { useTranslation } from '@hooks/useTranslation'
import ComingSoon from '@components/common/ComingSoon'
import { IS_COMING_SOON } from '@constants/common'
import { EINVOICE_STATUS } from '@constants/e-invoice'

const EInvoice = () => {
  const { translate } = useTranslation()
  const [status, setStatus] = useState(EINVOICE_STATUS.PENDING)

  useEffect(() => {
    if (window.localStorage.getItem('giftCodeInvoice')) {
      setStatus(EINVOICE_STATUS.SUBSCRIBED)
    } else setStatus(EINVOICE_STATUS.UNSUBSCRIBED)
  }, [])

  return status === EINVOICE_STATUS.PENDING ? (
    <></>
  ) : status === EINVOICE_STATUS.UNSUBSCRIBED ? (
    <div className='flex flex-col gap-8'>
      <SectionTitle
        title={translate('digitalbill_section_title')}
        subtitle={translate('digitalbill_section_subtitle')}
      />

      {IS_COMING_SOON ? <ComingSoon /> :
        <>
          <div
            style={{ boxShadow: "0px 8px 11px -3px rgba(0, 0, 0, 0.08)" }}
            className='bg-white p-10 rounded-2xl overflow-y-auto custom-scrollbar-1 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-10'
          >
            <CardStartup />
            <CardPro />
            <Card />
          </div>
          <PurchaseNotification />
        </>}
    </div>
  ) : (
    <div
      className='h-screen fixed w-screen top-0 left-0 bg-white'
      style={{ zIndex: 900 }}
    >
      <div
        onClick={() => {
          history.push('/dashboard');
        }}
        className='absolute cursor-pointer w-8 h-8 border border-primary bg-white rounded-lg flex justify-center items-center'
      >
        <SlArrowLeft size={16} color={theme.primary} />
      </div>
      <iframe
        title='e-invoice'
        width='100%'
        height='100%'
        src='https://hddt-v2-uat.bizzi.vn'
      />
    </div>
  );
}

export default EInvoice
