import React from "react";
import { useSelector } from "react-redux";
import { selectBusiness } from "@reducers/business.reducer";

import SectionTitle from "@components/section-title";
import { useTranslation } from "@hooks/useTranslation";
import SectionWrapper from "./SectionWrapper";
import Session from "./Session";
import TableWrapper from "./TableWrapper";

export default function BusinessProfile() {
  const business = useSelector(selectBusiness);
  const { translate } = useTranslation('business');

  return (
    <div className="w-full">
      <SectionTitle
        title={translate("business_info")}
        subtitle={translate("business_info_profile")}
      />

      {/* Table */}
      <TableWrapper>
        <SectionWrapper title={`1. ${translate("business_info")}`}>
          <Session title={translate("business_info_name")}>{business?.name}</Session>
          <Session title={translate("business_info_brand")}>{business?.trademark}</Session>
          <Session title={translate("business_info_type")}>{business?.business}</Session>
          <Session title={translate("business_info_tax_code")}>{business?.tax_no}</Session>
          <Session title={translate("business_info_address")}>{business?.address}</Session>
          <Session title={translate("business_info_phone")}>{business?.phone}</Session>
          <Session title={translate("business_info_mobile")}>{business?.mobile}</Session>
          <Session title={translate("business_info_email")}>{business?.email}</Session>
          <Session title={translate("business_info_website")}>{business?.website}</Session>
          {/* <Session isLabelField title={translate("business_info_bank")}>
            {business?.acquirer_bank?.logo_url && (
              <div className="flex items-center gap-2">
                <img
                  src={business?.acquirer_bank?.logo_url}
                  alt="bank"
                  className="w-auto h-5"
                />
                <p className="">{business?.acquirer_bank?.bankcode}</p>
              </div>
            )}
          </Session> */}
        </SectionWrapper>
        {/* <SectionWrapper title={`2. ${translate("business_info_representative")}`}>
          <Session title={translate("business_info_representative_name")}>
            {business?.representative_name}
          </Session>
          <Session title={translate("business_info_representative_position")}>
            {business?.position}
          </Session>
          <Session title={translate("business_info_representative_id")}>
            {business?.indentification_no}
          </Session>
        </SectionWrapper> */}
      </TableWrapper>
    </div>
  );
}
