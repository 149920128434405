import React, { useState } from "react";
import SectionTitle from "@components/section-title";
import CreatePaymentLink from "./CreatePaymentLink";
import PaymentLinkInfo from "./PaymentLinkInfo";

const PayByLinkPage = () => {
  const [linkCreated, setLinkCreated] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({
    order_id: "",
    amount: 0,
    app_id: "",
  });

  return (
    <>
      <SectionTitle title="Pay By Link" subtitle="Pay By Link" />

      <div className="mt-8 flex justify-center">
        {linkCreated ? (
          <PaymentLinkInfo paymentInfo={paymentInfo} />
        ) : (
          <CreatePaymentLink
            setLinkCreated={setLinkCreated}
            setPaymentInfo={setPaymentInfo}
          />
        )}
      </div>
    </>
  );
};
export default PayByLinkPage;
