import React, { useEffect, useState } from 'react';
import close from '@assets/images/product/close.svg';
import CustomButton from '@components/buttons/CustomButton';
import ModalWrap from '@components/modals/ModalWrap';
import { useTranslation } from '@hooks/useTranslation';
import { removeTable, updateTable } from '@services/table.service';

const PopupUpdate = ({ fetchListTable, isOpen, onClose, item }) => {
    const [name, setName] = useState(item?.table_name);
    const { translate } = useTranslation('sale_table')
    const handleDeleteTable = async () => {
        await removeTable(item?.table_id);
        onClose();
        fetchListTable();
    }
    const handleUpdateTable = async () => {
        const res = await updateTable(item?.table_id, {
            table_name: name,
            max_slot: 0,
            available_slot: 0,
            is_available: true
        })
        if (res) {
            fetchListTable();
            onClose();
        }
    }

    useEffect(() => {
        item && setName(item?.table_name)
    }, [item])

    return (
        <ModalWrap isOpen={isOpen} onClickBackdrop={onClose}>
            <div className="bg-white p-6 flex flex-col gap-8 rounded-xl relative">
                <img
                    src={close} alt=''
                    className='absolute cursor-pointer'
                    style={{ top: '16px', right: '16px' }}
                    onClick={onClose}
                />
                <p className='text-black text-base font-semibold leading-5 text-center'>{translate('update_table_name')}</p>

                <div className="flex flex-col">
                    <p className="text-black text-sm font-semibold leading-5">{translate('sale_order:table_name')}<span style={{ color: '#ED1C24' }}>*</span></p>
                    <input
                        type="text"
                        placeholder={translate('input_table_name')}
                        value={name}
                        className="create-form outline-none text-sm font-medium leading-5 rounded-lg mt-2 w-full"
                        style={{ padding: '10px 16px 10px 16px', border: '1px solid #E8E8E8', width: '366px' }}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <CustomButton onClick={handleDeleteTable} title={translate('common:remove')} outline type='button' />
                    <CustomButton onClick={handleUpdateTable} title={translate('common:update')} />
                </div>
            </div>
        </ModalWrap>
    )
}

export default PopupUpdate