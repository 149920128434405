import { FORGOT_PASSWORD, INFO, LOGIN } from "@constants/endpoint";
import portalClient from "./client_axios";

export const userService = {
  getProfile: (params) => {
    return portalClient.get(INFO, { params });
  },
  login: (params) => {
    return portalClient.post(LOGIN, params);
  },
  forgotPassword: (params) => {
    return portalClient.post(FORGOT_PASSWORD, params);

  },
  resetPassword: (params) => {
    return portalClient.put(FORGOT_PASSWORD, params);
  },
};
