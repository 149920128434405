import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createQR } from '@actions/va.action';
import { toPng } from 'html-to-image';
import { IoCloseSharp } from 'react-icons/io5';

import CustomButton from '@components/buttons/CustomButton';
import { useTranslation } from '@hooks/useTranslation';
import Skeleton from '@components/skeleton/MainSkeleton';

const ShowQR = ({ store, setShow }) => {
  const { translate } = useTranslation('bank');
  const [qr, setQR] = useState(null);
  const [qrLoading, setQrLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const getQR = async (va_account_no) => {
      setQrLoading(true);
      const res = await dispatch(createQR({ va_account_no }));
      if (res?.payload?.data?.qr_base64) setQR(res?.payload?.data?.qr_base64);
      else console.log(res?.payload?.errors);
      setQrLoading(false);
    };
    getQR(store.va_account_no);
  }, []);

  const ref = useRef(null);

  const handleDownloadQR = useCallback(() => {
    if (ref.current === null) return;
    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'qrcode.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => console.log(err));
  }, [ref]);

  return (
    <div className='fixed z-50 inset-0 bg-blur overflow-scroll flex justify-center items-center py-8'>
      <div className='relative flex flex-col gap-8 items-center bg-white rounded-2xl p-10'>
        {/* Icon Close */}
        <IoCloseSharp
          onClick={() => setShow(false)}
          style={{
            position: 'absolute',
            top: 16,
            right: 16,
            cursor: 'pointer',
          }}
          size={24}
        />

        {/* QR Wrap */}
        <div
          ref={ref}
          className='p-6 space-y-4 rounded-2xl border border-hint flex flex-col items-center'
        >
          <div style={{ width: 176, height: 176 }}>
            <Skeleton loading={qrLoading} width='100%' height='100%'>
              <img
                src={`data:image/png;base64,${qr}`}
                alt='qr'
                className='w-full h-full'
              />
            </Skeleton>
          </div>
          <p className='text-black text-sm leading-5 font-semibold text-center'>
            {store.name}
          </p>
        </div>

        {/* Buttons */}
        <div className='grid grid-cols-2 gap-4'>
          <CustomButton
            title={translate('common:close')}
            outline
            onClick={() => setShow(false)}
          />
          <CustomButton
            title={translate('shop_qr:management_download_qr')}
            onClick={handleDownloadQR}
            disabled={qrLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ShowQR;
