import portalClient from "./client_axios";
import { TRACKING } from "@constants/endpoint";

export const trackingServices = {
    getTrackingById: (data) => {
        const query = new URLSearchParams(data);
        return portalClient.get(`${TRACKING}?${query}`);
    },

    getListTracking: (data) => {
        const query = new URLSearchParams(data);
        return portalClient.get(`${TRACKING}?${query}`);
    },

    createTracking: (params) => {
        return portalClient.post(TRACKING, params);
    },

    updateTracking: (params) => {
        return portalClient.put(TRACKING, params);
    },

    deleteTracking: (params) => {
        return portalClient.delete(TRACKING, params);
    },
};
