import React, { useState } from 'react';
import useClickOutside from '@hooks/useClickOutside';
import Step1 from './Step1';
import Step2 from './Step2';

const PopupAddCustomer = ({ setCustomerName, onClose }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [step, setStep] = useState(0);
    const { nodeRef } = useClickOutside(onClose)

    return (
        <div style={{ zIndex: 60 }} className="fixed inset-0 bg-blur flex items-center justify-center">
            <div ref={nodeRef} className="bg-white p-6 flex flex-col gap-8 rounded-xl relative">
                {step === 0 ?
                    <Step1
                        step={step}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        setStep={setStep}
                        setCustomerName={setCustomerName}
                        handleBack={onClose}
                    />
                    : <Step2
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        setStep={setStep}
                        setCustomerName={setCustomerName}
                        handleBack={onClose}
                    />
                }
            </div>
        </div>
    )
}

export default PopupAddCustomer
