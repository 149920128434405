import HeadlessTippy from "@tippyjs/react/headless";
import React, { useState } from "react";
import "tippy.js/dist/tippy.css";
import ic_calendar from "@assets/images/txcard/ic-calendar.svg";
import {
  formatDateFromMilliseconds,
  formatDateToMilliseconds,
  formatDateToTimeStampCustom,
  getDaysDifference,
  getLastMonth,
  getSevenDayAgo,
  getThisMonth,
  getThisQuarter,
  getToday,
  getYesterday,
} from "@utils/lib";
import SelectFieldItem from "../select/SelectFieldItem";
import "../style.css";
import SelectDateConfig from "./SelectDateConfig";
import { useTranslation } from "@hooks/useTranslation";

const SelectDateField = ({ label, filters, setFilters, className }) => {
  const { translate } = useTranslation()
  const [showContent, setShowContent] = useState(false);
  const [showContentConfig, setShowContentConfig] = useState(false);

  const handleClick = (item, startDay, endDay) => {
    setSelectDate(item);
    setFilters((prev) => ({
      ...prev,
      start_time: formatDateToMilliseconds(startDay),
      end_time: formatDateToMilliseconds(endDay),
    }));
    setShowContent(false);
  };

  const data = [
    {
      title: translate("time_today"),
      onClick: (item) => {
        const [startDay, endDay] = getToday();
        handleClick(item, startDay, endDay);
      },
    },
    {
      title: translate("time_yesterday"),
      onClick: (item) => {
        const [startDay, endDay] = getYesterday();
        handleClick(item, startDay, endDay);
      },
    },
    {
      title: translate("time_7days_ago"),
      onClick: (item) => {
        const [startDay, endDay] = getSevenDayAgo();
        handleClick(item, startDay, endDay);
      },
    },
    {
      title: translate("time_this_month"),
      onClick: (item) => {
        const [startDay, endDay] = getThisMonth();
        handleClick(item, startDay, endDay);
      },
    },
    {
      title: translate("time_last_month"),
      onClick: (item) => {
        const [startDay, endDay] = getLastMonth();
        handleClick(item, startDay, endDay);
      },
    },
    {
      title: translate("time_this_quarter"),
      onClick: (item) => {
        const [startDay, endDay] = getThisQuarter();
        handleClick(item, startDay, endDay);
      },
    },
    {
      title: translate("time_custom"),
      onClick: (item) => {
        setSelectDate(item);
        setShowContentConfig(true);
      },
    },
  ];

  const [selectDate, setSelectDate] = useState(data[0]);
  const [startTime, setStartTime] = useState(new Date().toISOString().split("T")[0]);
  const [endTime, setEndTime] = useState(new Date().toISOString().split("T")[0]);

  const handleClose = () => {
    setFilters((prev) => ({
      ...prev,
      start_time: formatDateToTimeStampCustom(startTime),
      end_time: formatDateToTimeStampCustom(endTime, false),
    }));
    setShowContentConfig(false);
    setShowContent(false);
  };

  const renderReview = () => {
    return (
      <div
        tabIndex={-1}
        className={`inset-x-0 border border-gray-primary flex w-full flex-col overflow-hidden rounded-xl bg-white shadow-primary`}
      >
        {showContentConfig ? (
          <SelectDateConfig
            startTime={startTime}
            endTime={endTime}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            handleClose={handleClose}
            setShowContentConfig={setShowContentConfig}
          />
        ) : (
          data.map((item, index) => {
            const active = selectDate?.title === item?.title;

            return (
              <SelectFieldItem key={`select-title-${index}`} active={active} onClick={() => item.onClick(item)}>
                <span>{item?.title}</span>
              </SelectFieldItem>
            );
          }) || <div className="flex w-full items-center justify-center p-3">Không có dữ liệu</div>
        )}
      </div>
    );
  };

  return (
    <div className={`relative max-w-full font-monte transition`}>
      <div className="flex mb-1 text-14px font-semibold text-blackgray leading-5 ">{label}</div>
      <div className={showContentConfig ? "config" : ""}>
        <HeadlessTippy
          interactive
          visible={showContent}
          placement="bottom-start"
          offset={[0, 8]}
          render={() => renderReview()}
          onClickOutside={handleClose}
        >
          <div
            onClick={() => setShowContent(!showContent)}
            style={{ padding: "10px 10px 10px 16px", gap: 12 }}
            className={`flex w-full items-center cursor-pointer leading-5 font-medium justify-between rounded-lg border border-gray-primary text-blackgray ${className}`}
          >
            <div className="flex items-center truncate" style={{ flex: 1 }}>
              {formatDateFromMilliseconds(filters.start_time)} - {formatDateFromMilliseconds(filters.end_time)}
            </div>
            <img src={ic_calendar} alt="ic_calendar" width={24} />
          </div>
        </HeadlessTippy>
      </div>
    </div>
  );
};

export default SelectDateField;
