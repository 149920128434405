import { createSlice } from "@reduxjs/toolkit";
import { verifyBank } from "@actions/bank.actions";
import { getStaffById, updateStaff } from "@actions/staff.actions";

const initialState = {
  staff: {
    email: "",
    full_name: "",
    phone: "",
    password: "",
    store_id: "",
    staff_id: "",
  },
  isLoading: false,
};

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getStaffById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStaffById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.staff = action.payload.data;
      })
      .addCase(getStaffById.rejected, (state, action) => {
        state.isLoading = false;
      }),
});
export default staffSlice.reducer;

export const selectStaff = (state) => state.staff.staff;
export const selectIsLoadingStaff = (state) => state.staff.isLoading;
