import { createAsyncThunk } from "@reduxjs/toolkit";
import { productServices } from "@services";

export const getListProduct = createAsyncThunk(
  "store/product/getListProduct",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await productServices.getListProduct(data);
    return _data
  }
);

export const getProductById = createAsyncThunk(
  "store/product/getProductById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await productServices.getProductById(data);
    return _data
  }
);

export const createProduct = createAsyncThunk(
  "store/product/createProduct",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await productServices.createProduct(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "store/product/updateProduct",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await productServices.updateProduct(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addImageProduct = createAsyncThunk(
  "store/product/addImageProduct",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await productServices.addImageProduct(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "store/product/deleteProduct",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await productServices.deleteProduct(data);
    return _data;
  }
);
