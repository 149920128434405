import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationServices } from "@services";

export const getListNoti = createAsyncThunk(
  "store/notification/getListNoti",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await notificationServices.getListNoti(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createNoti = createAsyncThunk(
  "store/notification/createNoti",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await notificationServices.createNoti(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateNoti = createAsyncThunk(
  "store/notification/updateNoti",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await notificationServices.updateNoti(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);