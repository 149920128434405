import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "@components/buttons/CustomButton";
import InputField from "@components/fields/InputField";
import SectionTitle from "@components/section-title";
import { history } from "@helpers";
import { useTranslation } from "@hooks/useTranslation";
import axiosClient from "@services/client_axios";
import { CustomToastMessage } from "@utils/customToastMsg";
import TableWrapper from "../profile/TableWrapper";
import PaymentMethod from "./PaymentMethod";
import { EMENU_FORM } from "@constants/business/emenu";

const CompanySettingEMenu = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();
  const [paymentMethod, setPaymentMethod] = useState([]);
  const { translate } = useTranslation();

  const onFormSubmit = async (value) => {
    const formatData = paymentMethod
      .filter((item) => item.active)
      .map((item) => {
        const ewalletData = {
          url: value.url,
          access_key: value.access_key,
          secret_key: value.secret_key,
          partner_code: value.partner_code,
        };

        const qrData = {
          channel_id: value.pos,
        };

        const data =
          item.group.toLowerCase() === "ewallet"
            ? ewalletData
            : item.group.toLowerCase() === "qr"
              ? qrData
              : {};

        return {
          data,
          id: item.id,
        };
      });

    const dataSubmit = {
      ipn_url: value.link_result,
      cancel_url: value.link_cancel,
      redirect_url: value.link_success,
      data: formatData,
    };
    try {
      const { data } = await axiosClient.post("/payment-method", dataSubmit);
      if (data && data === "success") {
        CustomToastMessage.success("Updated payment method success");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  useEffect(() => {
    const onGetPaymentMethod = async () => {
      try {
        const { data } = await axiosClient.get("/payment-method");
        setPaymentMethod(data?.payment_method);
        if (data && data.endpoint) {
          setValue(EMENU_FORM.LINK_SUCCESS, data.endpoint?.redirect_url);
          setValue(EMENU_FORM.LINK_CANCEL, data.endpoint?.cancel_url);
          setValue(EMENU_FORM.LINK_RESULT, data.endpoint?.ipn_url);
        }
      } catch (error) { }
    };
    const interval = onGetPaymentMethod();
    return () => clearInterval(interval);
  }, [setValue]);

  const disabled =
    !watch(EMENU_FORM.LINK_SUCCESS) || !watch(EMENU_FORM.LINK_CANCEL) || !watch(EMENU_FORM.LINK_RESULT);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle
        title={translate("business_info")}
        subtitle={translate("setup_payment_gateway")}
      />
      <TableWrapper>
        <h1 style={{ color: "#262626" }} className="text-2xl font-bold leading-8">
          E-Menu
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <InputField
            isRequired
            setValue={setValue}
            label={translate("payment_successful")}
            placeholder={translate("payment_placeholder_successful")}
            id={EMENU_FORM.LINK_SUCCESS}
            name={EMENU_FORM.LINK_SUCCESS}
            value={watch(EMENU_FORM.LINK_SUCCESS)}
            extra=""
            register={register}
            errors={errors}
          />
          <InputField
            isRequired
            setValue={setValue}
            label={translate("payment_cancel")}
            placeholder={translate("payment_placeholder_cancel")}
            id={EMENU_FORM.LINK_CANCEL}
            name={EMENU_FORM.LINK_CANCEL}
            value={watch(EMENU_FORM.LINK_CANCEL)}
            extra=""
            register={register}
            errors={errors}
          />

          <InputField
            isRequired
            setValue={setValue}
            label={translate("payment_checkout")}
            placeholder={translate("payment_placeholder_checkout")}
            id={EMENU_FORM.LINK_RESULT}
            name={EMENU_FORM.LINK_RESULT}
            value={watch(EMENU_FORM.LINK_RESULT)}
            extra=""
            register={register}
            errors={errors}
          />
        </div>

        <PaymentMethod
          form={EMENU_FORM}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          register={register}
          watch={watch}
          setValue={setValue}
          errors={errors}
        />

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/staff")}
              title={translate("common:back")}
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title={translate("payment_update")}
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default CompanySettingEMenu;
