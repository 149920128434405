import { TRANSLATIONS } from '@constants/translations'

export const useTranslation = (defaultNs = 'default', manualLanguage) => {
  const language = ['vi', 'en'].includes(manualLanguage)
    ? manualLanguage
    : localStorage.getItem('language') || 'vi'

  const setLanguage = (language) => {
    localStorage.setItem('language', language)
    window.location.reload()
  }

  const translate = (key) => {
    const splitKey = key?.split(':')
    const [namespace, derivedKey] =
      splitKey?.length > 1 ? splitKey : [defaultNs, key]

    return TRANSLATIONS[language][namespace]?.[derivedKey] || key
  }

  return { translate, language, setLanguage }
}
