import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isCollapsed: false,
    isShow: true,
    activePath: '',
    currentOpenSubItems: ''
}

const sidebarSlice = createSlice({
    name: 'sidebarSlice',
    initialState,
    reducers: {
        setIsCollapsed: (state, action) => {
            state.isCollapsed = action.payload;
        },
        setIsShow: (state, action) => {
            state.isShow = action.payload;
        },
        setActivePath: (state, action) => {
            state.activePath = action.payload;
        },
        setCurrentOpenSubItems: (state, action) => {
            state.currentOpenSubItems = action.payload;
        }
    },
})


export const selectSidebar = (state) => state.sidebar
export const { setIsCollapsed, setIsShow, setActivePath, setCurrentOpenSubItems } = sidebarSlice.actions;


export default sidebarSlice.reducer;