import React, { useEffect } from "react";
import search from "@assets/images/create-table/search.svg";

import { QRCodeCanvas } from "qrcode.react";
import { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import CustomButton from "@components/buttons/CustomButton";
import ModalWrap from "@components/modals/ModalWrap";
import Skeleton from "@components/skeleton/MainSkeleton";
import useDebounce from "@hooks/useDebounce";

export const Search = ({ content, setInputSearch, tableName }) => {
  const [input, setInput] = useState("");
  const debounce = useDebounce(input);

  useEffect(() => {
    setInputSearch(debounce);
  }, [debounce]);

  return (
    <div className="flex items-center gap-4" style={{
      height: 53
    }}>
      {tableName && (
        <div className="flex items-center justify-center h-full py-2 px-4 border border-primary rounded-xl">
          <span className="text-base leading-5 font-semibold whitespace-no-wrap text-primary-text">{tableName}</span>
        </div>
      )}

      <div
        className="w-full h-full rounded-lg p-4 flex justify-between items-center bg-white shadow-primary"
      >
        <input
          type="text"
          placeholder={content}
          onChange={(e) => setInput(e.target.value)}
          className="bg-inherit outline-none w-full text-secondary-text text-sm font-normal"
        />
        <img src={search} alt="" onClick={() => setInputSearch(input)} />
      </div></div>
  );
};

export const Popup = ({
  icon,
  content,
  isOpen,
  onClose,
  showBtn = true,
  btnTitle = "Trở về màn hình chính",
  handleClickBtn,
}) => {
  return (
    <ModalWrap isOpen={isOpen} onClickBackdrop={onClose}>
      <div
        style={{ width: 328 }}
        className="relative bg-white px-6 py-8 rounded-2xl flex flex-col items-center"
      >
        <IoCloseSharp
          size={24}
          onClick={onClose}
          style={{
            position: "absolute",
            top: 16,
            right: 16,
            cursor: "pointer",
          }}
        />
        <img src={icon} alt="gif" className="h-20 w-auto mb-4" />
        <h2 className="text-primary-text text-base leading-5 font-medium mb-8">
          {content}
        </h2>
        {showBtn && (
          <CustomButton
            title={btnTitle}
            className="w-full"
            onClick={handleClickBtn}
          />
        )}
      </div>
    </ModalWrap>
  );
};

export const QrComponent = ({ data, isPayByLink, loading }) => {
  return isPayByLink ? (
    <div className='h-[200px] rounded-md border bg-white p-3 border-primary'>
      <Skeleton loading={loading}>
        <QRCodeCanvas id='qrCodeEl' value={data} size={170} />
      </Skeleton>
    </div>
  ) : (
    <div
      className='overflow-hidden border border-blackgray relative'
      style={{
        width: 180,
        height: 180,
        borderRadius: '14px',
      }}
    >
      <img className={`w-full h-full object-cover`} src={data} alt='' />
      {/* <img
        src={theme.favIcon} alt='favicon'
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        className='absolute bg-white p-1 w-10 h-10'
      /> */}
    </div>
  )
}
