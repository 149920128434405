/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { history } from "@helpers";
import { useDispatch, useSelector } from "react-redux";
import { getListStore } from "@actions/metadata.actions";
import { getListEDC } from "@actions/edc.actions";
import { selectListStore } from "@reducers/metadata.reducer";
import { selectListEDC } from "@reducers/edc.reducer";
import useCheck from "./useCheck";
import useEDC from "./useEDC";
import useOther from "./useOther";

import SectionTitle from "@components/section-title";
import CustomButton from "@components/buttons/CustomButton";
import SelectField from "@components/fields/SelectInPaymentField";
import UploadField from "@components/fields/upload/UploadField";
import useWindowSize from "@hooks/useWindowSize";
import { useTranslation } from "@hooks/useTranslation";
import { ALERT_TAB } from "@constants/feedback";

const CreateFeedback = () => {
  const dispatch = useDispatch();
  const temp = () => { };
  const { translate } = useTranslation();
  const { tab } = useParams();
  const vieTab = translate('feedback_subtitle')[tab]
  const check = useCheck({ temp, temp, temp });
  const edc = useEDC({ temp, temp, temp });
  const other = useOther({ temp, temp, temp });
  const tabList = [check, edc, other];
  const activeTab = tabList.find((item) => item.type === tab);
  const { isMd } = useWindowSize()
  const [loading, setLoading] = useState(false)

  const [payload, setPayload] = useState({
    store_id: "",
    content: "",
    description: "",
    serial_number: "",
    images: [],
  });

  const dataListStore = useSelector(selectListStore);
  const dataListEDC = useSelector(selectListEDC);

  const listStore = useMemo(() => {
    if (!dataListStore?.data) return [];
    return dataListStore?.data.map((store) => ({
      label: store.name,
      value: store.id,
    }));
  }, [dataListStore]);

  const listEDC = useMemo(() => {
    if (!dataListEDC?.data) return [];
    return dataListEDC?.data.map((edc) => ({ label: edc.serial_number, value: edc.serial_number }));
  }, [dataListEDC]);

  useEffect(() => {
    dispatch(getListStore());
    dispatch(getListEDC());
  }, [dispatch]);

  const [listField, setListField] = useState([]);

  useEffect(() => {
    const listField = [
      {
        title: translate("feedback_create_store_field"),
        data: listStore,
        placeHolder: translate("feedback_create_store_placeholder"),
        setValue: (store_id) => setPayload({ ...payload, store_id }),
      },
      {
        title: translate("feedback_create_content_field"),
        data: activeTab.listContent,
        placeHolder: translate("feedback_create_content_placeholder"),
        setValue: (content) => setPayload({ ...payload, content }),
      },
      {
        title: translate("feedback_create_edc_field"),
        data: listEDC,
        placeHolder: translate("feedback_create_edc_placeholder"),
        setValue: (serial_number) => setPayload({ ...payload, serial_number }),
      },
    ];
    // if (tab === "check") listField.splice(2, 1);
    if (tab === ALERT_TAB.OTHER) listField.splice(1, 1);
    setListField(listField);
  }, [tab, listStore, listEDC, payload]);

  const { store_id, description, images, content, serial_number } = payload
  const disabled = !store_id || !description || images.length === 0 || (!content && tab !== ALERT_TAB.OTHER) || (!serial_number && tab !== ALERT_TAB.CHECK) || loading
  const handleSubmit = async () => {
    setLoading(true)
    await activeTab.handleCreate(payload);
    setLoading(false)
  }

  return (
    <div>
      <SectionTitle
        title={translate("feedback_title")}
        subtitle={`${tab == ALERT_TAB.OTHER
          ? translate("feedback_create_issue_subtitle")
          : translate("feedback_create_request_subtitle")
          }`}
        rightTitle={vieTab}
      />

      <div className="mt-8 space-y-6">
        {/* Tab List */}
        <div
          className="bg-white rounded-xl flex shadow-primary"
          style={{
            width: isMd ? "max-content" : "100%",
            padding: 10,
          }}
        >
          {tabList.map((item) => (
            <button
              key={item.type}
              style={{
                width: isMd ? 'auto' : "100%",
                minWidth: isMd ? 163 : '33%',
                transition: "all 0.1s linear",
              }}
              onClick={() => history.push(`/feedback/${item.type}`)}
              className={`px-6 md:px-8 py-3 rounded text-sm leading-5 font-semibold text-center
              ${item.type == tab ? "bg-gradient text-white" : "text-blackgray"}`}
            >
              {translate("feedback_subtitle")[item.type]}
            </button>
          ))}
        </div>

        {/* Form */}
        <div
          className="bg-white p-10 rounded-2xl shadow-primary"
        >
          <div className="w-full grid md:grid-cols-2 gap-6">
            <div className="w-full flex flex-col gap-6">
              {listField.map((field) => (
                <div key={field.title} className="space-y-2">
                  <p className="text-blackgray text-sm leading-5 font-semibold">
                    {field.title}
                    <span className="text-error">*</span>
                  </p>
                  <SelectField
                    data={field.data.map((item) => ({
                      label: translate(item.label),
                      value: item.value
                    }))}
                    placeHolder={field.placeHolder}
                    setValue={field.setValue}
                  />
                </div>
              ))}
              {listField.length == 2 && (
                <div className="space-y-2">
                  <p className="text-blackgray text-sm leading-5 font-semibold">
                    {translate('feedback_table_description')}<span className="text-error">*</span>
                  </p>
                  <input
                    onChange={(e) =>
                      setPayload({ ...payload, description: e.target.value })
                    }
                    style={{ padding: "10px 16px" }}
                    placeholder={translate('feedback_create_description_placeholder')}
                    className="rounded-lg border border-gray-primary w-full text-blackgray text-sm leading-6 font-medium placeholder-hint"
                  />
                </div>
              )}
            </div>

            <div className="w-full flex flex-col gap-6">
              {/* Image Upload */}
              <UploadField
                label={translate("feedback_create_image_field")}
                isRequired
                filesPayload={payload.images}
                setFilesPayload={(files) =>
                  setPayload({ ...payload, images: files })
                }
              />
              {listField.length == 3 && (
                <div className="space-y-2">
                  <p className="text-blackgray text-sm leading-5 font-semibold">
                    {translate("feedback_create_description_field")}
                    <span className="text-error">*</span>
                  </p>
                  <input
                    onChange={(e) =>
                      setPayload({ ...payload, description: e.target.value })
                    }
                    style={{ padding: "10px 16px" }}
                    placeholder={translate(
                      "feedback_create_description_placeholder"
                    )}
                    className="rounded-lg border border-gray-primary w-full text-blackgray text-sm leading-6 font-medium placeholder-hint"
                  />
                </div>
              )}
            </div>
          </div>
          <div
            style={{ width: isMd ? 380 : "100%" }}
            className="grid grid-cols-2 gap-4 ml-auto mt-6"
          >
            <CustomButton
              title={translate("feedback_create_btn_back")}
              outline
              onClick={() => history.goBack()}
            />
            <CustomButton
              disabled={disabled}
              title={translate("feedback_create_btn_submit")}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFeedback;
