import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getListCustomer, getListProduct } from "@actions/index.js";
import { getListAccountNumber } from "@actions/metadata.actions.js";
import { getListOrder } from "@actions/order.actions.js";
import { history } from "@helpers/history.js";

const useSearchParams = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const updateSearch = useCallback(() => {
    history.push(`${location.pathname}?${queryParams.toString()}`);
  }, [history, location.pathname, queryParams]);

  const setSearchQuery = (searchTerm) => {
    if (searchTerm && queryParams.get("q") !== searchTerm?.toLowerCase()) {
      queryParams.set("q", searchTerm?.toLowerCase());
      updateSearch();
    } else {
      queryParams.delete("q");
      updateSearch();
    }
  };

  const updatePagination = useCallback(
    (query) => {
      queryParams.get("q")
        ? history.push(`${location.pathname}?${query.toString()}&q=${queryParams.get("q")}`)
        : history.push(`${location.pathname}?${query.toString()}`);
    },
    [history, location.pathname, queryParams]
  );

  useEffect(() => {
    const getData = () => {
      if (location.pathname === "/sale-product") {
        dispatch(getListProduct(queryParams));
      }
      if (location.pathname === "/sale-customer") {
        dispatch(getListCustomer(queryParams));
      }
      if (location.pathname === "/sale-order") {
        dispatch(getListOrder(queryParams));
      }
      if (location.pathname === "/bank") {
        dispatch(getListAccountNumber(queryParams));
      }
    };
    if (location.search && queryParams.get("page") && queryParams.get("page_size")) {
      const interval = getData();
      return () => clearInterval(interval);
    }
  }, [dispatch, location.pathname, location.search, queryParams]);

  return useMemo(() => {
    return {
      filtersParams: {
        page: queryParams.get("page") || 1,
        page_size: queryParams.get("page_size") || 10,
      },
      setSearchQuery,
      updatePagination,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSearchParams;
