import { yupResolver } from "@hookform/resolvers/yup";
import md5 from "md5";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as yup from "yup";
import { getStaffById, updateStaff } from "@actions/staff.actions";
import CustomButton from "@components/buttons/CustomButton";
import InputField from "@components/fields/InputField";
import InputFieldPw from "@components/fields/InputFieldPw";
import SelectStoreField from "@components/fields/SelectStoreField";
import SectionTitle from "@components/section-title";
import { useTranslation } from "@hooks/useTranslation";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";
import { history } from "@helpers";
import TableWrapper from "../profile/TableWrapper";
import { INITIAL_EMPLOYEE, UPDATE_STAFF_FORM } from "@constants/business/staff";

const UpdateStaff = () => {
  const { translate, language } = useTranslation('business');
  const location = useLocation();
  const id = new URLSearchParams(location.search)?.get("id");

  const schema = yup.object().shape({
    [UPDATE_STAFF_FORM.FULL_NAME]: yup.string().matches(/^[\p{L}\s]+$/u, translate('staff_name_invalid')),
    [UPDATE_STAFF_FORM.PHONE_NUMBER]: yup
      .string()
      .matches(/\S/, translate("sale_customer:no_white_space"))
      .matches(
        /^(0[3-9][0-9]{8}|05[5|6|7|8|9][0-9]{7}|(84|\+84)[3-9][0-9]{8})$/,
        translate("sale_customer:customer_phone_invalid")
      ),
    [UPDATE_STAFF_FORM.PASSWORD]: yup.string().nullable().min(6, translate('staff_pw_6char')),
  });

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [staff, setStaff] = useState(INITIAL_EMPLOYEE);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = async (value) => {
    setLoading(true)
    let raw = { ...value, id: staff.id };

    if (value[UPDATE_STAFF_FORM.OLD_PASSWORD] && value[UPDATE_STAFF_FORM.PASSWORD]) {
      raw = {
        ...raw,
        old_password: md5(value?.old_password),
        password: md5(value?.password),
      };
    }

    try {
      const res = await dispatch(updateStaff(raw));
      if (res?.meta?.requestStatus === "fulfilled") {
        history.goBack();
        CustomToastMessage.success(
          translate("edit_staff_title_success"),
          `${translate("edit_staff_desc")} ${value?.full_name}`,
        );
      } else
        CustomToastMessage.error(translate("edit_staff_title_fail"), ERROR_CODE[res?.payload?.error_code][language]);
    } catch (err) { console.log(err) }
    setLoading(false)
  };

  const disabled = !watch(UPDATE_STAFF_FORM.FULL_NAME) || !watch(UPDATE_STAFF_FORM.PHONE) || loading;

  const getStaff = async () => {
    const res = await dispatch(getStaffById(id));
    if (res?.meta?.requestStatus === "fulfilled") {
      const data = res?.payload?.data;
      setStaff(data);
      setValue(UPDATE_STAFF_FORM.EMAIL, data?.email);
      setValue(UPDATE_STAFF_FORM.FULL_NAME, data?.full_name);
      setValue(UPDATE_STAFF_FORM.PHONE, data?.phone);
      setValue(UPDATE_STAFF_FORM.STORE_ID, data?.store_id);
      setValue(UPDATE_STAFF_FORM.STAFF_ID, data?.staff_id);
    }
  };

  useEffect(() => {
    getStaff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <SectionTitle
        title={translate("business_info")}
        subtitle={translate("edit_staff_title")}
        rightTitle={translate("staff_manager")}
      />
      <TableWrapper>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <InputField
            disabled
            isRequired
            label={translate("staff_manager_id")}
            placeholder={translate("staff_placeholder_manager_id")}
            setValue={setValue}
            id={UPDATE_STAFF_FORM.STAFF_ID}
            name={UPDATE_STAFF_FORM.STAFF_ID}
            value={watch(UPDATE_STAFF_FORM.STAFF_ID)}
            defaultValue={staff[UPDATE_STAFF_FORM.STAFF_ID]}
            register={register}
            errors={errors}
          />
          <InputField
            isRequired
            label={translate("staff_manager_full_name")}
            placeholder={translate("staff_placeholder_full_name")}
            id={UPDATE_STAFF_FORM.FULL_NAME}
            name={UPDATE_STAFF_FORM.FULL_NAME}
            value={watch(UPDATE_STAFF_FORM.FULL_NAME)}
            defaultValue={staff[UPDATE_STAFF_FORM.FULL_NAME]}
            setValue={setValue}
            register={register}
            errors={errors}
          />

          <SelectStoreField
            initValue={staff[UPDATE_STAFF_FORM.STORE_ID]}
            isRequired
            register={register}
            name={UPDATE_STAFF_FORM.STORE_ID}
            setValue={setValue}
            errors={errors}
          />

          <InputField
            isRequired
            value={watch(UPDATE_STAFF_FORM.PHONE)}
            defaultValue={staff[UPDATE_STAFF_FORM.PHONE]}
            setValue={setValue}
            type="number"
            label={translate("staff_manager_phone_number")}
            placeholder={translate("staff_manager_placeholder_phone_number")}
            id={UPDATE_STAFF_FORM.PHONE}
            name={UPDATE_STAFF_FORM.PHONE}
            register={register}
            errors={errors}
          />
          <InputField
            disabled
            isRequired
            value={watch(UPDATE_STAFF_FORM.EMAIL)}
            defaultValue={staff[UPDATE_STAFF_FORM.EMAIL]}
            setValue={setValue}
            label={translate("staff_manager_email")}
            placeholder={translate("staff_manager_placeholder_email")}
            id={UPDATE_STAFF_FORM.EMAIL}
            name={UPDATE_STAFF_FORM.EMAIL}
            register={register}
            errors={errors}
          />

          <div />

          <div className="flex flex-col">
            <InputFieldPw
              setValue={setValue}
              label={translate("common:update") + ' ' + translate('staff_manager_password').toLowerCase()}
              placeholder={translate('staff_enter_old_pw')}
              id={UPDATE_STAFF_FORM.OLD_PASSWORD}
              name={UPDATE_STAFF_FORM.OLD_PASSWORD}
              register={register}
              errors={errors}
            />
            <InputFieldPw
              setValue={setValue}
              placeholder={translate('staff_enter_new_pw')}
              id={UPDATE_STAFF_FORM.PASSWORD}
              name={UPDATE_STAFF_FORM.PASSWORD}
              extra="mt-2"
              register={register}
              errors={errors}
            />
            <p className="mt-4 font-medium text-14px leading-5 text-grey-atom-grey">
              {translate('staff_note')}: {translate('staff_pw_6char')}
            </p>
          </div>
        </div>

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.goBack()}
              title={translate('common:back')}
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton type="submit" disabled={disabled} title={translate('common:update')} className="w-full" />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default UpdateStaff;
