/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from '@hooks/useTranslation'
import {
  selectIsLoading,
  selectProfile,
} from '@reducers/user.reducer'

import Emenu from './Emenu'
import PaymentSetting from './PaymentSetting'
import Reward from './Reward'

const SellSetting = () => {
  const { is_fnb } = useSelector(selectProfile)
  const isFetching = useSelector(selectIsLoading)
  const { translate } = useTranslation('sale_settings')

  const TAB = useMemo(
    () => [
      ...(is_fnb
        ? [
          {
            id: 0,
            title: translate('digital_menu'),
          },
        ]
        : []
      ),
      {
        id: 1,
        title: translate('sale_customer:reward_point'),
      },
      ...(is_fnb
        ? [
          {
            id: 2,
            title: translate('payment_setting'),
          },
        ]
        : []
      ),
    ],
    [is_fnb]
  )

  const [tab, setTab] = useState()

  useEffect(() => {
    setTab(TAB[0].id)
  }, [TAB])

  if (isFetching) return null

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='flex items-center justify-center py-8'>
        {TAB.map(({ title, id }, index) => {
          const backgroundTab = tab === id ? 'bg-gradient' : ''
          const borderLeftTab = index === 0 && 'rounded-tl-xl rounded-bl-xl '
          const borderRightTab =
            index === TAB.length - 1 && 'rounded-tr-xl rounded-br-xl'
          const colorText = tab === id ? 'text-white ' : 'text-label-field '

          return (
            <div
              key={id}
              style={{ padding: '10px 35px' }}
              className={`flex items-center justify-center cursor-pointer border border-gray-primary overflow-hidden ${backgroundTab} ${borderLeftTab} ${borderRightTab}`}
              onClick={() => setTab(id)}
            >
              <p className={`text-sm font-semibold leading-5 text-secondary `}>
                {title}
              </p>
            </div>
          )
        })}
      </div>

      {tab === 0 && <Emenu />}
      {tab === 1 && <Reward />}
      {tab === 2 && <PaymentSetting />}
    </div>
  )
}

export default SellSetting
