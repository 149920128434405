import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { history } from "@helpers";
import { useDispatch, useSelector } from "react-redux";
import { getListStore } from "@actions/metadata.actions";
import { getListEDC } from "@actions/edc.actions";
import { selectListStore } from "@reducers/metadata.reducer";
import { selectListEDC } from "@reducers/edc.reducer";
import { getFeedbackById } from "@actions";
import { selectFeedbackItem } from "@reducers/feedback.reducer";
import useCheck from "./useCheck";
import useEDC from "./useEDC";
import useOther from "./useOther";

import SectionTitle from "@components/section-title";
import CustomButton from "@components/buttons/CustomButton";
import SelectField from "@components/fields/SelectInPaymentField";
import UploadField from "@components/fields/upload/UploadField";
import { useTranslation } from "@hooks/useTranslation";
import useWindowSize from "@hooks/useWindowSize";

const UpdateFeedback = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const temp = () => { };
  const { tab, id } = useParams();
  const check = useCheck({ temp, temp, temp });
  const edc = useEDC({ temp, temp, temp });
  const other = useOther({ temp, temp, temp });
  const tabList = [check, edc, other];
  const activedTab = tabList.find((item) => item.type == tab);
  const { isMd } = useWindowSize()

  const [payload, setPayload] = useState({
    id,
    store_id: "",
    content: "",
    description: "",
    serial_number: "",
    images: [],
  });

  const dataListStore = useSelector(selectListStore);
  const dataListEDC = useSelector(selectListEDC);
  const dataFeedback = useSelector(selectFeedbackItem);

  const listStore = useMemo(() => {
    if (!dataListStore?.data) return [];
    return dataListStore?.data.map((store) => ({ label: store.name, value: store.id }));
  }, [dataListStore]);

  const listEDC = useMemo(() => {
    if (!dataListEDC?.data) return [];
    return dataListEDC?.data.map((edc) => ({ label: edc.serial_number, value: edc.serial_number }));
  }, [dataListEDC]);

  const feedbackItem = useMemo(() => {
    if (!dataFeedback?.data?.items[0]) return null;
    return dataFeedback?.data?.items[0];
  }, [dataFeedback]);

  useEffect(() => {
    dispatch(getListStore());
    dispatch(getListEDC());
    dispatch(getFeedbackById({ type: tab.toUpperCase(), id }));
  }, [dispatch]);

  const [listField, setListField] = useState([]);
  useEffect(() => {
    // const store = listStore.find(store => store.value == payload?.store)
    const listField = [
      {
        title: "Cửa hàng",
        data: listStore,
        placeHolder: payload?.store_name,
        setValue: (store_id) => setPayload({ ...payload, store_id }),
      },
      {
        title: "Nội dung",
        data: activedTab.listContent,
        placeHolder: payload?.content,
        setValue: (content) => setPayload({ ...payload, content }),
      },
      {
        title: "Mã EDC",
        data: listEDC,
        placeHolder: payload?.serial_number,
        setValue: (serial_number) => setPayload({ ...payload, serial_number }),
      },
    ];
    if (tab == "check") listField.splice(2, 1);
    if (tab == "other") listField.splice(1, 1);
    setListField(listField);
  }, [tab, listStore, listEDC, payload]);

  useEffect(() => {
    setPayload({ ...feedbackItem, store_id: feedbackItem?.store });
  }, [feedbackItem]);

  const { store_id, description, images, content, serial_number } = payload;
  const disabled =
    !store_id ||
    !description ||
    images.length == 0 ||
    (!content && tab != "other") ||
    (!serial_number && tab != "check");

  const onFormSubmit = () => activedTab.handleUpdate(payload);

  return (
    <div>
      <SectionTitle
        title={translate("feedback_title")}
        subtitle={`${translate("edit")} ${translate("feedback_subtitle")[tab]} ${tab == "other" ? translate("feedback_error_issue") : translate("feedback_error_request")
          }`}
        rightTitle={translate("feedback_subtitle")[tab]}
      />

      <div className="mt-8 space-y-6">
        {/* Tab List */}
        <div
          className="bg-white rounded-xl flex shadow-primary"
          style={{
            width: "min-content",
            padding: 10,
          }}
        >
          {tabList.map((item) => (
            <button
              key={item.type}
              style={{
                width: isMd ? 'auto' : "100%",
                minWidth: isMd ? 163 : '33%',
                transition: "all 0.1s linear",
              }}
              onClick={() => history.push(`/feedback/${item.type}`)}
              className={`px-8 py-3 rounded text-sm leading-5 font-semibold text-center
              ${item.type == tab ? "bg-gradient text-white" : "text-blackgray"}`}
            >
              {translate("feedback_subtitle")[item.type]}
            </button>
          ))}
        </div>

        {/* Form */}
        <form
          className="bg-white p-10 rounded-2xl shadow-primary"
        >
          <div className="w-full grid grid-cols-2 gap-6">
            <div className="w-full flex flex-col gap-6">
              {listField.map((field) => (
                <div key={field.title} className="space-y-2">
                  <p className="text-blackgray text-sm leading-5 font-semibold">
                    {field.title}
                    <span className="text-error">*</span>
                  </p>
                  <SelectField
                    data={field.data.map((item) => ({
                      label: translate(item.label),
                      value: item.value
                    }))}
                    placeHolder={field.placeHolder}
                    setValue={field.setValue}
                  />
                </div>
              ))}
              {listField.length == 2 && (
                <div className="space-y-2">
                  <p className="text-blackgray text-sm leading-5 font-semibold">
                    {translate("feedback_table_description")}
                    <span className="text-error">*</span>
                  </p>
                  <input
                    onChange={(e) => setPayload({ ...payload, description: e.target.value })}
                    value={payload.description || ""}
                    style={{ padding: "10px 16px" }}
                    placeholder={translate("feedback_create_description_placeholder")}
                    className="rounded-lg border border-gray-primary w-full text-blackgray text-sm leading-6 font-medium placeholder-hint"
                  />
                </div>
              )}
            </div>

            <div className="w-full flex flex-col gap-6">
              {/* Image Upload */}
              <UploadField
                label="Hình ảnh"
                isRequired
                filesPayload={payload.images}
                setFilesPayload={(files) => setPayload({ ...payload, images: files })}
                oldImages={feedbackItem?.images}
              />
              {listField.length == 3 && (
                <div className="space-y-2">
                  <p className="text-blackgray text-sm leading-5 font-semibold">
                    {translate("feedback_table_description")}
                    <span className="text-error">*</span>
                  </p>
                  <input
                    onChange={(e) => setPayload({ ...payload, description: e.target.value })}
                    value={payload.description || ""}
                    style={{ padding: "10px 16px" }}
                    placeholder={translate("feedback_create_description_placeholder")}
                    className="rounded-lg border border-gray-primary w-full text-blackgray text-sm leading-6 font-medium placeholder-hint"
                  />
                </div>
              )}
            </div>
          </div>
          <div style={{ width: 380 }} className="grid grid-cols-2 gap-4 ml-auto mt-6">
            <CustomButton
              title={translate("common:back")}
              outline
              onClick={() => history.push(`/feedback/${tab}`)}
            />
            <CustomButton
              disabled={disabled}
              title={translate("common:update")}
              onClick={onFormSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateFeedback;
