import { createSlice } from "@reduxjs/toolkit"
import { getListOrder, createOrder, updateOrder } from "@actions/order.actions"

const initialState = {
    listOrder: [],
    isLoading: false,
}

const orderSlice = createSlice({
    name: 'orderSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getListOrder.fulfilled, (state, action) => {
                state.listOrder = action.payload;
            })
            .addCase(getListOrder.rejected, (state, action) => {
                state.listOrder = [];
            })
    }
})

export const selectListOrder = state => state.order.listOrder;
export const selectIsLoading = state => state.order.isLoading;

export default orderSlice.reducer;