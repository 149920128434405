import React from 'react';
import { useSelector } from 'react-redux';
import qr from '@assets/images/product/img-qr.svg';
import CustomButton from '@components/buttons/CustomButton';
import { theme } from 'configTheme';
import { history } from '@helpers';
import { useTranslation } from '@hooks/useTranslation';
import { selectMerchant } from '@reducers/sass.reducer';
import { createOrder } from '@services/table.service';
import { CustomToastMessage } from '@utils/customToastMsg';
import { formatCurrency } from '@utils/lib';
import TableNameBox from './TableNameBox';

export const TableItem = ({
  item,
  setTableItem,
  setShowPopupUpdate,
  setShowPopupQR,
}) => {
  const { translate } = useTranslation('sale_table');
  const { website } = useSelector(selectMerchant)

  const handleCreateOrder = async () => {
    const res = await createOrder({
      customer_id: 0,
      table_id: item.table_id,
    });
    if (res.code === 201) {
      history.push(`/sale-order/${res.data.order_id}`);
    } else CustomToastMessage.error('Create Order Fail')
  };

  const handleShowModalUpdate = () => {
    setTableItem(item);
    setShowPopupUpdate(true);
  };

  const handleNavigateDetail = () => {
    history.push(`/sale-order/${item.order_id}`);
  };

  return (
    <div style={{ height: 172 }} className='pb-6 rounded-xl shadow-primary bg-white flex flex-col items-center justify-between w-full'>
      <div className="flex justify-between items-start w-full">
        <button
          type='button'
          className='rounded-lg w-10 h-10 relative items-center justify-center'
          style={{ backgroundColor: '#F8F8F8', margin: 6 }}
          onClick={item.order_id ? handleNavigateDetail : handleShowModalUpdate}
        >
          <div className='absolute w-6' style={{ top: 8, left: 8 }}>
            {item.order_id ? (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.5015 16.6395C22.5435 12.936 20.679 9.4935 17.487 7.5435C16.401 6.8805 15.225 6.453 14.0235 6.216C14.1645 5.922 14.25 5.5965 14.25 5.25C14.25 4.0095 13.2405 3 12 3C10.7595 3 9.75 4.0095 9.75 5.25C9.75 5.5965 9.8355 5.922 9.9765 6.216C8.775 6.453 7.599 6.879 6.513 7.542C3.321 9.492 1.4565 12.9345 1.4985 16.638C0.6285 16.95 0 17.7735 0 18.75C0 19.9905 1.0095 21 2.25 21H21.75C22.9905 21 24 19.9905 24 18.75C24 17.7735 23.3715 16.95 22.5015 16.6395ZM12 4.5C12.4125 4.5 12.75 4.8375 12.75 5.25C12.75 5.655 12.426 5.982 12.024 5.9955C12.0075 5.9955 11.991 5.9955 11.976 5.9955C11.574 5.982 11.25 5.655 11.25 5.25C11.25 4.8375 11.5875 4.5 12 4.5ZM7.2945 8.823C8.7345 7.944 10.347 7.503 11.961 7.497C11.9745 7.4955 11.9865 7.5 12 7.5C12.0135 7.5 12.0255 7.497 12.039 7.4955C13.653 7.503 15.2655 7.9425 16.704 8.8215C19.407 10.473 21 13.371 21.0045 16.5H2.9955C3 13.371 4.593 10.473 7.2945 8.823ZM21.75 19.5H2.25C1.8375 19.5 1.5 19.1625 1.5 18.75C1.5 18.3375 1.8375 18 2.25 18H21.75C22.1625 18 22.5 18.3375 22.5 18.75C22.5 19.1625 22.1625 19.5 21.75 19.5Z" fill={theme.secondary} />
                <path d="M17.5845 11.4945C16.1625 9.909 14.1285 9 12 9C11.586 9 11.25 9.336 11.25 9.75C11.25 10.164 11.586 10.5 12 10.5C13.7025 10.5 15.3315 11.2275 16.4685 12.4965C16.617 12.6615 16.821 12.7455 17.0265 12.7455C17.205 12.7455 17.3835 12.6825 17.5275 12.5535C17.835 12.276 17.862 11.8035 17.5845 11.4945Z" fill={theme.secondary} />
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C16.3556 22 20.0584 19.2159 21.4307 15.3332C21.6148 14.8125 21.3418 14.2412 20.8211 14.0572C20.3004 13.8731 19.7291 14.146 19.545 14.6668C18.4463 17.7753 15.4817 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C13.5516 4 14.8662 4.56117 16.1162 5.4406C16.9569 6.03212 17.7348 6.74106 18.5242 7.5H15.5C14.9477 7.5 14.5 7.94772 14.5 8.5C14.5 9.05228 14.9477 9.5 15.5 9.5H21C21.5523 9.5 22 9.05228 22 8.5V3C22 2.44772 21.5523 2 21 2C20.4477 2 20 2.44772 20 3V6.14371C19.1517 5.32583 18.2456 4.49337 17.267 3.80489C15.7949 2.76916 14.0847 2 12 2Z" fill="#B1B1B1" />
              </svg>
            )}
          </div>

          {/* Badge */}
          {item.order_id && (
            <span
              className='rounded-full p-1 flex items-center justify-center w-4 h-4 absolute top-0 right-0'
              style={{
                backgroundColor: '#ED1C24',
                transform: 'translate(25%, -25%)',
              }}
            >
              <span className='absolute text-white font-normal text-xs leading-5'>
                {item?.product_amount || 0}
              </span>
            </span>
          )}
        </button>

        <TableNameBox color={item.order_id ? theme.secondary : theme.primary} name={item.table_name} />

        <img
          style={{ margin: 6, width: 40, height: 40, cursor: 'pointer' }} src={qr} alt='qr-icon'
          onClick={() => {
            if (website) {
              setTableItem(item);
              setShowPopupQR(true);
            } else CustomToastMessage.info(translate('require_setting_emenu_website'))
          }}
        />
      </div>

      <div className='w-full flex justify-center'>
        {!item.order_id ? (
          <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3236_30909)">
              <path d="M37.6992 25.4144C37.8969 25.0294 38.0001 24.6039 38.0001 24.1636C38.0001 22.648 36.7671 21.415 35.2515 21.415H4.56691C3.05135 21.415 1.81836 22.648 1.81836 24.1636C1.81836 25.5513 2.85238 26.7014 4.19024 26.8856L4.91725 29.3576C5.31255 30.7015 6.56691 31.6402 7.96776 31.6402H22.8838C23.1149 31.6402 23.3022 31.4528 23.3022 31.2218C23.3022 30.9908 23.1149 30.8034 22.8838 30.8034H7.96776C6.93563 30.8034 6.01135 30.1118 5.72007 29.1216L5.07024 26.9122H28.2741C27.4585 28.0294 26.9756 29.4041 26.9756 30.8901C26.9756 34.6189 30.0092 37.6525 33.7379 37.6525C37.4667 37.6525 40.5003 34.6189 40.5003 30.8901C40.5003 28.6399 39.3947 26.6444 37.6992 25.4144ZM33.7379 36.8155C30.4706 36.8155 27.8124 34.1573 27.8124 30.89C27.8101 27.6237 30.4788 24.9613 33.7379 24.9644C36.919 24.9382 39.6855 27.6055 39.6635 30.89C39.6635 34.1573 37.0053 36.8155 33.7379 36.8155Z" fill={theme.primary} />
              <path d="M6.1538 20.2999C6.16705 20.3011 6.18021 20.3018 6.19329 20.3018C6.40679 20.3018 6.5891 20.1391 6.60935 19.9223C7.14559 14.1888 11.177 9.62612 16.4991 8.24133C18.7177 7.66928 21.0906 7.66278 23.309 8.24073C28.596 9.61783 32.6255 14.1408 33.1994 19.8232C33.2227 20.0531 33.4282 20.2209 33.6577 20.1975C33.8876 20.1742 34.0553 19.9691 34.032 19.7392C33.68 16.2544 32.055 13.0321 29.4563 10.6657C27.8165 9.17261 25.8729 8.09996 23.7794 7.50706V6.21766C23.7794 4.08372 22.0432 2.34766 19.9093 2.34766C17.7753 2.34766 16.0393 4.08381 16.0393 6.21766V7.50689C13.9298 8.10415 11.9733 9.18825 10.3253 10.6994C7.71995 13.0885 6.1044 16.3363 5.77628 19.8444C5.75465 20.0744 5.92371 20.2783 6.1538 20.2999Z" fill={theme.primary} />
              <path d="M25.1522 31.8074C25.4758 31.8074 25.738 31.5451 25.738 31.2216C25.738 30.898 25.4758 30.6357 25.1522 30.6357C24.8287 30.6357 24.5664 30.898 24.5664 31.2216C24.5664 31.5451 24.8287 31.8074 25.1522 31.8074Z" fill={theme.primary} />
              <path d="M37.363 30.4718H34.1562V27.2651C34.1562 27.034 33.9689 26.8467 33.7378 26.8467C33.5068 26.8467 33.3195 27.034 33.3195 27.2651V30.4718H30.1127C29.8817 30.4718 29.6943 30.6592 29.6943 30.8902C29.6943 31.1212 29.8817 31.3086 30.1127 31.3086H33.3195V34.5153C33.3195 34.7463 33.5068 34.9337 33.7378 34.9337C33.9689 34.9337 34.1562 34.7463 34.1562 34.5153V31.3086H37.363C37.594 31.3086 37.7813 31.1212 37.7813 30.8902C37.7813 30.6592 37.5941 30.4718 37.363 30.4718Z" fill={theme.primary} />
              <path d="M9.59636 6.80959C9.71252 7.01155 9.97132 7.07728 10.1679 6.96275C10.3681 6.84719 10.4366 6.59129 10.3211 6.39121L8.9244 3.97198C8.80884 3.77189 8.55303 3.70326 8.35286 3.81882C8.15269 3.93437 8.08414 4.19027 8.19969 4.39035L9.59636 6.80959Z" fill={theme.primary} />
              <path d="M3.33725 13.6399C3.74819 13.6556 3.91751 13.0563 3.54682 12.8591L1.12768 11.4623C0.927507 11.3469 0.67161 11.4154 0.55614 11.6155C0.440584 11.8157 0.509131 12.0715 0.709302 12.187L3.12853 13.5838C3.19434 13.6218 3.26631 13.6399 3.33725 13.6399Z" fill={theme.primary} />
              <path d="M36.48 13.64C36.551 13.64 36.6229 13.6219 36.6888 13.5838L39.1081 12.1871C39.3082 12.0715 39.3768 11.8156 39.2612 11.6155C39.1457 11.4154 38.89 11.3468 38.6897 11.4624L36.2705 12.8591C35.8997 13.0563 36.0693 13.6556 36.48 13.64Z" fill={theme.primary} />
              <path d="M29.6497 6.96275C29.8463 7.07728 30.1051 7.01155 30.2213 6.80959L31.618 4.39035C31.7336 4.19018 31.665 3.93437 31.4649 3.81882C31.2647 3.70326 31.0088 3.77189 30.8933 3.97198L29.4966 6.39121C29.381 6.59129 29.4496 6.84719 29.6497 6.96275Z" fill={theme.primary} />
            </g>
            <defs>
              <clipPath id="clip0_3236_30909">
                <rect width="40" height="40" fill="white" transform="translate(0.5)" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <p className='text-sm text-blackgray leading-5 font-semibold'>
            {translate('total_bill')}:{' '}
            <span className='text-base leading-6 text-secondary'>{formatCurrency(item?.total_price)}</span>
          </p>
        )}
      </div>

      <CustomButton
        outline
        style={{
          borderColor: item.order_id ? theme.secondary : theme.primary,
          color: item.order_id ? theme.secondary : theme.primary,
          width: 'max(263px, 80%)',
          height: 40
        }}
        title={translate(
          item.order_id
            ? 'sale_settings:view_detail'
            : 'create_bill'
        )}
        onClick={item.order_id ? handleNavigateDetail : handleCreateOrder}
        leftIcon={
          item.order_id ? (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.33301 1.33301H3.99967C2.52701 1.33301 1.33301 2.52701 1.33301 3.99967V11.9997C1.33301 13.4723 2.52701 14.6663 3.99967 14.6663H11.9997C13.4723 14.6663 14.6663 13.4723 14.6663 11.9997C14.6663 10.4857 14.6663 8.66634 14.6663 8.66634C14.6663 8.29834 14.3677 7.99967 13.9997 7.99967C13.6317 7.99967 13.333 8.29834 13.333 8.66634V11.9997C13.333 12.7357 12.7357 13.333 11.9997 13.333C9.77967 13.333 6.21901 13.333 3.99967 13.333C3.26301 13.333 2.66634 12.7357 2.66634 11.9997C2.66634 9.77967 2.66634 6.21901 2.66634 3.99967C2.66634 3.26301 3.26301 2.66634 3.99967 2.66634H7.33301C7.70101 2.66634 7.99967 2.36767 7.99967 1.99967C7.99967 1.63167 7.70101 1.33301 7.33301 1.33301ZM12.3903 2.66634H9.99967C9.63167 2.66634 9.33301 2.36767 9.33301 1.99967C9.33301 1.63167 9.63167 1.33301 9.99967 1.33301H13.9997C14.3677 1.33301 14.6663 1.63167 14.6663 1.99967V5.99967C14.6663 6.36767 14.3677 6.66634 13.9997 6.66634C13.6317 6.66634 13.333 6.36767 13.333 5.99967V3.60901L8.47101 8.47101C8.21101 8.73101 7.78834 8.73101 7.52834 8.47101C7.26767 8.21101 7.26767 7.78834 7.52834 7.52834L12.3903 2.66634Z" fill="curentColor" />
            </svg>
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
            >
              <path
                d='M7.53125 2.375H8.46875C8.55208 2.375 8.59375 2.41667 8.59375 2.5V13.5C8.59375 13.5833 8.55208 13.625 8.46875 13.625H7.53125C7.44792 13.625 7.40625 13.5833 7.40625 13.5V2.5C7.40625 2.41667 7.44792 2.375 7.53125 2.375Z'
                fill={'currentColor'}
              />
              <path
                d='M2.75 7.40625H13.25C13.3333 7.40625 13.375 7.44792 13.375 7.53125V8.46875C13.375 8.55208 13.3333 8.59375 13.25 8.59375H2.75C2.66667 8.59375 2.625 8.55208 2.625 8.46875V7.53125C2.625 7.44792 2.66667 7.40625 2.75 7.40625Z'
                fill={'currentColor'}
              />
            </svg>
          )
        }
      />
    </div>
  );
};
