import React, { useEffect } from 'react';
import Invoice from '../Invoice';
import { QrComponent } from '../../../order/Common';

const QRScan = ({ invoice, qr, setQrData }) => {
  useEffect(() => {
    setQrData({ qr })
  }, [qr])

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex flex-col items-center justify-center gap-3'>
        <QrComponent data={qr} />
      </div>

      <Invoice invoice={invoice} />
    </div>
  );
};

export default QRScan;
