import React, { useEffect } from 'react'
import { Router } from 'react-router-dom'
import 'react-tabs/style/react-tabs.css'
import { ToastContainer } from 'react-toastify'
import Routes from './Routes'
import './css/_components.css'
import './css/animate.css'
import './css/main.css'
import './css/tailwind.css'
import Layouts from './layouts'

import CheckCookie from './components/common/CheckCookie'
import { theme } from './configTheme'
import { history } from './helpers'

const App = () => {
  useEffect(() => {
    document.documentElement.style.setProperty('--primary', theme.primary)
    document.documentElement.style.setProperty('--secondary', theme.secondary)
    document.documentElement.style.setProperty('--sidebar', theme.sidebar)
    document.documentElement.style.setProperty(
      '--sidebar-hover',
      theme.sidebarHover
    )
    document.documentElement.style.setProperty(
      '--select-hover',
      theme.selectHover
    )
    document.documentElement.style.setProperty(
      '--text-primary',
      theme.textPrimary
    )
    document.documentElement.style.setProperty(
      '--text-secondary',
      theme.textSecondary
    )
    document.documentElement.style.setProperty('--error', theme.error)

    const favicon = document.getElementById('favicon')
    favicon.href = window.location.origin + theme.favIcon

    const language = localStorage.getItem('language')
    if (!language)
      localStorage.setItem('language', process.env.REACT_APP_DEFAULT_LANGUAGE)

    const hideSellManagement = localStorage.getItem('hideSellManagement')
    if (!hideSellManagement) localStorage.setItem('hideSellManagement', false)
  }, [])

  return (
    <div className='relative'>
      <Router history={history} basename='/portal'>
        <Layouts>
          <Routes />
        </Layouts>
        <ToastContainer
          hideProgressBar
          closeButton={false}
          position='bottom-right'
          autoClose={2400}
          icon={false}
          limit={3}
        />
      </Router>

      <CheckCookie />
    </div>
  )
}
export default App
