import axiosClient from "./client_axios";
import {
  DELETE_SETTING_TX,
  GET_LIST_BANK_ACCQUIRE_SETTING_TX,
  GET_LIST_CARD_SETTING_TX,
  GET_LIST_SETTING_TX,
} from "@constants/endpoint";

export const settingTxServices = {
  getListSettingTx: (params) => {
    return axiosClient.get(GET_LIST_SETTING_TX, { params });
  },
  getListRule: (params) => {
    return axiosClient.get(GET_LIST_SETTING_TX, { params });
  },
  getListCard: (params) => {
    return axiosClient.get(GET_LIST_CARD_SETTING_TX, { params });
  },
  getBankRelease: (params) => {
    return axiosClient.get(GET_LIST_BANK_ACCQUIRE_SETTING_TX, { params });
  },

  
  getBankHandle: (params) => {
    return axiosClient.get(GET_LIST_BANK_ACCQUIRE_SETTING_TX, { params });
  },

  getSettingTxById: (id) => {
    return axiosClient.get(`${GET_LIST_SETTING_TX}?id=${id}`);
  },

  updateSettingTx: ({ rule_name = "", card_type = "", issuer = "", acquirer = "" }) => {
    return axiosClient.put(
      `/metadata/rule?rule_name=${rule_name}&card_type=${card_type}&issuer=${issuer}&acquirer=${acquirer}`
    );
  },

  deleteSettingTx: (params) => {
    return axiosClient.delete(DELETE_SETTING_TX, params);
  },
};
