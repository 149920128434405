import { createAsyncThunk } from "@reduxjs/toolkit";
import { feedbackServices } from "@services";

export const getFeedbackById = createAsyncThunk(
  "feedback/getFeedbackById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await feedbackServices.getFeedbackById(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListFeedback = createAsyncThunk(
  "feedback/getListFeedback",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await feedbackServices.getListFeedback(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createFeedback = createAsyncThunk(
  "feedback/createFeedback",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await feedbackServices.createFeedback(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateFeedback = createAsyncThunk(
  "feedback/updateFeedback",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await feedbackServices.updateFeedback(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteFeedback = createAsyncThunk(
  "feedback/deleteFeedback",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await feedbackServices.deleteFeedback(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
