import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getListStore } from "@actions/metadata.actions";
import SectionTitle from "@components/section-title";
import Datatable from "@components/tsdatatable/datatables";
import { selectListStore } from "@reducers/metadata.reducer";
import { formatCurrency } from "@utils/lib";
import ShowQR from "./ShowQR";

import qr from "@assets/images/icon/qr.svg";
import redMulti from "@assets/images/icon/redMulti.svg";
import CustomButton from "@components/buttons/CustomButton";
import { useTranslation } from "@hooks/useTranslation";
import useSearchParams from "@pages/sale-management/customer/useSearchParams";

const DatatableComponent = ({
  customData,
  filters,
  numOfPage,
  setFilters,
  setShowQR,
  setSelectedStore,
}) => {
  const { translate } = useTranslation('business');

  const columns = React.useMemo(
    () => [
      {
        Header: translate("common:numerical_order"),
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: translate("store_code"),
        accessor: "id",
        disableSortBy: true,
        Cell: ({ value, row }) => {
          return (
            <span
              style={{
                width: 100,
              }}
            >
              {value || "None"}
            </span>
          );
        },
      },
      {
        Header: translate("store_name"),
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: translate("business_info_VA"),
        disableSortBy: true,
        Cell: ({ _, row }) => {
          const vaNo = row.original.va_account_no;
          return (
            <img
              className={`${vaNo ? "cursor-pointer" : "pointer-events-none"}`}
              src={vaNo ? qr : redMulti}
              alt="qr"
              width={20}
              onClick={() => {
                setSelectedStore(row.original);
                setShowQR(true);
              }}
            />
          );
        },
      },
      {
        Header: translate("staff_manager_store_location"),
        disableSortBy: true,
        accessor: "address",
        Cell: ({ value, row }) => {
          return <span>{value || ""}</span>;
        },
      },
    ],
    [setSelectedStore, setShowQR],
  );


  return (
    <>
      <Datatable
        columns={columns}
        data={customData}
        pagination={{
          page: filters.page,
          pageSize: filters.page_size,
          totalPages: numOfPage,
        }}
        setFilters={setFilters}
        filters={filters}
      />
    </>

  );
};

export default function ListStore() {
  const history = useHistory();
  const [showQR, setShowQR] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector(selectListStore);
  const [numOfPage, setNumOfPage] = useState(1);
  const { translate } = useTranslation('business');

  const { updatePagination, filtersParams } = useSearchParams();

  const [filters, setFilters] = useState(filtersParams);

  useEffect(() => {
    updatePagination(new URLSearchParams(filters));
  }, [dispatch, filters, updatePagination]);

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);
    return data?.data;
  }, [data]);

  const formatData = (ls) => {
    return ls && Array.isArray(ls)
      ? ls?.map((e) => {
        return {
          ...e,
          request_amount: formatCurrency(e.request_amount),
          original_transaction_date: moment(new Date(e.original_transaction_date * 1000)).format(
            "HH:mm:ss DD-MM-YYYY",
          ),
        };
      })
      : [];
  };

  useEffect(() => {
    dispatch(getListStore(filters));
    // dispatch(getListTxnQRCode(filters));
  }, [filters]);


  return (
    <section>
      <div className="w-full flex justify-between items-center">
        <SectionTitle title={translate("business_info")} subtitle={translate("store_manager")} />
        <CustomButton
          type={"add"}
          outline
          title={translate("common:add")}
          onClick={() => history.push("/store/add")}
        />
      </div>

      <div className="mt-8">
        {<DatatableComponent
          customData={customData}
          filters={filters}
          numOfPage={data.total_page}
          setFilters={setFilters}
          setShowQR={setShowQR}
          setSelectedStore={setSelectedStore}
        />}
      </div>

      {showQR && <ShowQR store={selectedStore} setShow={setShowQR} />}
    </section>
  );
}
