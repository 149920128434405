import React from "react";
import PropTypes from "prop-types";
import "@css/components/section-title.css";

const SectionTitle = ({ title, subtitle, rightTitle = "" }) => {
  return (
    <div className="">
      {/* Sidebar Section */}
      <div className=" text-xs leading-7 font-medium text-primary uppercase">{title}</div>

      <div className="flex items-center" style={{ gap: 10 }}>
        <div className="text-2xl leading-8 font-bold text-primary-text">{subtitle}</div>

        {rightTitle && <div style={{ width: 1.5, height: 16, background: "#B1BECF" }} />}

        {/* Right Title */}
        {rightTitle && <div className="text-primary text-sm leading-5 font-medium">{rightTitle}</div>}
      </div>
    </div>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  rightTitle: PropTypes.any,
};
export default SectionTitle;
