import React, { useEffect, useState } from 'react'
import success from '@assets/images/product/success.gif'
import SectionTitle from '@components/section-title'
import { useTranslation } from '@hooks/useTranslation'
import { getTable } from '@services/table.service'
import CreateTable from './CreateTable'
import PopupCreateTable from './PopupCreateTable'
import PopupQR from './PopupQR'
import PopupUpdate from './PopupUpdate'
import { TableItem } from './TableItem'
import { Popup, Search } from '../order/Common'

const ListTable = () => {
    const [tables, setTables] = useState([]);
    const [inputSearch, setInputSearch] = useState(null)
    const [showPopupCreate, setShowPopupCreate] = useState(false);
    const [showPopupUpdate, setShowPopupUpdate] = useState(false);
    const [showPopupQR, setShowPopupQR] = useState(false);
    const [showPopupSuccess, setShowPopupSuccess] = useState(false);
    const [resultSearch, setResultSearch] = useState([]);
    const { translate } = useTranslation('sale_table')

    const [tableItem, setTableItem] = useState(null)

    const fetchListTable = async () => {
        const res = await getTable();
        setTables(res)
        handleSearch(res)
    }

    const handleSearch = (data, inputSearch) => {
        if (inputSearch) {
            const result = data.filter(
                (item) => item.table_name.toUpperCase().search(inputSearch.toUpperCase()) !== -1
            );
            setResultSearch(result);
        } else {
            setResultSearch(data);
        }
    }

    useEffect(() => {
        if (tables && tables.length > 0) {
            handleSearch(tables, inputSearch)
        }
    }, [inputSearch]);

    useEffect(() => {
        if (showPopupSuccess) setTimeout(() => {
            setShowPopupSuccess(false)
        }, 3000)
    }, [showPopupSuccess])

    useEffect(() => {
        fetchListTable()
    }, [])

    useEffect(() => {
        if (showPopupSuccess) fetchListTable()
    }, [showPopupSuccess])

    return (
        <div>
            <SectionTitle title={translate('sale_management')} subtitle={translate('tables')} />
            <div className='flex flex-col gap-10 relative mt-4'>
                <Search content={translate('input_search_tables')} setInputSearch={setInputSearch} />
                <div className=''>
                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10">
                        {resultSearch.map((item, index) => (
                            <TableItem item={item} key={index} setTableItem={setTableItem} setShowPopupUpdate={setShowPopupUpdate} setShowPopupQR={setShowPopupQR} />
                        ))}
                        <CreateTable setShowPopup={setShowPopupCreate} />
                    </div>
                </div>
            </div>
            <PopupCreateTable isOpen={showPopupCreate} onClose={() => setShowPopupCreate(false)} setShowPopupSuccess={setShowPopupSuccess} />
            <Popup
                isOpen={showPopupSuccess}
                icon={success}
                content={translate('create_table_successful')}
                onClose={() => setShowPopupSuccess(false)}
                handleClickBtn={() => setShowPopupSuccess(false)}
            />
            <PopupUpdate isOpen={showPopupUpdate} fetchListTable={fetchListTable} onClose={() => setShowPopupUpdate(false)} item={tableItem} />
            <PopupQR isOpen={showPopupQR} onClose={() => setShowPopupQR(false)} item={tableItem} />
        </div>
    )
}

export default ListTable