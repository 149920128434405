import React, { Fragment, useEffect, useState } from 'react'
import SelectVaField from '@components/fields/SelectVaField'
import { useTranslation } from '@hooks/useTranslation'
import axiosClient from '@services/client_axios'
import CheckBoxCard from './CheckBoxCard'
import { formatPaymentMethodGroup } from '@utils/lib'
import { PAYMENT_METHOD_GROUP } from '@constants/business/emenu'

const PaymentMethod = ({
  form,
  register,
  watch,
  setValue,
  errors,
  paymentMethod,
  setPaymentMethod,
}) => {
  const { translate } = useTranslation('')
  const [posId, setPosId] = useState(watch(form.POS))

  const handleSelect = (event) => {
    const { value, checked: isChecked } = event.target

    setPaymentMethod((prev) =>
      prev.map((item) => {
        return item.id === Number(value) ? { ...item, active: isChecked } : item
      })
    )
  }

  useEffect(() => {
    const onGetPaymentMethod = async () => {
      try {
        const { data } = await axiosClient.get('/payment-config')
        if (data) {
          const dataFilter = data.filter(
            (item) => item.group === PAYMENT_METHOD_GROUP.EWALLET
          )
          const dataFilterQr = data.filter(
            (item) => item.group === PAYMENT_METHOD_GROUP.QR
          )

          if (!dataFilter || !dataFilterQr) return

          const dataMomo = dataFilter[0].data
          const dataQr = dataFilterQr[0].data

          setValue(form.URL, dataMomo?.url)
          setValue(form.ACCESS_KEY, dataMomo?.access_key)
          setValue(form.SECRET_KEY, dataMomo?.secret_key)
          setValue(form.PARTNER_CODE, dataMomo?.partner_code)
          setValue(form.POS, dataQr?.channel_id)
          setPosId(dataQr?.channel_id)
        }
      } catch (error) {
        console.log(error)
      }
    }
    const interval = onGetPaymentMethod()
    return () => clearInterval(interval)
  }, [setValue, posId])

  return (
    <div className='flex flex-col gap-4'>
      <p className='text-blackgray font-bold leading-6 text-base'>
        {translate('common:payment-method')}
      </p>

      {paymentMethod?.length > 0 &&
        paymentMethod.map(
          (item, index) =>
            item.group === PAYMENT_METHOD_GROUP.QR && (
              <Fragment key={index}>
                <CheckBoxCard
                  id={item.id}
                  checked={item.active}
                  onChange={(e) => handleSelect(e)}
                  title={formatPaymentMethodGroup(item.name)}
                />
                {/* POS */}
                {/* {item.group === PAYMENT_METHOD_GROUP.QR && item.active && (
                  <SelectVaField
                    register={register}
                    name={form.POS}
                    setValue={setValue}
                    errors={errors}
                    initialValue={posId}
                  />
                )} */}
                {/* Momo */}
                {/* {item.group === PAYMENT_METHOD_GROUP.EWALLET && item.active && (
                  <Ewallet
                    id={item.id_mongo}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    form={form}
                    watch={watch}
                  />
                )} */}
              </Fragment>
            )
        )}
    </div>
  )
}

export default PaymentMethod
