import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  editMerchantInfo,
  editWidgetSetting,
  getEcomInfo,
} from '@actions'
import LogoAtom from '@assets/images/LogoAtomFull.svg'
import SectionTitle from '@components/section-title'
import { history } from '@helpers'
import { useTranslation } from '@hooks/useTranslation'
import { selectProfile } from '@reducers/user.reducer'
import { CustomToastMessage } from '@utils/customToastMsg'
import Ecom from './Ecom'
import Preview from './Preview'
import CustomButton from '@components/buttons/CustomButton'
import TableWrapper from '../profile/TableWrapper'
import { ECOM_FORM, INIT_DATA } from '@constants/business/ecom'

const CompanySettingEcom = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm()

  const disabled =
    !watch(ECOM_FORM.RETURN_URL) || !watch(ECOM_FORM.CANCEL_URL) || !watch(ECOM_FORM.IPN)
  const { merchant_id } = useSelector(selectProfile)
  const { translate } = useTranslation()
  const [appId, setAppId] = useState('')
  const [merchantName, setMerchantName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [previewData, setPreviewData] = useState(INIT_DATA)
  const [previewDataSubmit, setPreviewDataSubmit] = useState(INIT_DATA)

  const dispatch = useDispatch()

  const handleGetEcomInfo = async (merchant_id) => {
    const res = await dispatch(getEcomInfo(merchant_id))
    if (res.meta.requestStatus === 'fulfilled' && res?.payload?.data) {
      const data = res?.payload?.data?.data
      const dataInit = {
        // active_methods: data?.widget?.active,
        primary_color: data?.widget?.primary_color,
        secondary_color: data?.widget?.secondary_color,
        logo_url: data?.widget?.logo_url || LogoAtom,
        logo_height: data?.widget?.logo_height,
        logo_position: data?.widget?.logo_position,
        button_color: data?.widget?.button_color,
        button_border: data?.widget?.button_border,
      }
      setPreviewData(dataInit)
      setPreviewDataSubmit(dataInit)
      setAppId(data?.widget?.id)
      setValue(ECOM_FORM.CHECKOUT, data?.[ECOM_FORM.CHECKOUT])
      setValue(ECOM_FORM.RETURN_URL, data?.[ECOM_FORM.RETURN_URL])
      setValue(ECOM_FORM.CANCEL_URL, data?.[ECOM_FORM.CANCEL_URL])
      setValue(ECOM_FORM.IPN, data?.[ECOM_FORM.IPN])
      setMerchantName(data?.[ECOM_FORM.MERCHANT_NAME])
    }
  }

  const onFormSubmit = async (value) => {
    setPreviewData(previewDataSubmit)

    const formatValueMerchant = {
      ...value,
      merchant_name: merchantName || 'Merchant Name',
    }

    try {
      setIsLoading(true)
      const [merchantInfoRes, widgetSettingRes] = await Promise.all([
        dispatch(
          editMerchantInfo({ id: merchant_id, data: formatValueMerchant })
        ),
        dispatch(editWidgetSetting({ id: appId, data: previewDataSubmit })),
      ])

      if (
        merchantInfoRes?.meta?.requestStatus === 'fulfilled' &&
        widgetSettingRes?.meta?.requestStatus === 'fulfilled'
      ) {
        CustomToastMessage.success(translate('toast_success'))
      } else {
        if (merchantInfoRes?.error?.message) {
          const err = merchantInfoRes?.payload?.response?.data
          CustomToastMessage.error(
            err?.error_code || translate('toast_fail'),
            err?.errors
              ? `${err?.errors?.[0]?.field}: ${err?.errors?.[0]?.msg}`
              : translate('get_merchant_info_fail')
          )
        }
        if (widgetSettingRes?.error?.message) {
          const err = widgetSettingRes?.payload?.response?.data
          CustomToastMessage.error(
            err?.error_code || translate('toast_fail'),
            err?.errors
              ? `${err?.errors?.[0]?.field}: ${err?.errors?.[0]?.msg}`
              : translate('get_widget_setting_fail')
          )
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }
  const onErrors = (errors) => console.error(errors)

  useEffect(() => {
    if (merchant_id) {
      handleGetEcomInfo(merchant_id)
    }
  }, [merchant_id])

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle
        title={translate('business_info')}
        subtitle={translate('setup_payment_gateway')}
      />
      <TableWrapper>
        <h1
          style={{ color: '#262626' }}
          className='text-2xl font-bold leading-8'
        >
          {translate('ATOM_payment')}
        </h1>
        <Ecom
          register={register}
          setValue={setValue}
          errors={errors}
          form={ECOM_FORM}
          watch={watch}
        />

        <Preview
          appId={appId}
          merchantId={merchant_id}
          previewData={previewData}
          setPreviewData={setPreviewData}
          previewDataSubmit={previewDataSubmit}
          setPreviewDataSubmit={setPreviewDataSubmit}
        />

        <div className='flex items-center justify-end gap-4 mt-8'>
          <div style={{ width: 182 }}>
            <CustomButton
              type='button'
              onClick={() => history.push('/staff')}
              title={translate('common:back')}
              outline
              className='w-full'
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type='submit'
              disabled={disabled || isLoading}
              title={translate('payment_update')}
              className='w-full'
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  )
}

export default CompanySettingEcom
