import { saleClient } from "./client_axios";
import { PRODUCT_MANAGER } from "@constants/endpoint";

export const productServices = {
    getListProduct: (params) => {
        return saleClient.get(`${PRODUCT_MANAGER}?${params}`);
    },

    getProductById: (id) => {
        return saleClient.get(`${PRODUCT_MANAGER}/${id}`);
    },

    createProduct: (params) => {
        return saleClient.post(PRODUCT_MANAGER, params);
    },

    updateProduct: (params) => {
        return saleClient.put(`${PRODUCT_MANAGER}/${params.code}`, params);
    },

    addImageProduct: (params) => {
        return saleClient.post(`${PRODUCT_MANAGER}/image`, params);
    },

    deleteProduct: (params) => {
        return saleClient.delete(`${PRODUCT_MANAGER}/${params}`);
    },
};
