import React, { useEffect, useState } from 'react'
import { useTranslation } from '@hooks/useTranslation'
import DoughnutChart from '@components/charts/doughnuts'
import ChartRow from '@components/dashboard/chart-row'
import CardChart from '@components/charts/card'
import DateSelectField from '@components/dashboard/date-select-field'
import ContentContainer from '@components/dashboard/content-container'
import DashboardTable from '@components/dashboard/table'
import { dashboardServices } from '@services/dashboard.service'
import { TYPE_OF_TIME } from '@constants/dashboard'
import { formatCurrency } from '@utils/lib'

const Dashboard = () => {
  const { translate } = useTranslation('dashboard')
  const [date, setDate] = useState(new Date().toISOString().split('T')[0])
  const [dashboardData, setDashboardData] = useState({})

  const separateKeyAndValue = (object) => {
    let keys = []
    let values = []

    Object.keys(object).forEach((key) => {
      // TODO: Fix temporary solution for key is E-wallet and Ecom
      if (key === 'E-wallet' || key === 'Ecom') return

      const value = object[key]
      keys.push(key)
      values.push(value)
    })

    return { keys, values }
  }

  const cardDailyRevenueDoughnut = {
    data: {
      labels: dashboardData?.['Card_daily_revenue']?.keys || [],
      datasets: [
        {
          data: dashboardData?.['Card_daily_revenue']?.values || [],
          backgroundColor: ['#000793', '#0035BA', '#52B9FF'],
          //   borderRadius: 8,
        },
      ],
    },
    name: translate('revenue_in_day'),
  }

  const cardDailyTxnDoughnut = {
    data: {
      labels: dashboardData?.['Card_daily_transaction']?.keys || [],
      datasets: [
        {
          data: dashboardData?.['Card_daily_transaction']?.values || [],
          backgroundColor: ['#000793', '#0035BA', '#52B9FF'],
          //   borderRadius: 8,
        },
      ],
    },
    name: translate('num_txn_in_day'),
  }

  const handleGetDashboardData = async () => {
    const res = await dashboardServices.getDashboardData({ date })
    const reader = res.body.getReader()

    while (true) {
      const { value, done } = await reader.read()
      if (done) break

      const stringifyData = new TextDecoder().decode(value)
      const dataArray = stringifyData.split('\n')

      if (Array.isArray(dataArray)) {
        // Handle each streaming segment
        dataArray.forEach((dataStr, index) => {
          if (index === dataArray.length - 1) return

          const data = JSON.parse(dataStr)

          // Format chart data to labels and data
          Object.keys(data).forEach((key) => {
            if (key === 'Table_recent_transaction')
              return setDashboardData((pre) => ({ ...pre, [key]: data[key] }))

            const { keys, values } = separateKeyAndValue(data[key].data)

            setDashboardData((pre) => ({
              ...pre,
              [key]: { keys, values, name: data[key]?.name },
            }))
          })
        })
      }
    }
  }

  useEffect(() => {
    handleGetDashboardData()
  }, [date])

  return (
    <div className='flex flex-col gap-4 mx-auto' style={{ maxWidth: 1440 }}>
      <h1 className='mt-4 font-bold text-2xl leading-8 text-primary-text'>
        {translate('overview_report')}
      </h1>

      <DateSelectField date={date} setDate={setDate} />

      <div className='flex flex-col gap-4'>
        <span
          className='font-semibold text-lg text-primary-text'
          style={{ lineHeight: '22px' }}
        >
          {translate('report_per_day')}
        </span>

        <div className='flex flex-col w-full gap-10'>
          {/* <iframe
              title="dashboard"
              width="100%"
              height="100%"
              src={metabase_url?.[language]}
          ></iframe> */}

          <ChartRow>
            <DoughnutChart
              name={'daily-revenue'}
              data={cardDailyRevenueDoughnut}
              key={JSON.stringify(cardDailyRevenueDoughnut)}
            />
            <DoughnutChart
              name={'annually-transaction'}
              data={cardDailyTxnDoughnut}
              key={JSON.stringify(cardDailyTxnDoughnut)}
            />
          </ChartRow>

          <ChartRow>
            <CardChart
              currDate={date}
              name={translate('revenue_in_day')}
              info={dashboardData?.['Card_daily_revenue_diff']}
            />
            <CardChart
              currDate={date}
              name={translate('txn_today')}
              info={dashboardData?.['Card_daily_trans_diff']}
            />
          </ChartRow>
          <ChartRow>
            <CardChart
              currDate={date}
              name={translate('revenue_this_month')}
              info={dashboardData?.['Card_monthly_revenue']}
              type={TYPE_OF_TIME.MONTH}
            />
            <CardChart
              currDate={date}
              name={translate('txn_in_month')}
              info={dashboardData?.['Card_monthly_transaction']}
              type={TYPE_OF_TIME.MONTH}
            />
          </ChartRow>
          <ChartRow>
            <CardChart
              currDate={date}
              name={translate('revenue_this_year')}
              info={dashboardData?.['Card_yearly_revenue']}
              type={TYPE_OF_TIME.YEAR}
            />
            <CardChart
              currDate={date}
              name={translate('total_txn_this_year')}
              info={dashboardData?.['Card_yearly_transaction']}
              type={TYPE_OF_TIME.YEAR}
            />
          </ChartRow>

          <ContentContainer name={translate('recently_txn')}>
            <DashboardTable
              data={
                Array.isArray(dashboardData?.['Table_recent_transaction']?.data)
                  ? dashboardData?.['Table_recent_transaction']?.data?.map(
                    (item) => ({ ...item, Amount: formatCurrency(item.Amount) })
                  ) : []
              }
            />
          </ContentContainer>
        </div>
      </div>
    </div>
  )
}
export default Dashboard
