import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, getListProduct } from "@actions/product.actions.js";
import { history } from "@helpers/history.js";
import { selectListProduct } from "@reducers/product.reducer.js";
import { CustomToastMessage } from "@utils/customToastMsg.js";
import { formatCurrency } from "@utils/lib.js";

import CustomButton from "@components/buttons/CustomButton.js";
import ModalDelete from "@components/modals/ModalDelete.js";
import SectionTitle from "@components/section-title/index.js";
import Datatable, { StatusCell } from "@components/tsdatatable/datatables.js";

import IcPen from "@assets/images/staff/ic_pen.svg";
import stop from "@assets/images/payment/stop.svg";
import SearchFieldParams from "@components/fields/SearchFieldParams.js";
import { useTranslation } from "@hooks/useTranslation.js";
import useSearchParams from "../customer/useSearchParams.js";
import { STATUS_CELL_COLOR } from "@constants/common.js";

const TableProduct = () => {
  const dispatch = useDispatch();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [numOfPage, setNumOfPage] = useState("1");
  const { updatePagination, setSearchQuery, filtersParams } = useSearchParams();
  const [filters, setFilters] = useState(filtersParams);

  const data = useSelector(selectListProduct);
  const { translate } = useTranslation('sale_product')

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);
    return data?.data;
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: translate('image'),
        accessor: "img",
        disableSortBy: true,
      },
      {
        Header: translate('product_name'),
        accessor: "name",
        Cell: ({ value, row }) => {
          return <span className="uppercase">{value}</span>;
        },
        disableSortBy: true,
      },
      {
        Header: translate('product_id'),
        accessor: "code",
        disableSortBy: true,
      },
      {
        Header: translate('price'),
        accessor: "price",
        Cell: ({ value, row }) => {
          return <span>{formatCurrency(value)}</span>;
        },
      },
      {
        Header: translate('status'),
        accessor: "status",
        Cell: ({ value, row }) => {
          const status = { title: "", color: "" };
          status.title = value == 0 ? translate('available') : value == 1 ? translate('empty') : translate('close');
          status.color = value == 0 ? STATUS_CELL_COLOR.BLUE : value == 1 ? STATUS_CELL_COLOR.RED : STATUS_CELL_COLOR.LIGHT_BLUE;
          return <StatusCell title={status.title} color={status.color} />;
        },
        disableSortBy: true,
      },
      {
        Header: translate('common:actions'),
        accessor: "",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              {/* <img
                src={moreInfo}
                alt="moreInfo"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setItem(row?.original);
                  // setShowDetailModal(true);
                }}
              /> */}
              <img
                src={IcPen}
                alt="IcPen"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  history.push(`/sale-product/update?id=${row?.original?.code}`);
                }}
              />
              <img
                src={stop}
                alt="stop"
                className={`w-4 ${row?.original?.status == 2 ? 'opacity-50 pointer-events-none' : 'cursor-pointer'}`}
                onClick={() => {
                  setSelectedProduct(row?.original);
                  setShowModalDelete(true);
                }}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    updatePagination(new URLSearchParams(filters));
  }, [dispatch, filters, updatePagination]);

  const handleDelete = async () => {
    try {
      const res = await dispatch(deleteProduct(selectedProduct?.code));
      if (res?.payload) {
        CustomToastMessage.success(
          translate('success'),
          `${translate('you_closed')} ${selectedProduct?.code}`
        );
        // dispatch(getListProduct(filters));
        dispatch(getListProduct(`page=${filters.page}&page_size=${filters.page_size}`));
        setShowModalDelete(false);
      } else CustomToastMessage.error(translate('fail'));
    } catch (err) { console.log(err) }
  };

  return (
    <section>
      <div className="w-full flex justify-between items-center">
        <SectionTitle title={translate('sale_management')} subtitle={translate('products')} />
      </div>

      <div className="mt-4 mb-10 w-full flex justify-between items-center gap-4">
        <SearchFieldParams
          setFilters={setFilters}
          setSearchQuery={setSearchQuery}
          id="search-bank-input"
          extraClass="flex-1 bg-white shadow-primary"
          placeholder={translate('input_search_products')}
        />
        <CustomButton
          type="add"
          onClick={() => history.push("/sale-product/create")}
        />
      </div>

      <Datatable
        columns={columns}
        data={customData}
        pagination={{
          page: filters?.page,
          pageSize: filters?.page_size,
          totalPages: numOfPage,
        }}
        filters={filters}
        setFilters={setFilters}
      />

      <ModalDelete
        desc={`${translate('sure_cease_trading')} ${selectedProduct?.name}?`}
        isOpen={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        item={selectedProduct}
        handleAction={handleDelete}
        titleBtnLeft={translate('no')}
        titleBtnRight={translate('yes')}
      />
    </section>
  );
};
export default TableProduct;
