import md5 from "md5";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createStaff } from "@actions/staff.actions";
import CustomButton from "@components/buttons/CustomButton";
import InputField from "@components/fields/InputField";
import SelectStoreField from "@components/fields/SelectStoreField";
import SectionTitle from "@components/section-title";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";
import { isValidPhoneNumber } from "@utils/lib";
import { useTranslation } from "@hooks/useTranslation";
import InputFieldPw from "@components/fields/InputFieldPw";
import { history } from "@helpers";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import TableWrapper from "../profile/TableWrapper";
import { STAFF_FORM } from "@constants/business/staff";

const AddStaff = () => {
  const { translate, language } = useTranslation('business');
  const dispatch = useDispatch();
  const [phoneAlert, setPhoneAlert] = useState();
  const [loading, setLoading] = useState(false)

  const schema = yup.object().shape({
    [STAFF_FORM.FULL_NAME]: yup.string().matches(/^[\p{L}\s]+$/u, translate('staff_name_invalid')),
    [STAFF_FORM.PHONE_NUMBER]: yup
      .string()
      .matches(/\S/, translate("sale_customer:no_white_space"))
      .matches(
        /^(0[3-9][0-9]{8}|05[5|6|7|8|9][0-9]{7}|(84|\+84)[3-9][0-9]{8})$/,
        translate("sale_customer:customer_phone_invalid")
      ),
    [STAFF_FORM.EMAIL]: yup
      .string()
      .email(translate('default:invalid_email')),
    [STAFF_FORM.PASSWORD]: yup.string().nullable().min(6, translate('staff_pw_6char')),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = async (value) => {
    setLoading(true)
    const raw = { ...value, password: md5(value.password) };
    try {
      if (isValidPhoneNumber(raw?.phone)) {
        const res = await dispatch(createStaff(raw));
        if (res?.meta?.requestStatus === "fulfilled") {
          history.goBack()
          CustomToastMessage.success(
            translate("add_staff_title_success"),
            `${translate("add_staff_des")} ${value?.full_name}`,
          );
        } else
          CustomToastMessage.error(translate("add_staff_title_fail"), ERROR_CODE[res?.payload?.error_code][language]);
      } else {
        setPhoneAlert(translate("staff_phone_invalid"));
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false)
  };
  const onErrors = (errors) => console.error(errors);

  const disabled =
    !watch(STAFF_FORM.EMAIL) ||
    !watch(STAFF_FORM.FULL_NAME) ||
    !watch(STAFF_FORM.PASSWORD) ||
    !watch(STAFF_FORM.PHONE) ||
    !watch(STAFF_FORM.STAFF_ID) ||
    !watch(STAFF_FORM.STORE_ID) || loading;

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle
        title={translate("business_info")}
        subtitle={translate("add_staff_title")}
        rightTitle={translate("staff_manager")}
      />
      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <InputField
            isRequired
            setValue={setValue}
            label={translate("staff_manager_id")}
            placeholder={translate("staff_placeholder_manager_id")}
            id={STAFF_FORM.STAFF_ID}
            name={STAFF_FORM.STAFF_ID}
            register={register}
            errors={phoneAlert || errors}
          />
          <InputField
            isRequired
            setValue={setValue}
            label={translate("staff_manager_full_name")}
            placeholder={translate("staff_placeholder_full_name")}
            id={STAFF_FORM.FULL_NAME}
            name={STAFF_FORM.FULL_NAME}
            register={register}
            errors={errors}
          />

          <SelectStoreField
            isRequired
            register={register}
            name={STAFF_FORM.STORE_ID}
            setValue={setValue}
            errors={errors}
          />
          <div>
            <InputField
              isRequired
              setValue={setValue}
              type="number"
              label={translate("staff_manager_phone_number")}
              placeholder={translate("staff_manager_placeholder_phone_number")}
              id={STAFF_FORM.PHONE}
              name={STAFF_FORM.PHONE}
              register={register}
              errors={errors}
            />
            {phoneAlert && <p className="text-xs font-normal mt-2 text-error">{phoneAlert}</p>}
          </div>

          <InputField
            isRequired
            setValue={setValue}
            label={translate("staff_manager_email")}
            placeholder={translate("staff_manager_placeholder_email")}
            id={STAFF_FORM.EMAIL}
            name={STAFF_FORM.EMAIL}
            register={register}
            errors={errors}
          />

          <InputFieldPw
            isRequired
            setValue={setValue}
            label={translate("staff_manager_password")}
            placeholder={translate("staff_manager_placeholder_password")}
            id={STAFF_FORM.PASSWORD}
            name={STAFF_FORM.PASSWORD}
            register={register}
            errors={errors}
            autoCompleteOff
          />
        </div>

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              // onClick={() => history.push("/staff")}
              onClick={() => history.goBack()}
              title={translate("common:back")}
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title={translate("common:add")}
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default AddStaff;
