import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getListTxnInstallment } from "@actions/index.js";
import Datatable, { ReceiptCell, StatusCell } from "@components/tsdatatable/datatables.js";
import { selectTxnInstallment } from "@reducers/transaction.reducer.js";
import { formatCurrency, formatDateTxn } from "@utils/lib.js";
import Filters from "@components/filters/Filters.js";
import SectionTitle from "@components/section-title/index.js";
import CustomButton from "@components/buttons/CustomButton.js";
import { TbFilterDollar } from "react-icons/tb";
import useWindowSize from "@hooks/useWindowSize.js";
import { useTranslation } from "@hooks/useTranslation.js";

const DataTableComponent = ({ customData, filters, setFilters, numOfPage, total_amount }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "STT",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Thời gian",
        accessor: "created_at",
        disableSortBy: true,
        Cell: ({ value, row }) => <p>{formatDateTxn(value)}</p>
      },
      {
        Header: "Cửa hàng",
        accessor: "store_name",
      },
      {
        Header: "Mã hoá đơn",
        accessor: "bill_id",
      },
      {
        Header: "Số tiền gốc",
        accessor: "amount",
        Cell: ({ value, row }) => <span>{value.toLocaleString() || "10000"}</span>,
      },
      {
        Header: "Phí",
        accessor: "fee",
        disableSortBy: true,
      },
      {
        Header: "Tổng số tiền",
        accessor: "total_amount",
        Cell: ({ value, row }) => <span>{formatCurrency(value)}</span>,
      },

      {
        Header: "Mã chuẩn chi",
        accessor: "approval_code",
        disableSortBy: true,
      },
      {
        Header: "Ngân hàng",
        accessor: "bank_code",
        disableSortBy: true,
      },

      {
        Header: "Loại thẻ",
        accessor: "card_type",
        disableSortBy: true,
      },
      {
        Header: "Số thẻ",
        accessor: "card_id",
        disableSortBy: true,
      },
      {
        Header: "Kỳ hạn",
        accessor: "period",
        disableSortBy: true,
      },
      {
        Header: "Họ tên",
        accessor: "full_name",
        disableSortBy: true,
      },
      {
        Header: "SDT",
        accessor: "phone",
        disableSortBy: true,
      },
      {
        Header: "CCCD",
        accessor: "identity_card",
        disableSortBy: true,
      },
      {
        Header: "Trạng thái",
        accessor: "status",
        Cell: ({ value, row }) => <StatusCell data={row} />,
        disableSortBy: true,
      },
      {
        Header: "Hoá đơn",
        accessor: "bill_url",
        Cell: ({ value, row }) => <ReceiptCell data={row} />,
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <Datatable
      styleTable={{
        width: 1600,
      }}
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      total_amount={total_amount}
      setFilters={setFilters}
      filters={filters}
    />
  );
};

const TsInstallment = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslation()
  const data = useSelector(selectTxnInstallment);
  const [showFilter, setShowFilter] = useState(false)
  const [numOfPage, setNumOfPage] = useState("1");
  const { isMd } = useWindowSize()

  // GMT +7
  const currentTime = new Date(); // hardcode the current date to show data
  const [y, m, d] = [currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate()];
  const startTime = new Date(y, m, d);
  const endTime = new Date(y, m, d, 23, 59, 59);

  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
    status: "all",
    start_time: Math.floor(startTime.getTime() / 1000),
    end_time: Math.floor(endTime.getTime() / 1000),
    bill_id: "",
    customer_id: "",
  });

  const [tempFilters, setTempFilters] = useState({
    page: 1,
    page_size: 10,
    status: "all",
    start_time: Math.floor(startTime.getTime() / 1000),
    end_time: Math.floor(endTime.getTime() / 1000),
    bill_id: "",
    customer_id: "",
  });

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(data?.total_page);
    return data?.data;
  }, [data]);

  const formatData = (ls) => {
    return ls
      ? ls.map((e) => {
        return {
          ...e,
          request_amount: formatCurrency(e.request_amount),
          original_transaction_date: moment(new Date(e.original_transaction_date * 1000)).format(
            "HH:mm:ss DD-MM-YYYY"
          ),
        };
      })
      : [];
  };

  useEffect(() => {
    dispatch(getListTxnInstallment(filters));
  }, [dispatch, filters]);

  return (
    <div className="relative">
      {/* <SectionTitle title={translate("report")} subtitle="Giao dịch trả góp" />
      <div
        style={{ border: "1px solid #F5F7FA" }}
        className="flex justify-between items-end bg-white rounded-xl mt-6 p-6 shadow-primary"
      >
        <Filters filters={tempFilters} setFilters={setTempFilters} />
        <div className="flex gap-4">
          <CustomButton
            filters={filters}
            setFilters={(params) => {
              setFilters(params);
              setTempFilters(params);
            }}
            type="reset"
            title={translate("report_flt_reset")}
          />
          <CustomButton filters={tempFilters} setFilters={setFilters} type="apply" title={translate("report_flt_apply")} />
        </div>
      </div> */}
      <div className="flex items-center justify-between">
        <SectionTitle title={translate("report")} subtitle={translate("report_txn_installment")} />
        <CustomButton onClick={() => setShowFilter(!showFilter)} className='block md:hidden' outline leftIcon={<TbFilterDollar size={16} />} />
      </div>
      <div
        style={{ border: "1px solid #F5F7FA", top: !isMd && 32, zIndex: showFilter || isMd ? 40 : -10 }}
        className="absolute right-0 md:relative flex gap-4 md:gap-0 flex-col md:flex-row md:justify-between items-end bg-white rounded-xl mt-6 p-6 shadow-primary"
      >
        <Filters filters={tempFilters} setFilters={setTempFilters} />
        <div className="grid grid-cols-2 w-full md:w-auto gap-4">
          <CustomButton
            filters={filters}
            setFilters={(params) => {
              setFilters(params);
              setTempFilters(params);
            }}
            type="reset"
            title={translate("report_flt_reset")}
          />
          <CustomButton filters={tempFilters} setFilters={setFilters} type="apply" title={translate("report_flt_apply")} />
        </div>
      </div>

      <div className="mt-8">
        <DataTableComponent
          customData={formatData(customData)}
          filters={filters}
          setFilters={setFilters}
          numOfPage={numOfPage}
          total_amount={data?.total_amount}
        />
      </div>
    </div>
  );
};
export default TsInstallment;
