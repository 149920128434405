import { QRCodeCanvas } from "qrcode.react";
import React from 'react';
import { useSelector } from "react-redux";
import close from '@assets/images/product/close.svg';
import CustomButton from "@components/buttons/CustomButton";
import { useTranslation } from "@hooks/useTranslation";
import { selectMerchant } from "@reducers/sass.reducer";
import ModalWrap from "@components/modals/ModalWrap";

export const formatWebsite = (str) => {
    if (!str) return ''
    if (str.slice(-1) === '/') return str
    else return str + '/'
}

const PopupQR = ({ isOpen, onClose, item }) => {
    const { translate } = useTranslation('sale_table')

    const downloadQRCode = () => {
        const qrCodeURL = document.getElementById('qrCodeEl')
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = "QR_Code.png";
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }

    const merchant = useSelector(selectMerchant);

    return (
        <ModalWrap isOpen={isOpen} onClickBackdrop={onClose}>
            <div className='h-fit rounded-3xl relative bg-white flex flex-col gap-8 items-center p-6' style={{ width: '414px', zIndex: '60' }}>
                <img
                    src={close} alt=''
                    className='absolute cursor-pointer'
                    style={{ top: '16px', right: '16px' }}
                    onClick={onClose}
                />
                <p className='text-black text-base font-semibold leading-5'>{`QR Code -  ${translate('table')}`} {item?.table_name}</p>
                <div className="bg-white rounded-xl flex items-center justify-center" style={{ width: '200px', height: '200px' }}>
                    <QRCodeCanvas
                        id="qrCodeEl"
                        value={`${formatWebsite(merchant.website)}order/${item?.table_id}${item?.order_id ? '/' + item?.order_id : ''}`}
                        size={220}
                    />
                </div>
                <CustomButton
                    title={translate('save_image')}
                    onClick={downloadQRCode}
                    className={'w-full'}
                />
            </div>
        </ModalWrap>
    )
}

export default PopupQR

