import axios from 'axios'
import queryString from 'query-string'

import { history, LocalStorageService } from '@helpers'
import { REFRESH_TOKEN } from '@constants/endpoint'
const localStorageService = LocalStorageService.getService()

const defaultHeader = {
  // "Access-Control-Allow-Origin": "*",
  // "Content-Type": "application/json",
  // Accept: "application/json",
}

// for multiple requests
let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const createAxiosInstance = (baseUrl) => {
  const instance = axios.create({
    baseURL: baseUrl,
    paramsSerializer: (params) => queryString.stringify(params),
  })

  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => {
      const token = localStorageService.getAccessToken()

      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      const originalRequest = response.config
      if (
        response.data?.error_code === 'TOKEN_EXPIRED' &&
        !originalRequest._retry
      ) {
        return handleRefreshToken(originalRequest, instance)
      }
      return handleResponse(response)
    },
    (error) => {
      const originalRequest = error.config
      if (
        error.response.status === 403 &&
        ['TOKEN_EXPIRED', 'AUTHEN_FAIL'].includes(
          error.response.data.error_code
        ) &&
        !originalRequest._retry
      ) {
        handleRefreshToken(originalRequest, instance)
      }

      return Promise.reject(handleError(error))
    }
  )

  return instance
}

const handleResponse = (res) => {
  if (res && res.data) {
    return res.data
  }

  return res
}

const handleError = (error) => {
  const { data } = error.response
  if (data && data.error_code === 'USER_LOCKED_USING') {
    clearAuthToken()
  }

  return data
}

const handleRefreshToken = (originalRequest, instance) => {
  if (isRefreshing) {
    return new Promise(function (resolve, reject) {
      failedQueue.push({ resolve, reject })
    })
      .then((token) => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token
        return instance.request(originalRequest)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }

  originalRequest._retry = true
  isRefreshing = true

  const refreshToken = localStorageService.getRefreshToken()
  const accessTokenExp = localStorageService.getAccessToken()

  if (!refreshToken) {
    history.push('/login')
  }

  return new Promise(function (resolve, reject) {
    axios
      .post(
        PORTAL_ENDPOINT + REFRESH_TOKEN,
        {
          refresh_token: refreshToken,
          access_token: accessTokenExp,
        },
        {
          headers: {
            ...defaultHeader,
            Authorization: `Bearer ${accessTokenExp}`,
          },
        }
      )
      .then((res) => {
        const { data } = res.data

        // 1) put token to LocalStorage
        localStorageService.setToken(data)
        localStorageService.setRefreshToken(data)

        // 2) Change Authorization header
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + data.access_token
        originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token

        processQueue(null, data.access_token)

        // 3) return originalRequest object with Axios
        resolve(instance.request(originalRequest))
      })
      .catch((err) => {
        clearAuthToken()
        processQueue(err, null)
        reject(err)
      })
      .finally(() => {
        isRefreshing = false
      })
  })
}

const clearAuthToken = () => {
  LocalStorageService.clearToken()
  history.push('/login')
}

const PORTAL_ENDPOINT = process.env.REACT_APP_PORTAL_API_ENDPOINT
const SALE_ENDPOINT = process.env.REACT_APP_SALE_API_ENDPOINT

const portalClient = createAxiosInstance(PORTAL_ENDPOINT)
const saleClient = createAxiosInstance(SALE_ENDPOINT)

export { saleClient }
export default portalClient
