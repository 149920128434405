
import LogoAtom from '@assets/images/LogoAtomFull.svg';
import IcBank from '@assets/images/payment/ic_bank.svg';
import IcJcb from '@assets/images/payment/ic_jcb.svg';
import IcMasterCard from '@assets/images/payment/ic_master-card.svg';
import IcMomo from '@assets/images/payment/ic_momo.svg';
import IcMoney from '@assets/images/payment/ic_money.svg';
import IcQr from '@assets/images/payment/ic_qr.svg';
import IcVisa from '@assets/images/payment/ic_visa.svg';
import IcWallet from '@assets/images/payment/ic_wallet.svg';
import IcZalo from '@assets/images/payment/ic_zalo.svg';
import IcMail from '@assets/images/payment/mail.svg';
import IcPhone from '@assets/images/payment/phone.svg';

export const ECOM_FORM = {
  CHECKOUT: 'checkout_url',
  RETURN_URL: 'return_url',
  CANCEL_URL: 'cancel_url',
  IPN: 'ipn_url',
  MERCHANT_ID: 'merchant_id',
  MERCHANT_NAME: 'merchant_name',
}

export const INIT_DATA = {
  // active_methods: [
  //   {
  //     name: 'cash',
  //     active: true,
  //   },
  //   {
  //     name: 'ewallet',
  //     active: true,
  //   },
  //   {
  //     name: 'qr_code',
  //     active: true,
  //   },
  //   {
  //     name: 'credit_card',
  //     active: true,
  //   },
  // ],
  primary_color: '#ffffff',
  secondary_color: '#f5f5f5',
  logo_url: LogoAtom,
  logo_height: 100,
  logo_position: 'center',
  button_color: '#fafafa',
  button_border: true,
}

export const CONTACTS = [
  {
    icon: IcPhone,
    content: `business_hotline`,
  },
  {
    icon: IcMail,
    content: 'Email: hotro247@kfc.vn',
  },
];

export const PAYMENTS = [
  {
    icon: IcMoney,
    content: 'payment_method_cash',
    value: 'cash',
  },
  {
    icon: IcBank,
    content: 'payment_method_wallet',
    subtitle: [IcMomo, IcZalo],
    value: 'ewallet',
  },
  {
    icon: IcQr,
    content: 'payment_method_qrcode',
    value: 'qr_code',
  },
  {
    icon: IcWallet,
    content: 'payment_method_bankcard',
    subtitle: [IcVisa, IcMasterCard, IcJcb],
    value: 'credit_card',
  },
];

export const LIST_SIZE = [
  { label: 'payment_size_small', value: 60 },
  { label: 'payment_size_medium', value: 80 },
  { label: 'payment_size_large', value: 100 },
];
export const LIST_POSITION = [
  { label: 'payment_position_left', value: 'left' },
  { label: 'payment_position_center', value: 'center' },
  { label: 'payment_position_right', value: 'right' },
];

export const SCALE_RATE = 0.42;

export const LIST_METHOD = [
  { label: 'payment_direct_payment', value: "cash" },
  { label: 'payment_wallet', value: "ewallet" },
  { label: 'payment_qrcode', value: "qr_code" },
  { label: 'payment_bankcard', value: "credit_card" },
];