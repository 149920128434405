import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from '@hooks/useTranslation'
import { TYPE_OF_TIME } from '@constants/dashboard'

const CardChart = ({ currDate, name, info, type = TYPE_OF_TIME.DAY }) => {
  const { language: embeddedLanguage } = useParams()
  const { translate } = useTranslation('dashboard', embeddedLanguage)

  const handleData = (info) => {
    const formatTime = () => {
      switch (type) {
        case TYPE_OF_TIME.DAY:
          return currDate
        case TYPE_OF_TIME.MONTH:
          return `${currDate.slice(0, 7)}-01`
        case TYPE_OF_TIME.YEAR:
          return `${currDate.slice(0, 4)}-01-01`
        default:
          return currDate
      }
    }

    // Have no data
    if (!info?.keys?.length)
      return {
        current: 0,
        previous: 0,
        time: formatTime(),
        diff: 0,
      }
    // Have current data only
    if (info.keys.length === 1)
      return {
        current: info.values[0],
        previous: 0,
        time: info.keys[0],
        diff: 100,
      }
    // Have full data
    if (info.keys.length === 2) {
      const previous = info.values[0]
      const current = info.values[1]
      const time = info.keys[1]

      const diff = previous
        ? (((current - previous) / previous) * 100).toFixed(2)
        : 100

      return {
        current,
        previous,
        time,
        diff,
      }
    }
  }

  const { current, previous, time, diff } = useMemo(
    () => handleData(info),
    [info]
  )

  return (
    <div
      className='flex flex-col p-6 w-full flex-1 overflow-hidden shadow gap-5 xl:gap-10 round'
      style={{ borderRadius: 20 }}
    >
      <span className='font-semibold text-base text-primary-text leading-5'>
        {name}
      </span>

      <div className='self-center flex flex-col gap-4 items-center w-full'>
        <span
          className='font-bold text-black self-start mx-auto max-w-full overflow-x-auto'
          style={{ fontSize: 50, lineHeight: '73px' }}
        >
          {new Intl.NumberFormat().format(current || 0)}
        </span>

        <p className='font-medium text-2xl leading-7 text-secondary-text'>
          {time}
        </p>

        <div className='flex items-center gap-4 font-medium text-2xl max-w-full overflow-x-auto mx-auto leading-7 text-secondary-text'>
          <span
            className='flex gap-1 items-center'
            style={{ color: diff > 0 ? '#2C902D' : '#ED1C24' }}
          >
            <svg
              style={{ transform: diff > 0 && 'rotate(180deg)' }}
              width='17'
              height='19'
              viewBox='0 0 17 19'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9.86523 1.37695C9.86523 0.824668 9.41752 0.376953 8.86523 0.376953C8.31295 0.376953 7.86523 0.824668 7.86523 1.37695L9.86523 1.37695ZM8.15813 18.0841C8.54865 18.4746 9.18182 18.4746 9.57234 18.0841L15.9363 11.7201C16.3268 11.3296 16.3268 10.6964 15.9363 10.3059C15.5458 9.91536 14.9126 9.91536 14.5221 10.3059L8.86524 15.9627L3.20838 10.3059C2.81786 9.91536 2.18469 9.91536 1.79417 10.3059C1.40364 10.6964 1.40364 11.3296 1.79417 11.7201L8.15813 18.0841ZM7.86523 1.37695L7.86524 17.377L9.86524 17.377L9.86523 1.37695L7.86523 1.37695Z'
                fill='currentColor'
              />
            </svg>
            {Math.abs(diff)}%
          </span>

          <span className='whitespace-no-wrap'>
            vs.{translate(`prev_${type}`)}:{' '}
            <span style={{ color: '#2C2C2C' }}> </span>
            {new Intl.NumberFormat().format(previous)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default CardChart
