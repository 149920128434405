import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { exportInvoice } from "@actions/invoice.actions";
import CustomButton from "@components/buttons/CustomButton";
import InputField from "@components/fields/InputField";
import ModalWrap from "@components/modals/ModalWrap";
import { useTranslation } from "@hooks/useTranslation";
import { saleClient } from "@services/client_axios";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";

const FORM = {
  TAX_CODE: "tax_code",
  ENTERPRISE: "enterprise",
  ADDRESS: "address",
  PHONE_NUMBER: "phoneNumber",
  EMAIL: "email",
  NAME: "name",
};

const ModalExport = ({ isOpen, onClose, orderId, setShowPopupSuccess }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    watch,
    clearErrors
  } = useForm();
  const dispatch = useDispatch();
  const { translate, language } = useTranslation('sale_order')
  // const searchDebounce = useDebounce(watch(FORM.TAX_CODE), 800);
  const [disabledField, setDisableField] = useState(true);
  const disabled =
    !watch(FORM.TAX_CODE) || !watch(FORM.ENTERPRISE) || !watch(FORM.ADDRESS) || !watch(FORM.EMAIL);

  const onFormSubmit = async (value) => {
    const raw = {
      order_id: Number(orderId),
      tax_info: value,
    };

    try {
      const res = await dispatch(exportInvoice(raw));
      if (res?.meta?.requestStatus === "fulfilled") {
        onClose();
        setShowPopupSuccess(true);
      } else CustomToastMessage.error(translate('common:fail'), ERROR_CODE[res?.payload?.error_code][language])
    } catch (err) {
      console.log(err);
    }
  };

  const resetValue = () => {
    setValue(FORM.ENTERPRISE, "");
    setValue(FORM.ADDRESS, "");
    setValue(FORM.EMAIL, "");
    setValue(FORM.PHONE_NUMBER, "");
  };

  const onGetDataEnterprise = async () => {
    // tax code test = "8836558803"
    if (!watch(FORM.TAX_CODE)) return
    try {
      const res = await saleClient.get(
        `https://vpbank-outscope-api-stag.atomsolution.vn/v1/api/invoices/common/party?tax_code=${watch(FORM.TAX_CODE)}`
      );
      const { data } = res;
      if (data) {
        setValue(FORM.ENTERPRISE, data?.[FORM.ENTERPRISE] || "");
        setValue(FORM.ADDRESS, data?.[FORM.ADDRESS] || "");
        setValue(FORM.EMAIL, data?.[FORM.EMAIL] || "");
        setValue(FORM.PHONE_NUMBER, data?.[FORM.PHONE_NUMBER] || "");
      }
    } catch (error) {
      console.log(error);
      resetValue();
      setError(FORM.TAX_CODE, { type: "custom", message: error?.msg });
    } finally {
      setDisableField(false);
    }
  };

  const handleClose = () => {
    onClose()
    clearErrors()
  }

  return (
    <ModalWrap isOpen={isOpen} onClickBackdrop={handleClose}>
      <div className="p-6">
        <IoCloseSharp
          className="absolute cursor-pointer hover:opacity-75"
          style={{ right: 16, top: 16 }}
          onClick={handleClose}
          size={20}
        />
        <form
          style={{ width: 632 }}
          onSubmit={handleSubmit(onFormSubmit)}
          className="flex flex-col items-center gap-6"
        >
          <p className="text-black text-sm font-semibold leading-5 ">{translate('export_invoice')}</p>
          <div className="w-full grid grid-cols-2 gap-4">
            <div style={{ gap: 6 }} className="flex items-end gap-1">
              <InputField
                isRequired
                setValue={setValue}
                type="text"
                variant={"tax_no"}
                label={translate('business_info_tax_code')}
                placeholder={translate('store_placeholder_tax_code')}
                id={FORM.TAX_CODE}
                name={FORM.TAX_CODE}
                extra="flex-1"
                register={register}
                errors={errors}
                itemRight={
                  <CustomButton
                    title={translate('get_info')}
                    onClick={onGetDataEnterprise}
                    outline disabled={!watch(FORM.TAX_CODE)}
                    type="button" style={{ width: 132, height: 44 }}
                  />
                }
              />
            </div>
            <InputField
              disabled={disabledField}
              isRequired
              setValue={setValue}
              type="email"
              label="Email"
              placeholder={translate('staff_manager_placeholder_email')}
              id={FORM.EMAIL}
              name={FORM.EMAIL}
              value={watch(FORM.EMAIL)}
              extra="flex-1"
              register={register}
              errors={errors}
            />
            <InputField
              disabled={disabledField}
              isRequired
              setValue={setValue}
              label={translate('company_name')}
              id={FORM.ENTERPRISE}
              name={FORM.ENTERPRISE}
              value={watch(FORM.ENTERPRISE)}
              extra="flex-1"
              register={register}
              errors={errors}
            />
            <InputField
              disabled={disabledField}
              setValue={setValue}
              type="number"
              label={translate('sale_customer:phone_number')}
              placeholder={translate('sale_customer:input_phone_number')}
              id={FORM.PHONE_NUMBER}
              name={FORM.PHONE_NUMBER}
              value={watch(FORM.PHONE_NUMBER)}
              extra="flex-1"
              register={register}
              errors={errors}
            />
            <InputField
              disabled={disabledField}
              isRequired
              setValue={setValue}
              label={translate('sale_settings:address')}
              id={FORM.ADDRESS}
              name={FORM.ADDRESS}
              value={watch(FORM.ADDRESS)}
              extra="flex-1"
              register={register}
              errors={errors}
            />
            <InputField
              disabled={disabledField}
              setValue={setValue}
              label={translate('executor_name')}
              placeholder={translate('input_executor_name')}
              id={FORM.NAME}
              name={FORM.NAME}
              value={watch(FORM.NAME)}
              extra="flex-1"
              register={register}
              errors={errors}
            />
          </div>
          <CustomButton
            disabled={disabled}
            type="submit"
            title={translate('export_temporary_invoice')}
            className={"w-full"}
          />
        </form>
      </div>
    </ModalWrap>
  );
};

export default ModalExport;
