import useWindowSize from '@hooks/useWindowSize'
import React from 'react'

const ChartRow = ({ children }) => {
  const { width } = useWindowSize()

  return (
    <div
      className='flex items-center w-full xl:flex-row flex-col'
      style={{
        gap: width >= 1280 ? 40 : 20,
      }}
    >
      {children}
    </div>
  )
}

export default ChartRow
