import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IoIosArrowDown } from 'react-icons/io'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import {
  selectSidebar,
  setCurrentOpenSubItems,
  setIsShow,
} from '@reducers/sidebar.reducer'
import { theme } from 'configTheme'
import useWindowSize from '@hooks/useWindowSize'

const SidebarItem = ({ item, center }) => {
  const { icon, title, link, subItems } = item
  const dispatch = useDispatch()
  const { isMd } = useWindowSize()
  const { isCollapsed, activePath, currentOpenSubItems } =
    useSelector(selectSidebar)
  const hasSubItem = useMemo(() => subItems && subItems.length > 0, [subItems])
  const isOpenSubItems = useMemo(
    () => currentOpenSubItems === title,
    [currentOpenSubItems]
  )

  const isActive = useMemo(() => {
    return (
      activePath === link ||
      ((!isOpenSubItems || isCollapsed) &&
        subItems?.find((item) => activePath === item.link))
    )
  }, [activePath, isOpenSubItems, isCollapsed])

  return (
    <div
      className='relative'
      onMouseOver={() => isCollapsed && dispatch(setCurrentOpenSubItems(title))}
      onMouseOut={() => isCollapsed && dispatch(setCurrentOpenSubItems(''))}
    >
      <Link
        onClick={() => {
          if (hasSubItem && !isCollapsed)
            dispatch(setCurrentOpenSubItems(!isOpenSubItems ? title : ''))
          else !isMd && dispatch(setIsShow(false))
        }}
        style={{
          gap: 10,
          lineHeight: '18px',
          padding: isCollapsed ? '14px 20px' : '12px 10px',
          color: isActive && theme.secondary,
          background: isActive && theme.sidebarHover,
          justifyContent: center && 'center',
        }}
        to={!hasSubItem && link}
        className='flex items-center text-white hover:text-secondary hover:bg-sidebar-hover rounded-lg font-medium'
      >
        {icon}
        <div
          style={{ gridTemplateRows: isCollapsed ? '0fr' : '1fr' }}
          className={`grid duration-300 ${!center && 'flex-1'} ${isCollapsed && 'absolute opacity-0'}`}
        >
          <p className='overflow-hidden'>{title}</p>
        </div>
        {!isCollapsed && hasSubItem && (
          <IoIosArrowDown
            color='currentColor'
            style={{ transform: isOpenSubItems && 'rotate(180deg)' }}
            className='duration-200 w-4 h-4 rotate-180'
          />
        )}
      </Link>

      <div
        style={{
          gridTemplateRows: isOpenSubItems ? '1fr' : '0fr',
          opacity: isOpenSubItems ? 1 : 0,
          ...(isCollapsed && {
            left: '100%',
            top: hasSubItem ? 0 : '50%',
            transform: !hasSubItem && 'translateY(-50%)',
            width: 'max-content',
            padding: isOpenSubItems && (hasSubItem ? 12 : '8px 12px'),
          }),
        }}
        className={`grid duration-200 ${isCollapsed && 'absolute z-50 rounded-lg bg-gradient shadow-2xl'}`}
      >
        <div className='overflow-hidden text-white font-medium'>
          {isCollapsed && (
            <p className={`${hasSubItem && 'mb-2'} text-secondary`}>{title}</p>
          )}
          {hasSubItem &&
            subItems.map((subItem) => (
              <Link
                key={subItem.title}
                to={subItem.link}
                onClick={() => !isMd && dispatch(setIsShow(false))}
                style={{
                  gap: 10,
                  padding: isCollapsed
                    ? '10px 10px 10px 16px'
                    : '10px 10px 10px 36px',
                  lineHeight: '18px',
                  color: activePath === subItem.link && theme.secondary,
                  background: activePath === subItem.link && theme.sidebarHover,
                }}
                className='flex items-center hover:text-secondary hover:bg-sidebar-hover rounded-lg duration-200'
              >
                {subItem.title}
              </Link>
            ))}
        </div>
      </div>
    </div>
  )
}

export default SidebarItem
