import HeadlessTippy from "@tippyjs/react/headless";
import React from "react";
import "tippy.js/dist/tippy.css";
import IcArrowDown from "@assets/images/icon/ic-arrow-down.svg";
import "../style.css";

const SelectFieldWrapper = ({
  register,
  name,
  errors,
  className,
  state,
  isRequired,

  showContent,
  setShowContent,
  label,
  initialText = "Chọn",
  currentValue,
  currentItemComp,
  children,
}) => {
  const renderReview = () => {
    return (
      <div
        tabIndex={-1}
        className={`inset-x-0 border border-gray-primary flex w-full flex-col overflow-hidden rounded-2xl bg-white shadow-primary`}
      >
        {children || (
          <div className="flex w-full items-center justify-center p-3">Không có dữ liệu</div>
        )}
      </div>
    );
  };

  return (
    <div className={`relative w-full cursor-pointer font-monte transition`}>
      {label && (
        <div className="flex mb-2 text-14px font-semibold text-primary-text leading-5 ">
          {label}
          {isRequired && <span className="text-error">*</span>}
        </div>
      )}
      <div>
        <HeadlessTippy
          interactive
          visible={showContent}
          placement="bottom-start"
          offset={[0, 8]}
          render={() => renderReview()}
          onClickOutside={() => setShowContent(false)}
        >
          <div
            {...register(name, {
              required: isRequired,
            })}
            onClick={() => setShowContent(!showContent)}
            className={`${state === "error"
              ? "border-red-500 text-red-500 "
              : "border-gray-primary text-dark-primary"
              } flex w-full  items-center justify-between rounded-lg border p-3 ${className}`}
          >
            {currentValue ? (
              currentItemComp
            ) : (
              <span className="text-14px text-hint leading-5">{initialText}</span>
            )}
            <img
              src={IcArrowDown}
              alt="IcArrowDown"
              className={`${showContent ? "rotate" : ""} icon-close transition-all`}
            />
          </div>
        </HeadlessTippy>
      </div>

      <p className="text-xs text-red-500">{errors[name] && errors[name].message}</p>
    </div>
  );
};

export default SelectFieldWrapper;
