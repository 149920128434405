import React from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '@components/buttons/CustomButton';
import { useTranslation } from '@hooks/useTranslation';
import { selectAmount, selectTotalPrice } from '@reducers/paymentMethod.reducer';
import { selectProfile } from '@reducers/user.reducer';
import { updateInvoice } from '@services';
import Invoice from '../Invoice';
import Refund from './Refund';
import { PAYMENT_METHOD } from '@constants/common';

const Cash = ({ invoice }) => {
  const amount = useSelector(selectAmount);
  const totalPrice = useSelector(selectTotalPrice);
  const { translate } = useTranslation()
  const { merchant_id } = useSelector(selectProfile)

  const handleUpdateInvoice = async () => {
    const payload = {
      ...invoice,
      payment_method: PAYMENT_METHOD.CASH,
      receive_amount: Number(amount),
      return_amount: Number(amount) - totalPrice,
      merchant_id
    }
    const res = await updateInvoice(payload);
    if (res) {
      const event = new CustomEvent('payment-success', { detail: payload });
      document.dispatchEvent(event);
    }
  };

  return (
    <>
      <div className='flex flex-col items-center gap-4'>
        <p className='text-base font-semibold leading-6 text-black text-center'>
          {translate('sale_management_cash_payment')}
        </p>
        {/* <p
          className="text-sm font-medium leading-5 text-center max-w-full"
          style={{ color: "#8C8C8C" }}
        >
          Bạn đang chọn phương thức thanh toán tiền mặt. Khi thanh toán xong, bạn vui lòng bấm vào
          nút “Hoàn tất thanh toán” để hoàn thành.
        </p> */}
      </div>
      <Invoice invoice={invoice} />
      <Refund totalAmount={invoice?.total_price} />
      <CustomButton
        disabled={Number(amount) < Number(totalPrice) || !amount}
        className={'w-64 mx-auto'}
        onClick={handleUpdateInvoice}
        title={translate('sale_management_complete_payment')}
      />
    </>
  );
}

export default Cash