import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { clearEmailForgotPw } from '@reducers/user.reducer'

ChartJS.register(ArcElement, Tooltip, Legend)

const DAILY_REVENUE_PROPS = {
  data: {
    labels: ['Card', 'Ecom', 'Other'],
    datasets: [
      {
        data: [234, 432, 50],
        backgroundColor: ['#509ee3', '#f9d45c', '#949aab'],
      },
    ],
  },
}

const DoughnutChart = ({ name, data }) => {
  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id)
    let listContainer = legendContainer.querySelector('ul')

    if (!listContainer) {
      listContainer = document.createElement('ul')
      listContainer.style.display = 'flex'
      listContainer.style.flexDirection = 'row'
      listContainer.style.margin = 0
      listContainer.style.padding = 0

      legendContainer.appendChild(listContainer)
    }

    return listContainer
  }

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const ul = getOrCreateLegendList(chart, options.containerID)

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove()
      }

      ul.style.display = 'flex'
      ul.style.flexDirection = 'column'
      ul.style.listStyle = 'none'
      ul.style.margin = 0
      ul.style.padding = 0
      ul.style.gap = '10px'

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart)

      const data = chart.data.datasets[0].data
      const total = data.reduce((acc, curr) => acc + curr, 0)

      items.forEach((item) => {
        const li = document.createElement('li')
        li.style.alignItems = 'center'
        li.style.cursor = 'pointer'
        li.style.display = 'flex'
        li.style.gap = '10px'

        li.onclick = () => {
          const { type } = chart.config
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index)
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex)
            )
          }
          chart.update()
        }

        // Color box
        const boxSpan = document.createElement('span')
        boxSpan.style.background = item.fillStyle
        boxSpan.style.borderColor = item.strokeStyle
        boxSpan.style.borderWidth = item.lineWidth + 'px'
        boxSpan.style.display = 'inline-block'
        boxSpan.style.flexShrink = 0
        boxSpan.style.height = '13px'
        boxSpan.style.width = '27px'
        boxSpan.style.borderRadius = '20px'

        // Text
        const textContainer = document.createElement('p')
        textContainer.style.color = item.fontColor
        textContainer.style.margin = 0
        textContainer.style.padding = 0
        textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

        const text = document.createTextNode(
          total
            ? `${item.text}: ${(data[item.index] / total).toFixed(4) * 100}%`
            : '0%'
        )
        textContainer.appendChild(text)
        textContainer.style.fontSize = '14px'
        textContainer.style.fontWeight = '500'
        textContainer.style.lineHeight = '19px'
        textContainer.style.fontFamily = 'Inter'
        textContainer.style.color = 'black'

        li.appendChild(boxSpan)
        li.appendChild(textContainer)
        ul.appendChild(li)
      })
    },
  }

  const centerText = {
    beforeDraw: function (chart) {
      const width = chart.width,
        height = chart.height,
        ctx = chart.ctx

      ctx.restore()
      const fontSize = (height / 114).toFixed(2)
      ctx.font = fontSize + 'em sans-serif'
      ctx.textBaseline = 'middle'

      const text = new Intl.NumberFormat('en-US', {
          notation: 'compact',
          maximumFractionDigits: 1,
        }).format(
          data.data.datasets[0].data.reduce((acc, curr) => acc + curr, 0) || 0
        ),
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2

      ctx.fillText(text, textX, textY)
      ctx.save()
    },
  }

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div')

    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)'
      tooltipEl.style.borderRadius = '3px'
      tooltipEl.style.color = 'white'
      tooltipEl.style.opacity = 1
      tooltipEl.style.pointerEvents = 'none'
      tooltipEl.style.position = 'absolute'
      tooltipEl.style.transform = 'translate(-50%, 0)'
      tooltipEl.style.transition = 'all .3s ease-out'

      const table = document.createElement('table')
      table.style.margin = '0px'

      tooltipEl.appendChild(table)
      chart.canvas.parentNode.appendChild(tooltipEl)
    }

    return tooltipEl
  }

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context
    const tooltipEl = getOrCreateTooltip(chart)

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0
      return
    }

    tooltipEl.style.minWidth = '150px'

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || []
      const bodyLines = tooltip.body.map((b) => b.lines)

      const tableHead = document.createElement('thead')

      titleLines.forEach((title) => {
        const tr = document.createElement('tr')
        tr.style.borderWidth = 0

        const th = document.createElement('th')
        th.style.borderWidth = 0
        const text = document.createTextNode(title)

        th.appendChild(text)
        tr.appendChild(th)
        tableHead.appendChild(tr)
      })

      const tableBody = document.createElement('tbody')
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i]

        const span = document.createElement('span')
        span.style.background = colors.backgroundColor
        span.style.borderColor = colors.borderColor
        span.style.borderWidth = '2px'
        span.style.marginRight = '10px'
        span.style.height = '10px'
        span.style.width = '10px'
        span.style.display = 'inline-block'

        const tr = document.createElement('tr')
        tr.style.backgroundColor = 'inherit'
        tr.style.borderWidth = 0

        const td = document.createElement('td')
        td.style.borderWidth = 0

        const text = document.createTextNode(body)

        td.appendChild(span)
        td.appendChild(text)
        tr.appendChild(td)
        tableBody.appendChild(tr)
      })

      const tableRoot = tooltipEl.querySelector('table')

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove()
      }

      // Add new children
      tableRoot.appendChild(tableHead)
      tableRoot.appendChild(tableBody)
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1
    tooltipEl.style.left = positionX + tooltip.caretX + 'px'
    tooltipEl.style.top = positionY + tooltip.caretY + 'px'
    tooltipEl.style.font = tooltip.options.bodyFont.string
    tooltipEl.style.padding =
      tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
  }

  return (
    <div
      className='flex relative w-full p-6 flex-1 overflow-hidden shadow'
      style={{
        gap: 80,
        borderRadius: 20,
      }}
    >
      <div
        className='flex flex-col justify-between h-full'
        style={{ width: 175 }}
      >
        <span className='font-semibold text-base leading-7 text-secondary-text'>
          {data.name}
        </span>

        <div
          id={`legend-container-${name}`}
          className='absolute'
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        ></div>
      </div>

      <div className='flex-1 overflow-hidden'>
        <Doughnut
          data={data.data}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              htmlLegend: {
                // ID of the container to put the legend in
                containerID: `legend-container-${name}`,
              },
              tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandler,
              },
              datalabels: {
                display: false,
              },
            },
          }}
          plugins={[centerText, htmlLegendPlugin]}
        />
      </div>
    </div>
  )
}

export default DoughnutChart
