import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import info from '@assets/images/product/info-circle.svg';
import user from '@assets/images/product/user-circle.svg';
import CustomButton from '@components/buttons/CustomButton';
import { useTranslation } from '@hooks/useTranslation';
import { getCustomer, updateCustomerOfOrder } from '@services/table.service';
import useDebounce from '@hooks/useDebounce';

const Step1 = ({
    step,
    phoneNumber,
    setPhoneNumber,
    setStep,
    setCustomerName,
    handleBack
}) => {
    const { order_id } = useParams()
    const [customers, setCustomers] = useState([])
    const debounceReceive = useDebounce(phoneNumber, 500);
    const { translate } = useTranslation('sale_order')

    const handleUpdateCustomer = async (item) => {
        const res = await updateCustomerOfOrder(order_id, item.customer_id)
        if (res) {
            setCustomerName(item?.name)
            handleBack()
        }
    }

    const handleSearchCustomer = async () => {
        const res = await getCustomer({
            phone: phoneNumber
        })
        if (res.customers) {
            setCustomers(res.customers)
        }
    }

    useEffect(() => {
        if (step === 0) {
            handleSearchCustomer()
        }
    }, [debounceReceive, step])


    return (
        <div style={{ width: 366 }} className='flex flex-col items-center gap-8'>
            <p className='text-black text-base font-semibold leading-5 text-center'>{translate('input_info_customer')}</p>

            <div className="flex flex-col gap-4 w-full">
                <div>
                    <p className="text-black text-sm font-semibold leading-5">{translate('sale_customer:phone_number')}</p>
                    <input
                        type="number"
                        name="phone"
                        // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        placeholder={translate('sale_customer:input_phone_number')}
                        className="create-form outline-none text-sm font-medium leading-5 rounded-2 mt-2 w-full"
                        style={{ padding: '10px 16px 10px 16px', border: '1px solid #E8E8E8' }}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        value={phoneNumber}
                    />
                </div>

                {phoneNumber &&
                    <>
                        {
                            customers.length === 0 ? (
                                <div className='mx-auto'>
                                    <img src={info} alt="" className="mx-auto" />
                                    <div className="mt-2" style={{ color: '#8C8C8C', maxWidth: '313PX' }}>
                                        <p className='text-sm font-normal leading-5 text-center'>
                                            {translate('sale_customer:phone_number')} “
                                            <span className="text-base font-semibold leading-6" style={{ color: '#262626' }}>
                                                {phoneNumber}
                                            </span>
                                            ” {translate('not_registered')}.
                                        </p>
                                        <p className='text-sm font-normal leading-5 text-center'>
                                            {translate('choose')} “
                                            <span className="text-base font-semibold leading-6" style={{ color: '#262626' }}>
                                                {translate('sale_customer:create_customers')}
                                            </span>
                                            ” {translate('add_this_phone_number')}.
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <p className="text-black text-sm font-semibold">{translate('info_customer')}</p>
                                    <div className='overflow-y-scroll' style={{ maxHeight: '200px' }}>
                                        <div className='flex flex-col gap-2'>
                                            {customers.map((item, index) => (
                                                <button
                                                    className="flex gap-4"
                                                    key={index}
                                                    onClick={() => handleUpdateCustomer(item)}
                                                >
                                                    <div className="p-2" style={{ backgroundColor: '#EEE', borderRadius: '9.6px' }}>
                                                        <img src={user} alt="" />
                                                    </div>
                                                    <div className="flex flex-col gap-[4px] items-start">
                                                        <p className="text-black text-base font-semibold">{item.name}</p>
                                                        <p className="text-black text-sm font-normal leading-5">{translate('customer')}</p>
                                                    </div>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </>
                }
            </div>

            <div className="grid grid-cols-2 gap-2 h-12 w-full">
                <CustomButton onClick={handleBack} title={translate('common:back')} outline />
                <CustomButton onClick={() => setStep(1)} title={translate('sale_customer:create_customers')} />
            </div>
        </div>
    )
}

export default Step1