import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getProductById, updateProduct } from "@actions/product.actions";
import { CustomToastMessage } from "@utils/customToastMsg";
import { formatCurrency } from "@utils/lib";

import CustomButton from "@components/buttons/CustomButton";
import InputField from "@components/fields/InputField";
import SelectCateField from "@components/fields/SelectCateField";
import UploadProductField from "@components/fields/upload/UploadProductField";
import SectionTitle from "@components/section-title";
import { StatusCell } from "@components/tsdatatable/datatables";
import { history } from "@helpers";
import { useTranslation } from "@hooks/useTranslation";
import TableWrapper from "../../business/profile/TableWrapper";
import { STATUS_CELL_COLOR } from "@constants/common";

const FORM = {
    NAME: "name",
    CODE: "code",
    IMG: "image_url",
    PRICE: "price",
    COST: "cost",
    TAX: "tax",
};

const listStatus = [
    { status: 0, title: 'available', color: STATUS_CELL_COLOR.BLUE },
    { status: 1, title: 'empty', color: STATUS_CELL_COLOR.RED },
    { status: 2, title: 'close', color: STATUS_CELL_COLOR.LIGHT_BLUE },
]

const UpdateProduct = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const id = query.get("id");
    const [imgUrl, setImgUrl] = useState("");
    const [listCate, setListCate] = useState([])
    const [status, setStatus] = useState(undefined)
    const [typeInput, setTypeInput] = useState('number')
    const priceRef = useRef('')
    const { translate } = useTranslation('sale_product')
    const [loading, setLoading] = useState(false)

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues: {
            [FORM.NAME]: "",
            [[FORM.PRICE]]: "",
        },
    });

    const onFormSubmit = async (value) => {
        setLoading(true)
        try {
            const res = await dispatch(updateProduct({
                ...value,
                img: imgUrl,
                cost: 0,
                tax: 0,
                status: status,
                price: priceRef.current,
                category_ids: listCate
            }))

            if (res?.meta?.requestStatus === 'fulfilled' && res?.payload) {
                // history.goBack();
                CustomToastMessage.success(translate('common:success'), `${translate('update_product_success')}${' '}${id}`);
                setTimeout(() => {
                    history.push("/sale-product")
                }, 2000);
            } else CustomToastMessage.error(translate('update_product_fail'));
        } catch (err) { console.log(err); }
        setLoading(false)
    };
    const onErrors = (errors) => console.error(errors);

    const disabled = !watch(FORM.NAME) || !watch(FORM.PRICE) || !imgUrl || loading;

    const onBlur = useCallback(() => {
        setTypeInput('text')
        setValue(FORM.PRICE, watch(FORM.PRICE) ? formatCurrency(watch(FORM.PRICE)) : watch(FORM.PRICE));
    }, [setValue, watch])

    const onFocus = useCallback(() => {
        setTypeInput("number")
        setValue(FORM.PRICE, priceRef.current);
    }, [setValue])

    const onUpdate = useCallback((formName, value) => {
        priceRef.current = formName === 'price' ? value : priceRef.current
    }, [])

    useEffect(() => {
        const getProdInfo = async () => {
            const res = await dispatch(getProductById(id));
            if (res?.payload && res?.payload?.data) {
                setTypeInput("text")
                priceRef.current = res?.payload?.data?.product?.price
                setValue(FORM.NAME, res?.payload?.data?.product?.name);
                setValue(FORM.PRICE, formatCurrency(res?.payload?.data?.product?.price));
                setValue(FORM.CODE, res?.payload?.data?.product?.code);
                setImgUrl(res?.payload?.data?.product?.img);
                setStatus(res?.payload?.data?.product?.status)
                setListCate(res?.payload?.data?.categories.map(cate => cate?.id))
            }
        };
        getProdInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
            <SectionTitle title={translate('product_list')} subtitle={translate('update_info_products')} />

            <TableWrapper>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="flex flex-col gap-6">
                        <UploadProductField
                            label={translate('product_image')}
                            isRequired
                            id={FORM.IMG}
                            name={FORM.IMG}
                            imgUrl={imgUrl}
                            setImgUrl={setImgUrl}
                        />

                        <div className="space-y-2">
                            <p className="text-primary-text text-sm leading-5 font-semibold">{translate('status')}<span className="text-error">*</span></p>
                            <div className="flex gap-4">
                                {listStatus.map(item => (
                                    <div
                                        onClick={() => setStatus(item.status)} key={item.status}
                                        className={`cursor-pointer ${(item.status != status) && "opacity-30"}`}
                                    >
                                        <StatusCell title={translate(item.title)} color={item.color} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-6">
                        <InputField
                            readOnly
                            isRequired
                            setValue={setValue}
                            label={translate('product_id')}
                            placeholder={translate('input_product_id')}
                            id={FORM.CODE}
                            name={FORM.CODE}
                            value={watch(FORM.CODE) || ''}
                            register={register}
                            errors={errors}
                        />

                        <InputField
                            isRequired
                            setValue={setValue}
                            label={translate('product_name')}
                            placeholder={translate('input_product_name')}
                            id={FORM.NAME}
                            name={FORM.NAME}
                            value={watch(FORM.NAME) || ''}
                            register={register}
                            errors={errors}
                        />

                        <InputField
                            isRequired
                            setValue={setValue}
                            label={translate('selling_price')}
                            placeholder={translate('input_selling_price')}
                            id={FORM.PRICE}
                            name={FORM.PRICE}
                            value={watch(FORM.PRICE)}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onUpdate={onUpdate}
                            register={register}
                            errors={errors}
                        />
                    </div>
                </div>

                <SelectCateField listCate={listCate} setListCate={setListCate} />

                <div className="flex items-center justify-end gap-4 mt-4">
                    <div style={{ width: 182 }}>
                        <CustomButton
                            // onClick={() => history.push("/sale-product")}
                            onClick={() => history.goBack()}
                            title={translate('back')} outline className="w-full"
                        />
                    </div>
                    <div style={{ width: 182 }}>
                        <CustomButton type="submit" disabled={disabled} title={translate('edit')} className="w-full" />
                    </div>
                </div>
            </TableWrapper>
        </form>
    );
};

export default UpdateProduct;
