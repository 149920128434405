import React from "react";
import { useDispatch } from "react-redux";
import { createFeedback, deleteFeedback, getListFeedback, updateFeedback } from "@actions";
import { history } from "@helpers";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";

import moreInfo from "@assets/images/payment/moreInfo.svg";
import IcTrash from "@assets/images/payment/trash.svg";
import { useTranslation } from "@hooks/useTranslation";
import { ALERT_TAB } from "@constants/feedback";

const useOther = ({
  setShowDetail = () => { },
  setShowDelete = () => { },
  setSelectedItem = () => { },
}) => {
  const { translate, language } = useTranslation();
  const type = ALERT_TAB.OTHER;
  const formTitle = translate("feedback_error_issue");
  const dispatch = useDispatch();
  const columns = React.useMemo(
    () => [
      {
        Header: translate("feedback_table_no"),
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: translate("feedback_table_store"),
        accessor: "store_name",
      },
      {
        Header: translate("feedback_table_edc"),
        accessor: "serial_number",
      },
      {
        Header: translate("feedback_table_description"),
        accessor: "description",
        Cell: ({ value }) => <p>{value ? value : ""}</p>,
      },
      {
        Header: translate("feedback_table_image"),
        accessor: "images",
        Cell: ({ value }) => (
          <div className="flex flex-wrap" style={{ gap: 10, maxWidth: 126 }}>
            {value.map((img, index) => (
              <img key={index} src={img} alt="err img" className="w-6 h-6 rounded" />
            ))}
          </div>
        ),
      },
      {
        Header: translate("actions"),
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <img
                src={moreInfo}
                alt="moreInfo"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setShowDetail(true);
                  setSelectedItem(row.original);
                }}
              />
              {/* <img
                src={IcPen}
                alt="IcPen"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  history.push(`/feedback/update/${type}/${row.original.id}`);
                }}
              /> */}
              <img
                src={IcTrash}
                alt="IcTrash"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setShowDelete(true);
                  setSelectedItem(row.original);
                }}
              />
            </div>
          );
        },
      },
    ],
    [setSelectedItem, setShowDelete, setShowDetail],
  );

  const handleCreate = async (payload) => {
    const formData = new FormData();
    formData.append("type", type.toUpperCase());
    formData.append("store_id", payload.store_id);
    formData.append("serial_number", payload.serial_number);
    formData.append("description", payload.description);
    payload.images.map((image) => formData.append("images", image));

    try {
      const res = await dispatch(createFeedback(formData));
      if (res?.meta?.requestStatus === "fulfilled") {
        CustomToastMessage.success(`${translate("add")} ${formTitle} ${translate("success")}`, "");
        setTimeout(() => dispatch(getListFeedback({ type: type.toUpperCase() })), 500);
        // history.push(`/feedback/${type}`);
        history.goBack();
      } else {
        CustomToastMessage.error(
          `${translate("add")} ${formTitle} ${translate("fail")}`,
          ERROR_CODE[res?.payload?.error_code][language],
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdate = async (payload) => {
    const formData = new FormData();
    formData.append("id", payload.id);
    formData.append("type", type.toUpperCase());
    formData.append("store_id", payload.store_id);
    formData.append("serial_number", payload.serial_number);
    formData.append("description", payload.description);
    const oldImages = [];
    payload.images.map((img) => {
      if (img?.name) formData.append("images", img);
      else oldImages.push(img);
    });
    formData.append("old_images", JSON.stringify(oldImages));

    try {
      const res = await dispatch(updateFeedback(formData));
      if (res?.meta?.requestStatus === "fulfilled") {
        CustomToastMessage.success(`${translate("edit")} ${formTitle} ${translate("success")}`, "");
        setTimeout(() => dispatch(getListFeedback({ type: type.toUpperCase() })), 500);
        history.push(`/feedback/${type}`);
      } else {
        CustomToastMessage.error(
          `${translate("edit")} ${formTitle} ${translate("fail")}`,
          ERROR_CODE[res?.payload?.error_code][language],
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await dispatch(deleteFeedback({ data: { id } }));
      if (res?.meta?.requestStatus === "fulfilled") {
        CustomToastMessage.success(`Xóa ${formTitle} ${translate("success")}`, "");
        dispatch(getListFeedback({ type: type.toUpperCase() }));
      } else {
        CustomToastMessage.error(
          `${translate("edit")} ${formTitle} ${translate("fail")}`,
          ERROR_CODE[res?.payload?.error_code][language],
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    formTitle,
    type,
    columns,
    handleCreate,
    handleUpdate,
    handleDelete,
  };
};

export default useOther;
