import { saleClient } from "./client_axios";

const URL = String(process.env.REACT_APP_IMAGE_API_ENDPOINT)

export const imageService = {
    postImage: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return saleClient.post(URL, formData);
    }
}