import { createAsyncThunk } from "@reduxjs/toolkit";
import { categoryServices } from "@services";

export const getListCategory = createAsyncThunk(
  "category/getListCategory",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await categoryServices.getListCategory(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCategory = createAsyncThunk(
  "category/createCategory",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await categoryServices.createCategory(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCategoryById = createAsyncThunk(
  "category/getCategoryById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await categoryServices.getCategoryById(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await categoryServices.deleteCategory(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
