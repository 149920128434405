import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListEDC } from "@actions/edc.actions";
import { selectListEDC } from "@reducers/edc.reducer";

import SectionTitle from "@components/section-title";
import Datatable from "@components/tsdatatable/datatables";
import IntergratePChannel from "./IntergratePChannel";

import moreInfo from "@assets/images/payment/moreInfo.svg";
import { useTranslation } from "@hooks/useTranslation";
import useSearchParams from "@pages/sale-management/customer/useSearchParams";

const DataTableComponent = ({
  filters,
  numOfPage,
  data,
  setFilters,
  setShowIntergrate,
  setSelectedEDC,
  translate,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: `${translate("common:numerical_order")}`,
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: `${translate("pos_machine_management_shop_branch")}`,
        accessor: "store_name",
        disableSortBy: true,
      },
      {
        Header: `${translate("pos_machine_management_edc_code")}`,
        accessor: "serial_number",
        Cell: ({ value }) => <div>{value || ""}</div>,
        disableSortBy: true,
      },
      // {
      //   Header: "TID",
      //   accessor: "tid",
      //   Cell: ({ value }) => <div className='flex gap-1 items-center'>{value.map(item => <p>{item}</p>)}</div>,
      // },
      // {
      //   Header: "MID",
      //   accessor: "mid",
      //   Cell: ({ value }) => <div className='flex gap-1 items-center'>{value.map(item => <p>{item}</p>)}</div>,
      // },
      {
        Header: `${translate("pos_machine_management_tid")}`,
        Cell: ({ row }) => (
          <div>
            {row.original.aggregated_data.map((item, index) => (
              <div key={index}>{`${item.tid[0]} ${item.tid[1]}`}</div>
            ))}
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: `${translate("pos_machine_management_mid")}`,
        Cell: ({ row }) => (
          <div>
            {row.original.aggregated_data.map((item, index) => (
              <div key={index}>{`${item.mid[0]} ${item.mid[1]}`}</div>
            ))}
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: `${translate("virtual_account_code")}`,
        accessor: "va_account_no",
        Cell: ({ value, row }) => {
          if (value)
            return (
              <div className="flex flex-col gap-2 items-start">
                <p>{value}</p>
                <p className="text-blue-2 font-medium italic">
                  {row.original.store_name}
                </p>
              </div>
            );
          else return "";
        },
        disableSortBy: true,
      },
      {
        Header: `${translate("common:actions")}`,
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <img
                src={moreInfo}
                alt="moreInfo"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setShowIntergrate(true);
                  setSelectedEDC(row.original);
                }}
              />
            </div>
          );
        },
        disableSortBy: true,
      },
    ],
    [setSelectedEDC, setShowIntergrate, translate]
  );

  return (
    <Datatable
      columns={columns}
      data={data}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

const EDC = () => {
  const { translate } = useTranslation('edc');
  const dispatch = useDispatch();
  const [showIntergrate, setShowIntergrate] = useState(false);
  const [selectedEDC, setSelectedEDC] = useState(null);
  const [numOfPage, setNumOfPage] = useState("1");

  const data = useSelector(selectListEDC);
  
  const { updatePagination, filtersParams } = useSearchParams();

  const [filters, setFilters] = useState(filtersParams);

  useEffect(() => {
    updatePagination(new URLSearchParams(filters));
  }, [dispatch, filters, updatePagination]);

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);
    return data?.data;
  }, [data]);

  useEffect(() => {
    dispatch(getListEDC(filters));
  }, [dispatch, filters]);

  return (
    <div>
      <SectionTitle
        title={translate("pos_machine_management")}
        subtitle={translate("pos_machine_management")}
      />
      <div className="mt-8">
        <DataTableComponent
          data={customData}
          filters={filters}
          setFilters={setFilters}
          numOfPage={numOfPage}
          setShowIntergrate={setShowIntergrate}
          setSelectedEDC={setSelectedEDC}
          translate={translate}
        />
      </div>
      {showIntergrate && (
        <IntergratePChannel
          selectedEDC={selectedEDC}
          setShow={setShowIntergrate}
          getListEDC={getListEDC}
        />
      )}
    </div>
  );
};

export default EDC;
