import { saleClient } from "./client_axios";
import portalClient from "./client_axios";
import { INVOICE_MANAGER } from "@constants/endpoint";

export const invoiceServices = {
  getListInvoice: (params) => {
    return saleClient.get(INVOICE_MANAGER, { params });
  },

  getDetailInvoice: (params) => {
    return saleClient.get(`${INVOICE_MANAGER}/${params}?is_orderid=true`);
  },

  createInvoice: (params) => {
    return saleClient.post(INVOICE_MANAGER, params);
  },

  updateInvoice: (params) => {
    return saleClient.put(`${INVOICE_MANAGER}/${params.code}`, params);
  },

  deleteInvoice: (params) => {
    return saleClient.delete(`${INVOICE_MANAGER}/${params}`);
  },

  getStatusPos: (params) => {
    return portalClient.get(`/pos/check-status`, { params });
  },

  exportInvoice: (params) => {
    return saleClient.post(`${INVOICE_MANAGER}/export`, params);
  },
};
