import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteInvoice, getListInvoice } from "@actions/invoice.actions.js";
import { selectListInvoice } from "@reducers/invoice.reducer.js";
import { CustomToastMessage } from "@utils/customToastMsg.js";
import { formatCurrency, formatPaymentMethod } from "@utils/lib.js";

import ModalDelete from "@components/modals/ModalDelete.js";
import SectionTitle from "@components/section-title/index.js";
import Datatable, { StatusCell } from "@components/tsdatatable/datatables.js";

import moment from "moment";
import moreInfo from "@assets/images/payment/moreInfo.svg";
import IcTrash from "@assets/images/payment/trash.svg";
import SearchField from "@components/fields/SearchField.js";
import { useTranslation } from "@hooks/useTranslation.js";
import { STATUS_CELL_COLOR } from "@constants/common";

const DataTableComponent = ({
  customData,
  filters,
  setFilters,
  numOfPage,
  setShowModalDelete,
  setItem,
}) => {
  const { translate } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: "STT",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Số HĐ",
        accessor: "invoice_id",
      },
      {
        Header: "Thời gian",
        accessor: "created_at",
        Cell: ({ value, row }) => <span>{moment(value).format("DD/MM/YYYY hh:mm")}</span>,
      },
      {
        Header: "Phương thức TT",
        accessor: "payment_method",
        Cell: ({ value, row }) => <span>{translate(formatPaymentMethod(value))}</span>,
      },
      {
        Header: "Trạng thái",
        accessor: "status",
        Cell: ({ value }) => {
          const isPaid = value == 1;
          return <StatusCell color={isPaid ? STATUS_CELL_COLOR.BLUE : STATUS_CELL_COLOR.RED} title={isPaid ? 'Đã thanh toán' : 'Huỷ'} />
        },
      },
      {
        Header: "Tổng tiền",
        accessor: "total_amount",
        Cell: ({ value }) => {
          return <p className={``}>{formatCurrency(value)}</p>;
        },
      },
      {
        Header: "Hành động",
        accessor: "",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <img
                src={moreInfo}
                alt="moreInfo"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setItem(row?.original);
                  // setShowDetailModal(true);
                }}
              />
              <img
                src={IcTrash}
                alt="IcTrash"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setItem(row?.original);
                  setShowModalDelete(true);
                }}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

const TableInvoice = () => {
  const dispatch = useDispatch();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [numOfPage, setNumOfPage] = useState("1");
  const { translate } = useTranslation('sale_management');

  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });

  const data = useSelector(selectListInvoice);

  const customData = useMemo(() => {
    if (!data.data) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);

    if (searchValue.trim() !== "")
      return data?.data?.filter((item) =>
        item?.invoice_id?.toString()?.toLowerCase().includes(searchValue.toLowerCase())
      );

    return data?.data;
  }, [data, searchValue]);

  useEffect(() => {
    dispatch(getListInvoice(filters));
  }, [dispatch, filters]);

  const handleDelete = async () => {
    try {
      const res = await dispatch(deleteInvoice(selectedInvoice?.code));
      if (res?.payload) {
        CustomToastMessage.success(
          "Thành công",
          `Bạn đã ngừng kinh doanh thành công sản phẩm với mã sản phẩm ${selectedInvoice?.code}`
        );
        dispatch(getListInvoice(filters));
        setShowModalDelete(false);
      } else CustomToastMessage.error("Thất bại");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section>
      <div className="w-full flex justify-between items-center">
        <SectionTitle title={translate('sale_management')} subtitle={translate('sale_management_invoices')} />
      </div>

      <div className="mt-4 mb-2 w-full flex justify-between items-center gap-4">
        <SearchField
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          id="search-bank-input"
          extraClass="flex-1 bg-white shadow-primary"
          placeholder="Tìm theo mã hóa đơn"
        />
        {/* <CustomButton
          type="add"
          outline
          title={"Thêm"}
          onClick={() => history.push("/sale-invoice/create")}
        /> */}
      </div>

      <div className="mt-8">
        <DataTableComponent
          customData={customData}
          filters={filters}
          numOfPage={numOfPage}
          setFilters={setFilters}
          setItem={setSelectedInvoice}
          setShowModalDelete={setShowModalDelete}
        />
      </div>

      <ModalDelete
        isOpen={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        title="Ngừng kinh doanh"
        desc={`Bạn có chắc muốn ngừng kinh doanh ${selectedInvoice?.name} ?`}
        item={selectedInvoice}
        handleAction={handleDelete}
        titleBtnLeft="Không"
        titleBtnRight="Có"
      />
    </section>
  );
};
export default TableInvoice;