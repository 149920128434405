import { createAsyncThunk } from "@reduxjs/toolkit";
import { bankServices } from "@services";

export const getTableBank = createAsyncThunk(
  "bank/getTableBank",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await bankServices.getTableBank(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSelectBank = createAsyncThunk(
  "bank/getSelectBank",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await bankServices.getSelectBank(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyBank = createAsyncThunk(
  "bank/verifyBank",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await bankServices.verifyBank(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getHolderName = createAsyncThunk(
  "bank/getHolderName",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await bankServices.getHolderName(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBankVam = createAsyncThunk(
  "bank/deleteBankVam",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await bankServices.deleteBankVam(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
