import { useTranslation } from '@hooks/useTranslation'
import React from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const DashboardTable = (props) => {
  const { data } = props
  const { language: embeddedLanguage } = useParams()

  const { translate } = useTranslation('dashboard', embeddedLanguage)

  const head = data?.length && Object.keys(data[0])

  return data?.length ? (
    <div className='flex flex-col gap-4'>
      <table className='border-separate border-spacing-0'>
        <thead>
          <tr>
            {head.map((item, index) => (
              <th
                key={index}
                scope='col'
                className={`w-1/4 border-l-0 border-neutral-200 bg-neutral-50 p-4 text-sm font-medium leading-[18px] text-secondary-text text-left`}
                style={{ lineHeight: '18px' }}
              >
                {translate(item.replace(' ', '_').toLowerCase())}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((rows, indexRow) => (
            <tr key={indexRow}>
              {Object.keys(rows).map((key, indexCell) => (
                <td
                  key={indexCell}
                  className={`w-1/4 border-l-0 border-t-0 border-neutral-200 p-4 text-base font-semibold leading-5 text-primary-text
                  ${Object.keys(rows).length - 1 === indexRow
                      ? 'first:rounded-bl-lg last:rounded-br-lg'
                      : ''
                    }`}
                >
                  {rows[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null
}

export default DashboardTable
