import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { createStore } from "@actions";
import CustomButton from "@components/buttons/CustomButton";
import InputField from "@components/fields/InputField";
import SectionTitle from "@components/section-title";
import { useTranslation } from "@hooks/useTranslation";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";
import TableWrapper from "../profile/TableWrapper";
import { history } from "@helpers";
import SelectField from "@components/fields/SelectInPaymentField";
import { useRegion } from "@hooks/useRegion";
import { ADD_STORE_FORM, COUNTRY_ID_DEFAULT } from "@constants/business/store";

const AddStore = () => {
  const dispatch = useDispatch();
  const { translate, language } = useTranslation('business');
  const [province, setProvince] = useState(null);
  const [district, setDistrict] = useState(null);
  const [ward, setWard] = useState(null);
  const [loading, setLoading] = useState(false)

  const schema = yup.object().shape({
    [ADD_STORE_FORM.TAX_NO]: yup
      .string()
      .required(translate("store_required"))
      .matches(/\S/, translate("store_matches"))
      .min(10, translate("store_min")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = async (value) => {
    setLoading(true)
    try {
      const res = await dispatch(createStore({
        ...value,
        country_id: COUNTRY_ID_DEFAULT,
        city_id: province,
        district_id: district,
        ward_id: ward
      }));
      if (res?.meta?.requestStatus === "fulfilled") {
        CustomToastMessage.success(
          translate("add_store_success_title"),
          `${translate("add_store_success_desc")} ${watch(ADD_STORE_FORM.STORE_NAME)}`,
        );
        history.push("/store");
      } else CustomToastMessage.error(`${translate("add_store")} ${translate("fail")}`, ERROR_CODE[res?.payload?.error_code][language])
    } catch (err) {
      console.log(err);
      CustomToastMessage.error(translate("fail"), err + "");
    }
    setLoading(false)
  };

  const { handleGetProvince, listProvince, listDistrict, listWard } = useRegion({
    type: 'main',
    province_code: province,
    district_code: district,
    ward_code: ward,
    setProvince, setDistrict, setWard
  })

  useEffect(() => {
    handleGetProvince()
  }, [])

  const disabled =
    !watch(ADD_STORE_FORM.STORE_NAME) || !watch(ADD_STORE_FORM.TAX_NO) ||
    !province || !district || !ward || !watch(ADD_STORE_FORM.ADDRESS_LINE_1) || loading;

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <SectionTitle
        title={translate("business_info")}
        subtitle={translate("add_store")}
        rightTitle={translate("store_manager")}
      />

      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <InputField
            isRequired
            setValue={setValue}
            label={translate("store_name")}
            placeholder={translate("store_placeholder_name")}
            id={ADD_STORE_FORM.STORE_NAME}
            name={ADD_STORE_FORM.STORE_NAME}
            register={register}
            errors={errors}
          />
          <InputField
            type="number"
            isRequired
            setValue={setValue}
            label={translate("store_tax_code")}
            placeholder={translate("store_placeholder_tax_code")}
            id={ADD_STORE_FORM.TAX_NO}
            name={ADD_STORE_FORM.TAX_NO}
            register={register}
            errors={errors}
          />

          <div className="flex flex-col gap-2">
            <p className="text-sm leading-5 font-semibold">
              {translate("region")}
              <span className="text-error">*</span>
            </p>
            <SelectField
              placeHolder={translate("city")}
              data={[].concat(listProvince)}
              setValue={setProvince}
              value={province}
            />
            <SelectField
              placeHolder={translate("district")}
              data={[].concat(listDistrict)}
              setValue={setDistrict}
              value={district}
            />
            <SelectField
              placeHolder={translate("ward")}
              data={[].concat(listWard)}
              setValue={setWard}
              value={ward}
            />
            <InputField
              isRequired
              setValue={setValue}
              placeholder={translate("address_detail")}
              id={ADD_STORE_FORM.ADDRESS_LINE_1}
              name={ADD_STORE_FORM.ADDRESS_LINE_1}
              register={register}
              errors={errors}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.goBack()}
              title={translate("common:back")}
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title={translate("common:add")}
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default AddStore;
