// Custom components
import React from "react";

function InputField(props) {
  const {
    register,
    isRequired,
    setValue,
    value,
    type,
    label,
    placeholder,
    id,
    name,
    extra,
    errors,
    variant,
    disabled,
    readOnly,
    onBlur,
    onFocus,
    onUpdate,
    pattern,
    defaultValue,
    inputStyle,
    itemRight,
  } = props;

  return (
    <div className={`${extra} font-monte`}>
      {label && (
        <div className={`mb-2`}>
          <label
            htmlFor={id}
            className={`${disabled
              ? ""
              : variant === "secondary"
                ? "text-secondary-text"
                : "text-primary-text"
              } text-sm leading-5 font-semibold`}
          >
            {label}
          </label>
          {isRequired && <span className='font-semibold text-error'>*</span>}
        </div>
      )}

      <div style={{ gap: 6 }} className={`relative flex items-center`}>
        <input
          defaultValue={defaultValue}
          onChange={(e) => {
            const value =
              name === "price"
                ? e.target.value.toString().length > 13
                  ? e.target.value.toString().substring(0, 13)
                  : e.target.value
                : e.target.value;

            setValue(name, value);
            onUpdate && onUpdate(name, value);
            if (type === 'percent') {
              if (/^[0-9]\d*\.?\d*$/.test(e.target.value) || e.target.value === '') {
                setValue(name, e.target.value);
              } else setValue(name, e.target.value?.slice(0, e.target.value.length - 1))
              // while(!/^[0-9]\d*\.?\d*$/.test(e.target.value) && !e.target.value === '') {
              //   setValue(name, e.target.value?.slice(0, e.target.value.length - 1))
              // }
            }
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          {...register(name, {
            required: isRequired,
          })}
          readOnly={readOnly}
          disabled={disabled}
          value={value}
          name={name}
          type={type}
          id={id}
          pattern={pattern}
          placeholder={placeholder}
          onKeyDown={(evt) => {
            if (type === "text" && variant === "tax_no") {
              const regexPattern = /^[0-9-]+$/;

              // Allow certain keys like backspace
              const isAllowedKey = evt.key === "Backspace" || regexPattern.test(evt.key);

              // Prevent the default action if the key is not allowed
              !isAllowedKey && evt.preventDefault();
            } else if (type === "number") {
              ["e", "E", "+", ".", ",", "-"].includes(evt.key) && evt.preventDefault();
            }
          }}
          onPaste={e => {
            const pastedContent = e.clipboardData.getData('Text')
            type === 'percent' && !/^[0-9]\d*\.?\d*$/.test(pastedContent) && e.preventDefault()
          }}
          onWheel={(event) => type === "number" && event.currentTarget.blur()}
          style={{ padding: "10px 16px" }}
          className={`${disabled
            ? "opacity-75 text-hint"
            : errors[name]
              ? "text-error border-error"
              : variant === "secondary"
                ? "text-grey-atom-grey border-gray-primary"
                : "text-dark-primary border-gray-primary"
            }
            ${readOnly && "opacity-50"}
            ${type === "radio" ? "h-auto cursor-pointer" : ""}
            ${inputStyle} flex w-full text-14px items-center justify-center rounded-lg border font-medium leading-5 outline-none placeholder-hint`}
        />
        {itemRight}
      </div>
      {errors[name] && (
        <p className='text-xs font-normal mt-2 text-error'>{errors[name].message}</p>
      )}
    </div>
  );
}

export default InputField;
