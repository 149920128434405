import moment from 'moment';
import React from 'react';
import { theme } from 'configTheme';
import { formatCurrency } from '@utils/lib';

import star from '@assets/images/icon/star.svg';
import logoWesap from '@assets/images/logoWesap.png';
import { useTranslation } from '@hooks/useTranslation';

export const TimeCreate = ({ date }) => {
  return (
    <div className="w-fit flex flex-row items-center"
      style={{
        border: '1px solid #91D5FF',
        borderRadius: '20px',
        padding: '6px 12px',
        backgroundColor: '#E6F7FF',
        gap: 6
      }}
    >
      <div className="flex items-center justify-center" style={{ width: '16px', height: '16px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 14 14"
          fill="none"
        >
          <g clipPath="url(#clip0_14371_60030)">
            <path
              d="M7 0.875C3.61758 0.875 0.875 3.61758 0.875 7C0.875 10.3824 3.61758 13.125 7 13.125C10.3824 13.125 13.125 10.3824 13.125 7C13.125 3.61758 10.3824 0.875 7 0.875ZM7 12.0859C4.1918 12.0859 1.91406 9.8082 1.91406 7C1.91406 4.1918 4.1918 1.91406 7 1.91406C9.8082 1.91406 12.0859 4.1918 12.0859 7C12.0859 9.8082 9.8082 12.0859 7 12.0859Z"
              fill="#1890FF"
            />
            <path
              d="M9.38847 8.73084L7.43886 7.32128V3.9375C7.43886 3.87734 7.38965 3.82812 7.32949 3.82812H6.67187C6.61172 3.82812 6.5625 3.87734 6.5625 3.9375V7.70272C6.5625 7.73827 6.57891 7.77108 6.60762 7.79159L8.86894 9.44041C8.91816 9.47596 8.98652 9.46502 9.02206 9.41717L9.41308 8.88397C9.44862 8.83338 9.43769 8.76502 9.38847 8.73084Z"
              fill="#1890FF"
            />
          </g>
          <defs>
            <clipPath id="clip0_14371_60030">
              <rect width="14" height="14" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <p className="text-sm leading-5 font-semibold" style={{ lineHeight: '10px', color: '#1890FF' }}>
        {date ? moment(date).format("DD/MM/YYYY HH:mm") : '26/12/2023 11:16'}
      </p>
    </div>
  )
}

const Invoice = ({ invoice }) => {
  const date = new Date(invoice?.updated_at * 1000).toLocaleString();
  const { translate } = useTranslation('sale_order')

  return (
    <div
      className="relative mx-auto flex flex-col w-full gap-4 py-6 px-10 rounded-3xl bg-white shadow-primary"
    >
      <div style={{ paddingBottom: '5px' }} className="flex flex-col gap-3 rounded-t-xl">
        <div className="w-full flex flex-row items-center justify-between text-base leading-6">
          <p className="font-medium text-base" style={{ color: '#262626' }}>{translate('txn:flt_invoice')}</p>
          <p className="font-semibold text-black">{invoice?.order_code}</p>
        </div>
        {invoice?.table_name &&
          <div className="w-full flex flex-row items-center justify-between text-base leading-6">
            <p className="font-medium  text-base" style={{ color: '#262626' }}>{translate('position')}</p>
            <p className="font-semibold text-black">{invoice.table_name}</p>
          </div>
        }
        <div className="w-full flex flex-row items-center justify-between text-base leading-6">
          <p className="font-medium text-base" style={{ color: '#262626' }}>{translate('created_time')}:</p>
          <TimeCreate date={date} />
        </div>
        <div className="w-full flex flex-row items-center justify-between text-base leading-6">
          <p className="font-medium text-base" style={{ color: '#262626' }}>{translate('point')}:</p>
          <div className='flex gap-1'>
            <img src={star} alt='star' width={20} />
            <p className='text-base leading-5 font-bold'>{invoice?.point || '0'}</p>
          </div>
        </div>
        <div className="w-full flex flex-row items-center justify-between text-base leading-6">
          <p className="font-medium text-base" style={{ color: '#262626' }}>{translate('subtotal')}:</p>
          <p className="font-semibold text-black">
            {formatCurrency(invoice?.total_price)}
          </p>
        </div>
      </div>
      <div className="w-full h-0 border-dashed" style={{ borderBottom: '1px dashed #D9D9D9' }}></div>
      <div className="w-full flex items-center justify-between text-base leading-6 rounded-b-xl bg-transparent">
        <p className="font-medium" style={{ color: '#262626' }}>{translate('grand_total')}</p>
        <p className="text-2xl leading-8 font-bold" style={{ color: theme.primary }}>
          {formatCurrency(invoice?.total_price)}
        </p>
      </div>
      <div style={{ gap: 6 }} className='flex items-center mx-auto'>
        <p style={{ letterSpacing: -0.2 }} className='text-xs leading-5 font-medium text-grey-9'>Powered by</p>
        <img src={logoWesap} alt='logoWesap' className='h-5 w-auto' />
      </div>
    </div>
  );
};

export default Invoice;
