import React from "react";

const SelectFieldItem = ({ onClick, active, children }) => {
  return (
    <div
      onClick={onClick}
      className={`${active ? "bg-select-hover text-primary" : "text-dark-primary hover:text-primary hover:bg-select-hover"
        }  px-4 py-2 font-medium text-sm transition-all duration-300 cursor-pointer `}
    >
      {children}
    </div>
  );
};

export default SelectFieldItem;
