import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getStatusPos } from "@actions";
import CustomButton from "@components/buttons/CustomButton";
import SelectDeviceField from "@components/fields/SelectDeviceField";
import { StatusCell } from "@components/tsdatatable/datatables";
import { useTranslation } from "@hooks/useTranslation";
import { saleClient } from "@services/client_axios";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";
import Invoice from "../Invoice";
import ModalOffline from "./ModalOffline";
import ModalWaitingPos from "./ModalWaitingPos";
import { LIMIT_CHECK_POS_STATUS, SERIAL_NUMBER, STATUS_CONNECT_POS } from "@constants/sale-management/table";
import { PAYMENT_METHOD, STATUS_CELL_COLOR } from "@constants/common";

const { NOT_CONNECTED, ONLINE } = STATUS_CONNECT_POS;

const Credit = ({ invoice }) => {
  const [statusPos, setStatusPos] = useState(NOT_CONNECTED);
  const [openModal, setOpenModal] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [openModalWaiting, setOpenModalWaiting] = useState(false);
  const { translate, language } = useTranslation('sale_order')
  const checkPosTimeRef = useRef(1);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const onFormSubmit = async (value) => {
    try {
      const res = await dispatch(getStatusPos(value));
      // setStatusPos(true)
      if (res?.meta?.requestStatus === "fulfilled") {
        setStatusPos(res?.payload?.data);
      } else CustomToastMessage.error(translate('error'), ERROR_CODE[res?.payload?.error_code][language])
    } catch (err) {
      console.log(err);
    }
  };

  const onPayment = useCallback(async () => {
    if (watch(SERIAL_NUMBER) && invoice?.invoice_id) {
      setIsPaymentLoading(true);
      const dataBody = {
        invoice_id: invoice?.invoice_id,
        serial_number: watch(SERIAL_NUMBER),
        payment_method: PAYMENT_METHOD.CARD,
      };

      try {
        const res = await saleClient.post(
          `${process.env.REACT_APP_SALE_API_ENDPOINT}/payment/pos`,
          dataBody
        );
        const data = res;
        if (data && data?.invoice_id) {
          setOpenModalWaiting(true);
        } else {
          CustomToastMessage.error(translate('error'), translate('payment_fail'));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsPaymentLoading(false);
      }
    }
  }, [invoice?.invoice_id, watch]);

  useEffect(() => {
    const serialNumber = watch(SERIAL_NUMBER);
    if (serialNumber && checkPosTimeRef.current <= LIMIT_CHECK_POS_STATUS) {
      const checkConnectPosStatus = async () => {
        const res = await dispatch(getStatusPos({ serial_number: serialNumber }));
        if (res?.meta?.requestStatus === "fulfilled") {
          setStatusPos(res?.payload?.data);
          checkPosTimeRef.current += 1;
        }
      };
      const intervalId = setInterval(() => checkConnectPosStatus(), 2000);
      return () => {
        checkPosTimeRef.current = 1;
        clearInterval(intervalId);
      };
    }
  }, [dispatch, statusPos, watch]);

  const isPosOnline = useMemo(() => statusPos === ONLINE, [statusPos]);

  return (
    <div className='flex flex-col items-center gap-6'>
      <form onSubmit={handleSubmit(onFormSubmit)} className='flex flex-col items-center w-full'>
        <div className='w-full flex items-end justify-between gap-4'>
          <SelectDeviceField
            isRequired
            register={register}
            name={SERIAL_NUMBER}
            setValue={setValue}
            errors={errors}
          />
          <CustomButton
            disabled={!watch(SERIAL_NUMBER)}
            type='submit' outline
            title={translate('check_connection')}
          />
        </div>
        {statusPos !== NOT_CONNECTED && (
          <div className='flex flex-col items-center mt-6 justify-center'>
            <StatusCell
              color={isPosOnline ? STATUS_CELL_COLOR.BLUE : STATUS_CELL_COLOR.RED}
              title={translate(isPosOnline ? 'connected' : 'disconnect')}
            />
            {!isPosOnline && (
              <div className='italic font-montserrat text-xs text-secondary-text mt-2'>
                {translate('ps_connection')}
              </div>
            )}
          </div>
        )}
      </form>

      <CustomButton
        disabled={isPaymentLoading || !watch(SERIAL_NUMBER) || !isPosOnline}
        title={translate('payment')}
        onClick={onPayment}
      />

      <Invoice invoice={invoice} />

      <ModalOffline isOpen={openModal} onClose={() => setOpenModal(false)} table_id={invoice?.table_id} />
      <ModalWaitingPos isOpen={openModalWaiting} invoice={invoice} onClose={() => setOpenModalWaiting(false)} />
    </div>
  );
};

export default Credit;
