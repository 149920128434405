export const TYPE_OF_TIME = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
}
// function generateTestSeries() {
//   // create test data
//   let series = []
//   var val = 0

//   let start = new Date(2021, 10, 15, 15)
//   let end = new Date(2021, 10, 15, 16)

//   while (start < end) {
//     val += Math.floor(Math.random() * 11) - 5

//     series.push({
//       x: start,
//       y: val,
//     })

//     start = new Date(start.getTime() + 60000)
//   }

//   return series
// }