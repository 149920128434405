import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { syncData } from "@actions/metadata.actions.js";
import { deleteSettingTx, getListSettingTx } from "@actions/setting-tx.actions.js";
import CustomButton from "@components/buttons/CustomButton.js";
import ModalDelete from "@components/modals/ModalDelete.js";
import SectionTitle from "@components/section-title/index.js";
import Datatable, { CellBank } from "@components/tsdatatable/datatables.js";
import { selectListSettingTx } from "@reducers/metadata.reducer.js";
import { CustomToastMessage } from "@utils/customToastMsg.js";
import { ERROR_CODE } from "@constants/errorCode.js";
import { formatCurrency, getCardTypeLogo } from "@utils/lib.js";
import { useTranslation } from "@hooks/useTranslation.js";

const DataTableComponent = ({
  customData,
  filters,
  setFilters,
  numOfPage,
  setIsOpenModal,
  setItem,
}) => {

  const handleDevelop = () => {
    CustomToastMessage.info("Đang phát triển!");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "STT",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Tên cửa hàng",
        accessor: "store_name",
      },
      {
        Header: "Tên Rule",
        accessor: "name",
      },
      {
        Header: "Loại thẻ",
        accessor: "card_branch",
        disableSortBy: true,
        Cell: ({ value, row }) => {
          return (
            <CellBank title={`${value?.toString()}`}>
              <div
                style={{ width: 60, height: 20 }}
                className="flex items-center justify-start overflow-hidden"
              >
                <img
                  src={getCardTypeLogo(value)}
                  alt="item"
                  className="w-full h-full object-contain"
                />
              </div>
            </CellBank>
          );
        },
      },
      {
        Header: "Ngân hàng phát hành",
        accessor: "logo.issuer",
        Cell: ({ value, row }) => {
          return (
            <div
              style={{ gap: 10, maxWidth: 306 }}
              className={`flex items-center justify-start flex-wrap`}
            >
              {value?.map((item, index) => (
                <CellBank key={index} title={row?.original?.issuer[index]}>
                  <div
                    style={{ width: 91, height: 20 }}
                    className="flex items-center justify-start overflow-hidden"
                  >
                    <img src={item} alt="item" className="w-full h-full object-contain" />
                  </div>
                </CellBank>
              ))}
            </div>
          );
        },
      },
      {
        Header: "Ngân hàng xử lý",
        accessor: "logo.acquirer",
        disableSortBy: true,
        Cell: ({ value, row }) => {
          return (
            <CellBank title={`${row?.original?.acquirer}`} className="h-5">
              <div
                style={{ width: 91, height: 20 }}
                className="flex items-center justify-start overflow-hidden"
              >
                <img src={value} alt="item" className="w-full h-full object-contain" />
              </div>
            </CellBank>
          );
        },
      },
      // {
      //   Header: "Hành động",
      //   accessor: "",
      //   Cell: ({ row }) => {
      //     return (
      //       <div className="flex gap-4 justify-center items-center">
      //         <img
      //           src={IcPen}
      //           alt="IcPen"
      //           style={{ width: 16, cursor: "pointer" }}
      //           onClick={() => {
      //             handleDevelop();
      //             // history.push(`/setting-tx/update?id=${row?.original?.id}`);
      //           }}
      //         />
      //         <img
      //           src={IcTrash}
      //           alt="IcTrash"
      //           style={{ width: 16, cursor: "pointer" }}
      //           onClick={() => {
      //             handleDevelop();
      //             // setItem(row?.original);
      //             // setIsOpenModal(true);
      //           }}
      //         />
      //       </div>
      //     );
      //   },
      // },
    ],
    [],
  );

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

const SettingTx = () => {
  const dispatch = useDispatch();
  const { language } = useTranslation()
  const data = useSelector(selectListSettingTx);
  const [numOfPage, setNumOfPage] = useState("1");
  const [item, setItem] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [filters, setFilters] = useState({});

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);
    return data?.data;
  }, [data.data, data.total_page]);

  const formatData = (ls) => {
    return ls
      ? ls.map((e) => {
        return {
          ...e,
          request_amount: formatCurrency(e.request_amount),
          original_transaction_date: moment(new Date(e.original_transaction_date * 1000)).format(
            "HH:mm:ss DD-MM-YYYY",
          ),
        };
      })
      : [];
  };

  const handleDelete = useCallback(async () => {
    try {
      const res = await dispatch(deleteSettingTx({ id: item?.id }));
      // const res = await dispatch(deleteSettingTx({ data: { id: staff?.id } }));
      if (res?.meta?.requestStatus === "fulfilled") {
        CustomToastMessage.success(res?.payload?.data);
        dispatch(getListSettingTx({}));
        setIsOpenModal(false);
      } else CustomToastMessage.error(ERROR_CODE[res?.payload?.error_code][language])
    } catch (err) {
      console.log(err);
    }
  }, [dispatch, item.id]);

  const handleSync = async () => {
    try {
      const res = await dispatch(syncData());
      if (res.payload.data === "success") {
        dispatch(getListSettingTx(filters));
      }
      // CustomToastMessage.info("Đang phát triển!");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(getListSettingTx(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <section>
      <div className="w-full flex justify-between items-center">
        <SectionTitle title="Thiết lập" subtitle="Xử lý giao dịch " />
        <div className="flex items-center gap-3">
          {/* <CustomButton
            type="add"
            outline
            title={"Thêm"}
            onClick={() => {
              CustomToastMessage.info("Đang phát triển!");
              // history.push("/setting-tx/create")
            }}
          /> */}
          <CustomButton
            title={"Đồng bộ dữ liệu"}
            className={"pl-4 pr-4 pt-2 pb-2 rounded-lg"}
            onClick={handleSync}
          />
        </div>
      </div>

      <div className="mt-8">
        <DataTableComponent
          customData={formatData(customData)}
          filters={filters}
          numOfPage={numOfPage}
          data={data}
          setFilters={setFilters}
          setItem={setItem}
          setIsOpenModal={setIsOpenModal}
        />
      </div>

      <ModalDelete
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        title="Xoá xử lý giao dịch"
        desc={`Bạn có chắc muốn xoá xử lý giao dịch KFC ?`}
        // item={staff}
        handleAction={handleDelete}
      />
    </section>
  );
};
export default SettingTx;
