import axiosClient from "./client_axios";
import { saleClient } from "./client_axios";
import { REGION } from "@constants/endpoint";

export const saleServices = {
    getListProvince: (params) => {
        return saleClient.get(`${REGION}/provinces`);
    },

    getListDistrict: (province_code) => {
        return saleClient.get(`${REGION}/districts/${province_code}`);
    },

    getListWard: (district_code) => {
        return saleClient.get(`${REGION}/wards/${district_code}`);
    },
}

export const portalServices = {
    getCountry: async () => {
        return axiosClient.get("/address/country");
    },

    getListProvince: async () => {
        return axiosClient.get(`/address/city`);
    },

    getListDistrict: async (city_id) => {
        const params = { city_id }
        return axiosClient.get(`/address/district`, { params });
    },

    getListWard: async (district_id) => {
        const params = { district_id }
        return axiosClient.get(`/address/ward`, { params });
    },
}