export const ERROR_CODE = {
  UNKNOWN_ERROR: {
    vi: "Lỗi không xác định.",
    en: "Unknown error."
  },
  AUTHEN_FAIL: {
    vi: "Lỗi xác thực người dùng.",
    en: "Authentication failed."
  },
  LOGIN_FAIL: {
    vi: "Tên đăng nhập hoặc Mật khẩu không hợp lệ.",
    en: "Invalid username or password."
  },
  TOKEN_EXPIRED: {
    vi: "Token hết hạn.",
    en: "Token expired."
  },
  BANK_INCORRECT: {
    vi: "Tài khoản thanh toán không hợp lệ.",
    en: "Incorrect payment account."
  },
  BANK_EXIST: {
    vi: "Tài khoản thanh toán đã tồn tại.",
    en: "Payment account already exists."
  },
  BANK_NOT_EXIST: {
    vi: "Tài khoản thanh toán không tồn tại",
    en: "Payment account does not exist."
  },
  DELETE_BANK_ERROR: {
    vi: `Vui lòng xóa ShopQR liên kết với ngân hàng trước khi xóa ngân hàng.`,
    en: `Please delete the ShopQR linked with the bank before deleting the bank.`
  },
  USER_INCORRECT: {
    vi: "Tài khoản người dùng không đúng.",
    en: "Incorrect user account."
  },
  EMAIL_IS_EXIST: {
    vi: "Vui lòng nhập email chưa đăng ký.",
    en: "Please enter an unregistered email."
  },
  TAX_NO_INCORECT: {
    vi: "Mã số thuế không đúng với đăng ký.",
    en: "Tax number does not match the registration."
  },
  TAX_NO_IS_EXST: {
    vi: "Vui lòng nhập mã số thuế chưa đăng ký.",
    en: "Please enter an unregistered tax number."
  },
  TAX_NO_NOT_EXIST: {
    vi: "Mã số thuế không tồn tại.",
    en: "Tax number does not exist."
  },
  HOLDER_NAME_NOT_MATCH: {
    vi: "Tài khoản thanh toán không khớp với Mã số thuế. Vui lòng thử lại.",
    en: "Payment account does not match the tax number. Please try again."
  },
  PERFIX_EXIST: {
    vi: "Tiền tố đơn hàng đã tồn tại.",
    en: "Order prefix already exists."
  },
  CHANNEL_EXIST: {
    vi: `ShopQR đã tồn tại.`,
    en: `ShopQR already exists.`
  },
  CHANNEL_NOT_FOUND: {
    vi: `Không tìm thấy ShopQR.`,
    en: `ShopQR not found.`
  },
  STORE_LINK_VA: {
    vi: "Cửa hàng đã có liên kết VA trước đó.",
    en: "Store has a previously linked VA."
  },
  PERMISSION_DENY: {
    vi: "Người dùng không có quyền truy cập, chỉnh sửa.",
    en: "User does not have permission to access or edit."
  },
  VA_NOT_FOUND: {
    vi: "Không tìm thấy tài khoản VA.",
    en: "VA account not found."
  },
  TXN_SAVE_ERROR: {
    vi: "Lưu giao dịch lỗi.",
    en: "Transaction save error."
  },
  BIN_CODE_EXPIRE: {
    vi: "Mã pin hết hạn. Vui lòng thử lại.",
    en: "PIN code expired. Please try again."
  },
  E_ADD_DEVICE: {
    vi: "Lỗi thêm thiết bị.",
    en: "Error adding device."
  },
  SUBSCRIBE_NOTY_ERROR: {
    vi: "Lỗi đăng ký nhận thông báo.",
    en: "Error subscribing to notifications."
  },
  DELETE_DEVICE_ERROR: {
    vi: "Xóa thiết bị thất bại. Vui lòng kiểm tra lại.",
    en: "Failed to delete device. Please check again."
  },
  PASSWORD_NOT_MATCH: {
    vi: "Sai mật khẩu hiện tại. Vui lòng thử lại.",
    en: "Current password is incorrect. Please try again."
  },
  TOKEN_INCORRECT: {
    vi: "Mã xác thực đã hết hạn.",
    en: "Verification code has expired."
  },
  ACCOUNT_EXIST: {
    vi: "Tài khoản người dùng đã tồn tại.",
    en: "User account already exists."
  },
  OTP_EXPIRE: {
    vi: "Mã OTP hết hạn. Vui lòng thử lại.",
    en: "OTP expired. Please try again."
  },
  SEND_EMAIL_ERROR: {
    vi: "Lỗi xảy ra trong quá trình gửi email.",
    en: "An error occurred while sending the email."
  },
  REQUIRED: {
    vi: "Lỗi yêu cầu phải nhập một field đã bắt buộc.",
    en: "Required field error."
  },
  PASSWORD_DUPLICATE: {
    vi: "Trùng mật khẩu.",
    en: "Duplicate password."
  },
  STAFF_EXIST: {
    vi: "Vui lòng nhập mã nhân viên chưa đăng ký.",
    en: "Please enter an unregistered staff ID."
  },
  STORE_NOT_EXIST: {
    vi: "Cửa hàng không tồn tại.",
    en: "Store does not exist."
  },
  LINK_EDC_VA_FAIL: {
    vi: `Vui lòng chọn ShopQR khác để tích hợp.`,
    en: `Please choose a different ShopQR for integration.`
  },
  BAD_REQUEST: {
    vi: "Lỗi không xác định.",
    en: "Bad request."
  },
  PHONE_IS_EXIST: {
    vi: "Số điện thoại đã tồn tại.",
    en: "Phone number already exists."
  },
  STAFF_LINK_VA: {
    vi: (name) => `Nhân viên ${name} đang được kết nối với một VA cửa hàng.`,
    en: (name) => `Staff ${name} is linked to a store VA.`
  },
  INVALID_EMAIL: {
    vi: "Email không hợp lệ.",
    en: "Invalid email."
  },
  ACCOUNT_NUMBER_INVALID_VCCB: {
    vi: `Số tài khoản không tồn tại trong BVBank.`,
    en: `Account number does not exist in BVBank`
  },
  PHONE_EXIST: {
    vi: "Số điện thoại đã tồn tại",
    en: "Phone number already exists"
  }
};

