import useWindowSize from '@hooks/useWindowSize'
import React from 'react'

const ContentContainer = ({ name, gap = 24, children }) => {
  const { width: windowSize } = useWindowSize()
  const handleGapContent = () => {
    if (gap >= 40 && windowSize < 1280) return gap / 2
    return gap
  }

  return (
    <div
      className='flex flex-col p-6 w-full flex-1 overflow-hidden shadow'
      style={{
        gap: handleGapContent(),
        borderRadius: 20,
        minHeight: 'fit-content',
      }}
    >
      <span className='font-semibold text-base text-primary-text leading-5'>
        {name}
      </span>

      {children}
    </div>
  )
}

export default ContentContainer
