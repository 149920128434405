import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";

import ModalDelete from "@components/modals/ModalDelete";
import SectionTitle from "@components/section-title";
import Datatable from "@components/tsdatatable/datatables";

import { getListTracking, deleteTracking } from "@actions";
// import moreInfo from "@assets/images/payment/moreInfo.svg";
// import trash from "@assets/images/payment/trash.svg";
// import CustomButton from "@components/buttons/CustomButton";
import { selectListTracking } from "@reducers/tracking.reducer";
import { formatDateTxn } from "@utils/lib";
import { useTranslation } from "@hooks/useTranslation";

const DataTableComponent = ({
  filters,
  numOfPage,
  data,
  setFilters,
  setSelectedTracking,
  setShowDetail,
  setShowFormDelete,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "STT",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Thẻ định danh",
        accessor: "card_id",
        Cell: ({ value }) => <p>{value}</p>,
      },
      {
        Header: "Số thẻ",
        accessor: "card_number",
      },
      {
        Header: "Ngày giờ",
        accessor: "created_at",
        Cell: ({ value }) => <div>{formatDateTxn(value, true)}</div>,
      },
      {
        Header: "Vĩ độ",
        accessor: "latitude",
        Cell: ({ value }) => <p>{value ? value : ""}</p>,
      },
      {
        Header: "Kinh độ",
        accessor: "longitude",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Số Serial",
        accessor: "serial_number",
      },
      // {
      //     Header: "Hành động",
      //     Cell: ({ row }) => {
      //         return (
      //             <div className="flex gap-4 justify-center items-center">
      //                 {/* <img
      //                     src={moreInfo}
      //                     alt="moreInfo"
      //                     style={{ width: 16, cursor: "pointer" }}
      //                     onClick={() => {
      //                         setSelectedTracking(row.original);
      //                         setShowDetail(true);
      //                     }}
      //                 /> */}
      //                 <img
      //                     src={trash}
      //                     alt="trash"
      //                     style={{ width: 16, cursor: "pointer" }}
      //                     onClick={() => {
      //                         setSelectedTracking(row.original);
      //                         setShowFormDelete(true);
      //                     }}
      //                 />
      //             </div>
      //         );
      //     },
      // },
    ],
    [setSelectedTracking, setShowDetail, setShowFormDelete]
  );

  return (
    <Datatable
      columns={columns}
      data={data}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      setFilters={setFilters}
      filters={filters}
    />
  );
};

const Tracking = () => {
  const [showFormDelete, setShowFormDelete] = useState(false);
  const [selectedTracking, setSelectedTracking] = useState(null);
  const dispatch = useDispatch();
  const { language } = useTranslation()

  const data = useSelector(selectListTracking);
  const [numOfPage, setNumOfPage] = useState("1");

  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });

  const customData = useMemo(() => {
    if (!data?.data?.items) return [];
    setNumOfPage(data?.data?.total_page ? data?.data?.total_page : 1);
    return data?.data?.items;
  }, [data]);

  const handleDelete = async (id) => {
    const res = await dispatch(deleteTracking({ data: { channel_id: id } }));
    if (res?.payload?.data) {
      CustomToastMessage.success(
        "Xoá điểm danh thành công",
        `Bạn vừa xoá thành công điểm danh với mã thẻ ${selectedTracking.card_id}.`
      );
      if (customData?.length == 1) {
        setFilters({ ...filters, page: filters.page - 1 });
      } else dispatch(getListTracking(filters));
    } else {
      CustomToastMessage.error(
        "Xoá điểm danh thất bại",
        ERROR_CODE[res.payload.error_code][language]
      );
    }
  };

  useEffect(() => {
    dispatch(getListTracking(filters));
  }, [dispatch, filters]);

  return (
    <>
      <div className="flex w-full justify-between items-center">
        <SectionTitle title="Điểm danh" subtitle="Điểm danh" />
        {/* <CustomButton type="add" outline title={"Thêm"} onClick={() => {}} /> */}
      </div>

      <div className="mt-8">
        <DataTableComponent
          filters={filters}
          setFilters={setFilters}
          data={customData}
          numOfPage={numOfPage}
          setSelectedTracking={setSelectedTracking}
          setShowFormDelete={setShowFormDelete}
        />
      </div>

      <ModalDelete
        title="Xóa điểm danh"
        desc={`Bạn có chắc chắn muốn xóa điểm danh với mã thẻ ${selectedTracking?.card_id}?`}
        isOpen={showFormDelete}
        onClose={() => setShowFormDelete(false)}
        item={selectedTracking}
        handleAction={handleDelete}
      />
    </>
  );
};

export default Tracking;
