import React from 'react'
import logoIcon from '@assets/images/accept-cookie/logo.svg'
import useWindowSize from '@hooks/useWindowSize'
import CustomButton from '../../buttons/CustomButton'
import { useTranslation } from '@hooks/useTranslation'

const AcceptCookie = ({ title, description, onClick }) => {
  const { isMd } = useWindowSize()
  const { translate } = useTranslation()

  return (
    <div
      className='fixed inset-0'
      style={{
        backgroundColor: '#000000B2',
        zIndex: 9999,
      }}
    >
      <div className='absolute animate-fade-in bottom-0 inset-x-0 bg-white p-4 md:py-8 md:px-10 flex flex-col md:flex-row gap-6 md:gap-15 md:justify-between md:items-center'>
        <div className='flex flex-col md:flex-row md:gap-10 items-start md:flex-1 gap-4'>
          <img
            src={logoIcon}
            alt='logo'
            style={{
              height: isMd ? 60 : 40,
            }}
          />

          <div className='flex flex-col gap-2'>
            <span
              className='font-bold text-base md:text-2xl text-primary-text'
              style={{
                lineHeight: isMd ? '30px' : '20px'
              }}
            >
              {title}
            </span>

            <p
              className='font-normal text-sm md:text-base text-secondary-text'
              style={{
                lineHeight: isMd ? '24px' : '22px'
              }}
            >
              {description}
            </p>
          </div>
        </div>

        <CustomButton
          title={translate('cookie:accept')}
          style={{
            borderRadius: 8,
            width: isMd && 157,
            height: 44
          }}
          onClick={onClick}
        />
      </div>
    </div>
  )
}

export default AcceptCookie
