import portalClient from "./client_axios";
import { FEEDBACK } from "@constants/endpoint";

export const feedbackServices = {
  getFeedbackById: (data) => {
    const query = new URLSearchParams(data);
    return portalClient.get(`${FEEDBACK}?${query}`);
  },

  getListFeedback: (data) => {
    const query = new URLSearchParams(data);
    return portalClient.get(`${FEEDBACK}?${query}`);
  },

  createFeedback: (params) => {
    return portalClient.post(FEEDBACK, params);
  },

  updateFeedback: (params) => {
    return portalClient.put(FEEDBACK, params);
  },

  deleteFeedback: (params) => {
    return portalClient.delete(FEEDBACK, params);
  },
};
