import portalClient from "./client_axios";
import { GET_LIST_EDC } from "@constants/endpoint";

export const edcServices = {
  mapEDC: (params) => {
    return portalClient.put('/map-edc-to-va', params)
  },

  getListEDC: (params) => {
    return portalClient.get(`${GET_LIST_EDC}`, { params })
  },
};
