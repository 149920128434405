import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { history } from "@helpers";
import { getProfile } from "@actions/user.actions";
import { getBusiness } from "@actions/business.actions";
import { getMerchant } from "@actions/sass.action";

const GetProfile = () => {
  const dispatch = useDispatch()
  let location = useLocation()

  useEffect(() => {
    const userToken = localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : false;
    if (userToken) {
      dispatch(getProfile())
      dispatch(getMerchant())
      dispatch(getBusiness())
    } else {
      if (!['/login', "/forgot-password", "/reset-password", "/portal"].includes(location.pathname)) {
        history.push("/login")
      }
    }
  }, []);

  return <></>;
};

export default GetProfile;
