import { createAsyncThunk } from "@reduxjs/toolkit";
import { orderServices } from "@services";

export const getListOrder = createAsyncThunk(
  "store/order/getListOrder",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await orderServices.getListOrder(data);
    return _data
  }
);

export const getOrderById = createAsyncThunk(
  "store/order/getOrderById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await orderServices.getOrderById(data);
    return _data
  }
);

export const createOrder = createAsyncThunk(
  "store/order/createOrder",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await orderServices.createOrder(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateOrder = createAsyncThunk(
  "store/order/updateOrder",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await orderServices.updateOrder(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "store/order/deleteOrder",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await orderServices.deleteOrder(data);
    return _data;
  }
);
