import { createSlice } from "@reduxjs/toolkit"
import { forgotPassword, getProfile, login, resetPassword } from "@actions/user.actions"
import { LocalStorageService } from "@helpers/localStorageService";
import { history } from "@helpers/history";

const initialState = {
  profile: {},
  emailForgotPw: '',
  isResetPwSuccess: false,
  isLoading: false,
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    logout: (state, action) => {
      LocalStorageService.clearToken();
      history.push('/login');
      state.profile = {};
    },
    clearEmailForgotPw: (state, action) => {
      state.emailForgotPw = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.fulfilled, (state, action) => {
        state.profile = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.profile = {};
        state.isLoading = false;
      })

      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload && action.payload.data) {
          LocalStorageService.setToken(action.payload.data);
          LocalStorageService.setRefreshToken(action.payload.data);
        }
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.emailForgotPw = action.meta.arg.email
        state.isLoading = false;
      })
      .addCase(forgotPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isResetPwSuccess = true
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
      })
  }
})


export const selectProfile = state => state.user.profile;
export const selectIsLoading = state => state.user.isLoading;
export const selectIsResetPwSuccess = state => state.user.isResetPwSuccess;
export const selectEmailForgotPw = state => state.user.emailForgotPw
export const { logout, clearEmailForgotPw } = userSlice.actions;


export default userSlice.reducer;