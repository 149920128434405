import { createSlice } from "@reduxjs/toolkit"
import { getListInvoice } from "@actions/invoice.actions"

const initialState = {
    listInvoice: [],
    isLoading: false,
}

const invoiceSlice = createSlice({
    name: 'invoiceSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getListInvoice.fulfilled, (state, action) => {
                state.listInvoice = action.payload;
            })
            .addCase(getListInvoice.rejected, (state, action) => {
                state.listInvoice = [];
            })
    }
})

export const selectListInvoice = state => state.invoice.listInvoice;
export const selectIsLoading = state => state.invoice.isLoading;

export default invoiceSlice.reducer;