import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getMerchant, updateMerchant } from '@actions'
import CustomButton from '@components/buttons/CustomButton'
import InputField from '@components/fields/InputField'
import PrefixPostfixInput from '@components/fields/PrefixPostfixInput'
import { useTranslation } from '@hooks/useTranslation'
import { selectBusiness } from '@reducers/business.reducer'
import { selectMerchant } from '@reducers/sass.reducer'
import { CustomToastMessage } from '@utils/customToastMsg'
import { ERROR_CODE } from '@constants/errorCode'
import { formatWebsite } from '../table/PopupQR'
import UploadImage from './UploadImage'
import UploadLogo from './UploadLogo'
import { EMENU_SETTING_FORM } from '@constants/sale-management/setting'

const Emenu = () => {
  const { translate, language } = useTranslation('sale_settings')
  const dispatch = useDispatch()
  const merchant = useSelector(selectMerchant)

  const business = useSelector(selectBusiness)

  const [loading, setLoading] = useState(false)

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm()

  const onSubmit = async (value) => {
    setLoading(true)
    try {
      const res = await dispatch(
        updateMerchant({
          ...value,
          [EMENU_SETTING_FORM.WEBSITE]: value[
            EMENU_SETTING_FORM.WEBSITE
          ].replaceAll(' ', ''),
        })
      )
      if (res?.meta?.requestStatus === 'fulfilled') {
        CustomToastMessage.success(
          translate('txn:status_success'),
          translate('update_emenu_success')
        )
        dispatch(getMerchant())
      } else
        CustomToastMessage.error(
          translate('fail'),
          ERROR_CODE[res?.payload?.error_code][language]
        )
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    setValue(EMENU_SETTING_FORM.ADDRESS, business?.address)
    setValue(EMENU_SETTING_FORM.HOTLINE, business?.phone)
  }, [business.position, dispatch])

  useEffect(() => {
    if (merchant)
      reset({
        ...merchant,
        // [EMENU_SETTING_FORM.WEBSITE]: formatWebsite(merchant[EMENU_SETTING_FORM.WEBSITE]),
        [EMENU_SETTING_FORM.ADDRESS]: watch(EMENU_SETTING_FORM.ADDRESS),
        [EMENU_SETTING_FORM.HOTLINE]: watch(EMENU_SETTING_FORM.HOTLINE),
      })
  }, [merchant])

  const disabled =
    !watch(EMENU_SETTING_FORM.LOGO) ||
    !watch(EMENU_SETTING_FORM.BANNER) ||
    // !watch(EMENU_SETTING_FORM.ADDRESS) ||
    // !watch(EMENU_SETTING_FORM.HOTLINE) ||
    !watch(EMENU_SETTING_FORM.WEBSITE) ||
    loading

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='p-8 rounded-xl bg-white shadow-primary flex flex-col gap-6'
    >
      {/* <p className='font-bold text-lg'>{translate('business_info')}</p> */}
      <div className='flex gap-6'>
        <div>
          <div className='flex flex-col mb-2'>
            <p className='text-blackgray font-bold leading-6 text-base inline-block pr-2'>
              Logo
            </p>
            <span className='text-grey-atom-grey font-medium leading-6 text-xs truncate'>
              <p
                className='text-red-500 leading-6 text-xs inline-block'
                style={{
                  marginRight: 2,
                }}
              >
                *
              </p>
              PNG, JPG, JPEG {translate('common:max_file_size')} 500KB
            </span>
          </div>

          <UploadLogo
            register={register}
            initValue={watch(EMENU_SETTING_FORM.LOGO)}
            name={EMENU_SETTING_FORM.LOGO}
            setValue={setValue}
            isRequired
          />
        </div>

        <div style={{ width: '1px', backgroundColor: '#F2F2F2' }} />

        <div>
          <div className='flex flex-col mb-2'>
            <p className='text-blackgray font-bold leading-6 text-base'>
              {translate('banner')}
            </p>
            <span className='text-grey-atom-grey font-medium leading-6 text-xs'>
              <p
                className='text-red-500 leading-6 text-xs inline-block'
                style={{
                  marginRight: 2,
                }}
              >
                *
              </p>
              PNG, JPG, JPEG {translate('common:max_file_size')} 700KB
            </span>
          </div>

          <UploadImage
            register={register}
            initValue={watch(EMENU_SETTING_FORM.BANNER)}
            name={EMENU_SETTING_FORM.BANNER}
            setValue={setValue}
            isRequired
          />

          <InputField
            isRequired
            setValue={setValue}
            label={translate('address')}
            placeholder={translate('input_address')}
            id={EMENU_SETTING_FORM.ADDRESS}
            name={EMENU_SETTING_FORM.ADDRESS}
            value={watch(EMENU_SETTING_FORM.ADDRESS)}
            extra='mt-3 '
            register={register}
            errors={errors}
            readOnly={true}
          />
          <InputField
            isRequired
            setValue={setValue}
            label='Hotline'
            placeholder={translate('input_hotline')}
            id={EMENU_SETTING_FORM.HOTLINE}
            name={EMENU_SETTING_FORM.HOTLINE}
            value={watch(EMENU_SETTING_FORM.HOTLINE)}
            extra='mt-3 '
            register={register}
            type='text'
            errors={errors}
            readOnly={true}
          />
          {/* <InputField
            isRequired
            setValue={setValue}
            label='Link website'
            placeholder={translate('input_website_link')}
            id={FORM.WEBSITE}
            name={FORM.WEBSITE}
            value={watch(FORM.WEBSITE)}
            extra='mt-3 '
            register={register}
            errors={errors}
          /> */}
          <PrefixPostfixInput
            isRequired
            setValue={setValue}
            label='Link website'
            placeholder={translate('input_website_link')}
            id={EMENU_SETTING_FORM.WEBSITE}
            name={EMENU_SETTING_FORM.WEBSITE}
            value={watch(EMENU_SETTING_FORM.WEBSITE)}
            extra='mt-3 '
            register={register}
            errors={errors}
            prefix={process.env.REACT_APP_EMENU_URL}
            inputStyle={{ width: 382 }}
            // prefix={'https://stag-emenu.atompos.vn/'}
          />

          <CustomButton
            disabled={disabled}
            type='submit'
            title={translate('common:save')}
            className={'w-full mt-8'}
          />
        </div>
      </div>
    </form>
  )
}

export default Emenu
