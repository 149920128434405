import React, { useEffect } from 'react'
import { useTranslation } from '@hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { theme } from 'configTheme'
import { selectProfile } from '@reducers/user.reducer';
import { selectSidebar, setActivePath, setCurrentOpenSubItems, setIsCollapsed, setIsShow } from '@reducers/sidebar.reducer';
import SidebarItem from './SidebarItem';
import ListItem from './ListItem';
import atomLogo from "@assets/images/sidebar/atomLogo.svg";
import IcAvatar from "@assets/images/icAvatarSidebar.svg";
import { SlArrowLeft } from 'react-icons/sl';
import { LogoutIcon } from './SidebarIcon';
import { IoClose } from 'react-icons/io5';
import useWindowSize from '@hooks/useWindowSize';

const Sidebar = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslation('sidebar')
  const { pathname } = useLocation()
  const { isCollapsed, isShow, currentOpenSubItems } = useSelector(selectSidebar)
  const { saleperson } = useSelector(selectProfile)
  const [showInfo, setShowInfo] = React.useState(true)
  const { isMd } = useWindowSize()

  useEffect(() => {
    dispatch(setActivePath('/' + pathname.split('/')[1]))
  }, [pathname])

  useEffect(() => {
    dispatch(setIsCollapsed(false))
    dispatch(setIsShow(isMd))
  }, [isMd])

  return (
    <div
      className={`absolute md:relative ${isShow ? "z-50 bg-blur w-full md:bg-transparent md:w-auto" : "z-10 bg-transparent w-0"}`}
      onClick={() => dispatch(setIsShow(false))}
    >
      <div
        style={{ width: isCollapsed ? 112 : 250, transform: !isShow && "translateX(-100%)" }}
        onClick={e => e.stopPropagation()}
        className='bg-gradient h-screen rounded-r-3xl px-4 py-8 flex flex-col justify-between duration-200 relative'
      >
        <div className="">
          <img src={theme.sidebarLogo} alt="logo" className='w-auto mb-8' style={{ maxHeight: 40 }} />

          <div
            onClick={() => dispatch(setIsShow(false))}
            style={{ zIndex: 41, right: 16, top: 32 }}
            className="absolute md:hidden cursor-pointer w-8 h-8 flex justify-center items-center"
          >
            <IoClose size={24} color={"white"} />
          </div>

          <button
            onClick={() => {
              dispatch(setCurrentOpenSubItems(''))
              setShowInfo(isCollapsed)
              setTimeout(() => {
                dispatch(setIsCollapsed(!isCollapsed))
              }, !isCollapsed && currentOpenSubItems && 200)
            }}
            style={{
              zIndex: 41,
              left: "100%",
              top: 32,
              transform: "translateX(-50%)",
            }}
            className="absolute hidden md:flex w-8 h-8 border border-primary bg-white rounded-lg justify-center items-center"
          >
            <SlArrowLeft
              size={16}
              color={theme.primary}
              style={{ transform: !isCollapsed && "rotate(180deg)" }}
              className='duration-200'
            />
          </button>

          <ListItem />
        </div>

        <div className="space-y-4">
          <div className={`flex flex-col border-b border-white pb-3 duration-200 ${isCollapsed ? 'mx-3 gap-2' : 'gap-5'}`}>
            <div
              onMouseOver={() => isCollapsed && setShowInfo(true)}
              onMouseOut={() => isCollapsed && setShowInfo(false)}
              className={`flex items-center gap-3 p-3 rounded-2xl bg-sidebar-hover relative cursor-pointer ${isCollapsed && "justify-center"}`}
            >
              <img className={`${isCollapsed ? "w-8 h-8" : "w-10 h-10"} rounded-full shadow`} src={IcAvatar} alt="avatar" />

              <div
                style={{
                  gridTemplateRows: showInfo ? '1fr' : '0fr',
                  ...(isCollapsed && {
                    left: '100%', top: 0,
                    width: 'max-content',
                    opacity: showInfo ? 1 : 0,
                    padding: showInfo && 12,
                  }),
                }}
                className={`grid ${showInfo && 'duration-200'} ${isCollapsed && 'absolute z-50 rounded-lg bg-gradient shadow-2xl'}`}
              >
                <div className='text-white space-y-1 overflow-hidden'>
                  <p className="text-sm leading-5 font-semibold">{saleperson?.name}</p>
                  <p className="text-xs leading-4">{saleperson?.phone}</p>
                </div>
              </div>
            </div>

            <SidebarItem item={{ icon: <LogoutIcon />, title: translate("logout"), link: "/login" }} center />
          </div>

          <div className={`flex justify-center items-center gap-1 ${isCollapsed && "flex-col"}`}>
            <p className="text-white text-xs leading-4 font-medium truncate">Powered by</p>
            <img src={atomLogo} alt="atomLogo" className="h-5 w-auto" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar