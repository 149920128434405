import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectListCategory } from '@reducers/category.reducer'
import { createCategory, getListCategory } from '@actions/category.actions';
import { CustomToastMessage } from '@utils/customToastMsg';

import add from '@assets/images/icon/addCate.svg'
import { useTranslation } from '@hooks/useTranslation';

const CateCell = ({ isActive = false, value, id, color, setListCate }) => {
    const colors = {
        red: { bg: '#FFF1F0', border: '#FFA39E', text: '#F5222D' },
        green: { bg: '#EBFAEF', border: '#B3EBC5', text: '#2EB553' },
        yellow: { bg: '#FEFFE6', border: '#FFFB8F', text: '#D4B106' },
        purple: { bg: '#F9F0FF', border: '#D3ADF7', text: '#722ED1' },
    }
    const [active, setActive] = useState(isActive)
    useEffect(() => setActive(isActive), [isActive])

    return (
        <button
            type="button" onClick={() => {
                if (active) { setListCate(prev => prev.filter(item => item != id)) }
                if (!active) { setListCate(prev => [...prev, id]) }
                setActive(!active)
            }}
            style={{ padding: '10px 12px', background: colors[color]?.bg, border: `1px solid ${colors[color]?.border}` }}
            className={`rounded-3xl border flex items-center gap-1 ${!active && 'opacity-30'}`}
        >
            <p style={{ color: colors[color]?.text }} className="text-sm leading-5 font-medium">{value}</p>
        </button>
    )
}

const BtnAddCate = () => {
    const dispatch = useDispatch()
    const [value, setValue] = useState('')
    const { translate } = useTranslation('sale_product')

    const handleCreateCate = async () => {
        const res = await dispatch(createCategory({ name: value, description: value }))
        if (res.payload.id) {
            setValue('')
            dispatch(getListCategory())
        } else CustomToastMessage.error(translate('add_product_catalogue_fail'))
    }

    return (
        <div
            type="button"
            style={{ padding: '10px 12px', background: '#D7D8DB' }}
            className={`rounded-3xl border flex items-center gap-1`}
        >
            {/* <p style={{ color: '#002766' }} className="text-sm leading-5 font-medium">Thêm</p> */}
            <input
                value={value}
                onChange={e => setValue(e.target.value)}
                onKeyDown={(e) => { if (e.key == 'Enter' && value) handleCreateCate() }}
                placeholder={translate('input_product_catalogue')} style={{ width: 116, color: '#002766' }}
                className='bg-transparent text-sm leading-5 font-medium'
            />
            <img
                onClick={() => { if (value) handleCreateCate() }}
                src={add} alt="add" className='w-5 cursor-pointer'
            />
        </div>
    )
}

const SelectCateField = ({ listCate = [], setListCate }) => {
    const dispatch = useDispatch();
    const colors = ['red', 'green', 'yellow', 'purple']
    const { categories } = useSelector(selectListCategory)
    const { translate } = useTranslation('sale_product')

    useEffect(() => {
        dispatch(getListCategory());
    }, [dispatch]);

    useEffect(() => setListCate(listCate), [listCate.length])

    return (
        <div className="space-y-2">
            <p className="text-primary-text text-sm leading-5 font-semibold">{translate('product_catalogue')}</p>
            <div className="flex flex-wrap gap-3">
                {categories && categories.length !== 0 && categories.map((item, index) =>
                    <div key={index}>
                        <CateCell
                            isActive={listCate.includes(item?.category_id)}
                            value={item?.category_name}
                            id={item?.category_id}
                            color={colors[index % 4]}
                            setListCate={setListCate}
                        />
                    </div>
                )}
                <BtnAddCate />
            </div>
        </div>
    )
}

export default SelectCateField