import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sellRegisterFree } from "@actions";
import { sell } from "@assets";
import { selectProfile } from "@reducers/user.reducer";
import { CustomToastMessage } from "@utils/customToastMsg";
import CardWrapper from "./CardWrapper";
import ModalEnterCode from "./ModalEnterCode";

const Select = ({ handleNextScreen }) => {
  const dispatch = useDispatch();
  const { merchant_id } = useSelector(selectProfile)
  const [showModal, setShowModal] = useState(false);

  const handleClickCode = () => setShowModal(true);
  const handleClickQr = () => CustomToastMessage.info("Tính năng đang phát triển");

  const handleClickFree = async () => {
    try {
      const res = await dispatch(sellRegisterFree({ entity_id: merchant_id }));
      if (res?.meta?.requestStatus === "fulfilled") {
        handleNextScreen();
      } else CustomToastMessage.error("Thất bại", res?.payload?.message);
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <>
      <div className="p-10 shadow-primary mt-10">
        <div className="grid grid-cols-1 md:gap-4 lg:gap-6 items-center justify-between">

          <CardWrapper onClick={handleClickFree} backgroundImg={sell.bgCardFree} className=''>
            <img src={sell.cardFree} alt="card" width={152} height={40} />

            <div className="flex items-center gap-3 mt-4 mb-8">
              {/* <img src={sell.icCheck} alt="icCheck" width={21.5} height={21.5} />
              <p className="text-blackgray text-base leading-6">Sử dụng miễn phí 14 ngày</p> */}
            </div>
          </CardWrapper>

          {/* <CardWrapper onClick={handleClickCode} backgroundImg={sell.bgCardVip}>
            <img src={sell.cardVip} alt="card" width={152} height={40} />

            <div className="flex items-center gap-3 mt-4 mb-8">
              <img src={sell.icCheck} alt="icCheck" width={21.5} height={21.5} />
              <p className="text-white text-sm leading-5">Mã mua trọn gói hoặc mã giới thiệu</p>
            </div>
          </CardWrapper>

          <CardWrapper onClick={handleClickQr} backgroundImg={sell.bgCardQr}>
            <img src={sell.cardQr} alt="card" width={152} height={40} />

            <div className="flex items-center gap-3 mt-4 mb-8">
              <p style={{ color: "#7C7878" }} className=" text-sm leading-5">
                <span className="text-blackgray text-2xl font-bold">100.000đ</span>
                /Tháng
              </p>
            </div>
          </CardWrapper> */}
        </div>
      </div>
      <ModalEnterCode isOpen={showModal} onClose={() => setShowModal(false)} handleNextScreen={handleNextScreen} />
    </>
  );
};

export default Select;
