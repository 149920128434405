import React from "react";
import { sell } from "@assets";

const Step4 = () => {
  return (
    <div style={{ marginTop: 48 }} className="flex flex-col items-center w-full ">
      <img src={sell.icSuccess} alt="icSuccess" className="w-8 h-8" />

      <p style={{ marginBottom: 48 }} className="text-grey-8 text-base font-medium leading-6 mt-2">
        Khởi tạo cửa hàng thành công
      </p>

      <img src={sell.step4} alt="step4" width={300} height={300} style={{ borderRadius: 9 }} />
    </div>
  );
};

export default Step4;
