import React, { useEffect, useState } from 'react'
import { IoCloseSharp } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { createPChannel } from '@actions/va.action'
import { selectIsLoading } from '@reducers/va.reducer'
import { CustomToastMessage } from '@utils/customToastMsg'
import { formatBankAccount, formatHolderName } from '@utils/lib'

import CustomButton from '@components/buttons/CustomButton'
import SelectField from '@components/fields/SelectInPaymentField'
import useClickOutside from '@hooks/useClickOutside'
import { useTranslation } from '@hooks/useTranslation'
import { metadataServices, bankServices } from '@services'
import { ERROR_CODE } from '@constants/errorCode'
import { LIST_CHANNEL, PCHANNEL_TITLE, VA_TYPE, VA_TYPE_LIST } from '@constants/shop-qr'

const CreatePChannel = ({ setShowForm, refectch }) => {
  const dispatch = useDispatch()
  const { nodeRef } = useClickOutside(() => setShowForm(false))
  const { translate, language } = useTranslation('bank')

  const [storeName, setStoreName] = useState(null)
  const [vaType, setVaType] = useState(VA_TYPE.STORE) //vaType is activeTab
  const [bankAcc, setBankAcc] = useState('')
  const [storeId, setStoreId] = useState('')
  const [staffId, setStaffId] = useState('')
  const [channel, setChannel] = useState('')

  const [listBank, setListBank] = useState([])
  const [listStore, setListStore] = useState([])
  const [listStaff, setListStaff] = useState([])

  const isLoading = useSelector(selectIsLoading)

  const handleGetOptionsData = async () => {
    try {
      const resBank = await bankServices.getTableBank({ page_size: 100000 })
      setListBank(resBank.data.data.map(({ bank_code, account_no, holder_name }) => ({
        label: `${bank_code} - ${formatHolderName(holder_name)} - ${formatBankAccount(account_no)}`,
        value: account_no,
      })))

      const resStore = await metadataServices.getListStore({ page_size: 100000 })
      setListStore(resStore.data.data.map((store) => ({
        label: store.name,
        value: store.id,
        storeName: store.name,
      })))

      const resStaff = await metadataServices.getListStaff({ page_size: 100000 })
      setListStaff(resStaff.data.data.map((staff) => ({
        label: staff.full_name,
        value: staff.staff_id,
        storeName: staff.store_name,
      })))
    } catch (error) { console.log(error) }
  }

  useEffect(() => {
    handleGetOptionsData()
  }, [dispatch])

  const handleSubmit = async () => {
    const payload = new FormData()
    payload.append('bank_account_no', bankAcc)
    payload.append('type', vaType)
    if (vaType == VA_TYPE.STORE) {
      payload.append('store_id', storeId)
      payload.append('store_name', storeName)
    } else {
      payload.append('staff_id', staffId)
      if (vaType == VA_TYPE.BILL) payload.append('channel', channel)
    }
    const res = await dispatch(createPChannel(payload))
    if (res?.payload?.data) {
      setShowForm(false)
      CustomToastMessage.success(
        `${translate(
          'shop_qr:management_at_counter_success_title1'
        )} ${PCHANNEL_TITLE} ${translate(
          'shop_qr:management_at_counter_success_title2'
        )}`,
        `${translate(
          'shop_qr:management_at_counter_success_des'
        )} ${PCHANNEL_TITLE} ${storeName}.`
      )
      refectch()
    } else {
      setShowForm(false)
      CustomToastMessage.error(
        `${translate(
          'shop_qr:management_at_counter_success_title1'
        )} ${PCHANNEL_TITLE} ${translate(
          'shop_qr:management_at_counter_fail_title'
        )}`,
        ERROR_CODE[res.payload.error_code][language]
      )
    }
  }

  const fields = [
    {
      title: `${translate('shop_qr:management_add_store')}`,
      selectField: (
        <SelectField
          data={listStore}
          placeHolder={translate('shop_qr:management_select_store')}
          setValue={setStoreId}
          setStoreName={setStoreName}
        />
      ),
    },
    {
      title: `${translate('shop_qr:management_add_staff')}`,
      selectField: (
        <SelectField
          data={listStaff}
          placeHolder={translate('shop_qr:management_select_staff')}
          setValue={setStaffId}
          setStoreName={setStoreName}
        />
      ),
    },
    {
      title: `${translate('shop_qr:management_sale_channel')}`,
      selectField: (
        <SelectField
          data={LIST_CHANNEL.map((channel) => ({
            label: translate(channel.label),
            value: channel.value
          }))}
          placeHolder={translate('shop_qr:management_select_sale_channel')}
          setValue={setChannel}
        />
      ),
    },
  ]

  const disabled = !bankAcc || isLoading ||
    (vaType == VA_TYPE.STORE && !storeId) ||
    (vaType == VA_TYPE.STAFF && !staffId) ||
    (vaType == VA_TYPE.BILL && (!staffId || !channel))

  return (
    <div className='fixed z-50 inset-0 bg-blur overflow-auto flex justify-center items-center'>
      <div
        // marginTop: '8.43%'
        ref={nodeRef}
        style={{ color: '#0F1D40', maxWidth: 436, height: 'min-content' }}
        className='bg-white rounded-2xl p-10 w-full relative'
      >
        {/* Title */}
        <h3 className='text-2xl leading-8 font-bold text-center'>
          {translate('shop_qr:management_add_shop_qr')}
        </h3>
        {/* Close icon */}
        <IoCloseSharp
          size={24}
          onClick={() => setShowForm(false)}
          style={{
            position: 'absolute',
            top: 16,
            right: 16,
            cursor: 'pointer',
          }}
        />
        {/* Tabs */}
        <div
          className='grid grid-cols-3'
          style={{ marginTop: 32, borderBottom: '0.5px solid #262626' }}
        >
          {VA_TYPE_LIST.map((tab, index) => {
            return (
              <div
                onClick={() => {
                  setVaType(tab)
                }}
                className={`flex justify-center items-center text-base leading-6 font-semibold 
                    ${vaType === tab && ' border-primary'}`}
                key={index}
                style={{
                  cursor: 'pointer',
                  padding: '10px 16px',
                  borderBottomWidth: 2,
                  color: vaType === tab ? '#262626' : '#B1BECF',
                }}
              >
                {tab == VA_TYPE.STORE
                  ? `${translate('shop_qr:management_add_store')}`
                  : tab == VA_TYPE.STAFF
                    ? `${translate('shop_qr:management_add_staff')}`
                    : `${translate('shop_qr:management_add_bill')}`}
              </div>
            )
          })}
        </div>
        {/* Select fields */}
        <div className='flex flex-col gap-4 mt-8'>
          <div className='flex flex-col gap-2'>
            <p
              style={{ fontSize: 14, color: '#505B76' }}
              className='leading-5 font-semibold capitalize'
            >
              {translate('bank')}
              <span className='text-error'>*</span>
            </p>
            <SelectField
              data={listBank}
              placeHolder={translate('shop_qr:add_bank_name_placeholder')}
              setValue={setBankAcc}
            />
          </div>
          {fields.map((field, index) => (
            <div
              style={{
                display:
                  (vaType == VA_TYPE.STORE && (index == 1 || index == 2)) ||
                    (vaType == VA_TYPE.STAFF && (index == 0 || index == 2)) ||
                    (vaType == VA_TYPE.BILL && index == 0)
                    ? 'none'
                    : 'block',
              }}
              key={index}
              className='space-y-2'
            >
              <p
                style={{ fontSize: 14, color: '#505B76' }}
                className='leading-5 font-semibold'
              >
                {field.title}
                <span className='text-error'>*</span>
              </p>
              {field.selectField}
            </div>
          ))}
          {vaType == VA_TYPE.BILL && (
            <div className='space-y-2'>
              <p
                style={{ fontSize: 14, color: '#505B76' }}
                className='leading-5 font-semibold'
              >
                {translate('shop_qr:management_add_bill_code')}
                {/* <span style={{ color: "#ED1C24" }}>*</span> */}
                <input
                  style={{ border: '1px solid #D9DBE9' }}
                  placeholder={translate(
                    'shop_qr:management_add_bill_code_placeholder'
                  )}
                  className='w-full p-3 rounded-lg text-sm leading-5 font-medium'
                />
              </p>
            </div>
          )}
        </div>
        {/* Buttons */}
        <div className='grid grid-cols-2 gap-4' style={{ marginTop: 42 }}>
          <CustomButton
            title={translate('common:cancel')}
            outline={true}
            onClick={() => setShowForm(false)}
          />
          <CustomButton
            title={translate('common:add')}
            disabled={disabled}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}

export default CreatePChannel
