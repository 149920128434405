import React from 'react'
import { formatCurrency } from '@utils/lib'
import { useTranslation } from '@hooks/useTranslation'
import { PRODUCT_TILES } from '@constants/sale-management/table'

const ProductList = ({ pickedItem }) => {
    const { translate } = useTranslation('sale_order')

    return (
        <div className='overflow-y-scroll' style={{ height: '200px' }}>
            <div className='flex sticky top-0 left-0 bg-white' style={{ padding: '5px 0px 5px 0px' }}>
                {PRODUCT_TILES.map((item, index) => (
                    <p
                        className={`flex items-center ${index === 0 || index === 1 ? 'justify-start px-2 py-4' : 'justify-end p-4'} text-sm font-bold leading-5`}
                        style={{ width: index === 0 ? '80px' : '210px', color: '#262626' }} key={index}
                    >{translate(item)}</p>
                ))}
            </div>
            {pickedItem.map((item, index) => (
                <div className='flex flex-col' key={index}>
                    <p
                        className='flex items-center text-sm font-normal leading-5'
                        style={{ color: '#262626', height: '52px' }}
                    >
                        <span className=' px-2 py-4' style={{ width: '80px' }}>{index + 1}</span>
                        <span className=' px-2 py-4' style={{ width: '210px' }}>{item.name}</span>
                        <span className='flex justify-end p-4' style={{ width: '210px' }}>{item.amount} {translate('item')}</span>
                        <span className='flex justify-end p-4' style={{ width: '210px' }}>
                            {formatCurrency(item.price)}
                        </span>
                        <span className='flex justify-end p-4' style={{ width: '210px' }}>
                            {formatCurrency(item.price * item.amount)}
                        </span>
                    </p>
                </div>
            ))}
        </div>
    )
}

export default ProductList