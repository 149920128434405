import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteBankVam, getTableBank, verifyBank } from "@actions/bank.actions";
import trash from "@assets/images/payment/trash.svg";
import CustomButton from "@components/buttons/CustomButton";
import ModalDelete from "@components/modals/ModalDelete";
import SectionTitle from "@components/section-title";
import Datatable, { StatusCell } from "@components/tsdatatable/datatables";
import { useTranslation } from "@hooks/useTranslation";
import { selectListBankMms } from "@reducers/bank.reducer";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";
import { formatBankAccount, formatHolderName } from "@utils/lib";
import useSearchParams from "../../sale-management/customer/useSearchParams";
import icVerified from '@assets/images/bank/ic_qr_verified.svg'
import icNotVerified from '@assets/images/bank/ic_qr_not_verified.svg'
import ModalQrVerify from "./add-bank/ModalQrVerify";
import { getListAccountNumber } from "@actions/metadata.actions";
import { selectProfile } from "@reducers/user.reducer";
import { ACCOUNT_TYPE, BANK_NAME_SHORT, PCHANNEL_TITLE } from "@constants/shop-qr";
import { STATUS_CELL_COLOR } from "@constants/common";

const DataTableComponent = ({
  filters,
  setFilters,
  numOfPage,
  data,
  setBank,
  setIsOpenModal,
  translate,
  handleSetShowQr,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: `${translate("common:numerical_order")}`,
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      // {
      //   Header: `${translate("shop_qr:bank_name")}`,
      //   accessor: "logo_url",
      //   Cell: ({ value, row }) => {
      //     return (
      //       <div className="flex items-center gap-3">
      //         <img src={value} alt="logo" className="h-6 w-auto" />
      //         <span className="text-14px text-dark-primary leading-5">
      //           {row.original.bank_code}
      //         </span>
      //       </div>
      //     );
      //   },
      // },
      {
        Header: `${translate("shop_qr:account_number")}`,
        accessor: "name",
        Cell: ({ value, row }) => {
          return <span className="text-14px text-dark-primary">{value}</span>;
        },
        disableSortBy: true,
      },
      {
        Header: `${translate("shop_qr:account_name")}`,
        accessor: "holder_name",
        Cell: ({ value }) => formatHolderName(value),
        disableSortBy: true,
      },
      {
        Header: translate('shop_qr:status'),
        accessor: "is_internal_transfer",
        Cell: ({ value }) => {
          const status = value === true ? translate('verified') : translate('not_verified');
          return (
            <StatusCell
              color={
                status == translate('verified') ? STATUS_CELL_COLOR.BLUE : STATUS_CELL_COLOR.LIGHT_BLUE
              }
              title={status}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: `${translate("common:actions")}`,
        accessor: "id",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-2">
              <img
                src={trash}
                alt="trash"
                style={{
                  width: 16,
                  cursor: row?.original.id !== 0 && row?.original.is_internal_transfer ? "pointer" : "",
                  opacity: row?.original.id !== 0 && row?.original.is_internal_transfer ? "" : "50%"
                }}
                onClick={() => {
                  if (row?.original.id !== 0 && row?.original.is_internal_transfer) {
                    setBank(row?.original);
                    setIsOpenModal(true);
                  }
                }}
              />
              <img
                src={row?.original.is_internal_transfer ? icVerified : icNotVerified}
                alt="qr"
                style={{ width: 24, cursor: !row?.original.is_internal_transfer ? "pointer" : "" }}
                onClick={() => {
                  if (!row?.original.is_internal_transfer) {
                    setBank(row?.original);
                    handleSetShowQr(row?.original)
                  }
                }}
              />
            </div>
          );
        },
        disableSortBy: true,
      },
    ],
    [handleSetShowQr, setBank, setIsOpenModal, translate],
  );

  return (
    <Datatable
      columns={columns}
      data={data}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
      textLeft
    // total_request_amount={formatCurrency(data?.data?.total_request_amount)}
    />
  );
};

const Bank = () => {
  const { translate, language } = useTranslation('bank');
  const dispatch = useDispatch();
  const history = useHistory();

  const data = useSelector(selectListBankMms);
  const { account_type } = useSelector(selectProfile)

  const [bank, setBank] = useState(null);
  const [showModalQr, setShowModalQr] = React.useState(false);
  const [base64String, setBase64String] = useState();
  const [numOfPage, setNumOfPage] = useState("1");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { updatePagination, filtersParams } = useSearchParams();

  const [filters, setFilters] = useState(filtersParams);

  useEffect(() => {
    updatePagination(new URLSearchParams(filters));
  }, [dispatch, filters, updatePagination]);

  const customData = useMemo(() => {
    const result = data?.data?.data;
    if (!result) return [];
    setNumOfPage(result?.data?.total_page || 1);
    return result;
  }, [data]);

  const isBusiness = useMemo(() => {
    return account_type === ACCOUNT_TYPE.BUSINESS
  }, [account_type])

  const handleDeleteBank = useCallback(async () => {
    try {
      const res = await dispatch(deleteBankVam({ data: { id: bank?.id } }));
      if (res?.meta?.requestStatus === "fulfilled") {
        CustomToastMessage.success(
          translate("shop_qr:delete_bank_success_title"),
          translate("shop_qr:delete_bank_success_des1") +
          " " +
          BANK_NAME_SHORT[bank?.bank_code] + ' ' +
          translate("shop_qr:delete_bank_success_des2") +
          " " +
          formatBankAccount(bank?.name),
        );
        dispatch(getListAccountNumber());
        setIsOpenModal(false);
      } else
        CustomToastMessage.error(translate("shop_qr:delete_bank_fail_title"), ERROR_CODE[res?.payload?.error_code][language]);
    } catch (err) {
      console.log(err);
    }
  }, [bank, dispatch]);

  const handleSetShowQr = useCallback(async (data) => {
    try {
      const res = await dispatch(
        verifyBank({
          phone: data.phone,
          holder_name: data.holder_name,
          bank_account_no: data.name,
          bank_code: data.bank_code || 'BVBank',
        })
      );
      if (res?.meta?.requestStatus === 'fulfilled') {
        setBase64String(res.payload.data);
        setShowModalQr(true);
      } else
        CustomToastMessage.error(ERROR_CODE[res?.payload?.error_code][language])
    } catch (err) {
      console.log(err);
    }
  }, [])

  return (
    <>
      <div className="w-full flex justify-between items-center">
        <SectionTitle title={PCHANNEL_TITLE} subtitle={translate("shop_qr:bank_account")} />
        <CustomButton
          type="add"
          outline
          title={translate("common:add")}
          onClick={() => history.push("/bank/add")}
        />
      </div>

      <div className="mt-8">
        <DataTableComponent
          filters={filters}
          data={customData}
          numOfPage={numOfPage}
          setFilters={setFilters}
          setBank={setBank}
          setIsOpenModal={setIsOpenModal}
          translate={translate}
          handleSetShowQr={handleSetShowQr}
        />
      </div>

      <ModalDelete
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        title={translate("shop_qr:delete_bank_account")}
        desc={`${translate("shop_qr:delete_bank_account_confirm")} ${formatBankAccount(
          bank?.name,
        )} ${bank?.holder_name} ${translate("txn:bank")} BVBank?`}
        item={bank}
        handleAction={handleDeleteBank}
        titleBtnLeft={translate("common:cancel")}
        titleBtnRight={translate("common:delete")}
      />

      <ModalQrVerify
        isOpen={showModalQr}
        isBusiness={isBusiness}
        bankAccountNo={bank?.name || ''}
        qr={base64String}
        onClose={() => setShowModalQr(false)}
      />
    </>
  );
};

export default Bank;
