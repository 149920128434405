/**
 * * TRANSACTION
 */
export const TRANSACTION = '/transaction'

export const TXN_MOMO = TRANSACTION + '/momo'
export const TXN_ECOM = TRANSACTION + '/ecom'
export const TXN_QR = TRANSACTION + '/qr'
export const TXN_VA = TRANSACTION + '/va'
export const TXN_CASH = TRANSACTION + '/cash'
export const TXN_CARD = TRANSACTION + '/card'
export const TXN_INSTALLMENT = TRANSACTION + '/installment'
export const TXN_SAVING = TRANSACTION + '/saving'
export const TXN_LOAN = TRANSACTION + '/loan'

/**
 * * AUTH
 */
const USER = '/user'
export const LOGIN = `${USER}/login`
export const REFRESH_TOKEN = `${USER}/refresh-token`
export const INFO = `${USER}/info`
export const USER_QR = `${USER}/qr`
export const FORGOT_PASSWORD = `${USER}/forgot-password`

/**
 * * BANK
 */
export const GET_TABLE_BANK = '/bank/account'
export const GET_SELECT_BANK = '/metadata/list-bank'
export const SEND_OTP_ADD_BANK = '/bank/account'
export const GET_HOLDER_NAME = '/bank/holder-name'

/**
 * * METADATA
 */
export const GET_LIST_STORE = '/metadata/store'
export const GET_LIST_STAFF = '/metadata/list-staff'
export const GET_LIST_EDC = '/metadata/edc'
export const GET_LIST_LINKED_VA = '/metadata/list-va-to-link'
export const GET_LIST_BANK_ACCOUNT = '/bank/get-bank-mms'
export const SYNC_DATA = '/metadata/rule/sync'

/**
 * * PAYMENT CHANNEL
 */
export const PAYMENT_CHANNEL = '/channel-payment'

/**
 * * BUSINESS
 */
export const BUSINESS = '/business'
export const EMPLOYEE = '/staff'

/**
 * * SETTING TRANSACTION
 */
export const GET_LIST_SETTING_TX = '/metadata/rule'
export const GET_LIST_CARD_SETTING_TX = '/metadata/list-card-type'
export const GET_LIST_BANK_ACCQUIRE_SETTING_TX = '/metadata/list-accquire'
export const DELETE_SETTING_TX = '/metadata/rule'

/**
 * * SALE MANAGEMENT
 */
export const PRODUCT_MANAGER = '/inventory/product'
export const CUSTOMER_MANAGER = '/customer'
export const INVOICE_MANAGER = '/invoice'
export const ORDER_MANAGER = '/order'
export const CATEGORY_MANAGER = '/category/'
export const TABLE_MANAGER = '/fnb/table'
export const NOTIFICATION = '/notification'
export const REGION = '/region'
export const SALE_REGISTER = '/saas'

/**
 * * FEEDBACK
 */
export const FEEDBACK = '/feedback'

/**
 * * STORE
 */
export const STORE = '/store'

/**
 * * TRACKING
 */
export const TRACKING = '/tracking'

/**
 * * DASHBOARD
 */
export const DASHBOARD_DATA = '/dashboard-data'
