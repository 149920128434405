import { createSlice } from "@reduxjs/toolkit"
import { getListCategory } from "@actions/category.actions"

const initialState = {
    listCategory: [],
    isLoading: false,
}

const categorySlice = createSlice({
    name: 'categorySlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getListCategory.fulfilled, (state, action) => {
                state.listCategory = action.payload;
            })
            .addCase(getListCategory.rejected, (state, action) => {
                state.listCategory = [];
            })
    }
})

export const selectListCategory = state => state.category.listCategory;
export const selectIsLoading = state => state.category.isLoading;

export default categorySlice.reducer;