import React, { useMemo } from 'react'
import SidebarItem from './SidebarItem'
import {
    BusinessIcon,
    EDCIcon,
    ErrorIcon,
    InvoiceIcon,
    OverviewIcon,
    PaybylinkIcon,
    PaymentIcon,
    ReportIcon,
    StoreIcon
} from './SidebarIcon';
import { useTranslation } from '@hooks/useTranslation';
import { useSelector } from 'react-redux';
import { selectSidebar } from '@reducers/sidebar.reducer';
import { selectProfile } from '@reducers/user.reducer';

const ListItem = () => {
    const { translate } = useTranslation('sidebar')
    const { isCollapsed } = useSelector(selectSidebar)
    const { is_fnb } = useSelector(selectProfile)

    const sidebarItems = useMemo(() => [
        {
            icon: <OverviewIcon />,
            title: translate("overviews"),
            link: "/dashboard",
        },
        {
            icon: <ReportIcon />,
            title: translate("report"),
            link: "",
            subItems: [
                { title: translate("report_txn_card"), link: "/tx-card" },
                // { title: translate("report_txn_ecom"), link: "/tx-ecom" },
                // { title: translate("report_txn_ewallet"), link: "/tx-momo" },
                // ...(is_fnb ? [{ title: translate("report_txn_cash"), link: "/tx-cash" },] : []),
                { title: translate("report_txn_va"), link: "/tx-va" },
                // { title: translate("report_txn_installment"), link: "/tx-installment" },
                // { title: translate("report_txn_saving"), link: "/tx-saving" },
                // { title: translate("report_txn_loan"), link: "/tx-loan" },
            ],
        },
        {
            icon: <PaymentIcon />,
            title: 'ShopQR',
            link: "",
            subItems: [
                {
                    title: translate("shop_qr:bank_account"),
                    link: "/bank",
                },
                {
                    title: translate("shop_qr:management"),
                    link: "/payment",
                },
            ],
        },
        {
            icon: <PaybylinkIcon />,
            title: "Pay by Link",
            link: "/pay-by-link",
        },
        {
            icon: <EDCIcon />,
            title: translate("pos_machine_management"),
            link: "/edc",
            subItems: [],
        },
        {
            icon: <StoreIcon />,
            title: translate("sale_management"),
            link: "",
            subItems: [
                {
                    title: translate("sale_management_customer_list"),
                    link: "/sale-customer",
                },
                {
                    title: translate("sale_management_product_list"),
                    link: "/sale-product",
                },
                {
                    title: translate("sale_management_order_list"),
                    link: "/sale-order",
                },
                ...(is_fnb ? [{
                    title: translate("sale_management_table_list"),
                    link: "/sale-table",
                }] : []),
                {
                    title: translate("sale_management_setting"),
                    link: "/sale-setting",
                },
            ],
        },
        {
            icon: <InvoiceIcon />,
            title: translate("e-voice"),
            link: "/e-invoice",
        },
        {
            icon: <ErrorIcon />,
            title: translate("feedback_title"),
            link: "/feedback",
        },
        {
            icon: <BusinessIcon />,
            title: translate("business_info"),
            link: "",
            subItems: [
                { title: translate("business_info_profile"), link: "/profile" },
                { title: translate("store_manager"), link: "/store" },
                { title: translate("staff_manager"), link: "/staff" },
                // { title: "E-Menu", link: "/e-menu" },
                // { title: translate("ATOM_payment"), link: "/e-com" },
            ],
        },
        // {
        //   icon: <></>,
        //   title: translate("sale_management_setting"),
        //   link: "/setting-tx",
        //   subItems: [],
        // },
        // {
        //     icon: <TrackingIcon />,
        //     title: translate("Attendance"),
        //     link: "/tracking",
        //     subItems: [],
        // },
    ], [is_fnb]);

    return (
        <div style={{ maxHeight: "calc(100vh - 312px)" }} className={`duration-200 ${isCollapsed ? 'mx-3' : 'overflow-auto custom-scrollbar'}`}>
            {sidebarItems.map((item) => <SidebarItem key={item.title} item={item} />)}
        </div>
    )
}

export default ListItem