import { CUSTOMER_MANAGER, INVOICE_MANAGER, ORDER_MANAGER, PRODUCT_MANAGER, TABLE_MANAGER } from "@constants/endpoint";
import { saleClient } from "./client_axios";

export const getProducts = async () => {
  const res = await saleClient.get(PRODUCT_MANAGER);
  return res.data;
};


export const createOrder = async (params) => {
  const res = await saleClient.post(ORDER_MANAGER, params);
  return res;
};

export const updateOrder = async (payload) => {
  const res = await saleClient.put(`${ORDER_MANAGER}/product`, payload);
  return res;
}

export const confirmOrder = async (params) => {
  const res = await saleClient.put(`${ORDER_MANAGER}/confirm`, params);
  return res.data;
}

export const removeOrderProduct = async (order_id, product_id) => {
  const res = await saleClient.delete(`${ORDER_MANAGER}/${order_id}/${product_id}`);
  return res.data;
}


export const updateCustomerOfOrder = async (order_id, customer_id) => {
  const res = await saleClient.put(`${ORDER_MANAGER}/${order_id}/${customer_id}`);
  return res;
};


// get list table
export const getTable = async () => {
  const res = await saleClient.get(TABLE_MANAGER);
  return res.tables;
};

// create new table
export const createTable = async (params) => {
  const res = await saleClient.post(TABLE_MANAGER, params);
  return res;
};

export const updateTable = async (table_id, params) => {
  const res = await saleClient.put(`${TABLE_MANAGER}/${table_id}`, params);
  return res;
};


export const removeTable = async (table_id) => {
  const res = await saleClient.delete(`${TABLE_MANAGER}/${table_id}`);
  return res;
};

export const getCustomer = async (params) => {
  const res = await saleClient.get(`${CUSTOMER_MANAGER}?q=${params.phone}&limit=${params.limit || 10}&offset=${params.offset || 0}`);
  return res;
};

//
export const getInvoice = async (invoice_id) => {
  const res = await saleClient.get(`${INVOICE_MANAGER}/${invoice_id}?is_orderid=false`);
  return res.data;
};

export const createInvoice = async (params) => {
  const res = await saleClient.post(INVOICE_MANAGER, params);
  return res;
};

export const updateInvoice = async (params) => {
  const res = await saleClient.put(INVOICE_MANAGER, params
  );
  return res;
};

//
export const generateQR = async (invoice_id) => {
  const res = await saleClient.get(`/payment/qr/${invoice_id}`);
  return res;
};

