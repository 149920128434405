import { createAsyncThunk } from "@reduxjs/toolkit";
import { userService } from "@services/user.service";

export const getProfile = createAsyncThunk("user/getProfile", async (data, { dispatch }) => {
  const _data = await userService.getProfile(data);
  return _data;
});

export const login = createAsyncThunk(
  "user/login",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await userService.login(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const forgotPassword = createAsyncThunk("user/forgotPassword", async (data, { dispatch, rejectWithValue }) => {
  try {
    const _data = await userService.forgotPassword(data);
    return _data;
  } catch (error) { return rejectWithValue(error); }
});

export const resetPassword = createAsyncThunk("user/resetPassword", async (data, { dispatch, rejectWithValue }) => {
  try {
    const _data = await userService.resetPassword(data);
    return _data;
  } catch (error) { return rejectWithValue(error); }
});