// Custom components
import React, { useState } from "react";
import IcEyeOff from "@assets/images/icon/ic_eye-off.svg";
import IcEye from "@assets/images/icon/ic_eye.svg";

function InputFieldPw(props) {
  const {
    register,
    isRequired,
    setValue,
    value,
    type,
    label,
    placeholder,
    id,
    name,
    extra,
    state,
    errors,
    variant,
    disabled,
    readOnly,
    autoCompleteOff
  } = props;
  const [showPw, setShowPw] = useState(false);

  return (
    <div className={`${extra} font-monte`}>
      {label && (
        <div className={`mb-2`}>
          <label
            htmlFor={id}
            className={`${disabled ? "text-hint " : variant === "secondary" ? "text-secondary-text " : "text-primary-text "
              } text-sm leading-5 font-semibold`}
          >
            {label}
          </label>
          {isRequired && <span className="text-error">*</span>}
        </div>
      )}

      <div className="relative">
        <input
          autoComplete={autoCompleteOff && "new-password"}
          onChange={(e) => setValue(name, e.target.value)}
          value={value}
          {...register(name, {
            required: isRequired,
          })}
          readOnly={readOnly}
          disabled={disabled}
          name={name}
          type={showPw ? "text" : "password"}
          id={id}
          placeholder={placeholder}
          onKeyDown={(evt) =>
            type === "number" && ["e", "E", "+", ".", ",", "-"].includes(evt.key) && evt.preventDefault()
          }
          onWheel={(event) => type === "number" && event.currentTarget.blur()}
          style={{ padding: "10px 16px" }}
          className={`${disabled || readOnly ? "opacity-70" : ""} ${errors[name]
            ? "text-error border-error "
            : variant === "secondary"
              ? "text-grey-atom-grey border-gray-primary "
              : "text-dark-primary border-gray-primary "
            }
          ${type === "radio" ? "h-auto cursor-pointer" : ""}
          flex w-full text-14px items-center justify-center rounded-lg border font-medium leading-5 outline-none placeholder-hint`}
        />
        <button
          type="button"
          onClick={() => setShowPw(!showPw)}
          style={{ right: 16, top: "50%" }}
          className={` absolute z-10 -translate-y-1/2 transform cursor-pointer`}
        >
          <img src={showPw ? IcEyeOff : IcEye} alt={`ic_eye${showPw ? "-off" : ""}`} />
        </button>
      </div>
      {errors[name] && <p className="text-xs font-normal mt-2 text-error">{errors[name].message}</p>}
    </div>
  );
}

export default InputFieldPw;
