/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { sell } from "@assets";
import ModalWrap from "@components/modals/ModalWrap";

const ModalLoading = ({ isOpen }) => {
  return (
    <ModalWrap isOpen={isOpen}>
      <div className='p-6 flex flex-col items-center'>
        <img
          src={sell.icLoading}
          alt="icLoading"
          width={50}
          height={48}
          style={{ borderRadius: 9 }}
        />
        <p className="text-grey-8 text-base font-medium leading-5 mt-2">
          Vui lòng chờ trong giây lát...
        </p>
      </div>
    </ModalWrap>
  );
};

export default ModalLoading;
