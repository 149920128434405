import React from "react";
import { useTranslation } from "@hooks/useTranslation";
import SelectCheckbox from "./input/SelectCheckbox";
import SelectFieldColor from "./input/SelectFieldColor";

const OptionBtnPayment = ({
  setPreviewData,
  previewDataSubmit,
  setPreviewDataSubmit,
}) => {
  const { translate } = useTranslation();

  const handleRadius = () => {
    setPreviewData((prev) => ({ ...prev, button_border: !prev.button_border }));
    setPreviewDataSubmit((prev) => ({ ...prev, button_border: !prev.button_border }));
  };

  const handleSetColor = (color) => {
    setPreviewData((prev) => ({ ...prev, button_color: color }));
    setPreviewDataSubmit((prev) => ({ ...prev, button_color: color }));
  };

  return (
    <div style={{ border: "1px solid #E8E8E8" }} className="rounded-lg p-4">
      <div className="flex items-center justify-between">
        <p className="text-xl leading-7 font-semibold text-black">
          {translate("payment_button_text")}
        </p>
        {/* <CustomButton
          title={translate("payment_gateway_apply")}
          onClick={handleApply}
          style={{ background: "#0094F4", border: "none", height: 40, width: 95 }}
        /> */}
      </div>
      <SelectFieldColor
        label={translate("payment_button_color")}
        color={previewDataSubmit?.button_color}
        setColor={handleSetColor}
      />

      <div className="mt-3">
        <SelectCheckbox
          label={translate("payment_button_border")}
          value={"radius"}
          handleClick={handleRadius}
          isActive={previewDataSubmit?.button_border}
        />
      </div>
    </div>
  );
};

export default OptionBtnPayment;
