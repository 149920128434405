import { createAsyncThunk } from "@reduxjs/toolkit";
import { settingTxServices } from "@services/setting-tx.service";

export const getListSettingTx = createAsyncThunk(
  "setting-tx/getListSettingTx",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await settingTxServices.getListSettingTx(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListRule = createAsyncThunk(
  "setting-tx/getListRule",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await settingTxServices.getListRule(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListCard = createAsyncThunk(
  "setting-tx/getListCard",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await settingTxServices.getListCard(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBankHandle = createAsyncThunk(
  "setting-tx/getBankHandle",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await settingTxServices.getBankHandle(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSettingTxById = createAsyncThunk(
  "setting-tx/getSettingTxById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await settingTxServices.getSettingTxById(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSettingTx = createAsyncThunk(
  "setting-tx/updateSettingTx",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await settingTxServices.updateSettingTx(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSettingTx = createAsyncThunk(
  "setting-tx/deleteSettingTx",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await settingTxServices.deleteSettingTx(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
