/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "tippy.js/dist/tippy.css";
import { getListEDC } from "@actions/edc.actions";
import SearchField from "./SearchField";
import SelectFieldContent from "./select/SelectFieldContent";
import SelectFieldItem from "./select/SelectFieldItem";
import SelectFieldWrapper from "./select/SelectFieldWrapper";
import "./style.css";
import { useTranslation } from "@hooks/useTranslation";

const SelectDeviceField = (props) => {
  const { name, setValue } = props;

  const [list, setList] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilters] = useState({})
  const { translate } = useTranslation('sale_order')

  const handleSelectBox = (data) => {
    setCurrentItem(data);
    setValue(name, data?.serial_number);
    setShowContent(false);
  };
  // Function to filter the array based on the 'name' ,'item_code' and 'full_name' property
  function filterByName() {
    if (searchValue?.trim().length === 0 || searchValue === null) return list;

    const normalizedFilter = searchValue?.toLowerCase().trim();
    return list.filter((item) =>
      item?.serial_number?.toString().toLowerCase().includes(normalizedFilter)
    );
  }

  const CurrentItemComp = () => (
    <div style={{ gap: 10 }} className="flex items-center ">
      <span className="text-14px leading-5">{currentItem?.serial_number}</span>
    </div>
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await dispatch(getListEDC({ page_size: 10000 }));
        if (res.meta.requestStatus === "fulfilled") {
          const data = res?.payload?.data?.data;
          const uniqueData = Array.from(new Set(data.map(obj => obj.serial_number)))
            .map(serial_number => data.find(obj => obj.serial_number === serial_number));
          // setList(uniqueData.filter(item => ['00024500707', '00024500712', '00043166245'].includes(item.serial_number)));
          setList(uniqueData);
        }
      } catch (err) { }
    };
    getData();
  }, []);

  return (
    <SelectFieldWrapper
      {...props}
      showContent={showContent}
      setShowContent={setShowContent}
      label={translate('select_payment_method')}
      initialText={translate('select_payment_method')}
      currentValue={currentItem}
      currentItemComp={<CurrentItemComp />}
    >
      <SearchField
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setFilters={setFilters}
        id="search-item-input"
        extraClass="m-4 mb-1"
      />
      <SelectFieldContent>
        {filterByName().map((item, index) => {
          const active = currentItem?.pos_id === item?.pos_id;
          return (
            <SelectFieldItem
              key={`${item?.pos_id}-${index}`}
              onClick={() => handleSelectBox(item)}
              active={active}
            >
              {item?.serial_number}
            </SelectFieldItem>
          );
        })}
      </SelectFieldContent>
    </SelectFieldWrapper>
  );
};

export default SelectDeviceField;
