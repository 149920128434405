import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createCustomer } from "@actions/customer.actions";
import { history } from "@helpers";
import { CustomToastMessage } from "@utils/customToastMsg";

import CustomButton from "@components/buttons/CustomButton";
import InputField from "@components/fields/InputField";
import SelectField from "@components/fields/SelectInPaymentField";
import SectionTitle from "@components/section-title";
import { CUSTOMER_FORM, getCustomerSchema } from "@constants/sale-management/customer";
import { useRegion } from "@hooks/useRegion";
import { useTranslation } from "@hooks/useTranslation";
import { ERROR_CODE } from "@constants/errorCode";
import TableWrapper from "../../business/profile/TableWrapper";

const CreateCustomer = () => {
  const { translate, language } = useTranslation('sale_customer');
  const schema = useMemo(() => getCustomerSchema(translate), [translate]);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [province, setProvince] = useState(null);
  const [district, setDistrict] = useState(null);
  const [ward, setWard] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = async (value) => {
    setLoading(true)
    try {
      const res = await dispatch(
        createCustomer({
          ...value,
          province_code: province,
          district_code: district,
          ward_code: ward,
        })
      );
      if (res?.payload?.customer_id) {
        CustomToastMessage.success(
          translate("create_customers_success"),
          `${translate("success_content")} ${res.payload?.code}`
        );
        history.goBack();
      } else {
        CustomToastMessage.error(
          translate("common:fail"),
          ERROR_CODE[res?.payload?.error_code][language]
        );
      }
    } catch (err) { console.log(err) }
    setLoading(false)
  };

  const disabled = !watch(CUSTOMER_FORM.NAME) || !watch(CUSTOMER_FORM.PHONE_NUMBER) || loading;

  const { handleGetProvince, listProvince, listDistrict, listWard } = useRegion({
    province_code: province,
    district_code: district,
    ward_code: ward,
    setProvince, setDistrict, setWard,
    type: 'sale'
  })

  useEffect(() => {
    handleGetProvince()
  }, []);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <SectionTitle
        title={`${translate("sale_management")}`}
        subtitle={`${translate("sale_order:add_customers")}`}
      />

      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
          <div className="flex flex-col gap-y-6">
            <InputField
              isRequired
              setValue={setValue}
              label={translate("customer_name")}
              placeholder={translate("input_customer_name")}
              id={CUSTOMER_FORM.NAME}
              name={CUSTOMER_FORM.NAME}
              register={register}
              errors={errors}
            />
            <InputField
              type="number"
              isRequired
              setValue={setValue}
              label={translate("phone_number")}
              placeholder={translate("input_phone_number")}
              value={watch(CUSTOMER_FORM?.PHONE_NUMBER) || " "}
              id={CUSTOMER_FORM?.PHONE_NUMBER}
              name={CUSTOMER_FORM?.PHONE_NUMBER}
              register={register}
              errors={errors}
            />
            <InputField
              readOnly
              label={translate("reward_point")}
              id={CUSTOMER_FORM?.POINT}
              name={CUSTOMER_FORM?.POINT}
              value={watch(CUSTOMER_FORM?.POINT) || " "}
              register={register}
              errors={errors}
              disabled
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-sm leading-5 font-semibold">
              {translate("common:region")}
            </p>
            <SelectField
              placeHolder={translate("common:city")}
              data={[].concat(listProvince)}
              setValue={setProvince}
              value={province}
            />
            <SelectField
              placeHolder={translate("common:district")}
              data={[].concat(listDistrict)}
              setValue={setDistrict}
              value={district}
            />
            <SelectField
              placeHolder={translate("common:ward")}
              data={[].concat(listWard)}
              setValue={setWard}
              value={ward}
            />
            <InputField
              setValue={setValue}
              placeholder={translate("common:address_detail")}
              id={CUSTOMER_FORM.ADDRESS}
              name={CUSTOMER_FORM.ADDRESS}
              value={watch(CUSTOMER_FORM.ADDRESS) || ""}
              register={register}
              errors={errors}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.goBack()}
              title={translate("common:cancel")}
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title={translate("common:add")}
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default CreateCustomer;
