import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { theme } from "configTheme";
import { history } from "@helpers";
import { formatCurrency, formatPaymentMethod, hasOrderCustomer } from "@utils/lib";

import CustomButton from "@components/buttons/CustomButton";
import ModalWrap from "@components/modals/ModalWrap";
import { Popup } from "../../order/Common";
import { TimeCreate } from "../../order/payment-page/Invoice";
import ModalExport from "./ModalExport";
import Session from "../../../business/profile/Session";

import { useTranslation } from "@hooks/useTranslation";

import icCustomer from "@assets/images/icon/ic_customer.svg";
import star from "@assets/images/icon/star.svg";
import paidEn from "@assets/images/paidEn.svg";
import paidVi from "@assets/images/paidVi.svg";
import success from "@assets/images/product/success.gif";
import { STATUS_ORDER } from "@constants/sale-management/order";
import { PAYMENT_METHOD } from "@constants/common";

const Cell = ({ value, alignRight = false }) => {
  if (!value) return <div></div>;
  if (value.toString().includes("http"))
    return (
      <div className={`w-full flex items-center ${alignRight && "justify-end"} px-4`}>
        <img src={value} alt='order-img' className="w-10 h-10 rounded" />
      </div>
    );
  return (
    <div
      style={{ color: theme.textPrimary }}
      className={`w-full p-4 flex items-center ${alignRight && "justify-end"}`}
    >
      {value}
    </div>
  );
};

const ModalDetail = ({
  title = "detail_order",
  isOpen,
  onClose,
  order,
  info,
  products,
  handlePrint
}) => {
  const { COMPLETED, HANDLING } = STATUS_ORDER
  const isPaid = order?.status === COMPLETED;
  const [exportInvoice, setExportInvoice] = useState(false);
  const [showPopupSuccess, setShowPopupSuccess] = useState(false);
  const { translate, language } = useTranslation('sale_order')

  return (
    <ModalWrap isOpen={isOpen} onClickBackdrop={onClose}>
      <div
        style={{ width: 1000, height: "min-content" }}
        className="p-12 flex flex-col gap-6 items-center"
      >
        {/* Close Icon */}
        <IoCloseSharp
          size={24}
          onClick={onClose}
          style={{ position: "absolute", top: 16, right: 16, cursor: "pointer", zIndex: 21 }}
        />

        {isPaid && (
          <img
            src={language == 'vi' ? paidVi : paidEn}
            alt="paid"
            width={200}
            className="absolute z-20"
            style={{ top: 10, right: 28 }}
          />
        )}

        {/* Title */}
        <h1 className="text-dark-primary text-2xl leading-8 font-bold">{translate(title)}</h1>

        {/* Detail Product */}
        <div className="w-full text-sm leading-5 font-medium">
          {/* Header */}
          <div
            style={{ gridTemplateColumns: "1fr 1fr 3fr 2fr 2fr 2fr" }}
            className="grid w-full font-bold border-b border-gray-primary"
          >
            <Cell value={translate('common:numerical_order')} />
            <Cell value={translate('image')} />
            <Cell value={translate('product_name')} />
            <Cell alignRight value={translate('quantity')} />
            <Cell alignRight value={translate('unit_price')} />
            <Cell alignRight value={translate('subtotal')} />
          </div>

          {products?.map((item, index) => (
            <div
              key={item.product_id}
              style={{ gridTemplateColumns: "1fr 1fr 3fr 2fr 2fr 2fr" }}
              className="grid w-full"
            >
              <Cell value={index + 1} />
              <Cell value={item.img} />
              <Cell value={item.name?.toUpperCase()} />
              <Cell alignRight value={item.amount + ' ' + translate('item')} />
              <Cell alignRight value={formatCurrency(item.price)} />
              <Cell alignRight value={formatCurrency(item.amount * item.price)} />
            </div>
          ))}

          <div className="w-full flex justify-between font-bold border-dashed border-t border-gray-primary mt-4">
            <p style={{ color: theme.textSecondary }} className="font-semibold px-2 py-3">
              {translate('point')}:
            </p>
            <div className="flex gap-1 px-2 py-3">
              <img src={star} alt="star" width={20} />
              <p style={{ color: theme.textPrimary }} className="text-base leading-5 font-semibold">
                {info?.point || "0"}
              </p>
            </div>
          </div>

          <div className="w-full flex justify-between font-bold">
            <p style={{ color: theme.textSecondary }} className="font-semibold px-2 py-3">
              {translate('subtotal')}:
            </p>
            <p
              style={{ color: theme.textPrimary }}
              className="text-base leading-5 font-bold px-2 py-3"
            >
              {formatCurrency(order?.total_money)}
            </p>
          </div>
        </div>

        {/* Payment */}
        <div className="w-full rounded-xl shadow-primary" >
          <div className="mx-10 py-4 space-y-4 border-b border-dashed border-grey-5">
            {info?.table_name && (
              <div className="flex justify-between items-center">
                <p className=" text-base leading-6 font-medium">{translate('table')}:</p>
                <p className="text-base leading-6 font-semibold">{info?.table_name}</p>
              </div>
            )}
            <div className="flex justify-between items-center">
              <p className=" text-base leading-6 font-medium">{translate('customer')}:</p>
              <CustomButton
                leftIcon={<img src={icCustomer} alt="icon_customer" />}
                className="rounded-full gap-2"
                style={{ height: 40, padding: 8, paddingLeft: 16, paddingRight: 16 }}
                title={hasOrderCustomer(info?.name) ? info?.name : translate('default_customer')}
              />
            </div>
            <div className="flex justify-between items-center">
              <p className=" text-base leading-6 font-medium">{translate('created_time')}:</p>
              <TimeCreate date={info?.updated_at * 1000} />
            </div>
          </div>

          <div className="mx-10 py-4 space-y-4">
            <div className="flex justify-between items-center">
              <p className="text-base leading-6 font-medium">{translate('common:payment-method')}:</p>
              {isPaid && (
                <p className="text-base leading-6 font-bold">
                  {translate(formatPaymentMethod(info?.payment_method))}
                </p>
              )}
            </div>
            {isPaid && info?.payment_method === PAYMENT_METHOD.CASH && (
              <div className="grid grid-cols-2 gap-4">
                <Session title={translate('amount_received')}>
                  {info?.receive_amount != null &&
                    formatCurrency(info?.receive_amount)}
                </Session>
                <Session title={translate('excess_money')}>
                  {info?.return_amount != null &&
                    formatCurrency(info?.return_amount)}
                </Session>
              </div>
            )}
          </div>
        </div>

        <div className={`flex gap-6 w-full`}>
          <CustomButton
            outline style={{ flex: 1, borderRadius: 8 }}
            title={translate('print_invoice')}
            onClick={() => handlePrint(order)}
          />
          <CustomButton
            style={{ flex: 1, borderRadius: 8 }}
            title={translate(isPaid ? 'export_invoice' : order?.status === HANDLING ? 'payment' : 'common:update')}
            onClick={() => isPaid ? setExportInvoice(true) : history.push(`/sale-order/${order?.order_id}`)}
          />
        </div>
      </div>

      <ModalExport
        isOpen={exportInvoice}
        onClose={() => setExportInvoice(false)}
        setShowPopupSuccess={setShowPopupSuccess}
        orderId={order?.order_id}
      />
      <Popup
        isOpen={showPopupSuccess}
        icon={success}
        content={translate('export_invoice_success')}
        showBtn={false}
        onClose={() => setShowPopupSuccess(false)}
      />
    </ModalWrap>
  );
};

export default ModalDetail;
