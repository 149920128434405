import { ReactNode } from "react";
import React from "react";

export const Highlight = ({
    text,
    semibold,
    newLine,
}) => {
    return (
        <span className={semibold ? "font-semibold" : "font-medium"}>
            {text}
            {newLine && <br />}
        </span>
    );
};

export const LinkText = ({
    text,
    link,
    newLine,
}) => {
    return (
        <span
            style={{ color: '#1890FF' }}
            className={`text-sm font-normal underline cursor-pointer`}
            onClick={() => window.open(link || text, "_blank")}
        >
            {text}
            {newLine && <br />}
        </span>
    );
};

export const SectionWrap = ({
    title,
    children,
    titleSize = "sm",
    tabSpace,
}) => {
    return (
        <div className="flex flex-col">
            <p
                className={`${titleSize == "sm" ? "text-sm" : "text-base"
                    } font-semibold`}
            >
                {title}
            </p>
            <div
                className={`${tabSpace && "pl-6"
                    } whitespace-pre-line text-sm font-normal`}
            >
                {children}
            </div>
        </div>
    );
};
