import React, { useEffect, useState } from "react";
import { TbChevronLeftPipe, TbChevronLeft, TbChevronRight, TbChevronRightPipe } from "react-icons/tb";
import "./pagination.css";
import PropTypes from "prop-types";
import { useTranslation } from "@hooks/useTranslation";
import { theme } from "configTheme";
function Pagination(props) {
  const { pageCount, canPreviousPage, previousPage, pageOptions, pageIndex, canNextPage, gotoPage, nextPage, setPage, page } = props;
  const { language } = useTranslation()

  const [first3Page, setFirst3Page] = useState([0, 1, 2]);
  const [last3Page, setLast3Page] = useState([pageCount - 3, pageCount - 2, pageCount - 1]);
  useEffect(() => {
    setFirst3Page([0, 1, 2]);
    setLast3Page([pageCount - 3, pageCount - 2, pageCount - 1]);
  }, [pageCount]);

  const formatPage = (index) => {
    return index < 1 ? 1 : (index > pageCount) ? pageCount : index;
  };

  useEffect(() => {
    const disPrev = pageIndex - first3Page[2];
    const disNext = last3Page[0] - pageIndex;
    if (first3Page.includes(pageIndex) && pageIndex != 0 && disNext >= 3) {
      setFirst3Page([pageIndex - 1, pageIndex, pageIndex + 1]);
    } else if (last3Page.includes(pageIndex) && pageIndex != pageOptions[pageCount - 1] && disPrev >= 3) {
      setLast3Page([pageIndex - 1, pageIndex, pageIndex + 1]);
    }
  }, [pageIndex]);

  return (
    <div className="flex items-center gap-2">
      <button
        className={`w-5 h-5 md:w-6 md:h-6 flex items-center justify-center ${!canPreviousPage && 'opacity-50 cursor-default'}`}
        onClick={() => {
          if (canPreviousPage) {
            setFirst3Page([0, 1, 2]);
            gotoPage(0);
            // setPage(1);
          }
        }}
        disabled={!canPreviousPage}
      >
        <TbChevronLeftPipe color={canPreviousPage ? "#6C6C6C" : "#ABB2C2"} className="w-5 h-5 md:w-6 md:h-6" />
      </button>

      <button
        className={`w-5 h-5 md:w-6 md:h-6 flex items-center justify-center ${!canPreviousPage && 'opacity-50 cursor-default'}`}
        onClick={() => {
          if (canPreviousPage) {
            previousPage();
            // setPage((pre) => pre - 1)
          }
        }}
        disabled={!canPreviousPage}
      >
        <TbChevronLeft className="w-5 h-5 md:w-6 md:h-6" color={canPreviousPage ? theme.primary : "#ABB2C2"} />
      </button>


      {pageCount <= 7 ? (
        <div className="flex gap-1 px-2">
          {pageOptions.map((page) => (
            <div
              onClick={() => gotoPage(page)}
              key={page}
              style={{ padding: "6px 12px", color: pageIndex == page ? theme.secondary : "#222020" }}
              className={`rounded-lg text-sm leading-5 font-normal cursor-pointer ${pageIndex == page && "bg-gradient"
                }`}
            >
              {page + 1}
            </div>
          ))}
        </div>
      ) : (
        <div className="flex gap-1 px-2">
          {[...first3Page, first3Page[2] + 1, ...last3Page].map((page, index) => {
            return (
              <div
                onClick={() => {
                  if ((pageIndex - first3Page[2] <= 3 && last3Page[0] - pageIndex <= 3) || index != 3) gotoPage(page);
                }}
                key={index}
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  color: page == pageIndex ? theme.secondary : "#222020",
                  transition: "all 0.3s linear",
                }}
                className={`px-2 md:px-3 rounded-lg text-sm leading-5 font-normal cursor-pointer ${pageIndex == page && "bg-gradient"
                  }`}
              >
                {index != 3
                  ? page + 1
                  : pageIndex - first3Page[2] <= 3 && last3Page[0] - pageIndex <= 3
                    ? page + 1
                    : "..."}
              </div>
            );
          })}
        </div>
      )}

      {/* <div className="flex gap-1 h-6 items-center">
        {language === 'vi' ? 'Trang' : 'Page'}
        <input
          type="number"
          value={page}
          onChange={(e) => setPage(e.target.value)}
          onBlur={(e) => {
            gotoPage(formatPage(e.target.value) - 1)
            setPage(formatPage(e.target.value))
          }
          }
          disabled={pageCount === 0}
          className="w-10 h-full text-center rounded-md"
          style={{
            border: '1px solid var(--primary)'
          }}
          onKeyDown={(e) => {
            if (['.', ',', '+', '-', 'e', 'E'].includes(e.key)) e.preventDefault();
            else if (e.key === 'Enter') e.currentTarget.blur();
          }}
        />
        <span>/ {pageCount}</span>
      </div> */}

      <button
        className={`flex items-center justify-center ${!canNextPage && 'opacity-50 cursor-default'}`}
        onClick={() => {
          if (canNextPage) {
            nextPage()
            // setPage((prev) => prev + 1)
          }
        }}
        disabled={!canNextPage}
      >
        <TbChevronRight color={canNextPage ? theme.primary : "#ABB2C2"} className="w-5 h-5 md:w-6 md:h-6" />
      </button>

      <button
        className={`flex items-center justify-center text-white ${!canNextPage && 'opacity-50 cursor-default'}`}
        onClick={() => {
          if (canNextPage) {
            setLast3Page([pageCount - 3, pageCount - 2, pageCount - 1]);
            gotoPage(pageCount - 1);
            // setPage(pageCount)
          }
        }}
        disabled={!canNextPage}
      >
        <TbChevronRightPipe color={canNextPage ? "#6C6C6C" : "#ABB2C2"} className="w-5 h-5 md:w-6 md:h-6" />
      </button>

    </div>
  );
}

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool,
  previousPage: PropTypes.func,
  pageOptions: PropTypes.any,
  pageIndex: PropTypes.any,
  canNextPage: PropTypes.bool,
  gotoPage: PropTypes.func,
  nextPage: PropTypes.func,
};

export default Pagination;
