import { createAsyncThunk } from "@reduxjs/toolkit";
import { customerServices } from "@services";

export const getListCustomer = createAsyncThunk(
  "store/customer/getListCustomer",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await customerServices.getListCustomer(data);
    return _data;
  }
);

export const getCustomerById = createAsyncThunk(
  "store/customer/getCustomerById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await customerServices.getCustomerById(data);
    return _data;
  }
);

export const createCustomer = createAsyncThunk(
  "store/customer/createCustomer",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await customerServices.createCustomer(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "store/customer/updateCustomer",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await customerServices.updateCustomer(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "store/customer/deleteCustomer",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await customerServices.deleteCustomer(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
