const imgRoot = '/images/theme'

export const theme = {
  name: "BVB",
  defaultBank: "vccb",
  primary: "#1F2B7B",
  sidebar: "#1F2B7B",
  // sidebar: "linear-gradient(302.95deg, #00C1F3 -14.84%, #1E2A7B 100.48%)",
  secondary: "#00BAEE",
  sidebarHover: "#00000033",
  selectHover: "#ECEDF1",
  textPrimary: "#2C2C2C",
  textSecondary: "#475467",
  error: "#F5222D",
  favIcon: imgRoot + "/favIcon.png",
  loginBg: imgRoot + "/loginBg.png",
  loginLogo: imgRoot + "/loginLogo.png",
  sidebarLogo: imgRoot + "/sidebarLogo.png",
  navBanner: imgRoot + "/navBanner.png",
  payByLinkBanner: imgRoot + "/payByLinkBanner.png",
  addBankBanner: imgRoot + "/addBankBanner.png",
  addBankBusinessBanner: imgRoot + "/addBankBusinessBanner.png",
  navBannerWeb: "https://bvbank.net.vn/",
};
