import { createAsyncThunk } from "@reduxjs/toolkit";
import { storeServices, bankServices } from "@services";

export const createStore = createAsyncThunk(
  "store/createStore",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await storeServices.createStore(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPresentative = createAsyncThunk(
  "store/getPresentative",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await storeServices.getPresentative(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteStore = createAsyncThunk(
  "store/deleteStore",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await storeServices.deleteStore(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
