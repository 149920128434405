import React from "react";
import { theme } from "configTheme";
import { useTranslation } from "@hooks/useTranslation";

const BtnReset = ({ title, setFilters, id, onClick }) => {
  const currentTime = new Date();
  const [y, m, d] = [
    currentTime.getFullYear(),
    currentTime.getMonth(),
    currentTime.getDate(),
  ];
  const startTime = new Date(y, m, d);
  const endTime = new Date(y, m, d, 23, 59, 59);
  return (
    <div
      id={id}
      onClick={() => {
        setFilters({
          page: 1,
          page_size: 10,
          status: "all",
          start_time: Math.floor(startTime.getTime() / 1000),
          end_time: Math.floor(endTime.getTime() / 1000),
          bill_id: '',
          customer_id: ''
        });
        onClick && onClick();
      }}
      className="flex items-center justify-center gap-1 bg-white rounded-lg px-4 py-3 border border-secondary cursor-pointer"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2047 3.27031L13.3234 3.95937C12.1219 2.42344 10.2531 1.4375 8.15469 1.4375C4.53126 1.4375 1.59844 4.36719 1.59376 7.99219C1.58907 11.6203 4.52813 14.5625 8.15469 14.5625C10.9875 14.5625 13.4016 12.7656 14.3203 10.2484C14.3438 10.1828 14.3094 10.1094 14.2438 10.0875L13.3578 9.78281C13.3269 9.77222 13.2931 9.77414 13.2636 9.78816C13.2341 9.80218 13.2113 9.82718 13.2 9.85781C13.1719 9.93594 13.1406 10.0141 13.1078 10.0906C12.8375 10.7312 12.45 11.3063 11.9563 11.8C11.4665 12.2907 10.8866 12.6822 10.2484 12.9531C9.58751 13.2328 8.88282 13.375 8.15782 13.375C7.43126 13.375 6.72813 13.2328 6.06719 12.9531C5.4284 12.6834 4.84832 12.2917 4.35938 11.8C3.86829 11.3103 3.47717 10.7297 3.20782 10.0906C2.92813 9.42812 2.78594 8.725 2.78594 7.99844C2.78594 7.27187 2.92813 6.56875 3.20782 5.90625C3.47813 5.26562 3.86563 4.69063 4.35938 4.19688C4.85313 3.70312 5.42813 3.31563 6.06719 3.04375C6.72813 2.76406 7.43282 2.62188 8.15782 2.62188C8.88438 2.62188 9.58751 2.76406 10.2484 3.04375C10.8872 3.31351 11.4673 3.70519 11.9563 4.19688C12.1109 4.35156 12.2563 4.51562 12.3906 4.6875L11.45 5.42188C11.4314 5.43627 11.4172 5.45563 11.4091 5.47772C11.401 5.49981 11.3993 5.52374 11.4042 5.54676C11.4091 5.56977 11.4204 5.59094 11.4368 5.60782C11.4532 5.6247 11.474 5.63661 11.4969 5.64219L14.2406 6.31406C14.3188 6.33281 14.3953 6.27344 14.3953 6.19375L14.4078 3.36719C14.4063 3.26406 14.2859 3.20625 14.2047 3.27031Z"
          fill={theme.secondary}
        />
      </svg>
      <p className="text-sm text-secondary font-medium">{title}</p>
    </div >
  );
};

const BtnApply = ({ title, setFilters, filters, id, onClick }) => {
  return (
    <div
      id={id}
      onClick={() => {
        setFilters(filters);
        onClick && onClick();
      }}
      className="flex items-center justify-center gap-1 bg-gradient rounded-lg px-4 py-3 cursor-pointer"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.74363 1.33337C4.20476 1.33337 1.33594 4.13884 1.33594 7.59955C1.33594 11.0603 4.20476 13.8657 7.74363 13.8657C9.25719 13.8657 10.6482 13.3525 11.7446 12.4944L13.8273 14.5258L13.8827 14.5725C14.0761 14.7124 14.3502 14.6965 14.5252 14.5249C14.7177 14.3362 14.7172 14.0307 14.5243 13.8424L12.4661 11.8349C13.5126 10.7196 14.1513 9.23253 14.1513 7.59955C14.1513 4.13884 11.2825 1.33337 7.74363 1.33337ZM7.74363 2.29853C10.7374 2.29853 13.1644 4.67188 13.1644 7.59955C13.1644 10.5272 10.7374 12.9006 7.74363 12.9006C4.74984 12.9006 2.32289 10.5272 2.32289 7.59955C2.32289 4.67188 4.74984 2.29853 7.74363 2.29853Z"
          fill={theme.secondary}
        />
      </svg>

      <button className="text-secondary text-sm font-medium">{title}</button>
    </div>
  );
};

const ButtonAdd = ({ className, onClick, id }) => {
  const { translate } = useTranslation('common');
  return (
    <button
      id={id}
      onClick={onClick}
      type="button"
      className={`flex items-center justify-center gap-1 border border-secondary rounded-lg p-2 text-secondary font-semibold ${className}`}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.66667 2.66667C8.66667 2.29848 8.36819 2 8 2C7.63181 2 7.33333 2.29848 7.33333 2.66667V7.33333H2.66667C2.29848 7.33333 2 7.63181 2 8C2 8.36819 2.29848 8.66667 2.66667 8.66667H7.33333V13.3333C7.33333 13.7015 7.63181 14 8 14C8.36819 14 8.66667 13.7015 8.66667 13.3333V8.66667H13.3333C13.7015 8.66667 14 8.36819 14 8C14 7.63181 13.7015 7.33333 13.3333 7.33333H8.66667V2.66667Z"
          fill={"currentColor"}
        />
      </svg>
      {translate("add")}
    </button>
  );
};

const CustomButton = ({
  type = "button",
  isLoading,
  className,
  style,
  title,
  onClick,
  outline,
  disabled,
  setFilters,
  filters,
  leftIcon,
  id,
}) => {
  const ENUMSBtn = {
    reset: (
      <BtnReset
        id={id}
        filters={filters}
        setFilters={setFilters}
        title={title}
        onClick={onClick}
      />
    ),
    apply: (
      <BtnApply
        id={id}
        filters={filters}
        setFilters={setFilters}
        title={title}
        onClick={onClick}
      />
    ),
    add: (
      <ButtonAdd
        id={id}
        className={className}
        title={title}
        onClick={onClick}
      />
    ),
  };

  return ENUMSBtn[type] ? (
    ENUMSBtn[type]
  ) : (
    <button
      id={id}
      type={type}
      disabled={disabled || isLoading}
      onClick={onClick}
      style={style}
      className={`
      ${disabled && "opacity-75"}
      ${outline
          ? "text-secondary border-secondary"
          : "bg-gradient border-none text-secondary"
        } flex items-center justify-center gap-3 rounded-lg border text-center px-8 py-3 text-14px font-semibold hover:opacity-75 whitespace-no-wrap ${className}`}
    >
      {leftIcon && leftIcon}
      {title}
    </button>
  );
};

export default CustomButton;
