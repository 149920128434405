import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListNoti } from "@actions";
import { NOTI_TYPE, selectListNoti } from "@reducers/noti.reducer";
import { CustomToastMessage } from "@utils/customToastMsg";

import NotiDropdown from "./NotiDropdown";

import bell from "@assets/images/icon/bell.svg";
import foodIcon from "@assets/images/noti/foodNoti.svg";
import orderIcon from "@assets/images/noti/orderNoti.svg";
import payIcon from "@assets/images/noti/payNoti.gif";
import { useSocket } from "@hooks/useSocket";
import { useTranslation } from "@hooks/useTranslation";
import useWindowSize from "@hooks/useWindowSize";
import { selectMerchant } from "@reducers/sass.reducer";

const { NEW_ORDER, PAYMENT, NEW_DISH } = NOTI_TYPE

const ButtonBell = () => {
  const { translate } = useTranslation('noti');
  const [hidden, setHidden] = useState(true);
  const { isMd } = useWindowSize();

  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const { ref_id } = useSelector(selectMerchant);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        buttonRef.current.contains(event.target) ||
        dropdownRef.current.contains(event.target)
      ) {
        return false;
      }
      setHidden(!hidden);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hidden, dropdownRef, buttonRef]);

  const handleDropdownClick = () => {
    setHidden(!hidden);
  };

  const dispatch = useDispatch();
  const listNoti = useSelector(selectListNoti);

  useSocket({
    url: process.env.REACT_APP_SALE_SOCKET,
    emitEvent: {
      name: "subscribe",
      payload: { merchant_id: String(ref_id) }
    },
    onEvent: {
      name: "notification",
      func: async (noti) => {
        const { notification_type, order_code } = noti;
        switch (notification_type) {
          case NEW_ORDER:
            CustomToastMessage.noti(
              translate("order_title"),
              translate("order_desc") + " " + order_code,
              orderIcon,
              noti,
              dispatch,
              translate
            );
            break;
          case PAYMENT:
            CustomToastMessage.noti(
              translate("payment_title"),
              translate("payment_desc") + " " + order_code,
              payIcon,
              noti,
              dispatch,
              translate
            );
            break;
          case NEW_DISH:
            CustomToastMessage.noti(
              translate("dishes_title"),
              translate("dishes_desc") + " " + order_code,
              foodIcon,
              noti,
              dispatch,
              translate
            );
            break;
          default:
            // CustomToastMessage.noti(translate('dishes_title'), order_code + ' ' + translate('dishes_desc'), foodNoti, noti, dispatch, translate)
            break;
        }
        await dispatch(getListNoti());
      }
    },
    enable: !!ref_id
  })

  useEffect(() => {
    dispatch(getListNoti())
  }, []);

  return (
    <div className="relative">
      <img
        src={bell}
        alt="bell"
        className="w-6 cursor-pointer"
        ref={buttonRef}
        onClick={handleDropdownClick}
      />
      {listNoti.find((noti) => noti.status !== 2) && (
        <span
          className="bg-gradient w-2 h-2 rounded-full absolute"
          style={{ right: 0, top: -1 }}
        />
      )}
      <div
        ref={dropdownRef}
        style={{ right: isMd ? -5 : 0, top: isMd ? "calc(100% + 13px)" : 96 }}
        className={`dropdown ${isMd ? "absolute" : "fixed left-0"} z-50 ${hidden ? "" : "open"
          }`}
      >
        <div className="dropdown-content bottom-start">
          <NotiDropdown setHidden={setHidden} />
        </div>
      </div>
    </div>
  );
};

export default ButtonBell;
