import React from 'react'

import icTooltip from '@assets/images/tooltip/ic_tooltip.svg';

const Tooltip = ({ icon, content }) => {
    return (
        <div className='tooltip'>
            <img
                src={icon || icTooltip}
                alt='icon'
            />
            <span className='tooltiptext'>{content}</span>
        </div>
    )
}

export default Tooltip