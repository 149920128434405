import { PAYMENT_METHOD_GROUP } from '@constants/business/emenu'
import { IMAGE_VALID_TYPES, PAYMENT_METHOD } from '@constants/common'
import { DEFAULT_ORDER_CUSTOMER } from '@constants/sale-management/customer'
import { TRANSLATIONS } from '@constants/translations'
import { CustomToastMessage } from './customToastMsg'

/**
 * The `formatCurrency` function formats a number as Vietnamese Dong currency (VND) with a maximum of 0
 * decimal places and a maximum of 13 significant digits.
 * @param number - The `number` parameter is the value that you want to format as currency. It can be
 * any numerical value.
 * @returns The function `formatCurrency` returns a formatted string representing the given number in
 * Vietnamese Dong (VND) currency format.
 */
export function formatCurrency(number, useGrouping = true) {
  // if (typeof number != 'number') return ''
  if (!number) return '0 VND'
  const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
    currencyDisplay: 'code',
    useGrouping: useGrouping,
    maximumFractionDigits: 0,
    // maximumSignificantDigits: 13,
  })

  // const numberSlice = number.toString().length > 13 ? number.toString().slice(0, 13) : number;

  return formatter.format(number).replace(/\./g, ',')
}

export const getSubDomain = () => {
  let subDomain = window.location.host.split('.')[0]
  if (window.location.host.split('.')) {
    if (process.env.NODE_ENV === 'development') {
      //   subDomain = "atom-m-75-mms"
    }
    return subDomain
  }
  return null
}

export const getStatus = (status) => {
  switch (status.toLowerCase()) {
    case 'success':
      return 'status_success'
    case 'fail':
      return 'status_fail'
    case 'pending':
      return 'status_pending'
    case 'PAID':
      return 'Paid'
    case 'WAITING_FOR_PAID':
      return 'Waiting'
    default:
      return ''
  }
}

export const formatBankAccount = (accountNumber) => {
  return accountNumber
  if (accountNumber?.length < 4) return accountNumber

  const firstEightDigits = accountNumber?.slice(0, 8)
  const lastFourDigits = accountNumber?.slice(-4)
  return firstEightDigits + '***' + lastFourDigits
}

export const formatVaType = (vaType) => {
  if (!vaType) return ''
  if (vaType == 'STORE') return 'shop_qr:management_shop_branch'
  if (vaType == 'STAFF') return 'shop_qr:management_staff'
  if (vaType == 'BILL') return 'shop_qr:management_add_bill'
}

export const formatChannel = (channel) => {
  if (!channel) return ''
  if (channel == 'WEBSITE') return 'Website'
  if (channel == 'KIOSK') return 'Kiosk'
  if (channel == 'MOBILE') return 'Mobile'
  if (channel == 'IN_STORE') return 'shop_qr:management_at_counter'
  if (channel == 'CALL_CENTER') return 'Call center'
}

export const formatPaymentMethod = (methodCode) => {
  if (typeof methodCode != 'number') return ' '
  switch (methodCode) {
    case PAYMENT_METHOD.QR:
      return 'qrCode'
    case PAYMENT_METHOD.CASH:
      return 'cash'
    case PAYMENT_METHOD.CARD:
      return 'card_payment'
    default:
      break
  }
}

export const formatPaymentMethodGroup = (methodCode) => {
  switch (methodCode) {
    case PAYMENT_METHOD_GROUP.QR:
      return 'VietQR'
    case PAYMENT_METHOD_GROUP.CASH:
      return 'sale_management_cash'
    case PAYMENT_METHOD_GROUP.ECOM:
      return 'payment_bankcard'
    case PAYMENT_METHOD_GROUP.EWALLET:
      return 'payment_wallet'
    default:
      break
  }
}

export const getCardTypeLogo = (cardType) => {
  const card = cardType.toString().toLowerCase()
  return `/images/card-type/${card || 'master'}.svg`
}

export const formatDateFromDate = (value) => {
  if (!value) return ''
  const arr = value?.toString()?.split('-')
  return arr?.reverse()?.join('/')
}

export const formatDateFromMilliseconds = (milliseconds, hasHHMM = false) => {
  if (!milliseconds) return ''
  const d = new Date(milliseconds * Math.pow(10, 3))
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
  const [hhmmss, ddMMyy] = d.toLocaleTimeString('vi-VN', options).split(' ')
  const [h, m, s] = hhmmss.split(':')
  return hasHHMM ? ddMMyy + ' ' + h + ':' + m : ddMMyy
}

export const formatDateToMilliseconds = (date) => {
  return Date.parse(date) / Math.pow(10, 3)
}

export const formatDateToTimeStampCustom = (
  date,
  startDay = true,
  tzString = ''
) => {
  const dateStr = `${date}T${startDay ? '00:00:00' : '23:59:59'}.007${tzString}`
  const formattedDate = new Date(dateStr)
  return formatDateToMilliseconds(formattedDate)
}

export const formatDateTxn = (value, hasHHMM = true) => {
  if (!value) return ''

  const [date, time] = value.split(' ')
  // const arr = date?.split("-");
  // return arr?.reverse()?.join("/");
  const newDate = date.split('-').reverse().join('/')
  const [h, m, s] = time.split(':')
  if (hasHHMM) return newDate + ' ' + h + ':' + m
  return newDate
}

export function getDaysDifference(date1, date2) {
  // Create Date objects for the input dates
  const startDate = new Date(date1)
  const endDate = new Date(date2)

  // Calculate the time difference in milliseconds
  const timeDifference = endDate - startDate

  // Convert the time difference to days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

  return daysDifference.toString()
}

export const getToday = () => {
  let currentDate = new Date()
  // Set the start of the day (0:00:00)
  let startOfDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    0,
    0,
    0,
    0
  )
  // Set the end of the day (23:59:59)
  let endOfDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    23,
    59,
    59,
    999
  )

  return [startOfDay, endOfDay]
}

export const getYesterday = () => {
  let currentDate = new Date()

  // Set the start of yesterday (0:00:00)
  let startOfYesterday = new Date(currentDate)
  startOfYesterday.setDate(currentDate.getDate() - 1)
  startOfYesterday.setHours(0, 0, 0, 0)

  // Set the end of yesterday (23:59:59)
  let endOfYesterday = new Date(currentDate)
  endOfYesterday.setDate(currentDate.getDate() - 1)
  endOfYesterday.setHours(23, 59, 59, 999)

  return [startOfYesterday, endOfYesterday]
}

export const getSevenDayAgo = () => {
  let currentDate = new Date()

  // Set the start of 7 days ago (0:00:00)
  let startOf7DaysAgo = new Date(currentDate)
  startOf7DaysAgo.setDate(currentDate.getDate() - 7)
  startOf7DaysAgo.setHours(0, 0, 0, 0)

  // Set the start of 7 days ago (0:00:00)
  let endOf7DaysAgo = new Date(currentDate)
  startOf7DaysAgo.setDate(currentDate.getDate() - 7)
  endOf7DaysAgo.setHours(23, 59, 59, 999)

  return [startOf7DaysAgo, endOf7DaysAgo]
}

export const getThisMonth = () => {
  let currentDate = new Date()

  // Set the start of the first day of the current month (0:00:00)
  let firstDayOfCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  )
  firstDayOfCurrentMonth.setHours(0, 0, 0, 0)

  // Set the end of today (23:59:59)
  let endOfToday = new Date(currentDate)
  endOfToday.setHours(23, 59, 59, 999)
  return [firstDayOfCurrentMonth, endOfToday]
}

export const getLastMonth = () => {
  let currentDate = new Date()

  // Set the start of the first day of the last month (0:00:00)
  let firstDayOfLastMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  )
  firstDayOfLastMonth.setHours(0, 0, 0, 0)

  // Set the end of the last day of the last month (23:59:59)
  let lastDayOfLastMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  )
  lastDayOfLastMonth.setHours(23, 59, 59, 999)

  return [firstDayOfLastMonth, lastDayOfLastMonth]
}

export const getThisQuarter = () => {
  let currentDate = new Date()
  // Determine the current quarter
  const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3)

  // Set the start of the first day of the current quarter (0:00:00)
  let firstDayOfCurrentQuarter = new Date(
    currentDate.getFullYear(),
    (currentQuarter - 1) * 3,
    1
  )
  firstDayOfCurrentQuarter.setHours(0, 0, 0, 0)

  return [firstDayOfCurrentQuarter, currentDate]
}

export const isValidPhoneNumber = (p) => {
  const phoneRe = /(84|0[3|5|7|8|9])+([0-9]{8})\b/
  const digits = p.replace(/\D/g, '')
  return phoneRe.test(digits)
}

export const hasOrderCustomer = (customer) => {
  return !!customer && customer !== DEFAULT_ORDER_CUSTOMER
}

export const validateFile = (file, size = 500) => {
  const language = localStorage.getItem('language') || 'vi'

  if (!file) {
    CustomToastMessage.error(
      TRANSLATIONS[language]['common']['validate_image_required']
    )
    return false
  }

  if (!IMAGE_VALID_TYPES.includes(file.type)) {
    CustomToastMessage.error(
      TRANSLATIONS[language]['common']['validate_image_format']
    )

    return false
  }

  if (file.size > size * 1024) {
    CustomToastMessage.error(
      TRANSLATIONS[language]['common']['validate_image_size'] + size + ' KB'
    )
    return false
  }

  return true
}

export const handleDragDrop = (setFile, setScale) => {
  return {
    onDragOver: (e) => {
      e.preventDefault()
      setScale && setScale(1.05)
    },
    onDragLeave: (e) => {
      e.preventDefault()
      setScale && setScale(1)
    },
    onDrop: (e) => {
      e.preventDefault()
      setFile(e.dataTransfer.files[0])
      setScale && setScale(1)
    },
  }
}

// Cookie
export const setCookie = (cookieName, cookieValue, expiredDay) => {
  let expiresResult = ''
  const d = new Date()
  if (expiredDay) {
    d.setTime(d.getTime() + expiredDay * 24 * 60 * 60 * 1000)
    const expires = 'expires=' + d.toUTCString()
    expiresResult = ';' + expires + ';path=/'
  } else {
    // Maximum date to expire
    const daysToExpire = new Date(2147483647 * 1000).toUTCString()
    expiresResult = `;expires=${daysToExpire};path=/`
  }

  document.cookie = cookieName + '=' + cookieValue + expiresResult
}

export const getCookie = (cname) => {
  let name = cname + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const checkCookie = (key) => {
  const value = getCookie(key)

  if (value === '' || value === 'false') return false

  return true
}

export const formatHolderName = (name) => name?.toUpperCase()
