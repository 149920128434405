/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getListFeedback } from '@actions'
import { selectListFeedback } from '@reducers/feedback.reducer'
import useCheck from './useCheck'
import useEDC from './useEDC'
import useOther from './useOther'

import { useTranslation } from '@hooks/useTranslation'
import useWindowSize from '@hooks/useWindowSize'
import SectionTitle from '@components/section-title'
import CustomButton from '@components/buttons/CustomButton'
import { history } from '@helpers'
import ModalDetail from './ModalDetail'
import ModalDelete from '@components/modals/ModalDelete'
import Datatable from "@components/tsdatatable/datatables";
import ComingSoon from '@components/common/ComingSoon'
import { IS_COMING_SOON } from '@constants/common'

const Feedback = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const tab = useParams().tab || 'check'
  const vieTab = translate('feedback_subtitle')[tab]
  const [showDetail, setShowDetail] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const check = useCheck({ setShowDetail, setShowDelete, setSelectedItem })
  const edc = useEDC({ setShowDetail, setShowDelete, setSelectedItem })
  const other = useOther({ setShowDetail, setShowDelete, setSelectedItem })
  const tabList = [check, edc, other]
  const activedTab = tabList.find((item) => item.type == tab)
  const { isMd } = useWindowSize()

  const data = useSelector(selectListFeedback)
  const [numOfPage, setNumOfPage] = useState('1')
  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  })

  const customData = useMemo(() => {
    if (!data?.data?.items) return []
    setNumOfPage(data?.data?.total_page)
    return data?.data?.items
  }, [data])

  useEffect(() => {
    !IS_COMING_SOON && dispatch(
      getListFeedback({
        type: tab.toUpperCase(0),
        page: filters.page,
        page_size: filters.page_size,
      })
    )
  }, [tab, filters.page, filters.page_size])

  return (
    <div>
      <div className="flex items-center justify-between">
        <SectionTitle title={translate("feedback_title")} subtitle={vieTab} />
        <CustomButton
          className="blcok md:hidden"
          type="add"
          onClick={() => history.push(`/feedback/create/${tab}`)}
        />
      </div>

      <div className="mt-8 space-y-6">
        {IS_COMING_SOON ? <ComingSoon /> :
          <>
            {/* Tab List + Add Button */}
            <div className="flex justify-between items-center">
              {/* Tab List */}
              <div
                className="bg-white rounded-xl flex w-full md:w-auto"
                style={{
                  boxShadow: "0px 8px 11px -3px rgba(0, 0, 0, 0.08)",
                  padding: 10,
                }}
              >
                {tabList.map((item) => (
                  <button
                    key={item.type}
                    style={{
                      width: isMd ? 'auto' : "33%",
                      minWidth: isMd ? 163 : '33%',
                      transition: "all 0.1s linear",
                    }}
                    onClick={() => history.push(`/feedback/${item.type}`)}
                    className={`px-6 md:px-8 py-3 rounded text-sm leading-5 font-semibold text-center
                ${item.type == tab ? "bg-gradient text-white" : "text-blackgray"}`}
                  >
                    {translate("feedback_subtitle")[item.type]}
                  </button>
                ))}
              </div>
              {/* Add Button */}
              <CustomButton
                className="hidden md:flex"
                type="add"
                onClick={() => history.push(`/feedback/create/${tab}`)}
              />
            </div>

            {/* Table */}
            <Datatable
              columns={activedTab.columns}
              data={customData}
              pagination={{
                page: filters.page,
                pageSize: filters.page_size,
                totalPages: numOfPage,
              }}
              filters={filters}
              setFilters={setFilters}
            />

            {showDetail && (
              <ModalDetail
                title={`${translate("detail")} ${activedTab.formTitle}`}
                setShowModal={setShowDetail}
                selectedItem={{ ...selectedItem, type: tab }}
              />
            )}
            <ModalDelete
              isOpen={showDelete}
              onClose={() => setShowDelete(false)}
              title={`${translate("delete")} ${activedTab?.formTitle}`}
              desc={`${translate("delete_confirm")} ${activedTab?.formTitle} ${translate(
                "delete_question",
              )}`}
              item={selectedItem}
              handleAction={activedTab?.handleDelete}
              titleBtnLeft={translate("cancel")}
              titleBtnRight={translate("delete")}
            />
          </>
        }
      </div>
    </div>
  );
}

export default Feedback
