import md5 from "md5";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStaff } from "@actions/staff.actions";
import CustomButton from "@components/buttons/CustomButton";
import SelectBankHandleField from "@components/fields/SelectBankHandleField";
import SelectBankReleaseField from "@components/fields/SelectBankReleaseField";
import SelectCardField from "@components/fields/SelectCardField";
import SelectRuleField from "@components/fields/SelectRuleField";
import SectionTitle from "@components/section-title";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";
import TableWrapper from "../../business/profile/TableWrapper";
import { useTranslation } from "@hooks/useTranslation";

const FORM = {
  RULE_NAME: "rule_name",
  CARD_TYPE: "card_type",
  ISSUER: "issuer",
  ACQUIRER: "acquirer",
};
const CreateCustomer = () => {
  const { translate, language } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const onFormSubmit = async (value) => {
    const raw = { ...value, password: md5(value.password) };
    // const raw = { ...value, password: value.password };
    try {
      const res = await dispatch(createStaff(raw));
      if (res?.meta?.requestStatus === "fulfilled") {
        history.push("/staff");
        CustomToastMessage.success(translate("success"));
      } else CustomToastMessage.error(translate("fail"), ERROR_CODE[res?.payload?.error_code][language])
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  const disabled =
    !watch(FORM.RULE_NAME) ||
    !watch(FORM.CARD_TYPE) ||
    !watch(FORM.ISSUER) ||
    !watch(FORM.ACQUIRER);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle title="Quản lý hóa đơn" subtitle="Thêm hóa đơn" />
      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2  gap-6">
          <SelectRuleField
            isRequired
            register={register}
            name={FORM.RULE_NAME}
            setValue={setValue}
            errors={errors}
          />
          <SelectCardField
            isRequired
            register={register}
            name={FORM.CARD_TYPE}
            setValue={setValue}
            errors={errors}
          />
        </div>
        <SelectBankReleaseField
          isRequired
          register={register}
          name={FORM.ISSUER}
          setValue={setValue}
          errors={errors}
        />
        <SelectBankHandleField
          isRequired
          register={register}
          name={FORM.ACQUIRER}
          setValue={setValue}
          errors={errors}
        />

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/setting-tx")}
              title="Quay lại"
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton type="submit" disabled={disabled} title="Thêm" className="w-full" />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default CreateCustomer;
