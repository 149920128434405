import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ForgotPassword from './pages/forgot-password'
import ResetPassword from './pages/reset-password'
import Login from './pages/login'

import Dashboard from './pages/dashboard'

import TxnCard from './pages/transactions/TxnCard'
import TxnCash from './pages/transactions/TxnCash'
import TxnEcom from './pages/transactions/TxnEcom'
import TxnInstallment from './pages/transactions/TxnInstallment'
import TxnVA from './pages/transactions/TxnVA'
import TxnWallet from './pages/transactions/TxnWallet'
import TxnSaving from './pages/transactions/TxnSaving'
import TxnLoan from './pages/transactions/TxnLoan'

import Bank from './pages/shop-qr/bank/Bank'
import AddBank from './pages/shop-qr/bank/add-bank'

import PaymentChannel from './pages/shop-qr/payment-channel/PaymentChannel'

import EDC from './pages/edc/EDC'

import Feedback from './pages/feedback'
import CreateFeedback from './pages/feedback/CreateFeedback'
import UpdateFeedback from './pages/feedback/UpdateFeedback'

import BusinessProfile from './pages/business/profile'

import AddStaff from './pages/business/staff/AddStaff'
import UpdateStaff from './pages/business/staff/UpdateStaff'
import ListStaff from './pages/business/staff'

import AddStore from './pages/business/store/AddStore'
import ListStore from './pages/business/store'

import CreateSettingTx from './pages/setting-tx/CreateSettingTx'
import SettingTx from './pages/setting-tx/SettingTx'
import UpdateSettingTx from './pages/setting-tx/UpdateSettingTx'

import CreateCustomer from './pages/sale-management/customer/CreateCustomer'
import TableCustomer from './pages/sale-management/customer/TableCustomer'
import UpdateCustomer from './pages/sale-management/customer/UpdateCustomer'

import CreateProduct from './pages/sale-management/product/CreateProduct'
import TableProduct from './pages/sale-management/product/TableProduct'
import UpdateProduct from './pages/sale-management/product/UpdateProduct'

import ListOrder from './pages/sale-management/order/list-order'

import CreateBill from './pages/sale-management/invoice/CreateBill'
import TableInvoice from './pages/sale-management/invoice/TableInvoice'

import UpdateOrder from './pages/sale-management/order/update-order'
import ListTable from './pages/sale-management/table/ListTable'

import EInvoice from './pages/e-invoice'

import Tracking from './pages/tracking'

import Index from './pages/index'
import PaymentPage from './pages/sale-management/order/payment-page/index'
import CompanySettingEcom from './pages/business/ecom'
import CompanySettingEMenu from './pages/business/emenu'
import TableCategory from './pages/sale-management/category/TableCategory'
import SellRegister from './pages/sale-management/register'
import SellSetting from './pages/sale-management/setting'
import PayByLinkPage from './pages/pay-by-link'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'

const Routes = () => {
  return (
    <Switch>
      {/* Auth */}
      <Route path='/login'>
        <Login />
      </Route>
      <Route path='/forgot-password'>
        <ForgotPassword />
      </Route>
      <Route path='/reset-password'>
        <ResetPassword />
      </Route>

      {/* Dashboard */}
      <Route exact path={`/`}>
        <Redirect to='/dashboard' />
      </Route>
      <Route path='/dashboard'>
        <Dashboard />
      </Route>
      <Route path='/embedded-dashboard/:language'>
        <Dashboard />
      </Route>

      {/* Transactions */}
      <Route path='/tx-card'>
        <TxnCard />
      </Route>
      <Route path='/tx-cash'>
        <TxnCash />
      </Route>
      <Route path='/tx-momo'>
        <TxnWallet />
      </Route>
      <Route path='/tx-installment'>
        <TxnInstallment />
      </Route>
      <Route path='/tx-va'>
        <TxnVA />
      </Route>
      <Route path='/tx-ecom'>
        <TxnEcom />
      </Route>
      <Route path='/tx-saving'>
        <TxnSaving />
      </Route>
      <Route path='/tx-loan'>
        <TxnLoan />
      </Route>

      {/* Payment */}
      <Route exact path='/bank'>
        <Bank />
      </Route>
      <Route path='/bank/add'>
        <AddBank />
      </Route>
      <Route path='/payment'>
        <PaymentChannel />
      </Route>

      {/* Pay By Link */}
      <Route path='/pay-by-link'>
        <PayByLinkPage />
      </Route>

      {/* EDC */}
      <Route path='/edc'>
        <EDC />
      </Route>

      {/* Sale Customer */}
      <Route path='/sale-customer/create'>
        <CreateCustomer />
      </Route>
      <Route path='/sale-customer/update'>
        <UpdateCustomer />
      </Route>
      <Route path='/sale-customer'>
        <TableCustomer />
      </Route>

      {/* Sale Product */}
      <Route path='/sale-product/create'>
        <CreateProduct />
      </Route>
      <Route path='/sale-product/update'>
        <UpdateProduct />
      </Route>
      <Route path='/sale-product'>
        <TableProduct />
      </Route>

      {/* Sale Order */}
      <Route path='/sale-order/payment/:order_id'>
        <PaymentPage />
      </Route>
      <Route path='/sale-order/:order_id'>
        <UpdateOrder />
      </Route>
      <Route path='/sale-order'>
        <ListOrder />
      </Route>

      {/* Sale Table */}
      <Route path='/sale-table'>
        <ListTable />
      </Route>

      {/* Sale Setting */}
      <Route path='/sale-setting'>
        <SellSetting />
      </Route>

      {/* Sale Register */}
      <Route path='/sale-register'>
        <SellRegister />
      </Route>

      {/* E-Invoice */}
      <Route path='/e-invoice'>
        <EInvoice />
      </Route>

      {/* Feedback */}
      <Route path='/feedback/create/:tab'>
        <CreateFeedback />
      </Route>
      <Route path='/feedback/update/:tab/:id'>
        <UpdateFeedback />
      </Route>
      <Route path='/feedback/:tab?'>
        <Feedback />
      </Route>

      {/* Business */}
      <Route path='/profile'>
        <BusinessProfile />
      </Route>
      <Route path='/store/add'>
        <AddStore />
      </Route>
      <Route path='/store'>
        <ListStore />
      </Route>
      <Route path='/staff/add'>
        <AddStaff />
      </Route>
      <Route path='/staff/update'>
        <UpdateStaff />
      </Route>
      <Route path='/staff'>
        <ListStaff />
      </Route>
      <Route path='/e-menu'>
        <CompanySettingEMenu />
      </Route>
      <Route path='/e-com'>
        <CompanySettingEcom />
      </Route>

      {/* Setting Transaction */}
      <Route path='/setting-tx/create'>
        <CreateSettingTx />
      </Route>
      <Route path='/setting-tx/update'>
        <UpdateSettingTx />
      </Route>
      <Route path='/setting-tx'>
        <SettingTx />
      </Route>

      {/* Tracking */}
      <Route path='/tracking'>
        <Tracking />
      </Route>

      {/* Category */}
      <Route path='/category'>
        <TableCategory />
      </Route>

      {/* Sale Invoice */}
      <Route path='/sale-invoice/create'>
        <CreateBill />
      </Route>
      <Route path='/sale-invoice'>
        <TableInvoice />
      </Route>

      <Route component={Index} />
    </Switch>
  )
}
export default Routes
