import React, { useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import LogoDigimi from '@assets/images/bank/digimi.png';
import LogoDigibiz from '@assets/images/bank/digibiz.svg';
import ModalWrap from '@components/modals/ModalWrap';
import { theme } from 'configTheme';
import { history } from '@helpers';
import { useSocket } from '@hooks/useSocket';
import { useTranslation } from '@hooks/useTranslation';
import { CustomToastMessage } from '@utils/customToastMsg';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { getListAccountNumber } from '@actions/metadata.actions';
import succesGif from '@assets/images/product/success.gif'

const ModalQrVerify = ({ isOpen, onClose, qr, bankAccountNo, isBusiness }) => {
    const { translate } = useTranslation('bank');
    const dispatch = useDispatch()
    const { pathname } = useLocation();
    const [verified, setVerified] = useState(false)

    useSocket({
        url: process.env.REACT_APP_VAM_SOCKET,
        onEvent: {
            name: bankAccountNo,
            func: (data, socket) => {
                setVerified(true)
                // CustomToastMessage.success(
                //     translate('shop_qr:add_bank_account_success_title'),
                //     translate('shop_qr:add_bank_account_success_desc') + ' ' + bankAccountNo
                // )
                setTimeout(() => {
                    if (pathname === '/bank') {
                        dispatch(getListAccountNumber())
                        onClose()
                    }
                    else history.push('/bank')
                }, 2000);

                setTimeout(() => {
                    setVerified(false)
                }, 2500);

                socket && socket.disconnect()
            }
        },
        enable: !!qr && !!bankAccountNo && isOpen,
    })

    return (
        <ModalWrap isOpen={isOpen} onClickBackdrop={onClose} style={{ padding: '32px 24px', width: 390 }}>
            {/* Close */}
            <IoCloseSharp
                onClick={onClose}
                style={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                    cursor: "pointer",
                }}
                size={24}
            />

            <div className='flex flex-col items-center' style={{ color: '#03131B' }}>
                {verified ?
                    // Show success screen if verified 
                    <div className='flex flex-col items-center'>
                        <div className='flex flex-col items-center'>
                            <p className='text-xl leading-6 font-bold'>{translate('login')}</p>
                            <p className='text-xl leading-6 font-bold'>
                                <span>{translate('shop_qr:verify_bank_digital_bank')}</span>{" "}
                                <span style={{ color: '#1E2A7B' }}>{isBusiness ? `Digibiz` : `Digimi`}</span>
                            </p>
                        </div>
                        <p className='text-sm leading-5 font-normal mt-4 text-center'>{translate('verify_success')}</p>
                        <img src={succesGif} alt='success-gif' width={220} height={220} className='mt-8' />
                    </div>
                    :
                    // Show QR if not verified yet
                    <div className='flex flex-col items-center'>
                        <div className='flex flex-col items-center'>
                            <p className='text-xl leading-6 font-bold'>{isBusiness ? translate('shop_qr:verify_bank_enterprise_title') : translate('shop_qr:verify_bank_title')}</p>
                            {isBusiness ?
                                <p className='text-xl leading-6 font-bold'>
                                    <span>{translate('shop_qr:verify_bank_digital_bank')}</span>{" "}
                                    <span style={{ color: '#1E2A7B' }}>{`Digibiz`}</span>
                                </p>
                                : <img src={theme.loginLogo} alt='bvb-logo' className='h-10 w-auto mt-2 object-cover' />}
                        </div>
                        <p className='text-sm leading-5 font-normal mt-4 text-center'>{translate('shop_qr:verify_bank_desc')}</p>
                        {qr && <img src={`data:image/png;base64,${qr}`} alt='qr-code' width={220} height={220} className='mt-4' />}
                    </div>

                }

                <img src={isBusiness ? LogoDigibiz : LogoDigimi} alt='logo' className='h-8 w-auto mt-4 object-cover' />
            </div>
        </ModalWrap >
    )
}

export default ModalQrVerify