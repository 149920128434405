import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { formatCurrency } from "@utils/lib";
import { setAmountReceive } from "@reducers/paymentMethod.reducer";
import { useTranslation } from "@hooks/useTranslation";

const Refund = ({ totalAmount }) => {
  const [amount, setAmount] = useState("");
  const [amountRefund, setAmountRefund] = useState("");
  const { translate } = useTranslation()

  const dispatch = useDispatch();

  const [typeInput, setTypeInput] = useState("number");
  const priceRef = useRef("");

  const handleTheRemainAmount = useCallback(
    (amount) => {
      if (!amount || Number(amount) < totalAmount) {
        setAmountRefund("..");
        return;
      }
      if (amount === totalAmount) {
        setAmountRefund(0);
        return;
      }
      setAmountRefund(formatCurrency(Number(amount) - totalAmount));
    },
    [totalAmount]
  );

  const onBlur = useCallback(() => {
    setTypeInput('text')
    setAmount(amount ? formatCurrency(amount) : "");
    handleTheRemainAmount(priceRef.current);
  }, [amount, handleTheRemainAmount]);

  const onFocus = useCallback(() => {
    setTypeInput("number")
    setAmount(priceRef.current || "");
  }, []);

  const handleChangeReceiveMoney = (e) => {
    const amount =
      e.target.value.toString().length > 13
        ? e.target.value.toString().substring(0, 13)
        : e.target.value;
    setAmount(amount);
    priceRef.current = amount;
  };

  useEffect(() => {
    if (amount && amount > 0) {
      handleTheRemainAmount(amount);
    }
  }, [amount, handleTheRemainAmount, totalAmount]);

  useEffect(() => {
    dispatch(
      setAmountReceive({
        receiveMoney: priceRef.current,
        totalPrice: totalAmount,
      })
    );
  }, [amount, dispatch, totalAmount]);

  return (
    <div className='flex flex-row justify-between space-x-8'>
      <div className='flex-1 flex-col flex space-y-2'>
        <span style={{ color: "#262626" }} className='text-sm font-semibold leading-5'>
          {translate('sale_management_amount_received')}
        </span>
        <input
          style={{
            padding: "10px 16px",
            border: "solid 1px #e8e8e8",
            background: "#FAFAFA",
          }}
          className='rounded-lg'
          value={amount}
          onChange={handleChangeReceiveMoney}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={translate('sale_management_input_money')}
          defaultValue={amount}
          type={typeInput}
          inputMode='numeric'
          pattern='[0-9]*'
        />
      </div>
      <div className='flex-1 flex-col flex space-y-2'>
        <span style={{ color: "#262626" }} className='text-sm font-semibold leading-5'>
          {translate('sale_management_excess_money')}</span>
        <input
          style={{
            padding: "10px 16px",
            border: "solid 1px #e8e8e8",
            background: "#FAFAFA",
          }}
          value={amountRefund}
          className='rounded-lg'
          placeholder='...'
          disabled
          type='text'
        />
      </div>
    </div>
  );
};

export default Refund;
