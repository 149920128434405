import React from 'react';
import SelectRadioField from '@components/fields/SelectRadioField';
import { useTranslation } from '@hooks/useTranslation';
import SelectFieldColor from './input/SelectFieldColor';
import SelectPaymentGatewayField from './input/SelectPaymentGatewayField';
import UploadLogoField from './input/UploadLogoField';
import { LIST_POSITION, LIST_SIZE } from '@constants/business/ecom';

const OptionGateway = ({
  setPreviewData,
  previewDataSubmit,
  setPreviewDataSubmit,
}) => {
  const { translate } = useTranslation();

  const handleSetPrimaryColor = (color) => {
    setPreviewData((prev) => ({ ...prev, primary_color: color }));
    setPreviewDataSubmit((prev) => ({ ...prev, primary_color: color }));
  };
  const handleSetSecondaryColor = (color) => {
    setPreviewData((prev) => ({ ...prev, secondary_color: color }));
    setPreviewDataSubmit((prev) => ({ ...prev, secondary_color: color }));
  };

  const handleChangeRadioHeight = (value) => {
    setPreviewData((prev) => ({ ...prev, logo_height: value }));
    setPreviewDataSubmit((prev) => ({ ...prev, logo_height: value }));
  };

  const handleChangeRadioPosition = (value) => {
    setPreviewData((prev) => ({ ...prev, logo_position: value }));
    setPreviewDataSubmit((prev) => ({ ...prev, logo_position: value }));
  };

  return (
    <div
      style={{ border: '1px solid #E8E8E8' }}
      className='flex-1 rounded-lg p-4 space-y-4'
    >
      <div className='flex items-center justify-between'>
        <p className='text-xl leading-7 font-semibold text-black'>
          {translate('payment_gateway')}
        </p>
        {/* <CustomButton
          title={translate("payment_gateway_apply")}
          onClick={handleApply}
          style={{ background: "#0094F4", border: "none", height: 40, width: 95 }}
        /> */}
      </div>

      <SelectPaymentGatewayField
        list={previewDataSubmit?.active_methods}
        setPreviewData={setPreviewData}
        setPreviewDataSubmit={setPreviewDataSubmit}
      />
      <SelectFieldColor
        label={translate('payment_primary_color')}
        color={previewDataSubmit?.primary_color}
        setColor={handleSetPrimaryColor}
      />
      <SelectFieldColor
        label={translate('payment_secondary_color')}
        color={previewDataSubmit?.secondary_color}
        setColor={handleSetSecondaryColor}
      />

      <UploadLogoField
        logoUrl={previewDataSubmit?.logo_url}
        setPreviewData={setPreviewData}
        setPreviewDataSubmit={setPreviewDataSubmit}
      />
      <SelectRadioField
        title={translate('payment_size')}
        data={LIST_SIZE}
        value={previewDataSubmit?.logo_height}
        setValue={handleChangeRadioHeight}
      />
      <SelectRadioField
        title={translate('payment_position')}
        data={LIST_POSITION}
        value={previewDataSubmit?.logo_position}
        setValue={handleChangeRadioPosition}
      />
    </div>
  );
};

export default OptionGateway;
