import React from "react";
import SelectCheckbox from "./SelectCheckbox";
import { useTranslation } from "@hooks/useTranslation";
import { LIST_METHOD } from "@constants/business/ecom";

const SelectPaymentGatewayField = ({ list = [], setPreviewData, setPreviewDataSubmit }) => {
  const { translate } = useTranslation();

  const handleClick = (value) => {
    setPreviewData((prev) => ({
      ...prev,
      active_methods: prev.active_methods.map((item) => {
        if (item.name === value) {
          return { ...item, active: !item.active };
        }
        return item;
      }),
    }));
    setPreviewDataSubmit((prev) => ({
      ...prev,
      active_methods: prev.active_methods.map((item) => {
        if (item.name === value) {
          return { ...item, active: !item.active };
        }
        return item;
      }),
    }));
  };

  return (
    <div className="space-y-2 text-sm leading-5 text-black">
      <p className="font-semibold">{translate("payment_type")}</p>
      {LIST_METHOD.map((item, index) => {
        const isActive = !!list.find((method) => method.active && method.name === item.value);

        return (
          <SelectCheckbox
            key={item.value + index}
            label={translate(item.label)}
            value={item.value}
            handleClick={() => handleClick(item.value)}
            isActive={isActive}
          />
        );
      })}
    </div>
  );
};

export default SelectPaymentGatewayField;
