import { createSlice } from "@reduxjs/toolkit";
import { getListStore, getListStaff, getListLinkedVa, getListAccountNumber } from "@actions/metadata.actions";
import { getListSettingTx } from "@actions/setting-tx.actions";

const initialState = {
  listStore: [],
  totalPageStore: 0,
  listStaff: [],
  listSettingTx: [],
  listLinkedVa: [],
  listAccountNumber: []
};

const metadataSlice = createSlice({
  name: "metadataSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getListStore.fulfilled, (state, action) => {
        state.listStore = action.payload.data;
        state.totalPageStore = action.payload.data.total_page;
      })
      .addCase(getListStore.rejected, (state, action) => {
        state.listStore = [];
      })

      .addCase(getListStaff.fulfilled, (state, action) => {
        state.listStaff = action.payload.data;
      })
      .addCase(getListStaff.rejected, (state, action) => {
        state.listStaff = [];
      })

      .addCase(getListSettingTx.fulfilled, (state, action) => {
        state.listSettingTx = action.payload.data;
      })
      .addCase(getListSettingTx.rejected, (state, action) => {
        state.listSettingTx = [];
      })

      .addCase(getListAccountNumber.fulfilled, (state, action) => {
        state.listAccountNumber = action.payload.data;
      })
      .addCase(getListAccountNumber.rejected, (state, action) => {
        state.listAccountNumber = [];
      })

      .addCase(getListLinkedVa.fulfilled, (state, action) => {
        state.listLinkedVa = action.payload.data;
      })
      .addCase(getListLinkedVa.rejected, (state, action) => {
        state.listLinkedVa = [];
      }),
});
export default metadataSlice.reducer;

export const selectListStore = (state) => state.metadata.listStore;
export const selectListStaff = (state) => state.metadata.listStaff;
export const selectListSettingTx = (state) => state.metadata.listSettingTx;
export const selectListLinkedVa = (state) => state.metadata.listLinkedVa;
export const selectTotalPageStore = (state) => state.metadata.totalPageStore;
export const selectListAccountNumber = (state) => state.metadata.listAccountNumber;
