import PropTypes from 'prop-types'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { usePagination, useSortBy, useTable } from 'react-table'
import { useReactToPrint } from 'react-to-print'
import 'tippy.js/dist/tippy.css'
import { getStatus } from '@utils/lib'
import CustomButton from '../buttons/CustomButton'
import Widget from '../widget'
import { ComponentToPrint } from './ComponentToPrint'
import TablePagination from './pagination'

import { useSelector } from 'react-redux'
import print from '@assets/images/payment/print.svg'
import { useTranslation } from '@hooks/useTranslation'
import { selectPageLoading } from '@reducers/page-loading.reducer'
import { CustomToastMessage } from '@utils/customToastMsg'
import { CellSkeleton } from '../skeleton/TableSkeleton'
import './datatable.css'
import { STATUS_CELL_COLOR } from '@constants/common'
import { theme } from 'configTheme'
import { TxnService } from '@services/transaction.service'

const RenderStatus = ({ title, color }) => {
  const { bg, text } = color
  return (
    <div
      style={{ background: bg, maxWidth: 180 }}
      className='rounded-full w-full py-1 px-3 flex justify-between gap-2 items-center'
    >
      <span
        className='w-2 h-2 rounded-full'
        style={{ background: text }}
      ></span>
      <p style={{ color: text, flex: 1 }} className='text-center font-medium'>
        {title}
      </p>
    </div>
  )
}

export const StatusCell = ({ data, color, title }) => {
  const { translate } = useTranslation('txn')
  const colors = {
    blue: { bg: theme.primary, text: '#FFFFFF' },
    red: { bg: '#F5222D1A', text: '#F5222D' },
    lightBlue: { bg: '#B7DDF2', text: '#232A75' },
    turquoise: { bg: theme.secondary, text: '#FFFFFF' },
  }

  if (data && data.values.response_code) {
    if (data.values.response_code === '00') {
      return <RenderStatus color={colors[STATUS_CELL_COLOR.BLUE]} title='Thành công' />
    }
    return <RenderStatus color={colors[STATUS_CELL_COLOR.RED]} title='Lỗi' />
  } else if (data && data.values.status) {
    if (
      data.values.status === 'pending' ||
      data.values.status === 'WAITING_FOR_PAID'
    ) {
      return (
        <RenderStatus
          color={colors[STATUS_CELL_COLOR.TURQUOISE]}
          title={translate(getStatus(data.values.status))}
        />
      )
    } else if (
      data.values.status === 'success' ||
      data.values.status === 'PAID'
    ) {
      return (
        <RenderStatus
          color={colors[STATUS_CELL_COLOR.BLUE]}
          title={translate(getStatus(data.values.status))}
        />
      )
    }
    return (
      <RenderStatus
        color={colors[STATUS_CELL_COLOR.RED]}
        title={translate(getStatus(data.values.status))}
      />
    )
  } else return <RenderStatus color={colors[color]} title={title} />
}

export const CellBank = ({ title, children, className }) => {
  return (
    <button className={`relative tippy-bank-cover cursor-pointer ${className}`}>
      <div
        style={{
          bottom: 'calc(100% + 5px)',
          left: '50%',
          transform: 'translateX(-50%)',
          color: '#434C76',
        }}
        className='tippy-bank z-50 absolute  items-center rounded-xl leading-5 border py-2 px-3 bg-white text-sm '
      >
        {title}
      </div>
      {children}
    </button>
  )
}

export const ReceiptCell = ({ data }) => {
  const componentRef = useRef(null)
  const onBeforeGetContentResolve = useRef(null)

  const handleAfterPrint = useCallback(() => { }, [])

  const handleBeforePrint = useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve
      setTimeout(() => {
        resolve()
      }, 2000)
    })
  }, [])

  const reactToPrintContent = useCallback(() => {
    return componentRef.current
  }, [])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'AwesomeFileName',
    //onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  })

  useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === 'function') {
      onBeforeGetContentResolve.current()
    }
  }, [])

  return (
    <div className='flex items-center justify-center'>
      {/* <div className='flex flex-row items-center justify-between space-x-2'>
        {loading && <ClipLoader color='#565E85' size={14} />}
        <button onClick={handlePrint} className='text-sm font-normal flex gap-10px items-center'>
          <FiUpload />
          {loading ? "Đang In" : "In"}
        </button>
      </div> */}
      <img
        src={print}
        alt='print'
        style={{ width: 16, cursor: 'pointer' }}
        onClick={handlePrint}
      />

      <div className='hidden'>
        <ComponentToPrint ref={componentRef} image={data?.original?.bill_url} />
      </div>
    </div>
  )
}

function Datatable(props) {
  const { translate } = useTranslation('sale_order')
  const {
    columns,
    data,
    setFilters,
    filters,
    styleTable,
    pagination,
    total_amount,
    hasPagination = true,
    textLeft = true,
  } = props
  const isEmpty = !data.length

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      manualPagination: true,
      autoResetPage: false,
      pageCount: pagination.totalPages,
    },
    useSortBy,
    usePagination,
  )

  const [pageNum, setPageNum] = useState(pageIndex + 1)

  const handlePaginationChange = useCallback(() => {
    // onPaginationChange({ page: pageIndex + 1, pageSize });
    if (pageIndex + 1 !== filters.page) {
      setFilters((current) => setFilters({ ...current, page: pageIndex + 1 }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize])

  useEffect(() => {
    handlePaginationChange()
  }, [handlePaginationChange, pageIndex])

  const typeTxn = useLocation().pathname.split('-')[1]

  const handleExport = async (filters) => {
    try {
      const res = await TxnService.exportTxnCSV({ ...filters, type: typeTxn })
      const file = URL.createObjectURL(res)

      const link = document.createElement('a')
      link.href = file
      link.download = `report-${typeTxn === 'momo' ? 'wallet' : typeTxn}.xlsx`
      link.click()
      CustomToastMessage.success(
        translate('txn:status_success'),
        translate('export_report_success')
      )
    } catch (error) {
      CustomToastMessage.error(
        translate('txn:status_fail'),
        translate('export_report_fail')
      )
    }
  }

  const isLoading = useSelector(selectPageLoading)
  // Render the UI for your table
  return (
    <>
      <Widget>
        <div className='overflow-x-auto'>
          <table
            style={styleTable}
            {...getTableProps()}
            className='table font-montserrat text-grey-atom-grey'
          >
            <thead className='sticky top-0 bg-white shadow-sm'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => {
                    const { canSort, isSorted, isSortedDesc } = column
                    var isColCenter =
                      column.Header == translate('sale_management_status') ||
                        column.Header == translate('sale_management_index') ||
                        column.Header == translate('sale_management_image')
                        ? false
                        : textLeft
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={`
                            ${index === 0 ? 'rounded-l-lg' : ''}
                            ${index === headerGroup.headers.length - 1 ? 'rounded-r-lg' : ''}`}
                      >
                        <div
                          className={
                            `flex flex-row gap-2 items-center text-secondary-text whitespace-no-wrap text-sm leading-5 font-semibold pointer-events-none
                            ${isColCenter ? 'justify-start' : 'justify-center'}`
                          }
                        >
                          <span className='text-left normal-case'>
                            {column.render('Header')}
                          </span>

                          {/* Sort icon */}
                          {/* {canSort && <div className="relative w-4 h-4 flex items-center justify-center">
                            <embed src={arrowDownIcon} style={{ 
                              transform: isSortedDesc ? 'rotate(180deg)' : isSorted ? 'rotate(0deg) scale(1)' : 'rotate(90deg) scale(0.5)',
                            }} alt='arrowDownIcon' className={`w-4 h-4 transition-all duration-300 absolute ${isSorted ? 'rotate-0' : 'opacity-0'}`} />
                            <embed src={print} alt='arrowDownIcon' style={{
                              transform: isSorted ? 'scale(0.5)' : 'scale(1)',
                            }} className={`w-4 h-4 transition-all duration-300 absolute ${isSorted ? 'opacity-0' : 'opacity-100'}`} />
                          </div>} */}
                          {/* Add a sort direction indicator */}
                          {/* <span className='ml-auto'>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FiChevronDown className='stroke-current text-2xs' />
                          ) : (
                            <FiChevronUp className='stroke-current text-2xs' />
                          )
                        ) : (
                          ""
                        )}
                      </span> */}
                        </div>
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {!isLoading ? (
                <>
                  {page.map((row, i) => {
                    prepareRow(row)
                    const isDisabledRow = false
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={`${isDisabledRow && 'opacity-50'}`}
                      >
                        {row.cells.map((cell, index) => {
                          var isColCenter =
                            cell.column.Header ==
                              translate('sale_management_status') ||
                              cell.column.Header ==
                              translate('sale_management_index') ||
                              cell.column.Header ==
                              translate('sale_management_image')
                              ? false
                              : textLeft
                          var isName =
                            cell.column.Header ==
                              translate('sale_management_product_name')
                              ? true
                              : false

                          return (
                            <td
                              data-label={columns[index].Header}
                              style={{
                                color: '#2C2C2C',
                                fontSize: 14,
                                fontWeight: 400,
                                width: isName ? '250px' : '',
                                whiteSpace: isName ? 'wrap' : '',
                              }}
                              {...cell.getCellProps()}
                            >
                              <div
                                className={`flex items-center ${isColCenter
                                  ? 'justify-start'
                                  : 'justify-center'
                                  }`}
                              >
                                {cell.column.Header ==
                                  translate('image') ? (
                                  <img
                                    src={cell.value}
                                    alt='product'
                                    className='w-10 h-10 rounded'
                                  />
                                ) : cell.column.Header ==
                                  translate('sale_management_index') ? (
                                  pageIndex * filters.page_size + cell.value
                                ) : (
                                  <div className='text-left'>
                                    {cell.render('Cell')}
                                  </div>
                                )}
                              </div>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </>
              ) : (
                <>
                  {Array.from({ length: Number(filters.page_size) }).map((_, index) => {
                    return <tr key={index}>
                      {columns?.map((_, index) => {
                        return <td key={index} style={{ height: 46 }}>
                          <CellSkeleton />
                        </td>
                      })}
                    </tr>
                  })}
                </>
              )}
            </tbody>
          </table>

          {!isLoading && isEmpty && (
            <p className='h-20 w-full flex justify-center items-center'>
              {translate('common:no_data')}
            </p>
          )}
        </div>

        {!isEmpty && total_amount > 0 && (
          <div className='p-5 flex flex-col-reverse gap-3 md:gap-0 md:flex-row justify-between md:items-center'>
            <CustomButton
              onClick={() => handleExport(filters)}
              title={translate('txn:table_export')}
              leftIcon={
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="icon/outlined/application/export">
                    <path id="Vector" d="M13.8797 11.8344H13.0391C12.9734 11.8344 12.9187 11.8891 12.9187 11.9547V12.9203H3.07969V3.07969H12.9203V4.04531C12.9203 4.11094 12.975 4.16563 13.0406 4.16563H13.8813C13.9469 4.16563 14.0016 4.1125 14.0016 4.04531V2.47969C14.0016 2.21406 13.7875 2 13.5219 2H2.47969C2.21406 2 2 2.21406 2 2.47969V13.5203C2 13.7859 2.21406 14 2.47969 14H13.5203C13.7859 14 14 13.7859 14 13.5203V11.9547C14 11.8875 13.9453 11.8344 13.8797 11.8344ZM14.1703 7.90156L11.9531 6.15156C11.8703 6.08594 11.75 6.14531 11.75 6.25V7.4375H6.84375C6.775 7.4375 6.71875 7.49375 6.71875 7.5625V8.4375C6.71875 8.50625 6.775 8.5625 6.84375 8.5625H11.75V9.75C11.75 9.85469 11.8719 9.91406 11.9531 9.84844L14.1703 8.09844C14.1853 8.08674 14.1973 8.0718 14.2056 8.05475C14.214 8.03769 14.2183 8.01897 14.2183 8C14.2183 7.98103 14.214 7.96231 14.2056 7.94525C14.1973 7.92819 14.1853 7.91326 14.1703 7.90156Z" fill="currentColor" />
                  </g>
                </svg>
              }
            />
            <div className='text-primary text-lg leading-7 font-semibold'>
              <span>{translate('txn:table_total_amount')}:</span>
              <span className='ml-4'>{total_amount?.toLocaleString()} VND</span>
            </div>
          </div>
        )}
      </Widget>
      {hasPagination && !isEmpty && (
        <div
          style={{ margin: '0' }}
          className={`flex flex-row flex-wrap items-start shadow-primary gap-4 md:gap-0 md:items-center ${isEmpty ? 'justify-center' : 'justify-between'
            } px-3 py-4 md:p-5 bg-white rounded-b-lg`}
        >
          <TablePagination
            pagination={pagination}
            setFilters={setFilters}
            pageCount={pageCount}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            // pageIndex={pageIndex}
            setPage={setPageNum}
            page={pageNum}
            pageIndex={filters.page - 1}
            gotoPage={gotoPage}
          />

          <div className='flex items-center gap-2'>
            <p className='text-sm font-medium text-grey-atom-grey whitespace-no-wrap'>
              {translate('common:rows')}
            </p>
            <select
              className='form-select text-grey-atom-grey text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none'
              value={pagination?.pageSize}
              style={{
                paddingRight: 25,
              }}
              onChange={(e) => {
                setFilters((current) =>
                  setFilters({
                    ...current,
                    page: 1,
                    page_size: Number(e.target.value),
                  })
                )
                gotoPage(0)
                setPageNum(1)
              }}
            >
              {[10, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  )
}
Datatable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  // onPaginationChange: PropTypes.func.isRequired,
  headerClass: PropTypes.string,
  loading: PropTypes.bool,
}
export default memo(Datatable)
