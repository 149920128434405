import { useEffect, useRef } from "react";

export default function useClickOutside(callback) {
    const nodeRef = useRef(null);

    useEffect(() => {
        function handleClickOutSide(ev) {
            if (nodeRef.current && !nodeRef.current.contains(ev.target)) {
                callback && callback();
            }
        }

        document.addEventListener("mousedown", handleClickOutSide);

        return () => {
            document.removeEventListener("mousedown", handleClickOutSide);
        };

    }, [nodeRef, callback]);

    return { nodeRef };
}