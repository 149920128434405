import React from 'react'
import Skeleton from './MainSkeleton'

export const CellSkeleton = () => {
  return (
    <Skeleton
      width={'100%'}
      height={'100%'}
      loading={true}
      borderRadius={'999px'}
      backgroundColor={'#ccc'}
    />
  )
}
