import React from 'react'
import Navbar from '@components/navbar'
import GetProfile from '@components/profile/GetProfile'
import Sidebar from '@components/sidebar'
import { history } from '@helpers/history'
import { LIMIT_UNFOCUS_TIME } from '@constants/common'
import { LocalStorageService } from "@helpers/localStorageService";

const MainLayout = ({ children }) => {
  const calcUnfocusedTime = React.useCallback(() => {
    const diffTime = Date.now() - LocalStorageService.getLastActiveTime()
    if (diffTime >= LIMIT_UNFOCUS_TIME) history.push('/login')
  }, [])

  const updateLastActiveTime = React.useCallback(() => LocalStorageService.setLastActiveTime(Date.now()), [])

  React.useEffect(() => {
    window.addEventListener('focus', calcUnfocusedTime)
    window.addEventListener('blur', updateLastActiveTime)
    window.addEventListener('unload', updateLastActiveTime)

    calcUnfocusedTime()

    return () => {
      window.removeEventListener('focus', calcUnfocusedTime)
      window.removeEventListener('blur', updateLastActiveTime)
      window.removeEventListener('unload', updateLastActiveTime)
    }
  }, [])

  return (
    <div className='flex bg-grey-50'>
      <GetProfile />

      <Sidebar />

      <div
        style={{ background: '#F9F9F9' }}
        className='text-grey-900 overflow-auto flex-1 h-screen'
      >
        <Navbar />

        <div
          style={{
            minHeight: 'calc(100vh - 92px)',
            height: 'calc(100vh - 92px)',
          }}
          className='w-full overflow-y-auto custom-scrollbar-1 p-3 md:pr-6 md:pl-12'
        >
          {children}
          {/* <Chatbot /> */}
        </div>
      </div>
    </div>
  )
}

export default MainLayout
