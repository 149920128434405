import React, { useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import icCustomer from "@assets/images/icon/ic_customer.svg";
import close from "@assets/images/product/close.svg";
import user from "@assets/images/product/user.svg";
import CustomButton from '@components/buttons/CustomButton';
import { history } from '@helpers';
import { useTranslation } from '@hooks/useTranslation';
import { formatCurrency, hasOrderCustomer } from '@utils/lib';
import PopupAddCustomer from './AddCustomer/page';
import ProductList from './ProductList';

const OrderDetail = ({ onClose, pickedItem, total, tableName, customerName, setCustomerName }) => {
    const [showAddCustomer, setShowAddCustomer] = useState(false);

    const { order_id } = useParams();
    const { translate } = useTranslation('sale_order')

    return (
        <div className="fixed z-50 inset-0 bg-blur flex items-center justify-center">
            <div className="relative flex flex-col gap-6 items-center rounded-2xl bg-white p-10">
                <img
                    src={close}
                    alt=""
                    className="absolute cursor-pointer"
                    style={{ top: "16px", right: "16px" }}
                    onClick={onClose}
                />
                <p className="text-black text-xl font-semibold leading-7">
                    {translate('order_detail')}
                    <span className="text-primary"> {`(${pickedItem?.length})`}</span>
                    {' '}{tableName}
                </p>
                <div className="flex flex-col gap-4">
                    <ProductList pickedItem={pickedItem} />
                    <div
                        className="flex flex-col gap-3 border-t border-dashed"
                        style={{ borderColor: "#E8E8E8" }}
                    >
                        <div className="flex items-center justify-between w-full py-3 px-2">
                            <span className="text-sm font-medium" style={{ color: "#8C8C8C" }}>
                                {translate('subtotal')}
                            </span>
                            <span className="text-base font-semibold leading-5 text-blackgray">
                                {formatCurrency(total)}
                            </span>
                        </div>
                        <div className="flex items-center justify-between w-full py-3 px-2">
                            <span className="text-sm font-medium" style={{ color: "#8C8C8C" }}>
                                {translate('customer')}
                            </span>
                            <CustomButton
                                onClick={() => setShowAddCustomer(true)}
                                title={hasOrderCustomer(customerName) ? customerName : translate('add_customers')}
                                leftIcon={!hasOrderCustomer(customerName) ? <img src={user} alt="" /> : <img src={icCustomer} alt="" />}
                                style={{ height: 40, borderRadius: '60px', padding: 8, paddingLeft: 16, paddingRight: 16, gap: 8 }}
                            />
                        </div>
                    </div>
                    <div className="w-full" style={{ height: "1px", backgroundColor: "#E8E8E8" }}></div>
                </div>
                <div className="w-full flex flex-col gap-3">
                    <div className="flex justify-between items-center">
                        <span className="text-sm font-medium text-blackgray">
                            {translate('total_payment')}
                        </span>
                        <span className="text-2xl font-semibold leading-8 text-black">
                            {formatCurrency(total)}
                        </span>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <CustomButton
                            onClick={onClose} outline className="rounded-lg"
                            title={translate('update_order')}
                        />
                        <CustomButton
                            onClick={() => history.push(`/sale-order/payment/${order_id}`)}
                            title={translate('payment')}
                            disabled={pickedItem?.length === 0} className="rounded-lg"
                        />
                    </div>
                </div>
            </div>
            {showAddCustomer &&
                <PopupAddCustomer
                    onClose={() => setShowAddCustomer(false)}
                    setCustomerName={setCustomerName}
                />
            }
        </div>
    )
}

export default OrderDetail
