import React, { useState } from 'react'
import IcUpload from '@assets/images/icon/ic_upload.svg'
import { handleDragDrop, validateFile } from '@utils/lib'
import { useTranslation } from '@hooks/useTranslation'
import { imageService } from '@services/image.service'

function UploadProductField({
  extra,
  label,
  disabled,
  variant,
  isRequired,
  imgUrl,
  setImgUrl,
  errors,
  name,
  id = 'upload',
}) {
  const [file, setFile] = useState(null)
  const [scale, setScale] = useState(1)
  const { translate } = useTranslation()

  const handleFileChange = async (value) => {
    if (!validateFile(value)) return

    if (file) URL.revokeObjectURL(file)

    try {
      const res = await imageService.postImage(value)
      setImgUrl(res)
    } catch (error) {
      setImgUrl(URL.createObjectURL(value))
    }

    setFile(URL.createObjectURL(value))
  }

  const Upload = () => {
    return (
      <label
        style={{ width: 111, height: 111 }}
        className={`rounded-xl border border-dashed border-gray-primary flex items-center justify-center cursor-pointer `}
        htmlFor={id}
      >
        <img
          src={IcUpload}
          alt='IcUpload'
          width={23}
          height={23}
          className=' '
        />
      </label>
    )
  }

  return (
    <div className={`${extra} font-monte `}>
      {label && (
        <div className='flex flex-col mb-2'>
          <div>
            <label
              htmlFor={id}
              className={`${
                disabled
                  ? 'text-hint '
                  : variant === 'secondary'
                    ? 'text-secondary-text '
                    : 'text-primary-text '
              } text-sm leading-5 font-semibold`}
            >
              {label}
            </label>
            {isRequired && <span className='font-semibold text-error'>*</span>}
          </div>
          <span className='text-grey-atom-grey font-medium leading-6 text-xs'>
            <p
              className='text-red-500 leading-6 text-xs inline-block'
              style={{
                marginRight: 2,
              }}
            >
              *
            </p>
            PNG, JPG, JPEG {translate('common:max_file_size')} 500KB
          </span>
        </div>
      )}

      <div
        style={{ gap: 48, transform: `scale(${scale})` }}
        className='flex items-center relative justify-center p-3 border rounded-xl border-gray-primary'
        {...handleDragDrop(handleFileChange, setScale)}
      >
        {imgUrl ? (
          <div
            style={{ width: 111, height: 111 }}
            className='select-field-wrapper relative cursor-pointer transition border border-gray-primary rounded-xl overflow-hidden'
          >
            <img src={imgUrl} alt='item' className='w-full h-full rounded-xl' />
            <div
              className={
                'select-field absolute inset-0 bg-blur z-50 transition'
              }
            >
              <Upload />
            </div>
          </div>
        ) : (
          <Upload />
        )}

        <input
          id={id}
          type='file'
          className='absolute inset-0 opacity-0 cursor-pointer'
          onChange={(e) => {
            handleFileChange(e.target.files[0])
            e.target.value = null
          }}
          accept='.png, .jpeg, .jpg'
        />
      </div>
    </div>
  )
}

export default UploadProductField
