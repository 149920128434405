import React from "react";
import { useTranslation } from "@hooks/useTranslation";

const SelectRadioField = ({ title, data = [], value, setValue }) => {
  const { translate } = useTranslation()

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="space-y-2 text-sm leading-5 text-black">
      <p className="font-semibold">{title}</p>
      <div className="flex items-center gap-4">
        {data.map((item) => (
          <label key={item.value} className="flex items-center gap-2 cursor-pointer">
            <input
              type="radio"
              value={item.value}
              checked={item.value == value}
              onChange={handleChange}
              className="cursor-pointer"
            />
            <p className="font-medium">{translate(item.label)}</p>
          </label>
        ))}
      </div>
    </div>
  );
};

export default SelectRadioField;
