import React, { useState } from "react";
import useWindowSize from "@hooks/useWindowSize";

import CustomButton from "@components/buttons/CustomButton";
import InputField from "@components/fields/InputField";

import diamond from "@assets/images/sale-setting/diamond.gif";
import { theme } from "configTheme";
import { useTranslation } from "@hooks/useTranslation";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getMerchant, updateMerchant } from "@actions";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";
import { selectMerchant } from "@reducers/sass.reducer";
import { REWARD_SETTING_FORM } from "@constants/sale-management/setting";

const Reward = () => {
  const { isMd } = useWindowSize();
  const { translate, language } = useTranslation('sale_settings');
  const dispatch = useDispatch()
  const defaultValues = useSelector(selectMerchant)

  const [loading, setLoading] = useState(false)

  const { handleSubmit, register, watch, setValue, formState: { errors } } = useForm({ defaultValues })

  const onSubmit = async (value) => {
    setLoading(true)
    try {
      const res = await dispatch(updateMerchant(value))
      if (res?.meta?.requestStatus === 'fulfilled') {
        CustomToastMessage.success(
          translate('txn:status_success'),
          translate('update_success')
        )
        dispatch(getMerchant())
      } else CustomToastMessage.error(translate('common:fail'), ERROR_CODE[res?.payload?.error_code][language])
    } catch (err) { console.log(err) }
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="bg-white rounded-xl p-12 flex flex-col items-center text-blackgray shadow-primary">
      <img src={diamond} alt="diamond" width={178} />
      <p className="text-sm leading-5 font-semibold">
        {translate("discount_rate")}
        <span className="text-error">*</span>
      </p>
      <div style={{ width: isMd ? 580 : "100%" }}>
        <InputField
          type="percent"
          setValue={setValue}
          placeholder={translate("discount_rate_percent")}
          id={REWARD_SETTING_FORM.POINT_PERCENT}
          name={REWARD_SETTING_FORM.POINT_PERCENT}
          value={watch(REWARD_SETTING_FORM.POINT_PERCENT)}
          extra="mt-3 w-full "
          register={register}
          errors={errors}
          inputStyle="text-center"
        />
      </div>
      <p className="text-sm leading-5 font-normal mt-2">
        (1 {translate("point")} = 1 VNĐ)
      </p>

      <div className="mt-6 mb-2 w-full">
        {/* <div className="flex items-center justify-between mb-3">
          <label htmlFor="lynkid" className="flex items-center gap-3 cursor-pointer">
            <input
              id="lynkid"
              type="checkbox"
              className="w-5 h-5 outline-[#BFBFBF] rounded"
              style={{ accentColor: theme.primary }}
            />
            <p className="text-sm leading-5 font-normal text-blackgray">
              {translate("link_app_checkbox")}
            </p>
          </label>

          <a
            href="/pdf/Điểm tích lũy liên kết LynkiD.pdf"
            target="_blank"
            className="text-primary text-sm leading-5 font-normal underline mt-2 block"
          >
            {translate("view_detail")}
          </a>
        </div> */}
        <CustomButton disabled={loading} type="submit" title={translate('common:save')} className="w-full" />
      </div>
    </form>
  );
};

export default Reward;
