import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "@helpers/history";
import { selectListOrder } from "@reducers/order.reducer";
import { createOrder, generateQR } from "@services/table.service";
import { formatCurrency } from "@utils/lib";
import useSearchParams from "@pages/sale-management/customer/useSearchParams";

import CustomButton from "@components/buttons/CustomButton";
import SearchFieldParams from "@components/fields/SearchFieldParams";
import SectionTitle from "@components/section-title";
import Datatable, { StatusCell } from "@components/tsdatatable/datatables";
import ModalDetail from "./ModalDetail";
import PrintInvoice from "./PrintInvoice";

import { FiEye } from "react-icons/fi";
import { useReactToPrint } from "react-to-print";
import IcPrint from "@assets/images/payment/print.svg";
import { useOrder } from "@hooks/useOrder";
import { useTranslation } from "@hooks/useTranslation";
import { selectProfile } from "@reducers/user.reducer";
import { invoiceServices } from "@services/invoice.service";
import { STATUS_ORDER } from "@constants/sale-management/order";
import { STATUS_CELL_COLOR } from "@constants/common";

const { COMPLETED, HANDLING } = STATUS_ORDER

const ListOrder = () => {
  const dispatch = useDispatch();
  const { is_fnb } = useSelector(selectProfile)
  const { translate } = useTranslation('sale_order')
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showPrintInvoice, setShowPrintInvoice] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [numOfPage, setNumOfPage] = useState("1");

  const { updatePagination, setSearchQuery, filtersParams } = useSearchParams();
  const [filters, setFilters] = useState(filtersParams);
  const data = useSelector(selectListOrder);

  const { info, products } = useOrder({ orderId: selectedOrder?.order_id })
  const [qr, setQr] = useState(null)

  const print = useReactToPrint({
    content: () => document.getElementById('print-content'),
  });

  const handlePrint = async (order) => {
    if (order?.status === HANDLING) {
      let invoiceId = info?.invoice_id
      if (order?.order_id !== info?.order_id) {
        try {
          const res = await invoiceServices.getDetailInvoice(order?.order_id);
          invoiceId = res?.data?.invoice_data?.invoice_id
        } catch (error) { console.log(error) }
      }
      try {
        const resQR = await generateQR(invoiceId)
        setQr(resQR?.QR)
      } catch (error) { console.log(error) }
    }
    setShowPrintInvoice(true);
    setTimeout(print, 500)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: translate('common:numerical_order'),
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: translate('order_id'),
        accessor: "code",
        disableSortBy: true,
      },
      {
        Header: translate('hour_date'),
        accessor: "updated_at",
        Cell: ({ value, row }) => {
          const date = new Date(value * 1000);
          return <span>{moment(date.toISOString()).format("DD/MM/YYYY HH:mm")}</span>;
        },
      },
      {
        Header: translate('status'),
        accessor: "status",
        disableSortBy: true,
        Cell: ({ value }) => {
          const title = value === COMPLETED ? translate('completed') : value === HANDLING ? translate('handling') : translate('selecting');
          return (
            <StatusCell
              color={value === HANDLING ? STATUS_CELL_COLOR.LIGHT_BLUE : value === COMPLETED ? STATUS_CELL_COLOR.BLUE : STATUS_CELL_COLOR.TURQUOISE}
              title={title}
            />
          );
        },
      },
      {
        Header: translate('total_amount'),
        accessor: "total_money",
        Cell: ({ value }) => <p>{formatCurrency(value)}</p>
      },
      {
        Header: translate('common:actions'),
        accessor: "order_id",
        disableSortBy: true,
        Cell: ({ value, row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <FiEye
                size={16} color="#8C8C8C" className="cursor-pointer"
                onClick={() => {
                  setSelectedOrder(row.original)
                  setShowModalDetail(true)
                }}
              />
              <img
                src={IcPrint}
                alt="IcPrint"
                style={{ width: 16, cursor: "pointer" }}
                onClick={async () => {
                  setSelectedOrder(row.original)
                  handlePrint(row.original)
                }}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const customData = useMemo(() => {
    if (!data.data) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);
    return data?.data;
  }, [data]);

  useEffect(() => {
    updatePagination(new URLSearchParams(filters));
  }, [dispatch, filters, updatePagination]);

  const handleCreateOrder = async () => {
    const order = await createOrder({ customer_id: 0 });
    if (order && order.data) history.push(`/sale-order/${order.data.order_id}`)
  };

  return (
    <section className=" relative">
      <SectionTitle title={translate('sale_management')} subtitle={translate('order_list')} />

      <div className="mt-4 mb-10 w-full flex justify-between items-center gap-4">
        <SearchFieldParams
          setFilters={setFilters}
          setSearchQuery={setSearchQuery}
          id="search-bank-input"
          extraClass="flex-1 bg-white shadow-primary"
          placeholder={translate('input_search_order')}
        />
        {!is_fnb && <CustomButton type="add" title={translate('create_order')} outline onClick={handleCreateOrder} />}
      </div>

      <Datatable
        columns={columns}
        data={customData}
        pagination={{
          page: filters?.page,
          pageSize: filters?.page_size,
          totalPages: numOfPage,
        }}
        filters={filters}
        setFilters={setFilters}
      />

      <ModalDetail
        isOpen={showModalDetail}
        onClose={() => setShowModalDetail(false)}
        order={selectedOrder}
        info={info}
        products={products}
        handlePrint={handlePrint}
      />

      {showPrintInvoice && (
        <div className="absolute" style={{ top: "-500%" }}>
          <PrintInvoice info={info} products={products} qr={qr} />
        </div>
      )}
    </section>
  );
};

export default ListOrder;
