import React, { useState } from 'react'
import close from '@assets/images/product/close.svg'
import CustomButton from '@components/buttons/CustomButton'
import { createTable } from '@services/table.service'
import { useTranslation } from '@hooks/useTranslation'
import ModalWrap from '@components/modals/ModalWrap'
import { INITIAL_CREATE_TABLE } from '@constants/sale-management/table'

const PopupCreateTable = ({ isOpen, onClose, setShowPopupSuccess }) => {
  const [tableName, setTableName] = useState('')
  const { translate } = useTranslation('sale_table')

  const handleCreate = async () => {
    const res = await createTable({ ...INITIAL_CREATE_TABLE, table_name: tableName });
    if (res) {
      setShowPopupSuccess(true);
      onClose();
    }
  }

  return (
    <ModalWrap isOpen={isOpen} onClickBackdrop={onClose}>
      <div className='px-8 py-6 w-fit h-fit rounded-3xl bg-white flex flex-col gap-6 items-center relative'>
        <img
          src={close} alt=''
          className='absolute cursor-pointer'
          style={{ top: '16px', right: '16px' }}
          onClick={() => onClose(false)}
        />
        <p className='text-black text-base font-semibold leading-5'>{translate('add_table')}</p>
        <div className='w-full flex flex-col gap-1'>
          <p className="text-sm font-semibold leading-5" style={{ color: '#262626' }}>{translate('sale_order:table_name')}</p>
          <input
            placeholder={translate('input_table_name')}
            className='rounded-lg create-form'
            style={{
              border: '1px solid #E8E8E8',
              width: '300px',
              padding: '10px 16px 10px 16px'
            }}
            onChange={(e) => setTableName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter")
                handleCreate();
            }}
          />
        </div>
        <CustomButton
          title={translate('save')}
          onClick={handleCreate}
          disabled={tableName === ''}
          className={'w-full'}
        />
      </div>
    </ModalWrap>
  )
}

export default PopupCreateTable