import React, { useState } from "react";
import down from "@assets/images/icon/chevrondown.svg";
import useClickOutside from "@hooks/useClickOutside";
import { useTranslation } from "@hooks/useTranslation";

const SwitchLanguage = ({ disabled }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { language, setLanguage } = useTranslation();
  const listLanguage = [
    { label: "VIE", value: "vi" },
    { label: "ENG", value: "en" },
  ];
  const currentLanguage = listLanguage.find((lang) => lang.value === language);
  const { nodeRef } = useClickOutside(() => setShowDropdown(false));

  return (
    <div
      ref={nodeRef}
      onClick={() => !disabled && setShowDropdown(!showDropdown)}
      className="flex items-center gap-2 py-1 px-3 rounded-lg border border-gray-primary cursor-pointer relative"
    >
      <span className="text-xs leading-5 font-semibold">
        {currentLanguage?.label}
      </span>
      <img src={down} alt="down" width={16} />
      {showDropdown && (
        <div
          style={{ top: "calc(100% + 4px)" }}
          className="absolute left-0 z-50 w-full rounded-lg border border-gray-primary bg-white"
        >
          {listLanguage.map((lang) => {
            const { value, label } = lang;
            return (
              <div
                onClick={() => value != language && setLanguage(value)}
                className="text-xs leading-5 font-semibold hover:bg-select-hover hover:text-primary px-3 py-1 transition-all"
                key={value}
              >
                {label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SwitchLanguage;
