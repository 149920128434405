import portalClient from "./client_axios";
import { STORE } from "@constants/endpoint";

export const storeServices = {
  createStore: async (params) => {
    return portalClient.post(STORE, params);
  },

  deleteStore: async (params) => {
    // const query = new URLSearchParams({ page: page.toString(), limit: limit.toString(), name });
    return portalClient.delete(STORE, params);
  },
};
