import React, { useEffect, useState } from 'react'
import { useTranslation } from '@hooks/useTranslation'
import { checkCookie, setCookie } from '@utils/lib'
import AcceptCookie from './AcceptCookie'

const CheckCookie = () => {
  const [showAcceptCookie, setShowAcceptCookie] = useState(false)
  const { translate } = useTranslation('cookie')

  const hideAcceptCookie = () => {
    setShowAcceptCookie(false)
  }

  const handleShowAcceptCookie = () => {
    setShowAcceptCookie(true)
  }

  const handleSetCookie = () => {
    setCookie('cookie', true)
    hideAcceptCookie()
  }

  useEffect(() => {
    if (!checkCookie('cookie')) {
      handleShowAcceptCookie()
    }
  }, [])

  return (
    showAcceptCookie && (
      <AcceptCookie
        title={translate('title')}
        description={
          <p>
            {translate('desc_1')}{' '}
            <a
              href='https://atomsolution.vn/vi/privacy-policy/'
              target='_blank'
              rel='noopener noreferrer'
              className='hover:opacity-75 underline'
              style={{ color: '#0D69D9' }}
            >
              {translate('desc_2')}
            </a>{' '}
            {translate('desc_3')}{' '}
            <a
              href='https://atomsolution.vn/vi/cookie-policy/'
              target='_blank'
              rel='noopener noreferrer'
              className='hover:opacity-75 underline'
              style={{ color: '#0D69D9' }}
            >
              {translate('desc_4')}
            </a>{' '}
            {translate('desc_5')}
          </p>
        }
        onClick={handleSetCookie}
      />
    )
  )
}

export default CheckCookie
