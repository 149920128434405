import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { theme } from 'configTheme'

import { useDispatch, useSelector } from 'react-redux'
import { getEcomInfo } from '@actions'
import CustomButton from '@components/buttons/CustomButton'
import InputField from '@components/fields/InputField'
import { useTranslation } from '@hooks/useTranslation'
import useWindowSize from '@hooks/useWindowSize'
import { selectProfile } from '@reducers/user.reducer'
import { CustomToastMessage } from '@utils/customToastMsg'
import { selectMerchant } from '@reducers/sass.reducer'
import { PAY_BY_LINK_FORM } from '@constants/pay-by-link'

const CreatePaymentLink = ({ setLinkCreated, setPaymentInfo }) => {
  const { translate, language } = useTranslation('pay_by_link')
  const { merchant_id } = useSelector(selectProfile)
  const { ref_id } = useSelector(selectMerchant)
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const schema = useMemo(
    () =>
      yup.object().shape({
        [PAY_BY_LINK_FORM.ORDER_ID]: yup
          .string()
          .required(translate('order_id_required'))
          .matches(/\S/, translate('no_white_space')),
        [PAY_BY_LINK_FORM.AMOUNT]: yup.string().required(translate('amount_required')),
      }),
    [language]
  )

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      [PAY_BY_LINK_FORM.ORDER_ID]: '',
      [PAY_BY_LINK_FORM.AMOUNT]: '',
    },
    resolver: yupResolver(schema),
  })

  const { isMd } = useWindowSize()

  const onBlur = useCallback(() => {
    if (watch(PAY_BY_LINK_FORM.AMOUNT) === '') return
    setValue(PAY_BY_LINK_FORM.AMOUNT, watch(PAY_BY_LINK_FORM.AMOUNT) + ' VND')
  }, [setValue, watch])

  const onFocus = useCallback(() => {
    setValue(PAY_BY_LINK_FORM.AMOUNT, watch(PAY_BY_LINK_FORM.AMOUNT).replace(/ VND/g, ''))
  }, [setValue, watch])

  const formatCurrencyValue = (value) =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  const handleChange = (name, value) => {
    if (value === '') {
      setValue(PAY_BY_LINK_FORM.AMOUNT, '')
      return
    }
    const valueAsNumber = parseInt(value.replace(/,/g, ''), 10)
    setValue(PAY_BY_LINK_FORM.AMOUNT, formatCurrencyValue(valueAsNumber))
  }

  const onFormSubmit = async (value) => {
    setLoading(true)
    const res = await dispatch(getEcomInfo(ref_id || merchant_id))
    if (res.meta.requestStatus === 'fulfilled' && res?.payload?.data) {
      const data = res.payload.data.data
      const appId = data.widget.id
      setPaymentInfo({
        order_id: value[PAY_BY_LINK_FORM.ORDER_ID],
        amount: value[PAY_BY_LINK_FORM.AMOUNT].replace(/[,VND' ']/g, ''),
        app_id: appId,
      })
      setLinkCreated(true)
    } else {
      CustomToastMessage.error(translate('create_payment_link_error'))
    }
    setLoading(false)
  }
  const onErrors = (errors) => console.error(errors)

  const disabled = !watch(PAY_BY_LINK_FORM.ORDER_ID) || !watch(PAY_BY_LINK_FORM.AMOUNT)

  return (
    <div className='flex rounded-xl overflow-hidden mx-auto mt-5 shadow-primary w-fit'>
      {/* Image */}
      <img
        src={theme.payByLinkBanner}
        alt='banner'
        width={548}
        height={575}
        className='hidden lg:block'
      />
      {/* Form */}
      <form
        onSubmit={handleSubmit(onFormSubmit, onErrors)}
        style={{ width: isMd ? 480 : '100%' }}
        className='flex flex-col justify-center gap-10 bg-white px-10 py-6'
      >
        <p
          className='font-bold text-start'
          style={{ fontSize: '28px', color: '#262626' }}
        >
          Pay by Link
        </p>

        <div className='w-full space-y-6'>
          <InputField
            variant=''
            isRequired
            setValue={setValue}
            label={translate('order_id')}
            placeholder={
              translate('common:enter') +
              ' ' +
              translate('order_id').toLowerCase()
            }
            id={PAY_BY_LINK_FORM.ORDER_ID}
            name={PAY_BY_LINK_FORM.ORDER_ID}
            value={watch(PAY_BY_LINK_FORM.ORDER_ID)}
            register={register}
            errors={errors}
          />

          <InputField
            isRequired
            setValue={setValue}
            label={translate('table_amount')}
            placeholder={
              translate('common:enter') +
              ' ' +
              translate('table_amount').toLowerCase()
            }
            id={PAY_BY_LINK_FORM.AMOUNT}
            name={PAY_BY_LINK_FORM.AMOUNT}
            value={watch(PAY_BY_LINK_FORM.AMOUNT)}
            register={register}
            type='text'
            onBlur={onBlur}
            onFocus={onFocus}
            onUpdate={handleChange}
            errors={errors}
          />
        </div>

        <CustomButton
          disabled={disabled || loading}
          type='submit'
          className='w-full'
          title={
            loading ? translate('creating') : translate('create_payment_link')
          }
        />
      </form>
    </div>
  )
}

export default CreatePaymentLink
