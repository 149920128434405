import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as yup from "yup";
import CustomButton from '@components/buttons/CustomButton';
import InputField from '@components/fields/InputField';
import SelectField from '@components/fields/SelectInPaymentField';
import { useRegion } from '@hooks/useRegion';
import { useTranslation } from '@hooks/useTranslation';
import { updateCustomerOfOrder } from '@services/table.service';
import { createCustomer } from '@actions/customer.actions';
import { CustomToastMessage } from '@utils/customToastMsg';
import { ERROR_CODE } from '@constants/errorCode';

const Step2 = ({
    phoneNumber,
    setPhoneNumber,
    setStep,
    setCustomerName,
    handleBack
}) => {
    const { order_id } = useParams()
    const dispatch = useDispatch()
    const { translate, language } = useTranslation('sale_customer')
    const schema = useMemo(
        () =>
            yup.object().shape({
                'name': yup
                    .string()
                    .required(translate("customer_name_required"))
                    .matches(/^[\p{L}\s]+$/u, translate("customer_name_only_letters"))
                    .matches(/^[^\s]/, translate("customer_name_no_leading_whitespace"))
                    .matches(
                        /^(?!.*\s\s).*$/,
                        translate("customer_name_no_consecutive_whitespace")
                    ),
                "phone_number": yup
                    .string()
                    .required(translate("please_input_phone_number"))
                    .matches(/\S/, translate("pay_by_link:no_white_space"))
                    .matches(
                        /^(0[3-9][0-9]{8}|05[5|6|7|8|9][0-9]{7}|(84|\+84)[3-9][0-9]{8})$/,
                        translate("customer_phone_invalid")
                    ),
            }),
        [language]
    );

    const [province, setProvince] = useState(null);
    const [district, setDistrict] = useState(null);
    const [ward, setWard] = useState(null);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm({ resolver: yupResolver(schema) });

    const handleUpdateOrder = (customer) => {
        const timer = setTimeout(async () => {
            const res = await updateCustomerOfOrder(order_id, customer.customer_id)
            if (res) {
                setCustomerName(customer?.name)
                handleBack()
            }
        }, 1000);
        return () => clearTimeout(timer);
    }

    const handleCreateCustomer = async (value) => {
        try {
            const res = await dispatch(
                createCustomer({
                    ...value,
                    province_code: String(province),
                    district_code: String(district),
                    ward_code: String(ward),
                })
            );
            if (res?.payload?.customer_id) {
                CustomToastMessage.success(
                    translate("create_customers_success"),
                    `${translate("success_content")} ${res.payload?.code}`
                );
                setPhoneNumber(value.phone_number)
                handleUpdateOrder(res.payload);
            } else {
                CustomToastMessage.error(
                    translate("common:fail"),
                    ERROR_CODE[res?.payload?.error_code][language]
                );
            }
        } catch (err) { console.log(err) }
    };

    const { handleGetProvince, listProvince, listDistrict, listWard } = useRegion({
        province_code: province,
        district_code: district,
        ward_code: ward,
        setProvince, setDistrict, setWard
    })

    const disabled = !watch('name') || !watch('phone_number');

    useEffect(() => {
        handleGetProvince();
    }, [])

    useEffect(() => {
        setValue('phone_number', phoneNumber)
    }, [phoneNumber])

    return (
        <form onSubmit={handleSubmit(handleCreateCustomer)} className='space-y-6' style={{ width: 432 }}>
            <p className='text-black text-base font-semibold leading-5 text-center'>{translate('create_new_customers')}</p>

            <div className="space-y-6">
                <InputField
                    isRequired
                    setValue={setValue}
                    label={translate("customer_name")}
                    placeholder={translate("input_customer_name")}
                    id={'name'}
                    name={'name'}
                    register={register}
                    errors={errors}
                />
                <InputField
                    type="number"
                    isRequired
                    setValue={setValue}
                    label={translate("phone_number")}
                    placeholder={translate("input_phone_number")}
                    value={watch('phone_number') || ''}
                    id={'phone_number'}
                    name={'phone_number'}
                    register={register}
                    errors={errors}
                />

                <div className='space-y-2'>
                    <p className="text-sm leading-5 font-semibold">
                        {translate("common:region")}
                    </p>
                    <SelectField
                        placeHolder={translate("common:city")}
                        data={[].concat(listProvince)}
                        setValue={setProvince}
                        value={province}
                    />
                    <SelectField
                        placeHolder={translate("common:district")}
                        data={[].concat(listDistrict)}
                        setValue={setDistrict}
                        value={district}
                    />
                    <SelectField
                        placeHolder={translate("common:ward")}
                        data={[].concat(listWard)}
                        setValue={setWard}
                        value={ward}
                    />
                    <InputField
                        setValue={setValue}
                        placeholder={translate("common:address_detail")}
                        id={'address'}
                        name={'address'}
                        value={watch('address') || ""}
                        register={register}
                        errors={errors}
                    />
                </div>
            </div>

            <div className="grid grid-cols-2 gap-2 h-12 w-full mt-6">
                <CustomButton onClick={() => setStep(0)} title={translate('common:back')} outline className='w-full' />
                <CustomButton type='submit' title={translate('create_customers')} className='w-full' disabled={disabled} />
            </div>
        </form>
    )
}

export default Step2