import { createSlice } from "@reduxjs/toolkit"
import { getListProduct, createProduct, updateProduct } from "@actions/product.actions"

const initialState = {
    listProduct: [],
    isLoading: false,
}

const productSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getListProduct.fulfilled, (state, action) => {
                state.listProduct = action.payload;
            })
            .addCase(getListProduct.rejected, (state, action) => {
                state.listProduct = [];
            })
    }
})

export const selectListProduct = state => state.product.listProduct;
export const selectIsLoading = state => state.product.isLoading;

export default productSlice.reducer;