import { combineReducers } from 'redux'
import sidebar from './sidebar.reducer'
import user from './user.reducer'
import business from './business.reducer'
import customer from './customer.reducer'
import edc from './edc.reducer'
import staff from './staff.reducer'
import feedback from './feedback.reducer'
import invoice from './invoice.reducer'
import metadata from './metadata.reducer'
import order from './order.reducer'
import va from './va.reducer'
import product from './product.reducer'
import purchaseNoti from './purchaseNoti.reducer'
import sass from './sass.reducer'
import store from './store.reducer'
import transaction from './transaction.reducer'
import bank from './bank.reducer'
import paymentMethod from './paymentMethod.reducer'
import category from './category.reducer'
import noti from './noti.reducer'
import tracking from './tracking.reducer'
import pageLoading from './page-loading.reducer'

const rootReducer = combineReducers({
  sidebar,
  user,
  transaction,
  bank,
  va,
  metadata,
  business,
  staff,
  edc,
  feedback,
  product,
  customer,
  order,
  invoice,
  store,
  purchaseNoti,
  sass,
  paymentMethod,
  category,
  noti,
  tracking,
  pageLoading,
})


export default rootReducer
