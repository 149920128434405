import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "tippy.js/dist/tippy.css";
import { getListStore } from "@actions/metadata.actions";
import SearchField from "./SearchField";
import SelectFieldContent from "./select/SelectFieldContent";
import SelectFieldItem from "./select/SelectFieldItem";
import SelectFieldWrapper from "./select/SelectFieldWrapper";
import "./style.css";

const LIST = [
  {
    id: 1,
    name: "CellphonS Chi nhánh Q7",
  },
  {
    id: 2,
    name: "CellphonS Q1 HCM",
  },
  {
    id: 3,
    name: "Cửa hàng CellphonS 458",
  },
];

const SelectRuleField = (props) => {
  const { register, name, setValue, errors, extra, state, isRequired, initValue } = props;

  const [list, setList] = useState(LIST);
  const [currentItem, setCurrentItem] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  const handleSelectBox = (data) => {
    setCurrentItem(data);
    setValue(name, data?.id);
    setShowContent(false);
  };

  // Function to filter the array based on the 'name' and 'display_name' property
  function filterByName() {
    if (searchValue.trim().length === 0) return list;

    const normalizedFilter = searchValue.toLowerCase().trim();
    return list.filter(
      (item) =>
        item?.name.toLowerCase().includes(normalizedFilter) ||
        item?.display_name.toLowerCase().includes(normalizedFilter)
    );
  }

  const CurrentItemComp = () => {
    return <p className="text-14px text-dark-primary leading-5 font-normal">{currentItem?.name}</p>;
  };

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const res = await dispatch(getListStore());
  //       if (res.meta.requestStatus === "fulfilled") {
  //         const data = res?.payload?.data;
  //         setList(data);
  //         setCurrentItem(data.find((item) => item?.id === initValue));
  //       }
  //     } catch (err) {}
  //   };
  //   getData();
  // }, [dispatch, initValue]);

  return (
    <div>
      <SelectFieldWrapper
        {...props}
        className={"py-10px px-4"}
        showContent={showContent}
        setShowContent={setShowContent}
        label={"Tên Rule"}
        initialText="Chọn tên Rule"
        currentValue={currentItem}
        currentItemComp={<CurrentItemComp />}
      >
        <SearchField
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          id="search-store-input"
          extraClass="m-4 mb-1"
        />
        <SelectFieldContent>
          {filterByName().map((item, index) => {
            const active = currentItem?.id === item?.id;
            return (
              <SelectFieldItem key={`${item?.id}-${index}`} onClick={() => handleSelectBox(item)} active={active}>
                {item?.name}
              </SelectFieldItem>
            );
          })}
        </SelectFieldContent>
      </SelectFieldWrapper>

    </div>
  );
};

export default SelectRuleField;
