import { createAsyncThunk } from "@reduxjs/toolkit";
import { invoiceServices } from "@services";

export const getListInvoice = createAsyncThunk(
  "store/invoice/getListInvoice",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await invoiceServices.getListInvoice(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createInvoice = createAsyncThunk(
  "store/invoice/createInvoice",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await invoiceServices.createInvoice(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInvoice = createAsyncThunk(
  "store/invoice/updateInvoice",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await invoiceServices.updateInvoice(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  "store/invoice/deleteInvoice",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await invoiceServices.deleteInvoice(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStatusPos = createAsyncThunk(
  "store/invoice/getStatusPos",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await invoiceServices.getStatusPos(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const exportInvoice = createAsyncThunk(
  "store/invoice/exportInvoice",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await invoiceServices.exportInvoice(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
