import momo from "@assets/images/sale-setting/momo.png";

export const LIST_WALLET = [{ name: "Momo", logo: momo }];

export const EMENU_FORM = {
  LINK_SUCCESS: "link_success",
  LINK_CANCEL: "link_cancel",
  LINK_RESULT: "link_result",
  POS: "pos",

  URL: "url",
  ACCESS_KEY: "access_key",
  SECRET_KEY: "secret_key",
  PARTNER_CODE: "partner_code",
};

export const PAYMENT_METHOD_GROUP = {
  QR: 'QR',
  EWALLET: 'Ewallet',
  CASH: 'Cash',
  ECOM: 'ECOM',
};