import React from 'react';

const Skeleton = ({
  width,
  height,
  loading = true,
  className,
  children,
  borderRadius = '8px',
  backgroundColor = '#797a7a',
}) => {
  if (!children)
    return (
      <div
        className={`animate-pulse ${className}`}
        style={{
          width,
          height,
          backgroundColor,
          borderRadius,
        }}
      />
    );

  return loading ? (
    <div
      className='relative'
      style={{
        width,
        height,
      }}
    >
      <div className='opacity-0'>{children}</div>
      <div
        className={`absolute inset-0 animate-pulse ${className}`}
        style={{
          backgroundColor,
          borderRadius,
        }}
      />
    </div>
  ) : (
    children
  );
};

export default Skeleton;
