import React, { useCallback, useEffect, useRef } from "react";
import useClickOutside from "@hooks/useClickOutside";

import './modal.css';

const defaultStyle = {
    background: '#FFFFFF',
}

const ModalWrap = ({ children, isOpen, onClickBackdrop, style }) => {
    const modalRef = useRef(null);
    const { nodeRef: childrenRef } = useClickOutside(onClickBackdrop)

    const open = useCallback(() => {
        if (modalRef?.current) {
            modalRef.current.showModal()
        }
    }, [modalRef])

    const close = useCallback(() => {
        if (modalRef?.current) {
            setTimeout(() => {
                modalRef.current && modalRef.current.close()
            }, 300)
        }
    }, [modalRef])

    useEffect(() => {
        isOpen ? open() : close()
    }, [isOpen, open, close])

    return (
        <dialog ref={modalRef} data-open={isOpen} style={{ ...defaultStyle, ...style, padding: 0 }}>
            <div ref={childrenRef} style={{ padding: style?.padding, overflow: 'hidden', position: 'relative' }}>
                {children}
            </div>
        </dialog>
    )
}

export default ModalWrap