import { createAsyncThunk } from "@reduxjs/toolkit";
import { trackingServices } from "@services";

export const getTrackingById = createAsyncThunk(
  "tracking/getTrackingById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await trackingServices.getTrackingById(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListTracking = createAsyncThunk(
  "tracking/getListTracking",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await trackingServices.getListTracking(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTracking = createAsyncThunk(
  "tracking/createTracking",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await trackingServices.createTracking(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTracking = createAsyncThunk(
  "tracking/updateTracking",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await trackingServices.updateTracking(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTracking = createAsyncThunk(
  "tracking/deleteTracking",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await trackingServices.deleteTracking(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
