import React, { useEffect, useState } from 'react'
import { theme } from 'configTheme'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { history } from '@helpers'
import * as yup from 'yup'

import CustomButton from '@components/buttons/CustomButton'
import InputField from '@components/fields/InputField'
import InputFieldPw from '@components/fields/InputFieldPw'
import useWindowSize from '@hooks/useWindowSize'
import { useTranslation } from '@hooks/useTranslation'
import { LOGIN_FORM } from '@constants/auth'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { logout, selectIsLoading } from '@reducers/user.reducer'
import { login } from '@actions/user.actions'
import ModalLockAccount from './ModalLockAccount'

const Login = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslation('login')
  const { isMd } = useWindowSize()
  const loading = useSelector(selectIsLoading)
  const [isLock, setIsLock] = useState(false)
  const [failCount, setFailCount] = useState(0)

  const schema = yup.object().shape({
    [LOGIN_FORM.EMAIL]: yup.string().email(translate('error_invalid_email')),
    [LOGIN_FORM.PASSWORD]: yup.string(),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    setError,
  } = useForm({ resolver: yupResolver(schema) })

  const onFormSubmit = async (value) => {
    try {
      const res = await dispatch(login(value))
      if (res?.payload?.data) {
        history.push(`/dashboard`)
      } else {
        setIsLock(res.payload?.errors?.is_locked)
        setFailCount(res.payload?.errors?.count_fail)
        setError(LOGIN_FORM.EMAIL, { message: '' })
        setError(LOGIN_FORM.PASSWORD, { message: '' })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const disabled =
    !watch(LOGIN_FORM.EMAIL) || !watch(LOGIN_FORM.PASSWORD) || loading

  useEffect(() => {
    dispatch(logout())
  }, [])

  return (
    <div className='flex rounded-xl overflow-hidden shadow-primary'>
      {/* Image */}
      <img
        src={theme.loginBg}
        alt='login_btn'
        width={548}
        height={575}
        className='hidden lg:block'
      />
      {/* Form */}
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        style={{ width: isMd ? 480 : '100%' }}
        className='flex flex-col justify-center items-center gap-10 bg-white px-10 py-6'
      >
        <img src={theme.loginLogo} alt='logo' className='h-16 w-auto' />

        <div className='w-full space-y-6'>
          <InputField
            isRequired
            setValue={setValue}
            label={translate('username')}
            placeholder={
              translate('enter') + ' ' + translate('username').toLowerCase()
            }
            id={LOGIN_FORM.EMAIL}
            name={LOGIN_FORM.EMAIL}
            value={watch(LOGIN_FORM.EMAIL)}
            register={register}
            errors={errors}
          />

          <InputFieldPw
            isRequired
            setValue={setValue}
            label={translate('password')}
            placeholder={
              translate('enter') + ' ' + translate('password').toLowerCase()
            }
            id={LOGIN_FORM.PASSWORD}
            name={LOGIN_FORM.PASSWORD}
            value={watch(LOGIN_FORM.PASSWORD)}
            register={register}
            errors={errors}
          />

          {!isLock && failCount > 0 && (
            <p
              className='text-error text-sm leading-5 font-medium'
              style={{ marginTop: 10 }}
            >
              {translate('incorrect')}{' '}
              <span className='font-bold'>
                {failCount} {translate('times')}
              </span>
            </p>
          )}

          <div className='flex justify-between items-center text-blue-2 text-sm leading-5 font-medium'>
            <div className='flex gap-3'>
              <input
                type='checkbox'
                style={{ accentColor: theme.primary }}
                className='h-5 w-5 rounded'
              />
              <p>{translate('save_account')}</p>
            </div>
            <Link to='/forgot-password' className='underline'>
              {translate('forgot_pw')}?
            </Link>
          </div>
        </div>

        <ModalLockAccount isOpen={isLock} onClose={() => setIsLock(false)} />

        <CustomButton
          disabled={disabled}
          type='submit'
          className='w-full'
          title={translate('login')}
        />
      </form>
    </div>
  )
}

export default Login
