import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
// import { getListAccountNumber } from '@actions/metadata.actions';
import { verifyBank } from '@actions/bank.actions';
import CustomButton from '@components/buttons/CustomButton';
// import ComboBoxAccountField from '@components/fields/ComboBoxAccountField';
// import SelectField from "@components/fields/SelectInPaymentField";
import InputField from '@components/fields/InputField';
import SectionTitle from '@components/section-title';
import { theme } from 'configTheme';
import { useTranslation } from '@hooks/useTranslation';
// import { selectListAccountNumber } from '@reducers/metadata.reducer';
import { CustomToastMessage } from '@utils/customToastMsg';
import { ERROR_CODE } from '@constants/errorCode';
import ModalPolicy from './ModalPolicy';
import ModalQrVerify from './ModalQrVerify';
import { selectProfile } from '@reducers/user.reducer';
import { ACCOUNT_TYPE, ADD_BANK_FORM, PCHANNEL_TITLE } from '@constants/shop-qr';

const AddBank = () => {
  const { translate, language } = useTranslation('bank');
  const dispatch = useDispatch();
  const [showModalPolicy, setShowModalPolicy] = React.useState(false);
  const [showModalQr, setShowModalQr] = React.useState(false);
  const [bankCode, setBankCode] = useState();
  const [base64String, setBase64String] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { account_type } = useSelector(selectProfile)
  // const [bankAccNo, setBankAccNo] = useState('')

  // const dataListAccount = useSelector(selectListAccountNumber);

  // const listAccount = useMemo(() => {
  //   if (!dataListAccount) return [];
  //   return dataListAccount.map((acc) => ({
  //     label: acc.name,
  //     value: acc.name,
  //     // bank_code: acc.bank_code,
  //   }));
  // }, [dataListAccount]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  // useEffect(() => {
  //   if (watch(ADD_BANK_FORM.BANK_ACCOUNT_NO)) {
  //     const selectedBank =
  //       listAccount.find(
  //         (acc) => acc.label === watch(ADD_BANK_FORM.BANK_ACCOUNT_NO).split(' ')[1]
  //       ) ||
  //       listAccount.find((acc) => acc.label === watch(ADD_BANK_FORM.BANK_ACCOUNT_NO));
  //     if (selectedBank) setBankCode(selectedBank.bank_code);
  //     else setBankCode('VCCB');
  //   }
  // }, [watch(ADD_BANK_FORM.BANK_ACCOUNT_NO)]);

  const onFormSubmit = async (value) => {
    delete value.check_box
    setIsLoading(true);
    try {
      const res = await dispatch(
        verifyBank({
          ...value,
          bank_account_no: watch(ADD_BANK_FORM.BANK_ACCOUNT_NO),
          bank_code: bankCode || 'BVBank',
        })
      );
      if (res?.meta?.requestStatus === 'fulfilled') {
        setBase64String(res.payload.data);
        setShowModalQr(true);
      } else
        CustomToastMessage.error(ERROR_CODE[res?.payload?.error_code][language])
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const disabled =
    !watch(ADD_BANK_FORM.BANK_ACCOUNT_NO) ||
    // !watch(ADD_BANK_FORM.CHECK_BOX) ||
    !watch(ADD_BANK_FORM.HOLDER_NAME) ||
    !watch(ADD_BANK_FORM.PHONE) ||
    isLoading;

  const isBusiness = useMemo(() => {
    return account_type === ACCOUNT_TYPE.BUSINESS
  }, [account_type])

  // useEffect(() => {
  //   dispatch(getListAccountNumber({}));
  // }, [dispatch]);

  // const onBlur = useCallback(() => {
  //     if (watch(ADD_BANK_FORM.BANK_ACCOUNT_NO) !== undefined) setValue(ADD_BANK_FORM.BANK_ACCOUNT_NO, bankCode + " " + watch(ADD_BANK_FORM.BANK_ACCOUNT_NO));
  // }, [setValue, watch, bankCode]);

  //   const onFocus = useCallback(() => {
  //     if (watch(ADD_BANK_FORM.BANK_ACCOUNT_NO))
  //       setValue(
  //         ADD_BANK_FORM.BANK_ACCOUNT_NO,
  //         watch(ADD_BANK_FORM.BANK_ACCOUNT_NO).replace(`${bankCode} `, '')
  //       );
  //   }, [setValue, watch, bankCode]);

  return (
    <div className='flex flex-col gap-6'>
      <SectionTitle
        title={PCHANNEL_TITLE}
        subtitle={translate('shop_qr:bank_account')}
        rightTitle={translate('shop_qr:input_bank_account')}
      />

      <div style={{ gridTemplateColumns: '1.64fr 1fr' }} className='grid gap-6'>
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          className='flex flex-col gap-6 bg-white p-6 rounded-lg'
          style={{ border: '1px solid #D9D9D9', height: 'fit-content' }}
        >
          <div className='flex flex-col gap-4'>
            <p
              className='text-lg leading-6 font-bold'
              style={{ color: '#03131B' }}
            >
              {translate('shop_qr:input_account_code')}
            </p>

            <InputField
              isRequired
              setValue={setValue}
              type="number"
              label={translate("shop_qr:account_number")}
              placeholder={translate("shop_qr:add_account_number_placeholder")}
              id={ADD_BANK_FORM.BANK_ACCOUNT_NO}
              name={ADD_BANK_FORM.BANK_ACCOUNT_NO}
              register={register}
              errors={errors}
            />

            <InputField
              isRequired
              setValue={setValue}
              type="text"
              label={translate("shop_qr:holder_name")}
              placeholder={translate("shop_qr:input_holder_name")}
              id={ADD_BANK_FORM.HOLDER_NAME}
              name={ADD_BANK_FORM.HOLDER_NAME}
              register={register}
              errors={errors}
              value={watch(ADD_BANK_FORM.HOLDER_NAME)?.toUpperCase()}
            />

            {/* <ComboBoxAccountField
              type='number'
              id={ADD_BANK_FORM.BANK_ACCOUNT_NO}
              name={ADD_BANK_FORM.BANK_ACCOUNT_NO}
              data={listAccount}
              errors={errors}
              register={register}
              setValue={setValue}
              value={watch(ADD_BANK_FORM.BANK_ACCOUNT_NO)}
              isRequired
              title={translate('shop_qr:account_number')}
            // onBlur={onBlur}
            // onFocus={onFocus}
            /> */}

            {/* <SelectField
              data={listAccount}
              placeHolder={translate("shop_qr:account_number")}
              setValue={setBankAccNo}
            /> */}

            <InputField
              isRequired
              setValue={setValue}
              type='number'
              label={translate('shop_qr:phone_number')}
              placeholder={translate('shop_qr:input_phone_number')}
              id={ADD_BANK_FORM.PHONE}
              name={ADD_BANK_FORM.PHONE}
              register={register}
              errors={errors}
            />

            <p className='text-[#03131B]'>
              {translate('shop_qr:phone_number_note')}
            </p>
          </div>

          <div className='space-y-4'>
            {/* <div
              className='flex gap-2 cursor-pointer'
              style={{ width: 'fit-content' }}
              onClick={(e) => {
                e.preventDefault();
                watch(ADD_BANK_FORM.CHECK_BOX)
                  ? setValue(ADD_BANK_FORM.CHECK_BOX, false)
                  : setShowModalPolicy(true);
              }}
            >
              <input
                onClick={(e) => {
                  watch(ADD_BANK_FORM.CHECK_BOX) && e.stopPropagation();
                  setValue(ADD_BANK_FORM.CHECK_BOX, false);
                }}
                checked={watch(ADD_BANK_FORM.CHECK_BOX)}
                name={ADD_BANK_FORM.CHECK_BOX}
                className='w-4 h-4 mt-1'
                {...register(ADD_BANK_FORM.CHECK_BOX)}
                type='checkbox'
                style={{ accentColor: theme.primary }}
              />

              <p className='text-blackgray text-sm font-normal'>
                {translate('shop_qr:service_approval')}{' '}
                <span className='text-primary italic underline'>
                  {translate('shop_qr:term_condition')}
                </span>
                .
              </p>
            </div> */}

            <CustomButton
              type='submit'
              disabled={disabled}
              title={isLoading ? translate('common:processing') : translate('common:continue')}
              className='w-full'
            />
          </div>
        </form>

        <img src={isBusiness ? theme.addBankBusinessBanner : theme.addBankBanner} alt='img-add-bank' className='rounded-lg' />
      </div>

      <ModalPolicy
        isOpen={showModalPolicy}
        onClose={() => setShowModalPolicy(false)}
        setCheckbox={() => setValue(ADD_BANK_FORM.CHECK_BOX, true)}
      />

      <ModalQrVerify
        isOpen={showModalQr}
        isBusiness={isBusiness}
        bankAccountNo={watch(ADD_BANK_FORM.BANK_ACCOUNT_NO)}
        qr={base64String}
        onClose={() => setShowModalQr(false)}
      />
    </div>
  );
};

export default AddBank;
