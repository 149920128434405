import React, { useMemo } from 'react';
import img_product from '@assets/images/product/img-product.png';
import sub from "@assets/images/product/sub.svg";
import { formatCurrency } from '@utils/lib';
import { theme } from 'configTheme';

const ChangeAmount = ({ product, newProds, setNewProds }) => {
    const value = useMemo(() => newProds[product.product_id]?.amount || 0, [newProds])

    const handleSubItem = () => {
        if (value > 0) {
            if (value === 1) {
                const temp = newProds
                delete temp[product.product_id]
                setNewProds({ ...temp })
            } else setNewProds({
                ...newProds,
                [product.product_id]: { ...product, amount: value - 1 }
            })
        }
    };

    const handleAddItem = () => {
        setNewProds({ ...newProds, [product.product_id]: { ...product, amount: value + 1 } })
    };

    return (
        <div
            className="flex gap-4 items-center rounded-full w-fit"
            style={{ backgroundColor: "#E8E8E8" }}
        >
            <img
                src={sub}
                alt=""
                width={32}
                className="cursor-pointer"
                onClick={() => handleSubItem()}
            />
            <p
                className="text-base font-semibold"
                style={{ lineHeight: "28px", color: "#262626" }}
            >
                {value}
            </p>

            <div
                onClick={() => handleAddItem()}
                className="w-fit h-fit relative cursor-pointer"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                >
                    <circle
                        cx="16"
                        cy="16"
                        r="15"
                        fill={theme.primary}
                        stroke="white"
                        strokeWidth="2"
                    />
                </svg>
                <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                    >
                        <path
                            d="M5 1V9"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M1 5H9"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

const ProductItem = ({ item, newProds, setNewProds }) => {
    return (
        <div className="w-full p-4 rounded-xl bg-white flex gap-5 shadow-primary"
        >
            <div className="overflow-hidden"
                style={{
                    width: '140px',
                    height: '140px',
                    borderRadius: '8px'
                }}
            >
                <img src={item?.img || img_product} alt="" className='w-full h-full object-cover object-center' />
            </div>
            <div className='flex-1 flex flex-col justify-between '>
                <div>
                    <p className='text-base font-semibold' style={{ color: '#262626' }}>{item.name}</p>
                    <p className='text-sm font-semibold' style={{ color: theme.primary, marginTop: '2px' }}>
                        {formatCurrency(item.price)}
                    </p>
                </div>

                <ChangeAmount
                    product={item}
                    newProds={newProds}
                    setNewProds={setNewProds}
                />
            </div>
        </div>

    )
}

export default ProductItem