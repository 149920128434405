import { toPng } from 'html-to-image';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { createQR } from '@actions/va.action';
import CustomButton from '@components/buttons/CustomButton';
import { theme } from 'configTheme';

import book from '@assets/images/payment/book.svg';
import eye from '@assets/images/icon/ic_eye.svg';
import napas from '@assets/images/payment/napas.png';
import vietQr from '@assets/images/payment/viet-qr.png';
import { useTranslation } from '@hooks/useTranslation';
import ModalWrap from '@components/modals/ModalWrap';
import Skeleton from '@components/skeleton/MainSkeleton';
import { APP_LIST } from '@constants/shop-qr';

const DetailPChannel = ({ isOpen, selectedChannel, onClose }) => {
  const { translate } = useTranslation('shop_qr');
  const [qr, setQR] = useState(null);
  const [qrLoading, setQrLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setQrLoading(true);
    const getQR = async (va_account_no) => {
      const res = await dispatch(createQR({ va_account_no }));
      if (res?.payload?.data?.qr_base64) setQR(res?.payload?.data?.qr_base64);
      setQrLoading(false);
    };
    selectedChannel && getQR(selectedChannel?.va_account_no);
  }, [dispatch, selectedChannel?.va_account_no]);

  const ref = useRef(null);
  const handleDownloadQR = useCallback(() => {
    if (ref.current === null) return;
    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'qrcode.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => console.log(err));
  }, [ref]);

  return (
    <ModalWrap
      isOpen={isOpen}
      onClickBackdrop={onClose}
      style={{
        height: 'min-content',
        width: 540,
        color: '#262626',
        // padding: 40,
      }}
    >
      <div className='p-10 w-full h-full overflow-auto'>
        <IoCloseSharp
          onClick={onClose}
          style={{
            position: 'absolute',
            top: 16,
            right: 16,
            cursor: 'pointer',
          }}
          size={24}
        />

        <h3 className='text-2xl font-bold text-center mb-4'>
          {translate('management_detail')}
        </h3>

        <div className='flex w-full flex-col items-center gap-8'>
          <div
            className='flex w-full flex-col items-center gap-4'
            style={{ borderBottom: '1px dashed rgba(228, 231, 236, 1)', height: 'min-content' }}
          >
            <div className='flex flex-col items-center gap-4 bg-white p-4' ref={ref}>
              <div className='py-8'>
                <img src={vietQr} alt='vietqr' className='w-auto' style={{ height: 52 }} />
              </div>
              <Skeleton loading={qrLoading}>
                <img
                  src={`data:image/png;base64,${qr}`}
                  alt='qr'
                  style={{
                    width: 255,
                    height: 257,
                  }}
                  className='border-primary border-4 rounded-lg'
                />
              </Skeleton>

              <div className='grid w-full grid-cols-2'>
                <div className='w-full flex items-center justify-center' style={{ height: 68 }}>
                  <img src={napas} alt='napas' className='h-6 w-auto' />
                </div>
                <div className='w-full flex items-center justify-center' style={{ height: 68 }}>
                  <img
                    src={theme.loginLogo}
                    alt='bank'
                    className='h-6 w-auto'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-1 items-center'>
                <h3 className='text-center text-lg leading-6 font-bold text-primary mb-1'>
                  {selectedChannel?.store_name}
                </h3>

                <p className='text-sm text-secondary-text'>
                  {translate('bank:virtual_account_code')}:
                  <span className='font-medium text-primary-text'>
                    {' '}{selectedChannel?.va_account_no}
                  </span>
                </p>

                <p className='text-sm text-secondary-text'>
                  {translate('account_name')}:
                  <span className='font-medium text-primary-text'>
                    {' '}{selectedChannel?.holder_name}
                  </span>
                </p>

                <p className='text-sm text-secondary-text'>
                  {translate('account_number')}:
                  <span className='font-medium text-primary-text'>
                    {' '}{selectedChannel?.bank_account_no}
                  </span>
                </p>
              </div>
            </div>

            <CustomButton
              onClick={handleDownloadQR}
              className='border-none items-center gap-2 justify-center mb-4'
              title={translate('management_download_qr')}
              disabled={qrLoading}
              outline
              leftIcon={
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.7498 14.2285C15.7498 14.3556 15.6953 14.4775 15.5981 14.5673C15.501 14.6572 15.3693 14.7077 15.232 14.7077H1.76809C1.63075 14.7077 1.49903 14.6572 1.40192 14.5673C1.3048 14.4775 1.25024 14.3556 1.25024 14.2285C1.25024 14.1014 1.3048 13.9796 1.40192 13.8897C1.49903 13.7998 1.63075 13.7494 1.76809 13.7494H15.232C15.3693 13.7494 15.501 13.7998 15.5981 13.8897C15.6953 13.9796 15.7498 14.1014 15.7498 14.2285ZM8.13236 12.1729C8.1805 12.2178 8.23778 12.2535 8.30088 12.2778C8.36399 12.3021 8.43167 12.3146 8.50003 12.3146C8.56839 12.3146 8.63608 12.3021 8.69918 12.2778C8.76229 12.2535 8.81956 12.2178 8.8677 12.1729L13.0104 8.33956C13.1079 8.24933 13.1627 8.12695 13.1627 7.99935C13.1627 7.87175 13.1079 7.74937 13.0104 7.65914C12.9129 7.56891 12.7807 7.51822 12.6428 7.51822C12.5049 7.51822 12.3726 7.56891 12.2751 7.65914L9.01787 10.6779V1.77018C9.01787 1.6431 8.96332 1.52122 8.8662 1.43136C8.76909 1.3415 8.63737 1.29102 8.50003 1.29102C8.36269 1.29102 8.23098 1.3415 8.13386 1.43136C8.03675 1.52122 7.98219 1.6431 7.98219 1.77018V10.6779L4.72496 7.65914C4.67668 7.61446 4.61936 7.57902 4.55628 7.55485C4.49319 7.53067 4.42558 7.51822 4.3573 7.51822C4.28901 7.51822 4.2214 7.53067 4.15832 7.55485C4.09523 7.57902 4.03791 7.61446 3.98963 7.65914C3.94135 7.70382 3.90305 7.75686 3.87691 7.81523C3.85078 7.8736 3.83734 7.93617 3.83734 7.99935C3.83734 8.12695 3.89212 8.24933 3.98963 8.33956L8.13236 12.1729Z" fill="currentColor" />
                </svg>
              }
            />
          </div>

          <CustomButton
            onClick={onClose}
            className='w-full'
            title={translate('common:close')}
            type='button'
            disabled={qrLoading}
          />
        </div>

      </div>
    </ModalWrap >
  );
};

export default DetailPChannel;
