import React from "react";

const Process = ({ step }) => {
  const checkPassStep3 = step > 2 && step <= 3;
  const checkPassStep2 = (step > 1 && step <= 2) || checkPassStep3;
  const checkPassStep1 = step < 2 || checkPassStep2 || checkPassStep3;

  const handleActive = (active) => {
    return active ? "bg-gradient" : "bg-grey-bold";
  };

  return (
    <div style={{ gap: 10 }} className="flex items-center w-full mt-4 mb-8">
      <div style={{ borderRadius: 6 }} className={`flex-1 h-2 ${handleActive(checkPassStep1)}`} />
      <div
        style={{ width: 10, height: 10 }}
        className={`rounded-full ${handleActive(checkPassStep2)}`}
      />
      <div style={{ borderRadius: 6 }} className={`flex-1 h-2 ${handleActive(checkPassStep2)}`} />
      <div
        style={{ width: 10, height: 10 }}
        className={`rounded-full ${handleActive(checkPassStep3)}`}
      />
      <div style={{ borderRadius: 6 }} className={`flex-1 h-2 ${handleActive(checkPassStep3)}`} />
    </div>
  );
};

export default Process;
