export const USE_CHECK_CONTENT = [
  { label: "feedback_error_settle", value: "feedback_error_settle" },
  { label: "feedback_error_transaction", value: "feedback_error_transaction" },
  { label: "feedback_error_loss", value: "feedback_error_loss" },
  { label: "feedback_error_other", value: "feedback_error_other" },
];

export const USE_EDC_CONTENT = [
  { label: "feedback_error_pin", value: "feedback_error_pin" },
  { label: "feedback_error_connect", value: "feedback_error_connect" },
  { label: "feedback_error_card", value: "feedback_error_card" },
  { label: "feedback_error_other", value: "feedback_error_other" },
]

export const ALERT_TAB = {
  CHECK: 'check',
  EDC: 'edc',
  OTHER: 'other',
};