import * as yup from "yup";

export const DEFAULT_ORDER_CUSTOMER = 'Customer';

export const CUSTOMER_FORM = {
  CUSTOMER_ID: "customer_id",
  CUSTOMER_CODE: "code",
  NAME: "name",
  PHONE_NUMBER: "phone_number",
  POINT: "point",
  TAX_NO: "tax_no",
  ADDRESS: "address"
};

export const getCustomerSchema = (translate) => {
  return yup.object().shape({
    [CUSTOMER_FORM.NAME]: yup
      .string()
      .required(translate("customer_name_required"))
      .matches(/^[\p{L}\s]+$/u, translate("customer_name_only_letters"))
      .matches(/^[^\s]/, translate("customer_name_no_leading_whitespace"))
      .matches(
        /^(?!.*\s\s).*$/,
        translate("customer_name_no_consecutive_whitespace")
      ),
    [CUSTOMER_FORM.PHONE_NUMBER]: yup
      .string()
      .required(translate("please_input_phone_number"))
      .matches(/\S/, translate("no_white_space"))
      .matches(
        /^(0[3-9][0-9]{8}|05[5|6|7|8|9][0-9]{7}|(84|\+84)[3-9][0-9]{8})$/,
        translate("customer_phone_invalid")
      ),
  });
};