import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListStore } from "@actions/metadata.actions";
import { selectListStore } from "@reducers/metadata.reducer";

import { useTranslation } from "@hooks/useTranslation";
import SelectField from "../fields/SelectInPaymentField";
import SelectDateField from "../fields/select-date/SelectDateField";

const Filters = ({ filters, setFilters, statusOptions = ["success", "fail", "pending"] }) => {
  const { translate } = useTranslation('txn')
  const dataStatus = ["all"].concat(statusOptions).map((status) => {
    if (status == "all") return { label: translate('option_all'), value: "all" };
    if (status == "success") return { label: translate('status_success'), value: "success" };
    if (status == "fail") return { label: translate('status_fail'), value: "fail" };
    if (status == "pending") return { label: translate('status_pending'), value: "pending" };
  });

  const dispatch = useDispatch();
  const store = useSelector(selectListStore);

  const dataStore = useMemo(() => {
    if (!store?.data) return [];
    return [{ label: translate('option_all'), value: "" }].concat(
      store?.data.map((store) => ({
        label: store.name,
        value: store.source_id,
      }))
    );
  }, [store]);

  useEffect(() => {
    dispatch(getListStore({ page_size: 10000 }));
  }, [dispatch]);

  const setStatus = (status) => setFilters({ ...filters, status });
  const setStore = (customer_id) => setFilters({ ...filters, customer_id: customer_id || '' });

  return (
    <div
      style={{ maxWidth: 975, gridTemplateColumns: "auto 1fr 1fr 1fr" }}
      className="flex flex-col md:grid gap-4 min-1620:gap-10 w-full md:w-3/4 relative z-10"
    >
      {/* Select Date */}
      <div className="space-y-1">
        <SelectDateField label={translate("flt_time")} filters={filters} setFilters={setFilters} />
      </div>

      {/* Select Status */}
      <div className={`space-y-1 ${dataStatus.length == 1 && "pointer-events-none opacity-50"}`}>
        <p className="text-blackgray text-sm leading-5 font-semibold">{translate("flt_status")}</p>
        <SelectField
          data={dataStatus}
          placeHolder={translate('option_all')}
          hasSearch={false}
          value={filters?.status}
          setValue={setStatus}
        />
      </div>

      {/* Select Store */}
      <div className="space-y-1">
        <p className="text-blackgray text-sm leading-5 font-semibold">{translate("flt_store")}</p>
        <SelectField
          data={dataStore}
          placeHolder={translate('option_all')}
          value={filters?.customer_id}
          setValue={setStore}
        />
      </div>

      {/* Input Bill ID */}
      <div className="space-y-1">
        <p className="text-blackgray text-sm leading-5 font-semibold">{translate("flt_invoice")}</p>
        <input
          // type="number"
          // onKeyDown={(e) => {
          //   if (e.key == "e" || e.key == "E") e.preventDefault();
          // }}
          onChange={(e) => setFilters({ ...filters, bill_id: e.target.value })}
          value={filters?.bill_id}
          style={{ padding: "10px 16px" }}
          placeholder={translate('common:enter') + ' ' + translate('flt_invoice').toLowerCase()}
          className="rounded-lg border border-gray-primary w-full text-blackgray text-sm leading-6 font-medium placeholder-hint"
        />
      </div>
    </div>
  );
};

export default Filters;
