import axios from 'axios'
import portalClient from './client_axios'
import {
  GET_HOLDER_NAME,
  GET_SELECT_BANK,
  GET_TABLE_BANK,
  SEND_OTP_ADD_BANK,
} from '@constants/endpoint'

export const bankServices = {
  getTableBank: (params) => {
    return portalClient.get(GET_TABLE_BANK, { params })
  },

  getSelectBank: () => {
    return portalClient.get(GET_SELECT_BANK)
  },

  verifyBank: (params) => {
    return portalClient.post(SEND_OTP_ADD_BANK, params)
  },

  getHolderName: ({ bank_account_no = '', bank_code = '' }) => {
    return portalClient.get(
      `${GET_HOLDER_NAME}?bank_account_no=${bank_account_no}&bank_code=${bank_code}`
    )
  },

  deleteBankVam: (params) => {
    return portalClient.delete(GET_TABLE_BANK, params)
  },
}
