import React, { useEffect, useState } from 'react'
import CustomButton from '@components/buttons/CustomButton'
import InputField from '@components/fields/InputField'
import { useTranslation } from '@hooks/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { CustomToastMessage } from '@utils/customToastMsg'
import { ERROR_CODE } from '@constants/errorCode'
import { editMerchantInfo, getEcomInfo } from '@actions'
import { selectProfile } from '@reducers/user.reducer'
import { PAYMENT_SETTING_FORM } from '@constants/sale-management/setting'

const PaymentSetting = () => {
  const { translate, language } = useTranslation()
  const dispatch = useDispatch()
  const { merchant_id } = useSelector(selectProfile)

  const [loading, setLoading] = useState(false)

  const { handleSubmit, register, reset, watch, setValue, formState: { errors } } = useForm()

  const onSubmit = async (value) => {
    const payload = {
      ...value, merchant_id: String(merchant_id),
      payment_methods: watch(PAYMENT_SETTING_FORM.PAYMENT_METHODS).filter(({ is_active }) => is_active).map(med => med.id)
    }
    setLoading(true)
    try {
      const res = await dispatch(editMerchantInfo(payload))
      if (res?.meta?.requestStatus === 'fulfilled') {
        CustomToastMessage.success(
          translate('txn:status_success'),
          translate('sale_management:update_success')
        )
      } else CustomToastMessage.error(translate('common:fail'), ERROR_CODE[res?.payload?.error_code][language])
    } catch (err) { console.log(err) }
    setLoading(false)
  }

  const handleGetEcomInfo = async () => {
    const { payload } = await dispatch(getEcomInfo(merchant_id))
    if (payload?.data?.data) {
      const methods = payload.data.data[PAYMENT_SETTING_FORM.PAYMENT_METHODS]?.filter(med => med.widget_type.includes('CHECKOUT'))
      reset({ ...payload.data.data, [PAYMENT_SETTING_FORM.PAYMENT_METHODS]: methods })
    }
  }

  useEffect(() => { if (merchant_id) handleGetEcomInfo() }, [merchant_id])

  const disabled =
    !watch(PAYMENT_SETTING_FORM.RETURN_URL) ||
    !watch(PAYMENT_SETTING_FORM.CANCEL_URL) ||
    !watch(PAYMENT_SETTING_FORM.IPN_URL) || loading

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='p-10 bg-white rounded-2xl flex flex-col gap-6 w-full shadow-primary' >
      <div className='text-2xl leading-8 font-bold text-primary-text'>
        {translate('setup_payment_gateway')}
      </div>

      <h1 className='text-2xl font-bold leading-8 text-blackgray'>
        E-Menu
      </h1>

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
        <InputField
          isRequired
          setValue={setValue}
          label={translate('payment_successful')}
          placeholder={translate('payment_placeholder_successful')}
          id={PAYMENT_SETTING_FORM.RETURN_URL}
          name={PAYMENT_SETTING_FORM.RETURN_URL}
          value={watch(PAYMENT_SETTING_FORM.RETURN_URL)}
          register={register}
          errors={errors}
        />
        <InputField
          isRequired
          setValue={setValue}
          label={translate('payment_cancel')}
          placeholder={translate('payment_placeholder_cancel')}
          id={PAYMENT_SETTING_FORM.CANCEL_URL}
          name={PAYMENT_SETTING_FORM.CANCEL_URL}
          value={watch(PAYMENT_SETTING_FORM.CANCEL_URL)}
          register={register}
          errors={errors}
        />

        <InputField
          isRequired
          setValue={setValue}
          label={translate('payment_checkout')}
          placeholder={translate('payment_placeholder_checkout')}
          id={PAYMENT_SETTING_FORM.IPN_URL}
          name={PAYMENT_SETTING_FORM.IPN_URL}
          value={watch(PAYMENT_SETTING_FORM.IPN_URL)}
          register={register}
          errors={errors}
        />
      </div>

      {/* <PaymentMethod
        form={FORM}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        register={register}
        watch={watch}
        setValue={setValue}
        errors={errors}
      /> */}

      <div className='flex justify-end mt-8 w-full'>
        <CustomButton
          type='submit'
          disabled={disabled}
          title={translate('common:save')}
          style={{ width: 182 }}
        />
      </div>
    </form>
  )
}

export default PaymentSetting
