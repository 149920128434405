import momo from '@assets/images/sale-setting/momo.png'
import zalopay from '@assets/images/sale-setting/zalopay.png'

export const EMENU_SETTING_FORM = {
  BANNER: 'banner',
  LOGO: 'logo',
  ADDRESS: 'address',
  HOTLINE: 'hotline',
  WEBSITE: 'website',
}

export const LIST_WALLET = [
  { name: 'Momo', logo: momo },
  { name: 'ZaloPay', logo: zalopay },
]

export const PAYMENT_SETTING_FORM = {
  RETURN_URL: 'return_url',
  CANCEL_URL: 'cancel_url',
  IPN_URL: 'ipn_url',
  PAYMENT_METHODS: "payment_methods"
}

export const REWARD_SETTING_FORM = { POINT_PERCENT: "point_percent" };