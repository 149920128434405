import React from 'react'
import order from '@assets/images/create-table/order.svg'
import backgroundIcon from '@assets/images/create-table/background.svg'
import { useTranslation } from '@hooks/useTranslation'
import TableNameBox from './TableItem/TableNameBox'
import CustomButton from '@components/buttons/CustomButton'

const CreateTable = ({ setShowPopup }) => {
  const { translate } = useTranslation('sale_table')

  return (
    <div style={{ height: 172 }} className='relative rounded-xl shadow-primary bg-white w-full overflow-hidden'>
      <img
        src={backgroundIcon}
        alt='top-left'
        className='absolute top-0 left-0'
        style={{ transform: 'translate(-40%, -40%)' }}
      />
      <img
        src={backgroundIcon}
        alt='bottom-right'
        className='absolute bottom-0 right-0'
        style={{ transform: 'translate(40%, 40%)' }}
      />

      <div className='relative w-full h-full z-10 pb-6 flex flex-col items-center justify-between'>
        <div className='flex justify-center items-start w-full' style={{ height: 52 }}>
          <TableNameBox color='#555555' name={translate('table')} />
        </div>

        <img src={order} alt='order-icon' className='w-12 h-12 absolute' style={{ bottom: 80, left: '50%', transform: 'translateX(-50%)' }} />

        <CustomButton
          outline
          style={{
            borderColor: '#B1B1B1',
            color: '#B1B1B1',
            width: 'max(263px, 80%)',
            height: 40
          }}
          title={translate('create_table')}
          onClick={() => setShowPopup(true)}
          leftIcon={
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
            >
              <path
                d='M7.53125 2.375H8.46875C8.55208 2.375 8.59375 2.41667 8.59375 2.5V13.5C8.59375 13.5833 8.55208 13.625 8.46875 13.625H7.53125C7.44792 13.625 7.40625 13.5833 7.40625 13.5V2.5C7.40625 2.41667 7.44792 2.375 7.53125 2.375Z'
                fill={'currentColor'}
              />
              <path
                d='M2.75 7.40625H13.25C13.3333 7.40625 13.375 7.44792 13.375 7.53125V8.46875C13.375 8.55208 13.3333 8.59375 13.25 8.59375H2.75C2.66667 8.59375 2.625 8.55208 2.625 8.46875V7.53125C2.625 7.44792 2.66667 7.40625 2.75 7.40625Z'
                fill={'currentColor'}
              />
            </svg>
          }
        />
      </div>
    </div>
  )
}

export default CreateTable
