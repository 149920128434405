import { saleClient } from "./client_axios";
import { ORDER_MANAGER } from "@constants/endpoint";

export const orderServices = {
  getListOrder: (params) => {
    return saleClient.get(`${ORDER_MANAGER}?${params}`);
  },

  getOrderById: (id) => {
    return saleClient.get(`${ORDER_MANAGER}/${id}`);
  },

  createOrder: (params) => {
    return saleClient.post(ORDER_MANAGER, params);
  },

  updateOrder: (params) => {
    return saleClient.put(`${ORDER_MANAGER}/${params.code}`, params);
  },

  deleteOrder: (params) => {
    return saleClient.delete(`${ORDER_MANAGER}/${params}`);
  },
};
