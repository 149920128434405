import { createSlice } from '@reduxjs/toolkit'
import { getTableBank, verifyBank } from '@actions/bank.actions'
import { getListAccountNumber } from '@actions/metadata.actions'

const initialState = {
  listBankMms: [],
  listBank: {
    data: [],
    total_page: 1,
    page: 1,
    page_size: 1
  },
  bankAccount: {
    bank_code: '',
    bank_account_no: '',
    holder_name: '',
  },
  isLoading: false,
}

const bankSlice = createSlice({
  name: 'bankSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getListAccountNumber.fulfilled, (state, action) => {
        state.listBankMms = action.payload
      })
      .addCase(getTableBank.fulfilled, (state, action) => {
        state.listBank = action.payload
      })
      .addCase(verifyBank.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(verifyBank.fulfilled, (state, action) => {
        state.bankAccount = action.meta.arg
        state.isLoading = false
      })
      .addCase(verifyBank.rejected, (state, action) => {
        state.isLoading = false
      }),
})
export default bankSlice.reducer

export const selectListBankMms = (state) => state.bank.listBankMms
export const selectListBank = (state) => state.bank.listBank
export const selectBankAccount = (state) => state.bank.bankAccount
export const selectIsLoadingBankAccount = (state) => state.bank.isLoading
