import React from 'react';
import LogoAtom from '@assets/images/LogoAtomFull.svg';
import IcUSA from '@assets/images/checkout/eng.svg';
import ImgPCI from '@assets/images/checkout/pci.png';
import IcVN from '@assets/images/checkout/vie.svg';
import IcBank from '@assets/images/payment/ic_bank.svg';
import IcJcb from '@assets/images/payment/ic_jcb.svg';
import IcMasterCard from '@assets/images/payment/ic_master-card.svg';
import IcMomo from '@assets/images/payment/ic_momo.svg';
import IcMoney from '@assets/images/payment/ic_money.svg';
import IcQr from '@assets/images/payment/ic_qr.svg';
import IcVisa from '@assets/images/payment/ic_visa.svg';
import IcWallet from '@assets/images/payment/ic_wallet.svg';
import IcZalo from '@assets/images/payment/ic_zalo.svg';
import IcMail from '@assets/images/payment/mail.svg';
import IcPhone from '@assets/images/payment/phone.svg';
import { useTranslation } from '@hooks/useTranslation';
import { CONTACTS, PAYMENTS, SCALE_RATE } from '@constants/business/ecom';

const PreviewView = ({ previewData }) => {
  const { translate } = useTranslation();

  const listPaymentMethod = previewData?.active_methods
    ?.filter((item) => item.active)
    .map((item) => PAYMENTS?.find((pay) => pay.value === item.name));

  const primaryColor = previewData.primary_color;
  const secondaryColor = previewData.secondary_color;
  const buttonColor = previewData.button_color;
  const isBorder = previewData.button_border;
  const logoHeight = previewData.logo_height;
  let logo = LogoAtom;

  if (
    typeof previewData.logo_url === 'object' &&
    previewData.logo_url !== null
  ) {
    logo = URL.createObjectURL(previewData.logo_url);
  } else if (typeof previewData.logo_url === 'string') {
    logo = previewData.logo_url;
  }

  return (
    <div
      style={{ border: '1px solid #E8E8E8' }}
      className='flex-1 rounded-lg p-4'
    >
      <h1 className='font-semibold text-black text-xl mb-4'>
        {' '}
        {translate('payment_preview')}
      </h1>
      <div className='p-2 rounded-2xl' style={{ background: '#E8E8E8' }}>
        <div className='flex flex-col items-center rounded-lg bg-white'>
          {/* Header */}
          <div
            style={{
              padding: '7.74px 32.38px',
              borderBottom: '1px solid #E8E8E8',
              borderRadius: '8px 8px 0 0',
              backgroundColor: primaryColor,
              gridTemplateColumns: `repeat(${previewData?.logo_position == 'center' ? 3 : 2
                }, 1fr)`,
              direction: previewData?.logo_position == 'right' ? 'rtl' : 'ltr',
            }}
            className='w-full grid transition-all'
          >
            {previewData?.logo_position == 'center' && <div />}
            <div
              className={`flex items-center ${previewData?.logo_position == 'center' && 'justify-center'
                }`}
            >
              <img
                src={logo}
                alt='logo'
                style={{
                  height: previewData?.logo_height * SCALE_RATE,
                  transition: 'linear 0.3s',
                }}
                className='w-auto'
              />
            </div>
            <div
              style={{ gap: 3.87 }}
              className='flex flex-col justify-center items-end'
            >
              {CONTACTS.map((contact, index) => (
                <div
                  key={`contact-${index}`}
                  style={{ gap: 3.87 }}
                  className='flex items-center'
                >
                  <img
                    src={contact.icon}
                    alt='icon'
                    style={{ height: 11.61, width: 'auto' }}
                  />
                  <div
                    style={{
                      color: '#262626',
                      fontSize: '7.742px',
                      lineHeight: '11.613px',
                    }}
                  >
                    {translate(contact.content)}
                  </div>
                </div>
              ))}
              <div style={{ gap: 5.81 }} className='flex items-center'>
                <img
                  src={IcVN}
                  alt='vn'
                  style={{ height: 11.61, width: 'auto' }}
                />
                <div
                  className='w-px h-full'
                  style={{ background: '#434C761A' }}
                />
                <img
                  src={IcUSA}
                  alt='usa'
                  style={{ height: 11.61, width: 'auto' }}
                />
              </div>
            </div>
          </div>

          {/* Body */}
          <div
            style={{
              gap: 11.6,
              background: '#E8E8E8',
              padding: '38px 0',
              backgroundColor: secondaryColor,
            }}
            className='w-full flex items-center justify-center flex-wrap'
          >
            <div
              style={{
                padding: 11.6,
                borderRadius: '5.8px',
              }}
              className='bg-white shadow-primary'
            >
              <div
                style={{
                  fontSize: '11.6px',
                  lineHeight: '15.483px',
                  marginBottom: 11.6,
                }}
                className='text-black '
              >
                {translate('sale_management_payment')}
              </div>
              <div style={{ gap: 7.74, width: 430 }} className=' flex flex-col'>
                {listPaymentMethod?.map((payment, index) => (
                  <div
                    key={index}
                    style={{
                      borderRadius: 5.8,
                      backgroundColor: '#F5F5F5',
                      padding: 7.74,
                    }}
                    className='w-full flex items-center justify-between'
                  >
                    <div style={{ gap: 7.74 }} className='flex items-center '>
                      <img src={payment.icon} alt='icon' width={11.61} />

                      <div>
                        <div
                          style={{
                            fontSize: '7.742px',
                            lineHeight: '11.613px',
                          }}
                          className='text-black font-semibold'
                        >
                          {translate(payment.content)}
                        </div>

                        {payment?.subtitle && (
                          <div
                            style={{ gap: 5.81 }}
                            className='flex items-center '
                          >
                            {payment?.subtitle.map((sub, index) => (
                              <img
                                key={index}
                                src={sub}
                                alt='icon'
                                width={11.61}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    {index === 0 && (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width={13}
                        height={12}
                        viewBox='0 0 13 12'
                        fill='none'
                      >
                        <path
                          d='M12.0349 5.8668C12.0349 8.80628 9.65198 11.1892 6.7125 11.1892C3.77302 11.1892 1.3901 8.80628 1.3901 5.8668C1.3901 2.92732 3.77302 0.544401 6.7125 0.544401C9.65198 0.544401 12.0349 2.92732 12.0349 5.8668Z'
                          stroke={buttonColor}
                          strokeWidth='0.967708'
                        />
                        <path
                          d='M9.61875 5.86602C9.61875 7.46937 8.31898 8.76914 6.71563 8.76914C5.11227 8.76914 3.8125 7.46937 3.8125 5.86602C3.8125 4.26266 5.11227 2.96289 6.71563 2.96289C8.31898 2.96289 9.61875 4.26266 9.61875 5.86602Z'
                          fill={buttonColor}
                        />
                      </svg>
                    )}
                    {index !== 0 && (
                      <input
                        type='radio'
                        checked={false}
                        className='cursor-pointer'
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div
              style={{
                padding: 11.6,
                borderRadius: '5.8px',
              }}
              className='bg-white shadow-primary'
            >
              <div className='flex items-center justify-between'>
                <div style={{ fontSize: 9.6 }} className='font-semibold'>
                  {translate('order_info')}
                </div>
                <div
                  style={{
                    backgroundColor: '#EEFAEA',
                    border: '1px solid #CAE9CF',
                    color: '#3E941F',
                    padding: 3.8,
                    fontSize: 7.742,
                  }}
                  className='rounded-full'
                >
                  01:00:00
                </div>
              </div>

              <div
                style={{ marginTop: 11.61, marginBottom: 19.35, gap: 7.74 }}
                className='flex flex-col '
              >
                <div className='flex items-center justify-between'>
                  <div
                    style={{ fontSize: 6.774, color: '#8991A3' }}
                    className=''
                  >
                    {translate('payment_acceptance_unit')}
                  </div>
                  <div style={{ fontSize: 9.6 }} className='font-semibold'>
                    KFC
                  </div>
                </div>

                <div className='flex items-center justify-between'>
                  <div
                    style={{ fontSize: 6.774, color: '#8991A3' }}
                    className=''
                  >
                    {translate('order_id')}
                  </div>
                  <div style={{ fontSize: 9.6 }} className='font-semibold'>
                    301778047
                  </div>
                </div>

                <div
                  style={{ border: '1px solid #E8E8E8' }}
                  className='w-full '
                />

                <div className='flex items-center justify-between'>
                  <div
                    style={{ fontSize: 6.774, color: '#8991A3' }}
                    className=''
                  >
                    {translate('payment_amount')}
                  </div>
                  <div
                    style={{
                      fontSize: 9.6,
                      color: buttonColor,
                      fontSize: 11.63,
                    }}
                    className='font-semibold'
                  >
                    1,889,000 VND
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-2 gap-4 '>
                <div
                  style={{
                    borderColor: buttonColor,
                    borderRadius: isBorder ? '1.935px' : '0',
                    color: buttonColor,
                    fontSize: '6.774px',
                    padding: '5.806px 15.483px',
                  }}
                  className='border font-semibold text-center'
                >
                  {translate('payment_cancelled')}
                </div>
                <div
                  style={{
                    borderColor: buttonColor,
                    borderRadius: isBorder ? '1.935px' : '0',
                    backgroundColor: buttonColor,
                    fontSize: '6.774px',
                    padding: '5.806px 15.483px',
                  }}
                  className='border font-semibold text-white text-center'
                >
                  {translate('payment')}
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div
            style={{
              padding: '11.61px 48.4px',
              gap: 5.81,
              backgroundColor: primaryColor,
            }}
            className='flex flex-col items-center justify-center w-full rounded-b-lg overflow-hidden'
          >
            <div
              style={{ gap: 3.82, fontSize: 6.77, lineHeight: '9.68px' }}
              className='flex items-center justify-center'
            >
              <p className='text-black font-medium'>
                {translate('payment_solution_provided_by')}
              </p>
              <img
                src={LogoAtom}
                alt='logo'
                style={{
                  height: 15.5,
                  width: 'auto',
                  transition: 'all 0.3s linear',
                  objectFit: 'contain',
                }}
              />
            </div>

            <img
              src={ImgPCI}
              alt='logo'
              style={{
                height: 20,
                width: 'auto',
                transition: 'all 0.3s linear',
                objectFit: 'contain',
              }}
            />

            <div
              style={{ color: '#8991A3', fontSize: 6.7, gap: 6.774 }}
              className='flex justify-center'
            >
              <div>Về ATOM</div>
              <div>{translate('preview_contact')} hotro247@atomsolution.vn</div>
              <div>Hotline: 1900 3353</div>
            </div>

            <div
              style={{
                color: '#8991A3',
                fontSize: 5.806,
                paddingTop: 4.84,
                borderTop: '1px solid #E8E8E8',
              }}
              className='flex justify-center w-full'
            >
              <div>©️ 2021 {translate('copy_right')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewView;
