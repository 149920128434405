import portalClient from "./client_axios";
import { GET_LIST_BANK_ACCOUNT, GET_LIST_LINKED_VA, GET_LIST_STAFF, GET_LIST_STORE, SYNC_DATA } from "@constants/endpoint";

export const metadataServices = {
  getListStore: (params) => {
    return portalClient.get(GET_LIST_STORE, { params });
  },

  getListStaff: (params) => {
    return portalClient.get(GET_LIST_STAFF, { params });
  },

  getListLinkedVa: (params) => {
    return portalClient.get(GET_LIST_LINKED_VA, { params });
  },

  getListAccountNumber: (params) => {
    return portalClient.get(`${GET_LIST_BANK_ACCOUNT}?${params}`);
  },

  syncData: (params) => {
    return portalClient.post(`${SYNC_DATA}`, params);
  },
};
