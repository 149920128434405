import React, { useCallback, useRef } from "react";
import IcColor from "@assets/images/setting/color.svg";

const SelectFieldColor = ({
  color,
  setColor,
  register,
  isRequired,
  label,
  id,
  name,
  extra,
  variant,
  disabled,
}) => {
  const inputRef = useRef();

  const handleDivClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const handleChangeColor = useCallback((e) => {
    const color = e.target.value;
    setColor(color);
  }, []);

  const handleChangeColorInput = useCallback(
    (e) => {
      const color = e.target.value;
      setColor(color);
    },
    [setColor],
  );

  return (
    <div className={`${extra} font-monte relative cursor-pointer`}>
      {label && (
        <div className={`mb-2`}>
          <label htmlFor={id} className={`text-black text-sm leading-5 font-semibold`}>
            {label}
          </label>
          {isRequired && <span className="font-semibold text-error">*</span>}
        </div>
      )}

      <input
        ref={inputRef}
        type="color"
        value={color}
        disabled={disabled}
        onChange={(e) => handleChangeColor(e)}
        className="opacity-0 flex-1 absolute inset-0"
      />
      <div className="flex items-center gap-4 ">
        <div
          style={{ padding: "10px 16px", borderColor: "#E8E8E8" }}
          className="flex-1 flex relative rounded-lg border text-sm font-semibold leading-5 text-black"
        >
          <input
            id={id}
            type="text"
            value={color}
            className="flex-1"
            onChange={(e) => handleChangeColorInput(e)}
          />
          <div
            onClick={handleDivClick}
            style={{ backgroundColor: color, borderColor: "#E8E8E8", top: "50%", right: 10 }}
            className="rounded absolute w-6 h-6 border transform -translate-y-1/2 "
          />
        </div>
        <img
          src={IcColor}
          alt="IcColor"
          style={{ width: 44 }}
          className=""
          onClick={handleDivClick}
        />
      </div>
    </div>
  );
};

export default SelectFieldColor;
