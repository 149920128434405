import React from 'react'
import { useLocation } from 'react-router-dom'
import AuthLayout from './auth-layout'
import MainLayout from './main-layout'
import Dashboard from '@pages/dashboard'

const Layouts = ({ children }) => {
  let location = useLocation()
  let { pathname } = { ...location }

  if (['/login', '/forgot-password', '/reset-password'].includes(pathname)) {
    return <AuthLayout>{children}</AuthLayout>
  } else if (pathname.includes('/embedded-dashboard')) return children
  else return <MainLayout>{children}</MainLayout>
}

export default Layouts
