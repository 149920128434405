import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectListNotiFood, selectListNotiOrder, selectListNotiPay } from '@reducers/noti.reducer'
import { getListNoti, getOrderById, updateNoti } from '@actions'

import orderNoti from '@assets/images/noti/orderNoti.svg'
import payNoti from '@assets/images/noti/payNoti.gif'
import foodNoti from '@assets/images/noti/foodNoti.svg'

import { history } from '@helpers'
import useWindowSize from '@hooks/useWindowSize'
import { useTranslation } from '@hooks/useTranslation'

const Item = ({ icon, title, desc, isRead, item, setHidden }) => {
  const { translate } = useTranslation('noti')
  const dispatch = useDispatch()
  const { isMd } = useWindowSize()
  const handleClickNoti = async () => {
    if (item.status !== 2) {
      const res = await dispatch(updateNoti({ ...item, status: 2 }))
      if (res?.payload) dispatch(getListNoti())
    }
    const res = await dispatch(getOrderById(item.order_id))
    if (res?.payload?.data?.order_info?.status === 0) history.push('/sale-order')
    else history.push(`/sale-order/${item.order_id}`)
    setHidden(true)
  }

  const formatDifferTime = (time) => {
    const day = Math.floor(time / 86400)
    if (day > 0) return day + ' ' + translate('time_day')
    else {
      const hour = Math.floor(time / 3600)
      if (hour > 0) return hour + ' ' + translate('sale_management_hour').toLowerCase()
      else {
        const minute = Math.floor(time / 60)
        return minute > 0 ? minute + ' ' + translate('sale_management_min').toLowerCase() : translate('noti:just_now')
      }
    }
  }

  const differTime = Math.floor(Date.now() / 1000 - item?.created_at)

  return (
    <div
      className={`${isRead ? 'opacity-50' : 'bg-grey-50 shadow-primary'} p-4 rounded-lg relative cursor-pointer`}
      style={{ width: isMd ? 419 : '100%', maxWidth: '100%' }} onClick={() => handleClickNoti()}
    >
      {!isRead && <span className='absolute bg-gradient rounded-full' style={{ width: 10, height: 10, top: 12, right: 12 }}></span>}
      <div className='flex items-center justify-between'>
        <img src={icon} alt='iconNoti' className='w-8' />
        <p className={`text-xs leading-5 font-normal ${!isRead && 'text-primary'}`}>{formatDifferTime(differTime)}</p>
      </div>
      <h2 className='text-sm leading-5 font-bold mt-1'>{title}</h2>
      <p className='text-sm leading-5 font-normal'>{desc}</p>
    </div>
  )
}

const NotiDropdown = ({ setHidden }) => {
  const { translate } = useTranslation('noti')
  const { isMd } = useWindowSize()
  const listNotiOrder = useSelector(selectListNotiOrder)
  const listNotiPay = useSelector(selectListNotiPay)
  const listNotiFood = useSelector(selectListNotiFood)

  const [data, setData] = useState([])
  const tabs = [
    {
      title: translate('new_order'),
      titleMobile: 'Đơn mới',
      iconItem: orderNoti,
      titleItem: translate('order_title'),
      descItem: (orderCode) => `${translate('order_desc')} ${orderCode}`,
    },
    {
      title: translate('request_payment'),
      titleMobile: 'Thanh toán',
      iconItem: payNoti,
      titleItem: translate('payment_title'),
      descItem: (orderCode) => `${translate('payment_desc')} ${orderCode}`,
    },
    {
      title: translate('add_dishes'),
      titleMobile: translate('dishes_title'),
      iconItem: foodNoti,
      titleItem: translate('dishes_title'),
      descItem: (orderCode) => `${translate('dishes_desc')} ${orderCode}`,
    },
  ]

  const [activeTab, setActivetab] = useState(tabs[0])

  useEffect(() => {
    if (activeTab.title === translate('new_order')) setData(listNotiOrder)
    else if (activeTab.title === translate('request_payment')) setData(listNotiPay)
    else setData(listNotiFood)
  }, [activeTab, listNotiOrder, listNotiPay, listNotiFood])

  return (
    <div className='bg-white pb-3 md:pb-6 rounded-xl overflow-auto' style={{ width: isMd ? 467 : '100%' }}>
      {/* Header */}
      <div className='space-y-4 p-3 md:p-6 bg-white'>
        <h1 className='text-xl leading-6 font-bold'>{translate('dropdown_title')}</h1>
        <div className='flex gap-2 md:gap-4'>
          {tabs.map(tab =>
            <div
              key={tab.title} onClick={() => setActivetab(tab)}
              className={`relative cursor-pointer px-4 py-2 rounded-3xl text-sm leading-4 font-semibold ${activeTab.title === tab.title && 'bg-select-hover text-primary'}`}
            >
              {isMd ? tab.title : tab.titleMobile}
              {((tab.title === translate('new_order') && listNotiOrder?.find(noti => noti.status != 2)) ||
                (tab.title === translate('request_payment') && listNotiPay?.find(noti => noti.status != 2)) ||
                (tab.title === translate('add_dishes') && listNotiFood?.find(noti => noti.status != 2)))
                &&
                <span className='bg-gradient w-2 h-2 rounded-full absolute' style={{ right: 6, top: 9 }}></span>
              }
            </div>
          )}
        </div>
      </div>

      <div className='flex flex-col gap-6 w-full px-3 md:px-6 overflow-scroll custom-scrollbar-1' style={{ maxHeight: 'calc(68vh)' }}>
        {/* Unread Noti */}
        <div className=''>
          <div className='w-full flex justify-between items-center'>
            <h2 className='text-lg leading-5 font-semibold' style={{ marginBottom: 10 }}>{translate('new')}</h2>
            <p className='text-sm leading-4 font-normal text-blue-6 cursor-pointer'>{translate('see_all')}</p>
          </div>
          <div className='space-y-2'>
            {[].concat(data?.filter(noti => noti.status != 2)).map((item, index) => {
              return (
                <div key={index}>
                  <Item
                    icon={activeTab.iconItem}
                    title={activeTab.titleItem}
                    desc={activeTab.descItem(item?.order_code)}
                    isRead={false}
                    item={item} setHidden={setHidden}
                  />
                </div>
              )
            })}
          </div>
        </div>

        {/* Read Noti */}
        <div className=''>
          <h2 className='text-lg leading-5 font-semibold' style={{ marginBottom: 10 }}>{translate('seen')}</h2>
          <div className='space-y-2'>
            {[].concat(data?.filter(noti => noti.status === 2)).map((item, index) => {
              return (
                <div key={index}>
                  <Item
                    icon={activeTab.iconItem}
                    title={activeTab.titleItem}
                    desc={activeTab.descItem(item?.table_name)}
                    isRead={true}
                    item={item} setHidden={setHidden}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotiDropdown
