/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { history } from '@helpers/history';
import PrintInvoice from '@pages/sale-management/order/list-order/PrintInvoice';
import PaymentMethod from './PaymentMethod';

import arrow_left from '@assets/images/product/arrow_left.svg';
import CustomButton from '@components/buttons/CustomButton';
import { useTranslation } from '@hooks/useTranslation';
import Tooltip from '@components/tooltip';
import { useOrder } from '@hooks/useOrder';
import { useDispatch, useSelector } from 'react-redux';
import { selectSocketData, setSocketData } from '@reducers/noti.reducer';
import { PAYMENTMETHOD } from '@constants/sale-management/table';
import { STATUS_INVOICE } from '@constants/sale-management/order';
import { theme } from 'configTheme';

const { PAID } = STATUS_INVOICE

const PaymentPage = () => {
  const { order_id } = useParams();
  const [isPaid, setIsPaid] = useState(false)
  const dispatch = useDispatch()
  const socketData = useSelector(selectSocketData)
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [qrData, setQrData] = useState(null);
  const { translate } = useTranslation('sale_order');
  const myAudio = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => document.getElementById('print-content'),
  });

  const { isFetched, info, products, qr } = useOrder({ orderId: order_id, hasQR: true })

  useEffect(() => {
    info?.order_id && setIsPaid(info?.status === PAID)
  }, [info])

  useEffect(() => {
    if (socketData?.order_id && socketData?.order_id == order_id) {
      setIsPaid(true)
      setPaymentMethod(socketData?.payment_method)
      myAudio.current && myAudio.current.play();
    }

    return () => dispatch(setSocketData(null))
  }, [socketData])

  if (!isFetched) return null

  return (
    <div>
      <audio ref={myAudio} src={process.env.REACT_APP_VOICE_URL} />

      {!isPaid &&
        <div
          className='flex gap-3 items-center cursor-pointer'
          onClick={() => history.goBack()}
        >
          <img src={arrow_left} alt='' />
          <p className='text-lg font-medium leading-6 text-blackgray'>
            {translate('common:back')}
          </p>
        </div>
      }

      <div className='flex w-full justify-center mt-6' style={{ gap: 116 }}>
        {!isPaid &&
          <div className='flex flex-col' style={{ width: 528, gap: 30 }}>
            <div className='flex rounded-xl border-2 border-gray-primary h-11'>
              {PAYMENTMETHOD.map((item) => {
                return (
                  <button
                    key={item.type}
                    className={`relative w-full flex space-x-2 justify-center items-center border-l border-r first:border-0 first:rounded-l-xl last:border-0 last:rounded-r-xl outline-none focus:outline-none active:outline-none`}
                    style={{ background: paymentMethod === item.type ? theme.sidebar : '#FFF' }}
                    onClick={() => setPaymentMethod(item.type)}
                  >
                    <p
                      className={`text-sm font-semibold leading-3`}
                      // style={{ color: item.type === paymentMethod ? '#fff' : '#434C76' }}
                      style={{ color: theme.secondary }}
                    >
                      {translate(item.name)}
                    </p>
                    {item.icon && (
                      <Tooltip
                        icon={
                          item.type === paymentMethod
                            ? item.iconActive
                            : item.icon
                        }
                        content={item.tooltip}
                      />
                    )}
                  </button>
                );
              })}
            </div>
            <PaymentMethod
              invoice={info}
              qr={qr}
              setQrData={setQrData}
              method={paymentMethod}
            />
          </div>
        }

        <div className='flex flex-col items-center'>
          {isPaid && (
            <p className='text-base leading-6 text-primary font-semibold mb-4'>
              {translate('payment_success')}
            </p>
          )}
          <PrintInvoice
            info={{ ...info, status: isPaid && PAID, payment_method: isPaid && paymentMethod }}
            products={products}
            {...qrData}
          />

          <div className='flex gap-4 mt-8 justify-center'>
            {isPaid &&
              <CustomButton
                outline
                title={translate('sale_table:back_to_home')}
                onClick={() =>
                  history.push(info?.table_id ? '/sale-table' : '/sale-order?page=1&page_size=10')
                }
              />
            }
            <CustomButton
              title={translate(isPaid ? 'btn_print_bill' : 'print_bill')}
              onClick={handlePrint}
              disabled={!qr}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
