import React from "react";

const TableWrapper = ({ children }) => {
  return (
    <div
      className="mt-8 p-10 bg-white rounded-2xl flex flex-col gap-6 w-full shadow-primary"
    >
      {children}
    </div>
  );
};

export default TableWrapper;
