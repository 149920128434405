import { createSlice } from "@reduxjs/toolkit";
import { getBusiness } from "@actions/business.actions";

const initialState = {
  business: {
    address: "",
    business: "",
    email: "",
    indentification_no: false,
    merchant_code: "",
    mobile: "",
    name: "",
    phone: "",
    position: false,
    representative_name: false,
    tax_no: "",
    trademark: "",
    website: "",
  },
};

const businessSlice = createSlice({
  name: "businessSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(getBusiness.fulfilled, (state, action) => {
      state.business = action.payload.data;
    }),
});
export default businessSlice.reducer;

export const selectBusiness = (state) => state.business.business;
