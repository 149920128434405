import { createSlice } from "@reduxjs/toolkit";
import { getPresentative } from "@actions";

const initialState = {
  listPresentative: [],

  isLoadingListCity: false,
  isLoadingListPresentative: false,
  isLoadingListDistrict: false,
  isLoadingListWard: false,
};

const storeSlice = createSlice({
  name: "storeSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      // presentative
      .addCase(getPresentative.fulfilled, (state, action) => {
        state.listPresentative = action.payload;
      })
      .addCase(getPresentative.pending, (state, action) => {
        state.isLoadingListPresentative = true;
      })
      .addCase(getPresentative.rejected, (state, action) => {
        state.isLoadingListPresentative = false;
      }),
});
export default storeSlice.reducer;

export const selectListPresentative = (state) => state.store.listPresentative;
export const selectIsLoadingStore = (state) => state.store.isLoading;
