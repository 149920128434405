import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getListTxnMoMo,
  getListTxnEcom,
  getListTxnQRCode,
  getListTxnVA,
  getListTxnCard,
  getListTxnCash,
  getListTxnInstallment,
  getListTxnSaving,
  getListTxnLoan,
} from '@actions'
import { TxnService } from '@services/transaction.service'

const initialState = {
  txnMoMo: [],
  txnEcom: [],
  txnQR: [],
  txnVA: [],
  txnCard: [],
  txnCash: [],
  txnInstallment: [],
  txnSaving: [],
  txnLoan: [],
}

const transactionSlice = createSlice({
  name: 'transactionSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getListTxnCard.fulfilled, (state, action) => {
        state.transCard = action.payload.data
      })
      .addCase(getListTxnMoMo.fulfilled, (state, action) => {
        state.txnMoMo = action.payload.data
      })
      .addCase(getListTxnEcom.fulfilled, (state, action) => {
        state.txnEcom = action.payload.data
      })
      .addCase(getListTxnQRCode.fulfilled, (state, action) => {
        state.txnQR = action.payload.data.items
      })
      .addCase(getListTxnVA.fulfilled, (state, action) => {
        state.txnVA = action.payload.data
      })
      .addCase(getListTxnCash.fulfilled, (state, action) => {
        state.txnCash = action.payload.data
      })
      .addCase(getListTxnInstallment.fulfilled, (state, action) => {
        state.txnInstallment = action.payload.data
      })
      .addCase(getListTxnSaving.fulfilled, (state, action) => {
        state.txnSaving = action.payload.data
      })
      .addCase(getListTxnLoan.fulfilled, (state, action) => {
        state.txnLoan = action.payload.data
      }),
})

export default transactionSlice.reducer

export const { } = transactionSlice.actions

export const selectTxnMoMo = (state) => state.transaction.txnMoMo
export const selectTxnEcom = (state) => state.transaction.txnEcom
export const selectTxnQR = (state) => state.transaction.txnQR
export const selectTxnCard = (state) => state.transaction.txnCard
export const selectTxnVA = (state) => state.transaction.txnVA
export const selectTxnCash = (state) => state.transaction.txnCash
export const selectTxnInstallment = (state) =>
  state.transaction.txnInstallment
export const selectTxnSaving = (state) => state.transaction.txnSaving
export const selectTxnLoan = (state) => state.transaction.txnLoan
