import React, { useEffect, useRef, useState } from 'react'
import { Highlight, SectionWrap, LinkText } from '@components/terms';
import ModalWrap from '@components/modals/ModalWrap'
import { IoCloseSharp } from 'react-icons/io5';
import CustomButton from '@components/buttons/CustomButton';
import { useTranslation } from '@hooks/useTranslation';

const ModalPolicy = ({ isOpen, onClose, setCheckbox }) => {
    const contentRef = useRef(null)
    const { translate } = useTranslation('bank');
    const [disabled, setDisabled] = useState(true);

    const checkScrollBottom = (e) => {
        const isAtBottom =
            Math.abs(e.scrollHeight - e.clientHeight - e.scrollTop) < 2;
        if (isAtBottom) {
            setDisabled(false);
            e.removeEventListener("scroll", checkScrollBottom);
        }
    };

    const handleClose = () => {
        onClose()
        setDisabled(true)
    }

    useEffect(() => {
        // Scroll to the top of the content
        if (contentRef.current && isOpen) {
            contentRef.current.scrollTop = 0;
        }
    }, [isOpen]);

    return (
        <ModalWrap isOpen={isOpen} onClickBackdrop={handleClose} style={{ padding: 24, width: 782 }}>
            <div style={{ height: '80vh', maxHeight: 798 }} className='flex flex-col'>
                {/* Close */}
                <h3 className='font-bold text-2xl text-center leading-7 pb-2'>{translate('terms_and_condition')}</h3>
                <IoCloseSharp
                    onClick={handleClose}
                    style={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                        cursor: "pointer",
                    }}
                    size={24}
                />

                <div ref={contentRef} onScroll={(e) => checkScrollBottom(e.target)} className='overflow-auto flex-1'>
                    <div className="flex flex-col gap-6">
                        <SectionWrap title={translate('clause_1')} titleSize="base" tabSpace>
                            {translate('clause_1_desc')}
                        </SectionWrap>

                        <SectionWrap
                            title={translate('clause_2')}
                            titleSize="base"
                            tabSpace
                        >
                            <Highlight semibold text={translate('qr_code')} /> {translate('qr_code_desc')} <br />
                            <Highlight
                                semibold
                                text={translate('services')}
                            />{" "}
                            {translate('services_desc')}{" "}
                            <br />
                            <Highlight semibold text={translate('qr_account')} /> {translate('qr_account_desc')} <br />
                            <Highlight semibold text={translate('qr_payment')} /> {translate('qr_payment_desc')} <br />
                        </SectionWrap>

                        <SectionWrap title={translate('clause_3')} titleSize="base">
                            <SectionWrap
                                title={translate('clause_3_section_1')}
                                tabSpace
                            >
                                {translate('clause_3_section_1_desc')}
                            </SectionWrap>
                            <SectionWrap title={translate('clause_3_section_2')} tabSpace>
                                {translate('clause_3_section_2_desc')}
                            </SectionWrap>
                            <SectionWrap
                                title={translate('clause_3_section_3')}
                                tabSpace
                            >
                                {translate('clause_3_section_3_desc_1')} <br />
                                <Highlight semibold text={translate('clause_3_section_3_bank_account')} /> {translate('clause_3_section_3_desc_2')} <br />
                                <Highlight semibold text={translate('clause_3_section_3_qr_account')} /> {translate('clause_3_section_3_desc_3')} <br />
                            </SectionWrap>
                            <SectionWrap title={translate('clause_3_section_4')} />
                        </SectionWrap>

                        <SectionWrap title={translate('clause_4')} titleSize="base" tabSpace>
                            {translate('clause_4_desc')}
                        </SectionWrap>

                        <SectionWrap
                            title={translate('clause_5')}
                            titleSize="base"
                            tabSpace
                        >
                            {translate('clause_5_desc_1')} <Highlight text={translate('user_agreement')} />
                            {translate('clause_5_desc_2')}{" "}
                            <LinkText text={translate('user_agreement_link')} /> {translate('clause_5_and')}{" "}
                            <Highlight text={translate('privacy_policy')} /> {translate('clause_5_desc_3')}{" "}
                            <LinkText text={translate('privacy_policy_link')} />{translate('clause_5_desc_4')}
                        </SectionWrap>

                        <SectionWrap
                            title={translate('clause_6')}
                            titleSize="base"
                            tabSpace
                        >
                            {translate('clause_6_desc_1')}{" "}
                            <Highlight text={translate('user_agreement')} /> {translate('clause_6_desc_2')}{" "}
                            <LinkText text={translate('user_agreement_link')} /> {translate('clause_6_and')}{" "}
                            <Highlight text={translate('privacy_policy')} /> {translate('clause_6_desc_3')}{" "}
                            <LinkText text={translate('privacy_policy_link')} />.
                        </SectionWrap>

                        <SectionWrap
                            title={translate('clause_7')}
                            titleSize="base"
                            tabSpace
                        >
                            {translate('clause_6_desc_1')}{" "}
                            <Highlight text={translate('user_agreement')} /> {translate('clause_6_desc_2')}{" "}
                            <LinkText text={translate('user_agreement_link')} /> {translate('clause_6_and')}{" "}
                            <Highlight text={translate('privacy_policy')} /> {translate('clause_6_desc_3')}{" "}
                            <LinkText text={translate('privacy_policy_link')} />.
                        </SectionWrap>

                        <SectionWrap
                            title={translate('clause_8')}
                            titleSize="base"
                            tabSpace
                        >
                            {translate('clause_8_desc_1')}
                            <br />
                            {translate('clause_8_desc_2')} <Highlight text={translate('user_agreement')} />
                            {translate('clause_5_desc_2')}{" "}
                            <LinkText text={translate('user_agreement_link')} /> {translate('clause_5_and')}{" "}
                            <Highlight text={translate('privacy_policy')} /> {translate('clause_5_desc_3')}{" "}
                            <LinkText text={translate('privacy_policy_link')} />{translate('clause_5_desc_4')}
                        </SectionWrap>
                    </div>
                </div>

                <CustomButton
                    onClick={() => {
                        setCheckbox()
                        handleClose()
                    }}
                    disabled={disabled}
                    title={translate("i_agree")}
                    className='w-full mt-8'
                />
            </div>
        </ModalWrap>
    )
}

export default ModalPolicy