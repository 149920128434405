import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import IcWarn from "@assets/images/icon/ic_warn.svg";
import CustomButton from "../buttons/CustomButton";
import ModalWrap from "./ModalWrap";

const ModalDelete = ({
  isOpen,
  onClose,
  handleAction,
  title,
  desc,
  item,
  titleBtnLeft,
  titleBtnRight,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteBank = async (id) => {
    setIsLoading(true);
    await handleAction(id);
    setIsLoading(false);
    onClose();
  };

  return (
    <ModalWrap isOpen={isOpen} onClickBackdrop={onClose}>
      <div className="w-96 bg-white rounded-2xl relative">
        <IoCloseSharp
          className="absolute cursor-pointer hover:opacity-75"
          style={{ right: 16, top: 16 }}
          onClick={onClose}
          size={20}
        />
        <div className=" mx-auto flex max-w-md flex-col gap-8 rounded-2xl px-6 py-8">
          <div className="flex flex-col items-center justify-center gap-6">
            <img src={IcWarn} alt="ic_warn" />
            <div className="flex flex-col gap-3 text-center text-black font-normal">
              <h1 className="text-xl font-bold">{title}</h1>
              <p className="text-sm">{desc}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <CustomButton
              outline
              title={titleBtnLeft}
              onClick={onClose}
            />
            <CustomButton
              title={titleBtnRight}
              disabled={isLoading}
              onClick={() => handleDeleteBank(item?.id)}
            />
          </div>
        </div>
      </div>
    </ModalWrap>
  );
};

export default ModalDelete;
