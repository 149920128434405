/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Cash from './Cash/page';
import Credit from './Credit/page';
import QRScan from './QRScan/page';
import Link from './Link/page';
import { PAYMENT_METHOD } from '@constants/common';

const { QR, CASH, CARD, LINK } = PAYMENT_METHOD

const PaymentMethod = ({
  invoice,
  qr,
  method,
  setQrData,
}) => {
  switch (method) {
    case QR:
      return <QRScan invoice={invoice} qr={qr} setQrData={setQrData} />
    case CASH:
      return <Cash invoice={invoice} />
    case CARD:
      return <Credit invoice={invoice} />
    case LINK:
      return <Link invoice={invoice} setQrData={setQrData} />
    default:
      return null
  }
};

export default PaymentMethod;
