import { LocalStorageService } from "@helpers";
import { saleClient } from "./client_axios";
import { SALE_REGISTER } from "@constants/endpoint";

export const saleServices = {
  getMerchant: () => {
    const query = new URLSearchParams({
      token: LocalStorageService.getAccessToken(),
    });
    return saleClient.get(`${SALE_REGISTER}/merchant?${query}`);
  },

  sellRegisterFree: (params) => {
    return saleClient.post(`${SALE_REGISTER}/subscription/register/free`, params);
  },

  sellRegisterCode: (params) => {
    return saleClient.post(`${SALE_REGISTER}/subscription/register/code`, params);
  },

  updateMerchant: (params) => {
    return saleClient.put(`${SALE_REGISTER}/merchant`, params);
  },
};
