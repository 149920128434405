import portalClient from "./client_axios";
import { PAYMENT_CHANNEL, USER_QR } from "@constants/endpoint";

export const paymentChannelService = {
    getListPaymentChannels: (params) => {
        return portalClient.get(PAYMENT_CHANNEL, { params });
    },

    createPaymentChannel: (params) => {
        return portalClient.post(PAYMENT_CHANNEL, params);
    },

    deletePaymentChannel: (params) => {
        return portalClient.delete(PAYMENT_CHANNEL, params)
    },

    createQR: (params) => {
        return portalClient.post(USER_QR, params)
    }
}