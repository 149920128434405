import { DASHBOARD_DATA } from '@constants/endpoint'
import { LocalStorageService } from '@helpers/localStorageService'

const PORTAL_ENDPOINT = process.env.REACT_APP_PORTAL_API_ENDPOINT

export const dashboardServices = {
  getDashboardData: (queries) => {
    const formatDate = () => {
      if (!queries?.date) return ''
      return 'date_filter=' + queries.date
    }

    const date = formatDate()
    const enableQuery = date ? '?' : ''

    return fetch(
      `${PORTAL_ENDPOINT}${DASHBOARD_DATA}${enableQuery}${date}`,
      {
        headers: {
          authorization: 'Bearer ' + LocalStorageService.getAccessToken()
        },
      }
    )
  },
}
