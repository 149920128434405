import React, { useEffect, useState } from 'react'
import { BsUpload } from 'react-icons/bs'
import LogoAtom from '@assets/images/LogoAtomFull.svg'
import { handleDragDrop, validateFile } from '@utils/lib'
import { useTranslation } from '@hooks/useTranslation'
import { imageService } from '@services/image.service'

const UploadLogoField = ({ logoUrl, setPreviewData, setPreviewDataSubmit }) => {
  const [file, setFile] = useState(logoUrl)
  const [scale, setScale] = useState(1)
  const { translate } = useTranslation()

  const handleResetValue = async (value) => {
    if (!validateFile(value)) return
    try {
      const res = await imageService.postImage(value)
      setPreviewData((prev) => ({ ...prev, logo_url: res }))
      setPreviewDataSubmit((prev) => ({ ...prev, logo_url: res }))
    } catch (error) {
      setPreviewData((prev) => ({
        ...prev,
        logo_url: URL.createObjectURL(value),
      }))
      setPreviewDataSubmit((prev) => ({
        ...prev,
        logo_url: URL.createObjectURL(value),
      }))
    }

    if (file) URL.revokeObjectURL(file)
    setFile(URL.createObjectURL(value))
  }

  const handleFileChange = (e) => {
    handleResetValue(e.target.files[0])
    e.target.value = null
  }

  useEffect(() => {
    if (typeof logoUrl === 'string') setFile(logoUrl)
  }, [logoUrl])

  return (
    <div className='space-y-2 text-sm leading-5 text-black'>
      <div className='flex flex-col mb-2'>
        <p className='font-semibold'>Logo</p>
        <span className='text-grey-atom-grey font-medium leading-6 text-xs'>
          <p
            className='text-red-500 leading-6 text-xs inline-block'
            style={{
              marginRight: 2,
            }}
          >
            *
          </p>
          PNG, JPG, JPEG {translate('common:max_file_size')} 500KB
        </span>
      </div>

      <div
        className='flex items-center gap-4 relative cursor-pointer'
        {...handleDragDrop(handleResetValue, setScale)}
        style={{
          transform: `scale(${scale})`,
        }}
      >
        <img src={file || LogoAtom} alt='logo' className='h-10 w-auto' />
        <label className='upload-label relative'>
          <BsUpload color='#595959' size={18} />
        </label>
        <input
          type='file'
          accept='.png, .jpg, .jpeg'
          className='absolute inset-0 opacity-0 cursor-pointer'
          onChange={(e) => handleFileChange(e)}
        />
      </div>
    </div>
  )
}

export default UploadLogoField
