import { createAsyncThunk } from "@reduxjs/toolkit";
import { TxnService } from "@services/transaction.service";

export const getListTxnInstallment = createAsyncThunk(
  "portal/getListTxnInstallment",
  async (params, { dispatch, getState }) => {
    const response = await TxnService.getListTxnInstallment({
      ...params,
    });
    return response;
  }
);

export const getListTxnMoMo = createAsyncThunk(
  "transaction/getListTxnMoMo",
  async (data, { dispatch }) => {
    const _data = await TxnService.getListTxnMoMo(data);
    return _data;
  }
);

export const getListTxnEcom = createAsyncThunk(
  "transaction/getListTxnEcom",
  async (data, { dispatch }) => {
    const _data = await TxnService.getListTxnEcom(data);
    return _data;
  }
);

export const getListTxnQRCode = createAsyncThunk(
  "transaction/getListTxnQRCode",
  async (data, { dispatch }) => {
    const _data = await TxnService.getListTxnQR(data);
    return _data;
  }
);

export const getListTxnCard = createAsyncThunk(
  "transaction/getListTxnCard",
  async (data, { dispatch }) => {
    const _data = await TxnService.getListTxnCard(data);
    return _data;
  }
);
export const getListTxnVA = createAsyncThunk(
  "transaction/getListTxnVA",
  async (data, { dispatch }) => {
    const _data = await TxnService.getListTxnVA(data);
    return _data;
  }
);

export const getListTxnCash = createAsyncThunk(
  "transaction/getListTxnCash",
  async (data, { dispatch }) => {
    const _data = await TxnService.getListTxnCash(data);
    return _data;
  }
);

export const getListTxnSaving = createAsyncThunk(
  "transaction/getListTxnSaving",
  async (data, { dispatch }) => {
    const _data = await TxnService.getListTxnSaving(data);
    return _data;
  }
);

export const getListTxnLoan = createAsyncThunk(
  "transaction/getListTxnLoan",
  async (data, { dispatch }) => {
    const _data = await TxnService.getListTxnLoan(data);
    return _data;
  }
);