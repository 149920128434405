import React, { useRef, useState } from 'react'
import 'tippy.js/dist/tippy.css'
import ic_calendar from '@assets/images/txcard/ic-calendar.svg'
import '../../fields/style.css'
import { useTranslation } from '@hooks/useTranslation'
import arrowDownIcon from '@assets/images/icon/ic-arrow-down.svg'
import useClickOutside from '@hooks/useClickOutside'

const DateSelectField = ({ date, setDate, className }) => {
  const { translate } = useTranslation()
  const [showDate, setShowDate] = useState(false)
  const dateRef = useRef(null)
  const { nodeRef } = useClickOutside(() => {
    setShowDate(false)
  })

  const handleShowDate = () => {
    // if (dateRef.current) dateRef.current.showPicker()
    setShowDate(true)
  }

  return (
    <label
      ref={nodeRef}
      className='bg-white relative rounded-lg flex justify-between items-center cursor-pointer gap-1 w-fit'
      style={{
        border: showDate ? '1px solid #6666FF' : '1px solid #E8E8E8',
        padding: '10px 10px 10px 16px',
      }}
      onClick={handleShowDate}
    >
      <div className='flex-1 flex items-center gap-2'>
        <img src={ic_calendar} className='w-4' alt='' />
        <input
          ref={dateRef}
          type='date'
          className='picker'
          onClick={(e) => {
            // e.stopPropagation()
            setShowDate(false)
          }}
          value={date}
          onChange={(e) => {
            setDate(e.target.value)
            setShowDate(false)
          }}
          id='testdate'
        />
      </div>

      <img
        src={arrowDownIcon}
        alt='arrowDown'
        width={16}
        style={{
          transition: 'all 0.3s linear',
          transform: showDate && 'rotate(180deg)',
        }}
      />
    </label>
  )
}

export default DateSelectField
