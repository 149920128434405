import { QRCodeCanvas } from 'qrcode.react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import icCopy from '@assets/images/pay-by-link/copy.svg'
import icDownload from '@assets/images/pay-by-link/download.svg'
import icCheck from '@assets/images/pay-by-link/ic_check.svg'
import IconSuccess from '@assets/images/pay-by-link/icon-success.svg'
import ImgSuccess from '@assets/images/pay-by-link/img-success.png'
import CustomButton from '@components/buttons/CustomButton'
import { useTranslation } from '@hooks/useTranslation'
import { selectProfile } from '@reducers/user.reducer'
import { selectMerchant } from '@reducers/sass.reducer'

const PaymentLinkInfo = ({ paymentInfo }) => {
  const { translate } = useTranslation('pay_by_link')
  const { merchant_id } = useSelector(selectProfile)
  const { ref_id } = useSelector(selectMerchant)
  const [isCopy, setIsCopy] = useState(false)
  const paymentLink =
    process.env.REACT_APP_CHECKOUT_URL +
    `/?appId=${paymentInfo.app_id}&orderId=${paymentInfo.order_id}&amount=${paymentInfo.amount
    }&merchantId=${ref_id || merchant_id}&createdAt=${Date.now()}`

  const handleCopy = () => {
    setIsCopy(true)
    navigator.clipboard.writeText(paymentLink)
    setTimeout(() => {
      setIsCopy(false)
    }, 2000)
  }
  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById('qrCodeEl')
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    let aEl = document.createElement('a')
    aEl.href = qrCodeURL
    aEl.download = 'payment_qr.png'
    document.body.appendChild(aEl)
    aEl.click()
    document.body.removeChild(aEl)
  }

  return (
    <div
      className='rounded-xl flex bg-white overflow-hidden shadow-primary'
      style={{
        width: 1131,
      }}
    >
      <div
        className='flex flex-col items-center gap-6 justify-between'
        style={{ width: 432 }}
      >
        <div className='flex flex-col items-center gap-4 pt-8'>
          <img src={IconSuccess} alt='icon-success' width={60} height={60} />
          <p className='font-bold text-2xl leading-8 text-blackgray'>
            {translate('link_created')}
          </p>
        </div>
        <img src={ImgSuccess} alt='img-success' className='w-full' />
      </div>

      <div className='flex-1 bg-gradient p-10 space-y-8'>
        <div className='rounded-2xl p-6 w-full flex flex-col gap-y-6 bg-sidebar-hover'>
          <p className='font-semibold text-base text-white'>
            {translate('share_payment_link')}
          </p>
          <div className='flex items-center gap-x-2 p-4 rounded-lg bg-white'>
            <a
              href={paymentLink}
              className='font-semibold text-base truncate hover:opacity-75'
              style={{ maxWidth: 427 }}
            >
              {paymentLink}
            </a>
            <CustomButton
              title={translate('common:copy')}
              leftIcon={
                <img
                  src={isCopy ? icCheck : icCopy}
                  className='h-4 w-4'
                  alt='copy'
                />
              }
              onClick={handleCopy}
            />
          </div>
        </div>

        <div className='flex gap-x-6'>
          <QRCodeCanvas
            id='qrCodeEl'
            value={paymentLink}
            size={250}
            className='p-4 bg-white rounded-lg'
          />
          <div className='flex flex-col justify-between text-white'>
            <div className='flex flex-col gap-y-4'>
              <p className='font-bold text-xl leading-7'>
                {translate('share_payment_link_title')}
              </p>
              <p
                className='font-medium text-base leading-6'
                style={{ color: '#F1F1F1' }}
              >
                {translate('share_payment_link_desc')}
              </p>
            </div>
            <button
              className='rounded-lg flex items-center gap-2 w-fit'
              style={{
                padding: '10px 12px',
                border: 'solid 1px #F0F0F0',
              }}
              onClick={downloadQRCode}
            >
              <img src={icDownload} className='h-4 w-4' alt='download' />
              <p className='text-sm leading-5 font-semibold'>
                {translate('save_qr_code')}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentLinkInfo
