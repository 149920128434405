import { saleClient } from "./client_axios";
import { NOTIFICATION } from "@constants/endpoint";

export const notificationServices = {
  getListNoti: (params) => {
    return saleClient.get(NOTIFICATION, { params });
  },

  createNoti: (params) => {
    return saleClient.post(NOTIFICATION, params);
  },

  updateNoti: (params) => {
    return saleClient.put(NOTIFICATION, params);
  },
};
