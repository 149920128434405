import axiosClient from "./client_axios";
import { EMPLOYEE } from "@constants/endpoint";

export const staffServices = {
  getStaffById: (id) => {
    return axiosClient.get(`${EMPLOYEE}?id=${id}`);
  },

  createStaff: (params) => {
    return axiosClient.post(EMPLOYEE, params);
  },

  updateStaff: (params) => {
    return axiosClient.put(EMPLOYEE, params);
  },

  deleteStaffById: (params) => {
    return axiosClient.delete(EMPLOYEE, params);
  },
};
