import React from 'react'
import ModalWrap from '@components/modals/ModalWrap'
import { useTranslation } from '@hooks/useTranslation'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import CustomButton from '@components/buttons/CustomButton'

const ModalLockAccount = ({ isOpen, onClose }) => {
  const { translate } = useTranslation('login')

  const handleNavigateMail = () => {
    window.open('mailto:bvbank247@bvbank.net.vn', '_blank')
  }

  return (
    <ModalWrap isOpen={isOpen} onClickBackdrop={onClose}>
      <div
        style={{ width: 510 }}
        className='flex flex-col items-center p-10 bg-white rounded-2xl shadow-primary'
      >
        <svg
          width='61'
          height='60'
          viewBox='0 0 61 60'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M30.5 58.5C46.2401 58.5 59 45.7401 59 30C59 14.2599 46.2401 1.5 30.5 1.5C14.7599 1.5 2 14.2599 2 30C2 45.7401 14.7599 58.5 30.5 58.5Z'
            fill='#ED1C24'
          />
          <path
            d='M39.3112 27.8326H38.6348V22.9663C38.6348 18.5737 35.0611 15 30.6685 15C26.2759 15 22.7022 18.5737 22.7022 22.9663V27.8326H22.0258C20.0775 27.8326 18.5 29.4135 18.5 31.3584V41.4708C18.5 43.4191 20.0775 45 22.0258 45H39.3112C41.2595 45 42.8371 43.4191 42.8371 41.4708V31.3584C42.8371 29.4135 41.2595 27.8326 39.3112 27.8326ZM32.2798 37.0146V39.8899C32.2798 40.7764 31.5618 41.5011 30.6685 41.5011C29.7753 41.5011 29.0573 40.7764 29.0573 39.8899V37.0146C28.1775 36.4753 27.5944 35.5045 27.5944 34.4022C27.5944 32.7067 28.973 31.3315 30.6685 31.3315C32.364 31.3315 33.7427 32.7067 33.7427 34.4022C33.7427 35.5045 33.1595 36.4753 32.2798 37.0146ZM35.1073 27.8326H26.2298V22.9663C26.2298 20.5187 28.2209 18.5276 30.6685 18.5276C33.1161 18.5276 35.1073 20.5187 35.1073 22.9663V27.8326Z'
            fill='white'
          />
        </svg>

        <p className='text-2xl leading-7 font-bold mt-6 text-primary-text'>
          {translate('lock_account')}
        </p>

        <div className='text-center mt-6 text-sm leading-5 text-secondary-text space-y-6'>
          <p>{translate('input_over_limit')}</p>

          <p>
            {translate('try_again')}{' '}
            <span className='font-semibold text-primary-text'>
              {translate('twenty_four_hours')}
            </span>{' '}
            {translate('or_using_feature')}{' '}
            <Link
              to='/forgot-password'
              className='font-semibold text-blue-2 underline'
            >
              {translate('forgot_password')}
            </Link>{' '}
            {translate('to_reset_pwd')}
          </p>
        </div>

        <div className='mt-6 flex w-full items-center gap-4'>
          <CustomButton
            className='w-full'
            style={{ maxHeight: 45 }}
            title={translate('close')}
            outline
            onClick={onClose}
          />

          {/* <a href='mailto:bvbank247@bvbank.net.vn' className='flex-1 block'> */}
          <CustomButton
            className='w-full'
            title={translate('contact')}
            onClick={handleNavigateMail}
          />
          {/* </a> */}
        </div>
      </div>
    </ModalWrap>
  )
}

export default ModalLockAccount
