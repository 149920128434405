import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "tippy.js/dist/tippy.css";
import { getBankHandle } from "@actions/setting-tx.actions";
import IcClose from "@assets/images/icon/ic_close.svg";
import SearchField from "./SearchField";
import SelectFieldContent from "./select/SelectFieldContent";
import SelectFieldItem from "./select/SelectFieldItem";
import SelectFieldWrapper from "./select/SelectFieldWrapper";
import "./style.css";
import MultipleSelectItem from "./select-mutilple/MultipleSelectItem";
import MultipleSelectWrapper from "./select-mutilple/MultipleSelectWrapper";

const SelectBankReleaseField = (props) => {
  const { register, name, setValue, errors, extra, state, isRequired, initValue } = props;

  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [listTemp, setListTemp] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSelectBox = (data) => {
    if (checkElement(data)) {
      deleteElement(data);
    } else {
      addElement(data);
    }
    setValue(name, data);
    setShowContent(false);
  };

  // check an element existing in array
  const checkElement = (element) => {
    return listTemp.find((item) => item === element);
  };

  // Adding an element to the array
  const addElement = (element) => {
    return setListTemp((prev) => [...prev, element]);
  };

  // Deleting an element from the array
  const deleteElement = (element) => {
    return setListTemp((prev) => prev.filter((item) => item !== element));
  };

  // Function to filter the array based on the 'name' and 'display_name' property
  function filterByName() {
    if (searchValue.trim().length === 0) return list;

    const normalizedFilter = searchValue.toLowerCase().trim();
    return list.filter((item) => item.toLowerCase().includes(normalizedFilter));
  }

  const CurrentItemComp = () => {
    return (
      <MultipleSelectWrapper>
        {listTemp?.map((item, index) => (
          <MultipleSelectItem key={index} onClick={() => deleteElement(item)}>
            {item}
          </MultipleSelectItem>
        ))}
      </MultipleSelectWrapper>
    );
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await dispatch(getBankHandle());
        if (res.meta.requestStatus === "fulfilled") {
          const data = res?.payload?.data;
          setList(data);
          // setListTemp(data.find((item) => item?.id === initValue));
        }
      } catch (err) { }
    };
    getData();
  }, [dispatch, initValue]);

  return (
    <div>
      <SelectFieldWrapper
        {...props}
        className={"py-10px px-4"}
        showContent={showContent}
        setShowContent={setShowContent}
        label={"Ngân hàng phát hành"}
        initialText="Chọn loại ngân hàng"
        currentValue={listTemp?.length > 0 ? listTemp : null}
        currentItemComp={<CurrentItemComp />}
      >
        <SearchField
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          id="search-store-input"
          extraClass="m-4 mb-1"
        />
        <SelectFieldContent>
          {filterByName().map((item, index) => {
            const active = listTemp.find((temp) => temp === item);
            return (
              <SelectFieldItem key={`${item}-${index}`} onClick={() => handleSelectBox(item)} active={active}>
                {item}
              </SelectFieldItem>
            );
          })}
        </SelectFieldContent>
      </SelectFieldWrapper>
    </div>
  );
};

export default SelectBankReleaseField;
