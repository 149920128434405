import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { TbFilterDollar } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import { getListTxnCard } from '@actions'
import SectionTitle from '@components/section-title'
import Datatable, {
  ReceiptCell,
  StatusCell,
} from '@components/tsdatatable/datatables'
import { selectTxnCard } from '@reducers/transaction.reducer'
import {
  formatBankAccount,
  formatCurrency,
  formatDateFromMilliseconds,
  formatDateTxn,
} from '@utils/lib'

import CustomButton from '@components/buttons/CustomButton'
import Filters from '@components/filters/Filters'
import { useTranslation } from '@hooks/useTranslation'
import useWindowSize from '@hooks/useWindowSize'
import useSearchParams from '@pages/sale-management/customer/useSearchParams'

const DatatableComponent = ({
  customData,
  filters,
  numOfPage,
  total_amount,
  setFilters,
}) => {
  const { translate } = useTranslation('txn')

  const columns = React.useMemo(
    () => [
      {
        Header: translate('table_no'),
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: translate('table_time'),
        accessor: 'created_at',
        Cell: ({ value, row }) => <p>{formatDateTxn(value)}</p>,
      },
      {
        Header: translate('table_store'),
        accessor: 'store_name',
        disableSortBy: true,
      },
      {
        Header: translate('table_invoice_id'),
        accessor: 'bill_id',
        Cell: ({ value, row }) => <span>{value || '0000'}</span>,
        disableSortBy: true,
      },
      {
        Header: translate('table_card'),
        accessor: 'card_id',
        Cell: ({ value, row }) => (
          <span>{value ? formatBankAccount(value) : '***8673'}</span>
        ),
        disableSortBy: true,
      },
      {
        Header: translate('table_amount'),
        accessor: 'amount',
        Cell: ({ value, row }) => <span>{formatCurrency(value)}</span>,
      },
      {
        Header: translate('table_credit'),
        accessor: 'credit',
        Cell: ({ value, row }) => <span>{value}</span>,
        disableSortBy: true,
      },
      {
        Header: translate('table_settlement_time'),
        accessor: 'settlement_time',
        Cell: ({ value, row }) => <p>{formatDateFromMilliseconds(value, true)}</p>,
      },
      {
        Header: translate('table_status'),
        accessor: 'status',
        Cell: ({ value, row }) => <StatusCell data={row} />,
        disableSortBy: true,
      },
      // {
      //   Header: translate("table_bank"),
      //   accessor: "bank_code",
      //   Cell: ({ value, row }) => <span>{value || "MB"}</span>,
      // },
      {
        Header: translate('table_print_invoice'),
        accessor: 'bill_url',
        Cell: ({ value, row }) => <ReceiptCell data={row} />,
        disableSortBy: true,
      },
    ],
    []
  )

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
      total_amount={total_amount}
    />
  )
}

const TransactionCard = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslation('txn')
  const data = useSelector(selectTxnCard)
  const [showFilter, setShowFilter] = useState(false)
  const [numOfPage, setnumOfPage] = useState('1')
  const { isMd } = useWindowSize()

  // GMT +7
  const currentTime = new Date()
  const [y, m, d] = [
    currentTime.getFullYear(),
    currentTime.getMonth(),
    currentTime.getDate(),
  ]
  const startTime = new Date(y, m, d)
  const endTime = new Date(y, m, d, 23, 59, 59)

  const [tempFilters, setTempFilters] = useState({
    page: 1,
    page_size: 10,
    status: 'all',
    start_time: Math.floor(startTime.getTime() / 1000),
    end_time: Math.floor(endTime.getTime() / 1000),
    bill_id: '',
    customer_id: '',
  })

  const { updatePagination, filtersParams } = useSearchParams();

  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
    status: 'all',
    start_time: Math.floor(startTime.getTime() / 1000),
    end_time: Math.floor(endTime.getTime() / 1000),
    bill_id: '',
    customer_id: '',
  });

  useEffect(() => {
    updatePagination(new URLSearchParams(filters));
  }, [dispatch, filters, updatePagination]);

  const customData = useMemo(() => {
    if (!data?.data) return []
    setnumOfPage(data?.total_page)
    return data?.data
  }, [data?.data, data?.total_page])

  const formatData = (ls) => {
    return ls
      ? ls.map((e) => {
        return {
          ...e,
          request_amount: formatCurrency(e.request_amount),
          original_transaction_date: moment(
            new Date(e.original_transaction_date * 1000)
          ).format('HH:mm:ss DD-MM-YYYY'),
        }
      })
      : []
  }

  useEffect(() => {
    dispatch(getListTxnCard(filters))
  }, [dispatch, filters])

  return (
    <div className='relative'>
      <div className='flex items-center justify-between'>
        <SectionTitle
          title={translate('report')}
          subtitle={translate('txn_card')}
        />
        <CustomButton
          id='button-filter'
          onClick={() => setShowFilter(!showFilter)}
          className='block md:hidden'
          outline
          leftIcon={<TbFilterDollar size={16} />}
        />
      </div>
      <div
        id="filter"
        style={{ border: "1px solid #F5F7FA", top: !isMd && 32, zIndex: showFilter || isMd ? 40 : -10 }}
        className="absolute right-0 md:relative flex gap-4 md:gap-0 flex-col md:flex-row md:justify-between items-end bg-white rounded-xl mt-6 p-6 shadow-primary"
      >
        <Filters
          filters={tempFilters}
          setFilters={setTempFilters}
          statusOptions={['success', 'fail']}
        />
        <div className='grid grid-cols-2 w-full md:w-auto gap-4'>
          <CustomButton
            filters={filters}
            setFilters={(params) => {
              setFilters(params)
              setTempFilters(params)
            }}
            type='reset'
            title={translate('flt_reset')}
          />
          <CustomButton
            filters={tempFilters}
            setFilters={setFilters}
            type='apply'
            title={translate('flt_apply')}
          />
        </div>
      </div>

      <div className='mt-8'>
        <DatatableComponent
          customData={formatData(customData)}
          filters={filters}
          setFilters={setFilters}
          numOfPage={numOfPage}
          total_amount={data.total_amount}
        />
      </div>
    </div>
  )
}
export default TransactionCard
