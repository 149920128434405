import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@hooks/useTranslation';
import { selectBusiness } from '@reducers/business.reducer';
import { formatCurrency, formatPaymentMethod } from '@utils/lib';
import { QrComponent } from '../../order/Common';
import { STATUS_INVOICE } from '@constants/sale-management/order';

const Row = ({ col1, col2, col3, col4, col5, borderBottom }) => {
  return (
    <div
      className='w-full flex justify-between font-medium'
      style={{
        borderBottom: borderBottom && '1px solid #8C8C8C',
        lineHeight: '14px',
        marginTop: 10,
        paddingBottom: 10,
      }}
    >
      <p style={{ width: '9%' }} className='text-left'>
        {col1}
      </p>
      <p style={{ width: '22.7%' }} className='text-center'>
        {col2}
      </p>
      <p style={{ width: '15%' }} className='text-center'>
        {col3}
      </p>
      <p style={{ width: '22.7%' }} className='text-center'>
        {col4}
      </p>
      <p style={{ width: '22.7%' }} className='text-center'>
        {col5}
      </p>
    </div>
  );
};

const PrintInvoice = ({ info, products, qr, isPayByLink, loadingQr }) => {
  const { translate } = useTranslation('sale_order');

  const { address, trademark } = useSelector(selectBusiness);

  const { PAID } = STATUS_INVOICE
  const [isPaid, date] = useMemo(() => [info?.status === PAID, moment(info?.updated_at * 1000).format('DD/MM/YYYY HH:mm').split(' ')], [info])

  return (
    <div
      style={{
        fontSize: 10,
        width: 284,
        height: 'min-content',
      }}
      id='print-content'
      className='bg-white rounded-xl flex flex-col items-center overflow-hidden p-4 shadow-primary'
    >
      <h1 className='text-sm leading-5 font-bold'>{trademark}</h1>
      <p style={{ lineHeight: '14px' }} className='mt-2 font-medium'>
        {translate('business_info_address')}: {address}
      </p>

      <div className='flex flex-col items-center w-full my-4'>
        <h2 className='text-base leading-6 font-bold uppercase'>
          {translate('invoice')}
        </h2>
        <div
          style={{ fontSize: 10, lineHeight: '14px' }}
          className='mt-4 w-full flex justify-between'
        >
          <div className='flex gap-2'>
            <p className='font-semibold'>
              {translate('invoice_id')}:
            </p>
            <p className='font-medium'>{info?.order_code}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>
              {translate('date')}:
            </p>
            <p className='font-medium'>{date[0]}</p>
          </div>
        </div>
        <div
          style={{ fontSize: 10, lineHeight: '14px' }}
          className='mt-3 w-full flex justify-between'
        >
          <div className='flex gap-2'>
            <p className='font-semibold'>
              {translate('table')}:
            </p>
            <p className='font-medium'>{info?.table_name}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>
              {translate('hour_date')}:
            </p>
            <p className='font-medium'>{date[1]}</p>
          </div>
        </div>
        <div className='w-full flex justify-between mt-3'>
          <p className='font-semibold'>
            {translate('invoice_status')}:
          </p>
          <p className='font-medium'>
            {translate(isPaid ? 'paid' : 'not_payment')}
          </p>
        </div>
        {isPaid && (
          <div
            className='w-full flex justify-between mt-3 pb-4'
            style={{ borderBottom: '1px solid #8C8C8C' }}
          >
            <p className='font-semibold'>{translate('common:payment-method')}:</p>
            <p className='font-medium'>
              {translate(formatPaymentMethod(info?.payment_method))}
            </p>
          </div>
        )}

        <Row
          col1={translate('common:numerical_order')}
          col2={translate('product_name')}
          col3={translate('quantity')}
          col4={translate('unit_price')}
          col5={translate('subtotal')}
          borderBottom
        />
        {[].concat(products).map((item, index) => (
          <div style={{ marginTop: 6 }} className='w-full' key={index}>
            <Row
              col1={index + 1}
              col2={item?.name?.toUpperCase()}
              col3={item?.amount + ' ' + translate('item')}
              col4={formatCurrency(item?.price).replace("\u00A0", "\n")}
              col5={formatCurrency(item?.amount * item?.price).replace("\u00A0", "\n")}
            />
          </div>
        ))}

        <div
          className='w-full pb-4 flex items-center justify-between'
          style={{ marginTop: 6, borderBottom: '1px dashed #D9D9D9' }}
        >
          <p className='font-semibold'>
            {translate('grand_total')}:
          </p>
          <p className='text-sm leading-5 font-semibold'>
            {formatCurrency(info?.total_price)}
          </p>
        </div>

        <div className='w-full mt-4 flex justify-between text-lg leading-7'>
          <p className='font-bold'>
            {translate('total_amount')}:
          </p>
          <p className='font-bold'>
            {formatCurrency(info?.total_price)}
          </p>
        </div>
      </div>

      <div className='flex flex-col items-center w-full gap-4'>
        <div className='text-xs leading-4 font-semibold italic text-center'>
          {trademark} {translate('thanks')}!
        </div>

        <span
          className='border border-dashed h-px w-full'
          style={{ borderColor: '#D9D9D9' }}
        />

        {!isPaid &&
          <div className='w-full flex flex-col items-center gap-4'>
            <h1 className='text-base leading-6 font-bold text-center'>
              {/* Payment method != pay by link */}
              {!isPayByLink
                ? translate('scan_qr')
                : translate('scan_qr_link')}
            </h1>

            <QrComponent data={qr} isPayByLink={isPayByLink} loading={loadingQr} />
          </div>
        }
      </div>
    </div>
  );
};

export default PrintInvoice;
