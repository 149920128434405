import { createSlice } from "@reduxjs/toolkit";
import { getListTracking, getTrackingById } from "@actions";

const initialState = {
  trackingItem: null,
  listTracking: [],
  isLoading: false,
};

const trackingSlice = createSlice({
  name: "trackingSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getListTracking.fulfilled, (state, action) => {
        state.listTracking = action.payload;
        state.isLoading = false;
      })
      .addCase(getListTracking.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListTracking.rejected, (state, action) => {
        state.listTracking = [];
        state.isLoading = false;
      })
      .addCase(getTrackingById.fulfilled, (state, action) => {
        state.trackingItem = action.payload;
        state.isLoading = false;
      })
      .addCase(getTrackingById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTrackingById.rejected, (state, action) => {
        state.trackingItem = null;
        state.isLoading = false;
      }),
});
export default trackingSlice.reducer;

export const selectListTracking = (state) => state.tracking.listTracking;
export const selectIsLoading = (state) => state.tracking.isLoading;
export const selectTrackingItem = (state) => state.tracking.trackingItem;
