import { saleClient } from "./client_axios";
import { CATEGORY_MANAGER } from "@constants/endpoint";

export const categoryServices = {
  getListCategory: (params) => {
    return saleClient.get(CATEGORY_MANAGER, { params });
  },
  createCategory: (params) => {
    return saleClient.post(CATEGORY_MANAGER, params);
  },
  getCategoryById: (id) => {
    return saleClient.get(`${CATEGORY_MANAGER}/${id}`);
  },
  deleteCategory: (id) => {
    return saleClient.delete(`${CATEGORY_MANAGER}/${id}`);
  },
};
