import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { theme } from "configTheme";

const SelectCheckbox = ({ label, value, handleClick, isActive = false }) => {
  return (
    <div onClick={handleClick} className="flex items-center gap-2 cursor-pointer">
      <div
        style={{
          background: isActive && theme.sidebar,
          border: !isActive && "1px solid #E8E8E8",
          color: isActive ? "white" : "transparent",
        }}
        className="rounded w-5 h-5 flex items-center justify-center transition-all"
      >
        <AiOutlineCheck color="curentColor" size={13} />
      </div>
      <p className="">{label}</p>
    </div>
  );
};
export default SelectCheckbox;
