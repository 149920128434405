import { createSlice } from "@reduxjs/toolkit"
import { getListCustomer } from "@actions/customer.actions"

const initialState = {
    listCustomer: [],
    isLoading: false,
}

const customerSlice = createSlice({
    name: 'customerSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getListCustomer.fulfilled, (state, action) => {
                state.listCustomer = action.payload;
            })
            .addCase(getListCustomer.rejected, (state, action) => {
                state.listCustomer = [];
            })
    }
})

export const selectListCustomer = state => state.customer.listCustomer;
export const selectIsLoading = state => state.customer.isLoading;

export default customerSlice.reducer;