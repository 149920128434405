import React from 'react'
import { useTranslation } from '@hooks/useTranslation'
import BgImg from '@assets/images/coming-soon/bg.png'
import { theme } from 'configTheme'

const ComingSoon = () => {
  const { translate } = useTranslation('common')

  return (
    <div
      className='flex items-center rounded-3xl border border-grey-5 shadow-primary mx-auto'
      style={{
        backgroundImage: `url(${BgImg})`,
        backgroundSize: '100% 100%',
        width: '100%',
        maxWidth: 900,
        height: 360,
        paddingLeft: 100
      }}
    >
      <div className='space-y-6'>
        <img
          className='h-12 w-auto'
          src={theme.loginLogo}
          alt='coming-soon'
        />

        <h1 style={{ fontSize: 60, lineHeight: '72px' }} className='font-bold'>
          {translate('coming_soon')}...
        </h1>
      </div>
    </div>
  )
}

export default ComingSoon
