import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";

import { useTranslation } from "@hooks/useTranslation";
import FullSizeImage from "./FullSizeImage";
import Session from "../business/profile/Session";

const ModalDetail = ({ title = "Chi tiết", setShowModal, selectedItem }) => {
  const [imgFullSize, setImgFullSize] = useState(null);
  const { translate } = useTranslation();

  return (
    <>
      {imgFullSize && (
        <FullSizeImage src={imgFullSize} setImgFullSize={setImgFullSize} />
      )}

      <div
        style={{ zIndex: 60 }}
        className="fixed inset-0 bg-blur flex justify-center items-center"
      >
        <div
          style={{ width: 800 }}
          className="relative bg-white p-12 rounded-2xl flex flex-col gap-6 items-center"
        >
          {/* Close Icon */}
          <IoCloseSharp
            size={24}
            onClick={() => setShowModal(false)}
            style={{
              position: "absolute",
              top: 16,
              right: 16,
              cursor: "pointer",
            }}
          />

          {/* Title */}
          <h1 className="text-dark-primary text-2xl leading-8 font-bold">
            {title}
          </h1>

          {/* Fields */}
          <div className="w-full flex flex-col gap-6">
            <div className="w-full flex flex-col md:flex-row gap-6">
              <Session
                title={translate("feedback_create_store_field")}
                children={selectedItem.store_name}
              />
              {selectedItem.content && (
                <Session
                  title={translate("feedback_create_content_field")}
                  children={translate(selectedItem.content)}
                />
              )}
            </div>
            <div className="w-full flex flex-col md:flex-row gap-6">
              <Session
                title={translate("feedback_create_description_field")}
                children={selectedItem.description}
              />
              {selectedItem.type && (
                <Session
                  title={translate("feedback_create_edc_field")}
                  children={selectedItem.serial_number || ""}
                />
              )}
            </div>
            <div className="space-y-2">
              <div className="text-secondary-text leading-5 font-semibold">
                {translate("feedback_create_image_field")}
              </div>
              <div
                style={{ maxHeight: 140 }}
                className="flex flex-wrap gap-3 p-3 rounded-xl border border-gray-primary overflow-y-scroll custom-scrollbar "
              >
                {selectedItem.images.map((src, index) => (
                  <div key={index}>
                    <div
                      onClick={() => setImgFullSize(src)}
                      style={{ width: 116, height: 116 }}
                      className="flex-shrink-0 hover:opacity-75 transition flex items-center justify-center rounded overflow-hidden border border-gray-primary cursor-pointer"
                    >
                      <img
                        src={src}
                        alt="err img"
                        className=" object-contain "
                        draggable={false}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalDetail;
