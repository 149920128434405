import React from "react";
import IcClose from "@assets/images/icon/ic_close.svg";

const MultipleSelectItem = ({ children, onClick }) => {
  return (
    <div
      style={{ padding: 6, gap: 6 }}
      onClick={(e) => e.stopPropagation()}
      className="rounded-lg flex items-center text-14px border border-gray-primary text-dark-primary leading-5 font-normal"
    >
      {children}
      <img src={IcClose} alt="IcClose" style={{ width: 16, cursor: "pointer" }} onClick={onClick} />
    </div>
  );
};

export default MultipleSelectItem;
