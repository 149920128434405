import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cart from '@assets/images/product/cart.svg';
import success from '@assets/images/product/success.gif';
import CustomButton from '@components/buttons/CustomButton';
import SectionTitle from '@components/section-title';
import { useTranslation } from '@hooks/useTranslation';
import { confirmOrder, getProducts, updateOrder } from '@services/table.service';
import { formatCurrency } from '@utils/lib';
import { Popup, Search } from '../../order/Common';
import OrderDetail from './OrderDetail';
import ProductItem from './ProductItem';
import { getOrderById } from '@actions';
import { useDispatch } from 'react-redux';
import { CustomToastMessage } from '@utils/customToastMsg';
import { history } from '@helpers';
import { STATUS_ORDER } from '@constants/sale-management/order';

const UpdateOrder = () => {
    const { order_id } = useParams();
    const dispatch = useDispatch()
    const { translate } = useTranslation('sale_order');

    const { SELECTING, COMPLETED } = STATUS_ORDER

    const [cateProds, setCateProds] = useState([]);
    const [currentProds, setCurrentProds] = useState([]);
    const [newProds, setNewProds] = useState({});

    const [orderInfo, setOrderInfo] = useState();
    const [total, setTotal] = useState(0);
    const [customerName, setCustomerName] = useState();

    const [showCart, setShowCart] = useState(false);
    const [showPopupSuccess, setShowPopupSuccess] = useState(false);
    const [inputSearch, setInputSearch] = useState('');
    const [resultSearch, setResultSearch] = useState([]);

    const [loading, setLoading] = useState(false)

    const fetchProduct = async () => {
        const res = await getProducts();
        if (res) {
            const data = res.filter((item) => item.status === 0);
            setCateProds(data);
        }
    };

    const handleSearch = () => {
        if (inputSearch !== '') {
            const result = cateProds.filter(
                (item) =>
                    item.name.toUpperCase().search(inputSearch.toUpperCase()) !== -1
            );
            setResultSearch(result);
        } else {
            setResultSearch(cateProds);
        }
    };

    useEffect(() => {
        if (cateProds) { handleSearch() }
    }, [cateProds, inputSearch]);

    const getDetailOrder = async () => {
        const res = await dispatch(getOrderById(order_id));
        const data = res?.payload?.data
        if (data) {
            if (data?.order_info?.status !== COMPLETED) {
                setOrderInfo(data?.order_info)
                setCurrentProds(data?.products)
                setCustomerName(data?.order_info?.name)
                const total = data?.products?.reduce((acc, item) => acc += item?.amount * item?.price, 0)
                setTotal(total)
            } else history.push('/sale-order?page=1&page_size=10')
        }
    }

    const handleConfirmOrder = async () => {
        setLoading(true)
        if (orderInfo?.status === SELECTING) await confirmOrder({ order_id })
        const res = await updateOrder({ order_id, products: Object.values(newProds) })
        if (res?.code === 201) {
            setNewProds({})
            setShowPopupSuccess(true);
            getDetailOrder()
            setTimeout(() => setShowPopupSuccess && setShowPopupSuccess(false), 2000);
        } else CustomToastMessage.error('Thêm món thất bại')
        setLoading(false)
    };

    useEffect(() => {
        fetchProduct()
        order_id && getDetailOrder()
    }, [order_id])

    return (
        <div className='w-full h-full relative'>
            <div
                className='flex flex-col gap-10 relative h-full overflow-y-scroll'
                style={{ height: 'calc(100% - 102px)' }}
            >
                <div className='pb-1 flex flex-col gap-6' style={{ backgroundColor: '#FAFAFA' }}>
                    <SectionTitle
                        title={translate('sale_management')}
                        subtitle={translate('sale_product:products')}
                    />
                    <Search
                        content={translate('input_search_dish')}
                        setInputSearch={setInputSearch}
                        tableName={orderInfo?.table_name}
                    />
                </div>
                <div className='w-full'>
                    {resultSearch.length > 0 ? (
                        <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  gap-6'>
                            {resultSearch?.map((item, index) => (
                                item.status === 0 ? <ProductItem
                                    key={index}
                                    item={item}
                                    newProds={newProds}
                                    setNewProds={setNewProds}
                                /> : <></>
                            ))}
                        </div>
                    ) : (
                        <p className='w-full text-center'>
                            {translate('empty_product')}
                        </p>
                    )}
                </div>
            </div>
            <div
                className='absolute left-0  w-full bottom-0 pb-4'
                style={{ backgroundColor: '#FAFAFA' }}
            >
                <div
                    className='flex flex-wrap gap-3 md:gap-0 justify-between items-center bg-white shadow-primary'
                    style={{
                        padding: '10px 16px 10px 16px',
                    }}
                >
                    <div
                        className='flex gap-4 items-center cursor-pointer'
                        onClick={() => setShowCart(true)}
                    >
                        <button className='p-3 rounded bg-gradient'>
                            <img src={cart} alt='' />
                        </button>
                        <div>
                            <p className='text-base font-medium'>
                                {translate('order_detail')}
                            </p>
                            <p
                                className='text-xl font-semibold'
                                style={{ marginTop: '-2px' }}
                            >
                                {translate('added')}{' '}
                                {orderInfo?.product_amount}{' '}
                                {translate('item')}
                            </p>
                        </div>
                    </div>
                    <div className='flex gap-3 h-[48px]'>
                        {total > 0 && (
                            <CustomButton
                                title={formatCurrency(total)}
                                onClick={() => setShowCart(true)}
                                outline
                                style={{ minWidth: 143 }}
                            />
                        )}
                        <CustomButton
                            title={translate(
                                Object.keys(newProds).length === 0 && currentProds.length > 0
                                    ? 'payment'
                                    : 'confirm_order'
                            )}
                            onClick={() =>
                                Object.keys(newProds).length > 0
                                    ? handleConfirmOrder()
                                    : setShowCart(true)
                            }
                            disabled={(Object.keys(newProds).length === 0 && currentProds.length === 0) || loading}
                            style={{ width: 195 }}
                        />
                    </div>
                </div>
            </div>
            {showCart && (
                <OrderDetail
                    onClose={() => setShowCart(false)}
                    pickedItem={currentProds}
                    total={total}
                    tableName={orderInfo?.table_name}
                    customerName={customerName}
                    setCustomerName={setCustomerName}
                />
            )}
            <Popup
                isOpen={showPopupSuccess}
                icon={success}
                showBtn={false}
                content={translate('confirm_order_success')}
                onClose={() => setShowPopupSuccess(false)}
                handleClickBtn={() => setShowPopupSuccess(false)}
            />
        </div>
    );
};
export default UpdateOrder;
