export const STAFF_FORM = {
  EMAIL: "email",
  FULL_NAME: "full_name",
  PHONE: "phone",
  PASSWORD: "password",
  STORE_ID: "store_id",
  STAFF_ID: "staff_id",
};

export const UPDATE_STAFF_FORM = {
  EMAIL: "email",
  FULL_NAME: "full_name",
  PHONE: "phone",
  OLD_PASSWORD: "old_password",
  PASSWORD: "password",
  STORE_ID: "store_id",
  STAFF_ID: "staff_id",
};

export const INITIAL_EMPLOYEE = {
  id: "",
  email: "",
  full_name: "",
  phone: "",
  password: "",
  old_password: "",
  store_id: "",
  staff_id: "",
}