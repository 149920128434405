import { useEffect, useState } from "react";
import { generateQR, invoiceServices } from "@services";
import { STATUS_INVOICE } from "@constants/sale-management/order";

const { UNPAID } = STATUS_INVOICE

export const useOrder = ({ orderId, hasQR }) => {
    const [isFetched, setIsFetched] = useState(false)
    const [info, setInfo] = useState({})
    const [products, setProducts] = useState([])
    const [qr, setQr] = useState(null)

    const genOrderDetail = async () => {
        let newInfo = {}
        let newProducts = []
        try {
            const res = await invoiceServices.getDetailInvoice(orderId);
            newInfo = res.data.invoice_data
            newProducts = res.data.products_data
            if (hasQR && newInfo?.order_id) {
                if (newInfo?.status === UNPAID) {
                    try {
                        const resQR = await generateQR(newInfo?.invoice_id)
                        setQr(resQR?.QR)
                    } catch (error) { console.log(error) }
                }
            }
        } catch (error) { console.log(error) }
        setInfo(newInfo)
        setProducts(newProducts)
        setIsFetched(true)
    };

    useEffect(() => {
        orderId && genOrderDetail()
    }, [orderId])

    return { isFetched, info, products, qr }
}