import portalClient from './client_axios'
import {
  TXN_INSTALLMENT,
  TRANSACTION,
  TXN_CARD,
  TXN_CASH,
  TXN_ECOM,
  TXN_MOMO,
  TXN_QR,
  TXN_VA,
  TXN_SAVING,
  TXN_LOAN
} from '@constants/endpoint'

export const TxnService = {
  getListTxnCard: (params) => {
    return portalClient.get(TXN_CARD, { params })
  },

  getListTxnMoMo: (params) => {
    return portalClient.get(TXN_MOMO, { params })
  },

  getListTxnEcom: (params) => {
    return portalClient.get(TXN_ECOM, { params })
  },

  getListTxnQR: (params) => {
    return portalClient.get(TXN_QR, { params })
  },
  getListTxnVA: (params) => {
    return portalClient.get(TXN_VA, { params })
  },
  getListTxnCash: (params) => {
    return portalClient.get(TXN_CASH, { params })
  },
  getListTxnInstallment: (params) => {
    return portalClient.get(TXN_INSTALLMENT, {
      params,
    })
  },
  getListTxnSaving: (params) => {
    return portalClient.get(TXN_SAVING, { params })
  },
  getListTxnLoan: (params) => {
    return portalClient.get(TXN_LOAN, { params })
  },

  exportTxnCSV: (params) => {
    const url = new URL(
      process.env.REACT_APP_PORTAL_API_ENDPOINT + TRANSACTION + '/' + params?.type
    )
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    )
    url.searchParams.append('export', 'True')
    return fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
      .then(res => res.blob())
  },
}
