/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { history } from "@helpers";
import { theme } from "configTheme";
import { useTranslation } from "@hooks/useTranslation";
import InputField from "@components/fields/InputField";
import CustomButton from "@components/buttons/CustomButton";
import { selectEmailForgotPw, clearEmailForgotPw } from "@reducers/user.reducer";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPassword } from "@actions/user.actions";
import { ERROR_CODE } from "@constants/errorCode";

function maskPII(str) {
  if (!str) return str;
  const indexOfAt = str.indexOf("@");
  if (indexOfAt <= 2) return str;

  return str[0] + "*".repeat(indexOfAt - 2) + str.substring(indexOfAt - 1);
}

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { translate, language } = useTranslation('login')
  const [isLoading, setIsLoading] = useState(false)
  const schema = useMemo(() => yup.object().shape({
    email: yup
      .string()
      .email(translate('default:invalid_email')),
  }))

  const { handleSubmit, register, watch, setValue, errors, setError } = useForm({ resolver: yupResolver(schema) });
  const email = useSelector(selectEmailForgotPw)

  const onSubmit = async (data) => {
    setIsLoading(true)
    const payload = email ? { email } : data
    const res = await dispatch(forgotPassword(payload))
    if (res?.meta?.requestStatus == "rejected") {
      setError('email', { message: ERROR_CODE[res?.payload?.error_code || 'BAD_REQUEST'][language] })
    }
    setIsLoading(false)
  };

  const handleBack = () => {
    if (email) {
      dispatch(clearEmailForgotPw())
      setIsLoading(false)
    } else history.goBack()
  }

  const disabled = !watch('email') || isLoading

  return (
    <form
      onSubmit={handleSubmit(onSubmit)} style={{ width: 463 }}
      className="flex flex-col items-center p-8 bg-white rounded-2xl shadow-primary"
    >
      <div className="w-12 h-12 flex items-center justify-center">
        {email ? (
          <svg width="39" height="32" viewBox="0 0 39 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 8L17.28 18.52C17.9373 18.9586 18.7098 19.1926 19.5 19.1926C20.2902 19.1926 21.0627 18.9586 21.72 18.52L37.5 8M5.5 30H33.5C34.5609 30 35.5783 29.5786 36.3284 28.8284C37.0786 28.0783 37.5 27.0609 37.5 26V6C37.5 4.93913 37.0786 3.92172 36.3284 3.17157C35.5783 2.42143 34.5609 2 33.5 2H5.5C4.43913 2 3.42172 2.42143 2.67157 3.17157C1.92143 3.92172 1.5 4.93913 1.5 6V26C1.5 27.0609 1.92143 28.0783 2.67157 28.8284C3.42172 29.5786 4.43913 30 5.5 30Z" stroke={theme.primary} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="40" viewBox="0 0 34 40" fill="none">
            <path
              d="M26.3436 14.8961V10.6021C26.3436 5.57609 22.2676 1.50009 17.2416 1.50009C12.2156 1.47809 8.12356 5.53409 8.10156 10.5621V10.6021V14.8961"
              stroke={theme.primary}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.866 38.4999H9.584C5.396 38.4999 2 35.1059 2 30.9159V22.3379C2 18.1479 5.396 14.7539 9.584 14.7539H24.866C29.054 14.7539 32.45 18.1479 32.45 22.3379V30.9159C32.45 35.1059 29.054 38.4999 24.866 38.4999Z"
              stroke={theme.primary}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.2266 24.4062V28.8482"
              stroke={theme.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>

      <p className="text-xl leading-7 font-bold mt-2 text-primary-text">
        {email ? translate('sent_email') : translate('forgot_pw') + '?'}
      </p>

      <div className="mt-6 text-sm leading-5 text-secondary-text">
        {email ? (
          <p className="text-center">
            {translate('sent_email_notice_1')}{' '}
            <span className="font-semibold text-primary-text">{maskPII(email)}</span>
            {' '}{translate('sent_email_notice_2')}
          </p>
        ) : (
          <>
            <p className="text-center">{translate('enter_email_notice')}</p>
            <InputField
              isRequired
              setValue={setValue}
              placeholder={translate('enter_email_placeholder')}
              id={'email'}
              name={'email'}
              value={watch('email')}
              register={register}
              errors={errors}
              extra={"mt-3"}
            />
          </>
        )}
      </div>

      <div className="flex flex-col gap-4 w-full mt-10">
        <CustomButton title={translate(email ? 'resend_link' : 'send_link')} type="submit" disabled={disabled} />
        <CustomButton title={translate('back')} type="button" onClick={handleBack} outline />
      </div>
    </form>
  );
};

export default ForgotPassword;
