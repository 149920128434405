import React from "react";
import OptionBtnPayment from "./OptionBtnPayment";
import OptionGateway from "./OptionGateway";
import PreviewScript from "./PreviewScript";
import PreviewView from "./PreviewView";

const Preview = ({
  appId,
  merchantId,
  previewData,
  setPreviewData,
  previewDataSubmit,
  setPreviewDataSubmit,
}) => {
  return (
    <div style={{ display: "grid", gridTemplateColumns: "36% 64%" }} className="gap-4">
      <div className="flex flex-col gap-4">
        <OptionGateway
          setPreviewData={setPreviewData}
          previewDataSubmit={previewDataSubmit}
          setPreviewDataSubmit={setPreviewDataSubmit}
        />
        <OptionBtnPayment
          setPreviewData={setPreviewData}
          previewDataSubmit={previewDataSubmit}
          setPreviewDataSubmit={setPreviewDataSubmit}
        />
      </div>

      <div className="flex flex-col gap-4">
        <PreviewView previewData={previewData} />
        <PreviewScript appId={appId} merchantId={merchantId} />
      </div>
    </div>
  );
};

export default Preview;
