import React, { useEffect } from 'react'
import { FiMenu } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import IcAvatar from '@assets/images/IcAvatar.png'
import { theme } from 'configTheme'
import { useSocket } from '@hooks/useSocket'
import { useTranslation } from '@hooks/useTranslation'
import { setSocketData } from '@reducers/noti.reducer'
import { selectProfile } from '@reducers/user.reducer'
import { selectMerchant } from '@reducers/sass.reducer'
import { CustomToastMessage } from '@utils/customToastMsg'
import SwitchLanguage from '../switch-language'
import ButtonBell from './ButtonBell'
import '@css/components/navbar.css'
import { setIsShow } from '@reducers/sidebar.reducer'
import { selectBusiness } from '@reducers/business.reducer'

const Navbar = () => {
  const { pathname } = useLocation()
  const { translate } = useTranslation()
  const dispatch = useDispatch()

  const { name } = useSelector(selectBusiness)
  const { ref_id } = useSelector(selectMerchant)

  const { data } = useSocket({
    url: process.env.REACT_APP_SALE_SOCKET,
    emitEvent: {
      name: 'subscribe',
      payload: { merchant_id: String(ref_id) },
    },
    onEvent: {
      name: 'payment_success',
      func: async ({ data }) => {
        CustomToastMessage.payByLink({ info: data?.invoice_data, translate })
      },
    },
    enable: !!ref_id,
  })

  useEffect(() => {
    if (data && pathname.includes('/sale-order/payment')) {
      dispatch(setSocketData(data?.data?.invoice_data))
    }
  }, [pathname, data])

  return (
    <div className='shadow-primary'>
      {!(
        pathname === '/e-invoice' &&
        !!window.localStorage.getItem('giftCodeInvoice')
      ) && (
          <div
            className='navbar bg-white px-3 md:pl-12 md:pr-6'
            style={{ paddingTop: 6, paddingBottom: 6 }}
          >
            <div
              className='w-full flex gap-6 items-center justify-end relative'
              style={{ height: 80 }}
            >
              {theme.navBannerWeb && (
                <a
                  href={theme.navBannerWeb}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='rounded-2xl overflow-hidden hidden md:block hover:opacity-75 duration-200'
                  style={{ height: 80, flex: 1 }}
                >
                  <img
                    src={theme.navBanner}
                    alt='banner'
                    style={{
                      width: '100%',
                      height: 80,
                      objectFit: 'cover',
                      objectPosition: 'left',
                    }}
                  />
                </a>
              )}
              <div className='block md:hidden flex-1'>
                <FiMenu
                  onClick={() => dispatch(setIsShow(true))}
                  size={24}
                  className='cursor-pointer'
                />
              </div>
              <div className='flex items-center gap-4'>
                {/* <img src={search} alt="search" className="w-6 cursor-pointer" /> */}
                <ButtonBell />
                <SwitchLanguage />
              </div>
              <div className='flex items-center gap-2'>
                <img
                  className='w-10 h-10 rounded-full shadow cursor-pointer'
                  src={IcAvatar}
                  alt='avatar'
                />
                <span
                  className='text-sm leading-5 font-semibold text-blue-primary truncate'
                  style={{ maxWidth: 250 }}
                >
                  {name}
                </span>
              </div>
            </div>
          </div>
        )}
      {/* {showPromotion && <Promotion data={dataPromotion} setShow={setShowPromotion} />} */}
    </div>
  )
}

export default Navbar
