import kiotviet from '@assets/images/payment/kiotviet.png';
import odoo from '@assets/images/payment/odoo.png';
import sapo from '@assets/images/payment/sapo.png';
import wordpress from '@assets/images/payment/wordpress.png';

export const LIST_CHANNEL = [
    { label: 'Website', value: 'WEBSITE' },
    { label: 'Kiosk', value: 'KIOSK' },
    { label: 'Mobile app', value: 'MOBILE' },
    { label: 'shop_qr:management_at_counter', value: 'IN_STORE' },
    { label: 'Call center', value: 'CALL_CENTER' },
]

export const APP_LIST = [
    {
        logo: wordpress,
        name: 'shop_qr:wordpress_integrated',
        desc: 'shop_qr:wordpress_integrated_desc',
    },
    {
        logo: odoo,
        name: 'shop_qr:odoo_integrated',
        desc: 'shop_qr:odoo_integrated_desc',
    },
    {
        logo: sapo,
        name: 'shop_qr:sapo_integrated',
        desc: 'shop_qr:sapo_integrated_desc',
    },
    {
        logo: kiotviet,
        name: 'shop_qr:kiotviet_integrated',
        desc: 'shop_qr:kiotviet_integrated_desc',
    },
]

export const ADD_BANK_FORM = {
    BANK_CODE: 'bank_code',
    BANK_ACCOUNT_NO: 'bank_account_no',
    HOLDER_NAME: 'holder_name',
    PHONE: 'phone',
    CHECK_BOX: 'check_box',
};

export const PCHANNEL_TITLE = 'ShopQR';

export const BANK_NAME_SHORT = {
    VCCB: 'BVBank',
    OCB: 'OCB',
    VPB: 'VPBank',
    HDB: 'HDBank',
};

export const ACCOUNT_TYPE = {
    PERSONAL: 'person',
    HOUSEHOLD_BUSINESS: 'household',
    BUSINESS: 'business'
}

export const VA_TYPE_LIST = ['STORE', 'STAFF', 'BILL'];

export const VA_TYPE = {
    STORE: 'STORE',
    BILL: 'BILL',
    STAFF: 'STAFF',
};

