import { createAsyncThunk } from "@reduxjs/toolkit";
import { staffServices } from "@services/staff.service";

export const getStaffById = createAsyncThunk(
  "staff/getStaff",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await staffServices.getStaffById(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createStaff = createAsyncThunk(
  "staff/getListStaff",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await staffServices.createStaff(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateStaff = createAsyncThunk(
  "staff/updateStaff",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await staffServices.updateStaff(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteStaffById = createAsyncThunk(
  "staff/deleteStaffById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await staffServices.deleteStaffById(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
