import md5 from "md5";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStaff } from "@actions/staff.actions";
import CustomButton from "@components/buttons/CustomButton";
import SelectBankHandleField from "@components/fields/SelectBankHandleField";
import SelectBankReleaseField from "@components/fields/SelectBankReleaseField";
import SelectCardField from "@components/fields/SelectCardField";
import SelectRuleField from "@components/fields/SelectRuleField";
import SectionTitle from "@components/section-title";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";
import { useTranslation } from "@hooks/useTranslation";
import TableWrapper from "../business/profile/TableWrapper";
import { SETTINGS_FORM } from "@constants/settings-txn";

const CreateSettingTx = () => {
  const { translate, language } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const onFormSubmit = async (value) => {
    const raw = { ...value, password: md5(value.password) };
    // const raw = { ...value, password: value.password };
    try {
      const res = await dispatch(createStaff(raw));
      if (res?.meta?.requestStatus === "fulfilled") {
        history.push("/staff");
        CustomToastMessage.success(translate("success"));
      } else CustomToastMessage.error("Thêm nhân viên thất bại", ERROR_CODE[res?.payload?.error_code][language])
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  const disabled =
    !watch(SETTINGS_FORM.RULE_NAME) ||
    !watch(SETTINGS_FORM.CARD_TYPE) ||
    !watch(SETTINGS_FORM.ISSUER) ||
    !watch(SETTINGS_FORM.ACQUIRER);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle title="Thiết lập xử lý giao dịch" subtitle="Thêm xử lý giao dịch" />
      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2  gap-6">
          <SelectRuleField
            isRequired
            register={register}
            name={SETTINGS_FORM.RULE_NAME}
            setValue={setValue}
            errors={errors}
          />
          <SelectCardField
            isRequired
            register={register}
            name={SETTINGS_FORM.CARD_TYPE}
            setValue={setValue}
            errors={errors}
          />
        </div>
        <SelectBankReleaseField
          isRequired
          register={register}
          name={SETTINGS_FORM.ISSUER}
          setValue={setValue}
          errors={errors}
        />
        <SelectBankHandleField
          isRequired
          register={register}
          name={SETTINGS_FORM.ACQUIRER}
          setValue={setValue}
          errors={errors}
        />

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/setting-tx")}
              title="Quay lại"
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton type="submit" disabled={disabled} title="Thêm" className="w-full" />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default CreateSettingTx;
