import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { history } from "@helpers/history.js";
import { formatCurrency } from "@utils/lib.js";
import { selectListCustomer } from "@reducers/customer.reducer.js";
import useSearchParams from "./useSearchParams.js";

import SectionTitle from "@components/section-title/index.js";
import Datatable from "@components/tsdatatable/datatables.js";
import ModalDelete from "@components/modals/ModalDelete.js";
import SearchFieldParams from "@components/fields/SearchFieldParams.js";
import CustomButton from "@components/buttons/CustomButton.js";

import IcPen from "@assets/images/staff/ic_pen.svg";
import { useTranslation } from "@hooks/useTranslation.js";

const TableCustomer = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [numOfPage, setNumOfPage] = useState("1");
  const { updatePagination, setSearchQuery, filtersParams } = useSearchParams();
  const [filters, setFilters] = useState(filtersParams);
  const data = useSelector(selectListCustomer);
  const { translate } = useTranslation('sale_customer')

  const customData = useMemo(() => {
    const result = data?.customers;

    if (!result) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);

    return result;
  }, [data]);

  useEffect(() => {
    updatePagination(new URLSearchParams(filters));
  }, [filters, updatePagination]);

  const handleDelete = async () => {
    // try {
    //   const res = await dispatch(deleteCustomer(selectedCustomer?.code));
    //   if (res?.payload) {
    //     CustomToastMessage.success(
    //       "Thành công",
    //       `Bạn đã ngừng kinh doanh thành công sản phẩm với mã sản phẩm ${selectedCustomer?.code}`
    //     );
    //     dispatch(getListCustomer(filters));
    //     setShowModalDelete(false);
    //   } else CustomToastMessage.error("Thất bại");
    // } catch (err) {
    //   console.log(err);
    // }
  };

  const columns = useMemo(
    () => [
      {
        Header: translate('index'),
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: translate('customer_id'),
        accessor: "code",
        disableSortBy: true,
      },
      {
        Header: translate('customer_name'),
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: translate('phone_number'),
        accessor: "phone_number",
        disableSortBy: true,
      },
      {
        Header: translate('total_spending'),
        accessor: "total_spending",
        Cell: ({ value }) => formatCurrency(value) || '0 VND',
      },
      {
        Header: translate('reward_point'),
        accessor: "point",
        Cell: ({ value }) => <p>{value}</p>,
      },
      {
        Header: translate('total_quantity_order'),
        accessor: "total_orders",
      },
      {
        Header: translate('common:actions'),
        accessor: "",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <img
                src={IcPen}
                alt="IcPen"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  history.push(`/sale-customer/update?id=${row?.original?.customer_id}`);
                }}
              />
              {/* <img
                src={IcTrash}
                alt="IcTrash"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setItem(row?.original);
                  setShowModalDelete(true);
                }}
              /> */}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <section>
      <div className="w-full flex justify-between items-center">
        <SectionTitle title={translate('sale_management')} subtitle={translate('customer_list')} />
      </div>

      <div className="mt-4 mb-10 w-full flex justify-between items-center gap-4">
        <SearchFieldParams
          setFilters={setFilters}
          setSearchQuery={setSearchQuery}
          id="search-bank-input"
          extraClass="flex-1 bg-white shadow-primary"
          placeholder={translate('input_search_customers')}
        />
        <CustomButton
          type="add"
          onClick={() => history.push("/sale-customer/create")}
        />
      </div>

      <Datatable
        columns={columns}
        data={customData}
        pagination={{
          page: filters?.page,
          pageSize: filters?.page_size,
          totalPages: numOfPage,
        }}
        filters={filters}
        setFilters={setFilters}
      />

      <ModalDelete
        isOpen={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        title={translate('cease_trading')}
        desc={`${translate('sure_cease_trading')}} ${selectedCustomer?.name} ?`}
        item={selectedCustomer}
        handleAction={handleDelete}
        titleBtnLeft={translate('no')}
        titleBtnRight={translate('yes')}
      />
    </section>
  );
};

export default TableCustomer;
