import React from "react";
import { theme } from "configTheme";

const CheckBoxCard = ({ id, value, title = "", onChange, checked, disabled }) => {
  return (
    <label
      className={`${disabled ? " " : "cursor-pointer hover:opacity-75"} flex gap-2 items-center`}
      htmlFor={id}
    >
      <input
        style={{ accentColor: theme.primary }}
        type={"checkbox"}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        id={id}
        name={id}
        value={id}
      />
      <span
        style={{ letterSpacing: -0.2 }}
        className={` ${disabled ? "text-grey-6 " : "text-blackgray "
          } font-medium leading-6 text-sm`}
      >
        {title}
      </span>
    </label>
  );
};

export default CheckBoxCard;
