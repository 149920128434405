import React from 'react'
import { IoCloseSharp } from 'react-icons/io5'
import IcLoading from '@assets/images/icon/pos_awaiting.gif'
import CustomButton from '@components/buttons/CustomButton'
import ModalWrap from '@components/modals/ModalWrap'
import { history } from '@helpers'
import { useTranslation } from '@hooks/useTranslation'

const ModalWaitingPos = ({ invoice, onClose, isOpen }) => {
  const { translate } = useTranslation('sale_order')

  return (
    <ModalWrap isOpen={isOpen} onClickBackdrop={onClose}>
      <IoCloseSharp
        className='absolute cursor-pointer hover:opacity-75'
        style={{ right: 16, top: 16 }}
        onClick={() => {
          onClose()
        }}
        size={20}
      />

      <div
        style={{ width: 320 }}
        className='mx-auto flex max-w-md flex-col gap-8 rounded-2xl px-6 py-8'
      >
        <div className='flex flex-col items-center justify-center gap-6'>
          <div style={{ width: '200px' }} className='mx-auto'>
            <img src={IcLoading} alt='ic_warn' />
          </div>
          <div
            style={{ gap: 10 }}
            className='flex flex-col  text-center text-black font-normal'
          >
            <h1 className='text-xl font-bold'>
              {translate('waiting_payment')}
            </h1>
            <p className='text-sm text-grey-3 font-normal'>
              {translate('txn:flt_invoice')}:{' '}
              <span className='text-black font-bold'>
                {invoice?.order_code}
              </span>
            </p>
          </div>
        </div>

        <CustomButton
          title={translate('sale_table:back_to_home')}
          onClick={() => {
            onClose()
            history.push(
              `${invoice?.invoice_data?.table_id
                ? '/sale-table'
                : '/sale-order?page=1&page_size=10'
              }`
            )
          }}
        />
      </div>
    </ModalWrap>
  )
}

export default ModalWaitingPos
