import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "@components/section-title";
import Datatable from "@components/tsdatatable/datatables";
import { formatCurrency } from "@utils/lib";

import { useHistory } from "react-router-dom";
import { deleteStaffById } from "@actions/staff.actions";
import { getListStaff } from "@actions/metadata.actions";
import IcPen from "@assets/images/staff/ic_pen.svg";
import IcTrash from "@assets/images/payment/trash.svg";
import CustomButton from "@components/buttons/CustomButton";
import ModalDelete from "@components/modals/ModalDelete";
import { useTranslation } from "@hooks/useTranslation";
import { selectListStaff } from "@reducers/metadata.reducer";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";
import useSearchParams from "@pages/sale-management/customer/useSearchParams";

const DatatableComponent = ({
  customData,
  filters,
  numOfPage,
  setFilters,
  hasPagination,
  setStaff,
  setIsOpenModal,
}) => {
  const history = useHistory();
  const { translate } = useTranslation('business');

  const columns = React.useMemo(
    () => [
      {
        Header: translate("common:numerical_order"),
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: translate("staff_manager_id"),
        accessor: "staff_id",
        disableSortBy: true,
        Cell: ({ value, row }) => {
          return <span>{value || "None"}</span>;
        },
      },
      {
        Header: translate("staff_manager_full_name"),
        accessor: "full_name",
        disableSortBy: true,
      },
      {
        Header: translate("staff_manager_store_name"),
        accessor: "store_name",
        disableSortBy: true,
      },
      {
        Header: translate("staff_manager_phone_number"),
        accessor: "phone",
        disableSortBy: true,
      },
      {
        Header: "Email",
        accessor: "email",
        disableSortBy: true,
      },
      {
        Header: translate("staff_manager_action"),
        accessor: "",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <img
                src={IcPen}
                alt="IcPen"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  history.push(`/staff/update?id=${row?.original?.id}`);
                }}
              />
              <img
                src={IcTrash}
                alt="IcTrash"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setStaff(row?.original);
                  setIsOpenModal(true);
                }}
              />
            </div>
          );
        },
      },
    ],
    [history, setStaff, setIsOpenModal],
  );

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      setFilters={setFilters}
      filters={filters}
      hasPagination={hasPagination}
    />
  );
};

export default function ListStaff() {
  const dispatch = useDispatch();
  const data = useSelector(selectListStaff);
  const [numOfPage, setNumOfPage] = useState("1");
  const [staff, setStaff] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { translate, language } = useTranslation('business');
  const history = useHistory();

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(data?.total_page);
    return data?.data;
  }, [data]);

  
  const { updatePagination, filtersParams } = useSearchParams();

  const [filters, setFilters] = useState(filtersParams);

  useEffect(() => {
    updatePagination(new URLSearchParams(filters));
  }, [dispatch, filters, updatePagination]);

  const formatData = (ls) => {
    return ls
      ? ls.map((e) => {
        return {
          ...e,
          request_amount: formatCurrency(e.request_amount),
          original_transaction_date: moment(new Date(e.original_transaction_date * 1000)).format(
            "HH:mm:ss DD-MM-YYYY",
          ),
        };
      })
      : [];
  };

  const handleDelete = useCallback(async () => {
    try {
      const res = await dispatch(deleteStaffById({ data: { id: staff?.id } }));
      if (res?.meta?.requestStatus === "fulfilled") {
        CustomToastMessage.success(translate('delete_staff_success_title'), translate('delete_staff_success_desc') + ' ' + staff?.full_name);

        dispatch(getListStaff());
        setIsOpenModal(false);
      } else {
        const errCode = ERROR_CODE[res?.payload?.error_code][language]
        const desc = typeof errCode == 'string' ? errCode : errCode(staff?.full_name)
        CustomToastMessage.error(translate('delete_staff_fail'), desc);
      }
    } catch (err) {
      console.log(err);
    }
  }, [staff, dispatch]);

  useEffect(() => {
    dispatch(getListStaff(filters));
  }, [dispatch, filters]);

  return (
    <section>
      <div className="w-full flex justify-between items-center">
        <SectionTitle title={translate("business_info")} subtitle={translate("staff_manager")} />
        <CustomButton
          type={"add"}
          outline
          title={translate("common:add")}
          onClick={() => history.push("/staff/add")}
        />
      </div>

      <div className="mt-8">
        <DatatableComponent
          customData={formatData(customData)}
          filters={filters}
          numOfPage={numOfPage}
          data={data}
          setFilters={setFilters}
          setStaff={setStaff}
          setIsOpenModal={setIsOpenModal}
        />
      </div>

      <ModalDelete
        item={staff}
        handleAction={handleDelete}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        title={translate("modal_delete_title_staff")}
        desc={`${translate("modal_delete_des_staff1")} ${staff?.full_name} ${translate(
          "modal_delete_des_staff2",
        )} ${staff?.staff_id} ?`}
        titleBtnLeft={translate("common:cancel")}
        titleBtnRight={translate("common:delete")}
      />
    </section>
  );
}
