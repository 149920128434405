import React from "react";
import { v4 as uuidv4 } from 'uuid';

function lightenColor(color, amount = 30) {
  // Convert hex to RGB
  let r = parseInt(color.slice(1, 3), 16);
  let g = parseInt(color.slice(3, 5), 16);
  let b = parseInt(color.slice(5, 7), 16);

  // Lighten each component by the amount
  r = Math.min(255, r + amount);
  g = Math.min(255, g + amount);
  b = Math.min(255, b + amount);

  // Convert back to hex
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

const TableNameBox = ({ color = '#04FC48', name }) => {
  const id = uuidv4()

  return (
    <div className='relative flex items-center justify-center'>
      <svg width="157" height="27" viewBox="0 0 157 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0C0 0 5.43228 3.01558 6.5 7.5C9 18 19.5 27 33 27H79.5H123.5C137 27 147.5 18 150 7.5C151.068 3.01558 156.5 0 156.5 0H79.5H0Z" fill={`url(#${id})`} />
        <defs>
          <linearGradient id={id} x1="78" y1="0" x2="78" y2="27" gradientUnits="userSpaceOnUse">
            <stop stopColor={color} />
            <stop offset="0.449072" stopColor={lightenColor(color)} />
            <stop offset="1" stopColor={color} />
          </linearGradient>
        </defs>
      </svg>

      <span className='absolute font-semibold text-sm leading-5 text-white'>
        {name}
      </span>
    </div>

  )
};

export default TableNameBox;
