import { saleClient } from "./client_axios";
import { CUSTOMER_MANAGER } from "@constants/endpoint";

export const customerServices = {
  getListCustomer: (params) => {
    return saleClient.get(`${CUSTOMER_MANAGER}?${params}`);
  },

  getCustomerById: (id) => {
    return saleClient.get(`${CUSTOMER_MANAGER}/${id}`);
  },

  createCustomer: (params) => {
    return saleClient.post(CUSTOMER_MANAGER, params);
  },

  updateCustomer: (params) => {
    return saleClient.put(`${CUSTOMER_MANAGER}/${params.customer_id}`, params);
  },

  deleteCustomer: (params) => {
    return saleClient.delete(`${CUSTOMER_MANAGER}/${params}`);
  },
};
