/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import ic_check from "@assets/images/icon/ic_check.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CustomButton from "@components/buttons/CustomButton";
import { theme } from "configTheme";
import { useTranslation } from "@hooks/useTranslation";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import { ERROR_CODE } from "@constants/errorCode";
import { selectIsLoading, selectIsResetPwSuccess } from "@reducers/user.reducer";
import InputFieldPw from "@components/fields/InputFieldPw";
import { history } from "@helpers/history";
import { resetPassword } from "@actions/user.actions";
import { CustomToastMessage } from "@utils/customToastMsg";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const { translate, language } = useTranslation('login')
  const isLoading = useSelector(selectIsLoading)
  const isSuccess = useSelector(selectIsResetPwSuccess)
  const schema = useMemo(() => yup.object().shape({
    new_password: yup
      .string()
      .matches(/^.{8,34}$/, translate('required_length'))
      .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/, translate('required_uppercase'))
      .matches(/^[a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>[\]\\/'`~+-_=]*$/, translate('required_no_vietnamese')),
    confirm_new_password: yup
      .string()
      .oneOf([yup.ref("new_password")], translate('password_not_match'))
  }))

  const { handleSubmit, register, watch, setValue, errors } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async ({ new_password }) => {
    const res = await dispatch(resetPassword({ token, new_password }))
    if (res?.meta?.requestStatus == "rejected") {
      CustomToastMessage.error('Error', ERROR_CODE[res?.payload?.error_code || 'BAD_REQUEST'][language])
    }
  };

  const disabled = !watch('new_password') || !watch('confirm_new_password') || isLoading

  useEffect(() => { if (!token) history.push('/login') }, [])

  return (
    <div style={{ width: isSuccess ? 400 : 463 }} className="flex flex-col items-center p-8 bg-white rounded-2xl shadow-primary">
      <div className="w-12 h-12 flex items-center justify-center">
        {isSuccess ? (
          <img src={ic_check} alt="icon-success" className="w-12 h-12" />
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="40" viewBox="0 0 34 40" fill="none">
            <path
              d="M26.3436 14.8961V10.6021C26.3436 5.57609 22.2676 1.50009 17.2416 1.50009C12.2156 1.47809 8.12356 5.53409 8.10156 10.5621V10.6021V14.8961"
              stroke={theme.primary}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.866 38.4999H9.584C5.396 38.4999 2 35.1059 2 30.9159V22.3379C2 18.1479 5.396 14.7539 9.584 14.7539H24.866C29.054 14.7539 32.45 18.1479 32.45 22.3379V30.9159C32.45 35.1059 29.054 38.4999 24.866 38.4999Z"
              stroke={theme.primary}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.2266 24.4062V28.8482"
              stroke={theme.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>

      {!isSuccess &&
        <p className="text-xl leading-7 font-bold mt-2 text-primary-text">
          {translate('new_password')}
        </p>
      }

      <div className="mt-6 w-full">
        {isSuccess ? (
          <div className="w-full flex flex-col items-center gap-10">
            <p className="text-2xl leading-7 font-semibold text-primary-text">{translate('changed_password_success')}</p>
            <CustomButton title={translate('turn_back')} className={'w-full'} onClick={() => history.push('/login')} />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputFieldPw
              isRequired
              setValue={setValue}
              placeholder={translate('new_password_placeholder')}
              label={translate('new_password')}
              id={'new_password'}
              name={'new_password'}
              value={watch('new_password')}
              register={register}
              errors={errors}
            />
            <InputFieldPw
              isRequired
              setValue={setValue}
              placeholder={translate('confirm_new_password_placeholder')}
              label={translate('confirm_new_password')}
              id={'confirm_new_password'}
              name={'confirm_new_password'}
              value={watch('confirm_new_password')}
              register={register}
              errors={errors}
              extra={"mt-4"}
            />

            <div className="mt-6 text-secondary-text text-xs leading-4">
              <p className="text-primary-text font-medium">{translate('note')}</p>
              <ul className="pl-6 mt-2 list-disc list-outside">
                <li>
                  {translate('required_length')}
                </li>
                <li>
                  {translate('required_uppercase')}
                </li>
                <li>
                  {translate('required_no_vietnamese')}
                </li>
                <li>
                  {translate('required_not_found')}{" "}
                  <span className="font-semibold text-primary-text">“{translate('not_found')}”</span>.
                </li>
              </ul>
            </div>

            <CustomButton
              title={translate('save')}
              type="submit" disabled={disabled}
              className={'mt-10 w-full'}
            />
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
