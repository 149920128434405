import React, { useEffect, useState } from 'react'
import arrowDown from '@assets/images/icon/ic-arrow-down.svg'
import search from '@assets/images/icon/ic-search.svg'
import useClickOutside from '@hooks/useClickOutside'
import { useTranslation } from '@hooks/useTranslation'

const SelectField = ({
  data = [],
  placeHolder = 'Chọn',
  hasSearch = true,
  value,
  setValue,
  setStoreName = null,
  iconDropdown,
  rotateIcon = true,
  iconWidth,
  icon,
  style,
}) => {
  const [list, setList] = useState(data)
  const [showData, setShowData] = useState(false)
  const [item, setItem] = useState(null)

  const { nodeRef } = useClickOutside(() => setShowData(false))
  const { translate } = useTranslation('common')

  const filterList = (target) => {
    const filteredList = data.filter((item) => {
      if (typeof item.label == 'string')
        return item.label.toLowerCase().includes(target)
      return item?.label?.props?.children.find((child) =>
        child?.props?.children?.toLowerCase()?.includes(target)
      )
    })
    setList(filteredList)
  }

  useEffect(() => {
    setList(data)
  }, [data])

  useEffect(() => {
    if (value) {
      const item = data.find((item) => item?.value === value)
      if (item) setItem(item)
    } else setItem(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, value])

  return (
    <div ref={nodeRef} className='relative'>
      <div
        onClick={() => setShowData(!showData)}
        style={{
          border: showData ? '1px solid #6666FF' : '1px solid #E8E8E8',
          padding: '10px 10px 10px 16px',
          ...style,
        }}
        className='bg-white rounded-lg flex justify-between items-center cursor-pointer gap-4'
      >
        <div className='flex items-center gap-2'>
          {icon && <img className='w-4 h-4' src={icon} alt='icon' />}

          <p
            style={{
              color: !item?.label ? '#475467' : '#0F1D40',
              fontSize: 14,
              fontWeight: !item?.label && 'normal',
            }}
            className='leading-6 font-medium'
          >
            {item?.label ? item?.label : placeHolder}
          </p>
        </div>
        <img
          src={iconDropdown ? iconDropdown : arrowDown}
          alt='arrowDown'
          width={iconWidth}
          style={{
            transition: 'all 0.3s linear',
            transform: showData && rotateIcon && 'rotate(180deg)',
          }}
        />
      </div>

      {showData && (
        <div
          style={{
            border: '1px solid #E8E8E8',
            top: 'calc(100% + 8px)',
          }}
          className='absolute z-20 w-full bg-white rounded-lg flex flex-col overflow-auto shadow-primary'
        >
          {hasSearch && (
            <div
              style={{
                background: '#F5F5F5',
                gap: 10,
                marginBottom: list.length > 0 ? 4 : 16,
              }}
              className='rounded-lg mx-4 mt-4 px-3 py-2 flex'
            >
              <img src={search} alt='search'></img>
              <input
                onChange={(e) => filterList(e.target.value.toLowerCase())}
                style={{
                  fontSize: 14,
                  color: '#0F1D40',
                  background: '#F5F5F5',
                }}
                className='w-full leading-5 font-normal'
                placeholder={translate('common:search')}
              />
            </div>
          )}
          {list.length > 0 && (
            <div
              className='flex flex-col gap-1'
              style={{ maxHeight: 216, overflow: 'auto' }}
            >
              {list.map((item, index) => {
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                      setShowData(false)
                      setItem(item)
                      setValue(item.value)
                      if (setStoreName) setStoreName(item.storeName)
                    }}
                    key={index}
                    style={{ fontSize: 14 }}
                    className='px-4 py-2 leading-6 font-medium cursor-pointer hover:bg-select-hover hover:text-primary'
                  >
                    {item.label}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default SelectField
