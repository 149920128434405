import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEcomInfo } from '@actions';
import copyIcon from '@assets/images/pay-by-link/white-copy.svg';
import { useTranslation } from '@hooks/useTranslation';
import { selectProfile } from '@reducers/user.reducer';
import { selectMerchant } from '@reducers/sass.reducer';
import { QrComponent } from '../../../order/Common';
import Invoice from '../Invoice';

const Link = ({ invoice, setQrData }) => {
  const { translate } = useTranslation('sale_order');
  const [isCopied, setIsCopied] = useState(false);
  const [appId, setAppId] = useState('');
  const { merchant_id } = useSelector(selectProfile);
  const { ref_id } = useSelector(selectMerchant)

  const dispatch = useDispatch();
  const handleGetEcomInfo = async () => {
    const res = await dispatch(getEcomInfo(ref_id || merchant_id));
    if (res.meta.requestStatus === 'fulfilled' && res?.payload?.data) {
      const data = res.payload.data.data;
      const appId = data.widget.id;
      setAppId(appId);
    }
  };

  const paymentLink =
    process.env.REACT_APP_CHECKOUT_URL +
    `/?appId=${appId}&orderId=${invoice?.order_code}&amount=${invoice?.total_price}&merchantId=${ref_id || merchant_id}`;

  const handleCopy = async (text) => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      const copyTimeout = setTimeout(() => {
        setIsCopied(false);
      }, 1000);
      return () => clearTimeout(copyTimeout);
    }
  }, [isCopied]);

  useEffect(() => {
    if (paymentLink) setQrData({ qr: paymentLink, loadingQr: !appId, isPayByLink: true });
  }, [paymentLink, appId]);

  useEffect(() => {
    handleGetEcomInfo();
  }, []);

  return (
    <div className='flex flex-col gap-4 items-center w-full'>
      <QrComponent data={paymentLink} loading={!appId} isPayByLink />

      <div
        className='rounded-2xl bg-gradient w-full'
        style={{ padding: 10 }}
      >
        <div className='bg-white p-4 flex items-center rounded-lg gap-4 justify-between'>
          <a
            href={paymentLink}
            className='font-semibold text-sm text-[#262626] break-normal truncate flex-1 hover:opacity-75'
            style={{
              textWrap: 'nowrap',
              wordBreak: 'keep-all',
            }}
          >
            {appId && paymentLink}
          </a>

          <button
            className='flex items-center gap-2 py-2 px-3 rounded-lg cursor-pointer bg-gradient hover:opacity-75 duration-200'
            onClick={() => handleCopy(paymentLink)}
          >
            <img src={copyIcon} alt='copy' width={16} height={16} />

            <span className='text-white font-semibold text-sm whitespace-no-wrap'>
              {isCopied
                ? translate('link_payment__copied')
                : translate('link_payment__copy')}
            </span>
          </button>
        </div>
      </div>

      <Invoice invoice={invoice} />
    </div>
  );
};

export default Link;
