import React from 'react'

import mail from '@assets/images/mail.svg'
import telephone from '@assets/images/telephone.svg'

import { useTranslation } from '@hooks/useTranslation'

const AuthLayout = ({ children }) => {
  const { translate } = useTranslation('login')
  const listHelp = [
    {
      icon: telephone,
      title: translate('auth_hotline') + ': 1900 555 596',
      link: '',
    },
    {
      icon: mail,
      title: 'Email: bvbank247@bvbank.net.vn',
      link: 'mailto:bvbank247@bvbank.net.vn',
    },
  ]

  return (
    <div
      className='w-screen h-screen py-10 flex flex-col items-center justify-between'
      style={{
        backgroundImage: 'url(/bg-login.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div style={{ flex: 1 }} className='flex items-center justify-center'>
        {children}
      </div>
      <div className='flex flex-col md:flex-row gap-2 md:gap-10 items-center justify-center'>
        {listHelp.map((item) => {
          const Tag = item?.link ? `a` : `div`
          const props = item?.link ? { href: item.link, target: '_blank' } : {}

          return (
            <Tag
              key={item.title}
              {...props}
              className='flex items-center gap-2 cursor-pointer'
            >
              <img src={item.icon} alt='iphone' />
              <span className='text-blue-2 md:text-sm text-xs'>
                {item.title}
              </span>
            </Tag>
          )
        })}
      </div>
    </div>
  )
}

export default AuthLayout
