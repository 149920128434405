import { PAYMENT_METHOD } from "../common";

export const PRODUCT_TILES = [
  'common:numerical_order',
  "product_name",
  "quantity",
  "unit_price",
  "subtotal",
]

const { QR, CASH, CARD, LINK } = PAYMENT_METHOD

export const PAYMENTMETHOD = [
  {
    type: QR,
    name: 'qrCode',
  },
  // {
  //   type: CASH,
  //   name: 'cash',
  //   icon: ic_tooltip,
  //   iconActive: ic_tooltipActive,
  //   tooltip: 'cash_tooltip',
  // },
  {
    type: CARD,
    name: 'card_payment',
  },
  {
    type: LINK,
    name: 'link_payment',
  },
];

export const LIMIT_CHECK_POS_STATUS = 150; // 2s call in 5 minutes
export const SERIAL_NUMBER = "serial_number";

export const INITIAL_CREATE_TABLE = {
  table_name: "string",
  max_slot: 0,
  available_slot: 0,
  customer_id: 0,
  is_available: true
}

export const STATUS_CONNECT_POS = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  NOT_CONNECTED: 'NOT_CONNECTED',
};