import {
  getListCustomer,
  getListFeedback,
  getListOrder,
  getListProduct,
  getListTxnCard,
  getListTxnCash,
  getListTxnVA,
} from '@actions'
import { getBusiness } from '@actions/business.actions'
import { getListEDC } from '@actions/edc.actions'
import { getListStaff, getListStore } from '@actions/metadata.actions'
import { getListPayment } from '@actions/va.action'
import { getTableBank } from '@actions/bank.actions'

const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
  loading: false,
}

const pageLoadingSlice = createSlice({
  name: 'pageLoading',
  initialState,
  reducers: {
    setPageLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) =>
    builder
      // Txn Card
      .addCase(getListTxnCard.pending, (state) => {
        state.loading = true
      })
      .addCase(getListTxnCard.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(getListTxnCard.rejected, (state) => {
        state.loading = false
      })
      // Txn Cash
      .addCase(getListTxnCash.pending, (state) => {
        state.loading = true
      })
      .addCase(getListTxnCash.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(getListTxnCash.rejected, (state) => {
        state.loading = false
      })
      // Txn VA
      .addCase(getListTxnVA.pending, (state) => {
        state.loading = true
      })
      .addCase(getListTxnVA.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(getListTxnVA.rejected, (state) => {
        state.loading = false
      })
      // Payment Channel
      .addCase(getListPayment.pending, (state) => {
        state.loading = true
      })
      .addCase(getListPayment.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(getListPayment.rejected, (state) => {
        state.loading = false
      })
      // Bank
      .addCase(getTableBank.pending, (state) => {
        state.loading = true
      })
      .addCase(getTableBank.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(getTableBank.rejected, (state) => {
        state.loading = false
      })
      // EDC
      .addCase(getListEDC.pending, (state) => {
        state.loading = true
      })
      .addCase(getListEDC.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(getListEDC.rejected, (state) => {
        state.loading = false
      })
      // Alert
      .addCase(getListFeedback.pending, (state) => {
        state.loading = true
      })
      .addCase(getListFeedback.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(getListFeedback.rejected, (state) => {
        state.loading = false
      })
      // Store Management
      .addCase(getListStore.pending, (state) => {
        state.loading = true
      })
      .addCase(getListStore.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(getListStore.rejected, (state) => {
        state.loading = false
      })
      // Staff Management
      .addCase(getListStaff.pending, (state) => {
        state.loading = true
      })
      .addCase(getListStaff.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(getListStaff.rejected, (state) => {
        state.loading = false
      })
      // Customers
      .addCase(getListCustomer.pending, (state) => {
        state.loading = true
      })
      .addCase(getListCustomer.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(getListCustomer.rejected, (state) => {
        state.loading = false
      })
      // Products
      .addCase(getListProduct.pending, (state) => {
        state.loading = true
      })
      .addCase(getListProduct.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(getListProduct.rejected, (state) => {
        state.loading = false
      })
      // Orders
      .addCase(getListOrder.pending, (state) => {
        state.loading = true
      })
      .addCase(getListOrder.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(getListOrder.rejected, (state) => {
        state.loading = false
      })
})

export const selectPageLoading = (state) => state.pageLoading.loading
export const { setPageLoading } = pageLoadingSlice.actions

export default pageLoadingSlice.reducer
