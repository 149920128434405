import { createSlice } from "@reduxjs/toolkit";
import { getListNoti } from "@actions/noti.actions";

export const NOTI_TYPE = {
  NEW_ORDER: 0,
  PAYMENT: 1,
  NEW_DISH: 2
}

const initialState = {
  listNoti: [],
  listNotiOrder: [],
  listNotiPay: [],
  listNotiFood: [],
  isLoading: false,
  socketData: null
};

const notiSlice = createSlice({
  name: "notiSlice",
  initialState,
  reducers: {
    setSocketData: (state, action) => {
      state.socketData = action.payload
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getListNoti.fulfilled, (state, action) => {
        state.listNoti = action.payload.data || [];
        const res = action?.payload?.data ? action.payload.data.reduce((acc, noti, index) => {
          acc[noti.notification_type] = acc[noti.notification_type] || []
          acc[noti.notification_type].push(noti)
          return acc
        }, {}) : {}
        state.listNotiOrder = res[NOTI_TYPE.NEW_ORDER] || []
        state.listNotiPay = res[NOTI_TYPE.PAYMENT] || []
        state.listNotiFood = res[NOTI_TYPE.NEW_DISH] || []
      })
      .addCase(getListNoti.rejected, (state, action) => {
        state.listNoti = [];
        state.listNotiOrder = [];
        state.listNotiPay = [];
        state.listNotiFood = [];
      })
});

export const selectListNoti = (state) => state.noti.listNoti;
export const selectListNotiOrder = (state) => state.noti.listNotiOrder;
export const selectListNotiPay = (state) => state.noti.listNotiPay;
export const selectListNotiFood = (state) => state.noti.listNotiFood;
export const selectIsLoading = (state) => state.noti.isLoading;
export const selectSocketData = (state) => state.noti.socketData
export const { setSocketData } = notiSlice.actions

export default notiSlice.reducer;