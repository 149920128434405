import { createAsyncThunk } from "@reduxjs/toolkit";
import { portalServices, saleServices } from "@services/region.service";

export const saleActions = {
    getListProvince: createAsyncThunk(
        "store/customer/getListProvince",
        async (data, { dispatch, getState, rejectWithValue }) => {
            try {
                const _data = await saleServices.getListProvince(data);
                return _data;
            } catch (error) {
                return rejectWithValue(error);
            }
        }
    ),

    getListDistrict: createAsyncThunk(
        "store/customer/getListDistrict",
        async (data, { dispatch, getState, rejectWithValue }) => {
            try {
                const _data = await saleServices.getListDistrict(data);
                return _data;
            } catch (error) {
                return rejectWithValue(error);
            }
        }
    ),

    getListWard: createAsyncThunk(
        "store/customer/getListWard",
        async (data, { dispatch, getState, rejectWithValue }) => {
            try {
                const _data = await saleServices.getListWard(data);
                return _data;
            } catch (error) {
                return rejectWithValue(error);
            }
        }
    )
}

export const mainActions = {
    getCountry: createAsyncThunk(
        "store/getCountry",
        async (data, { dispatch, getState, rejectWithValue }) => {
            try {
                const _data = await portalServices.getCountry(data);
                return _data;
            } catch (error) {
                return rejectWithValue(error);
            }
        }
    ),

    getListProvince: createAsyncThunk(
        "store/getCity",
        async (data, { dispatch, getState, rejectWithValue }) => {
            try {
                const _data = await portalServices.getListProvince(data);
                return _data;
            } catch (error) {
                return rejectWithValue(error);
            }
        }
    ),

    getListDistrict: createAsyncThunk(
        "store/getDistrict",
        async (data, { dispatch, getState, rejectWithValue }) => {
            try {
                const _data = await portalServices.getListDistrict(data);
                return _data;
            } catch (error) {
                return rejectWithValue(error);
            }
        }
    ),

    getListWard: createAsyncThunk(
        "store/getWard",
        async (data, { dispatch, getState, rejectWithValue }) => {
            try {
                const _data = await portalServices.getListWard(data);
                return _data;
            } catch (error) {
                return rejectWithValue(error);
            }
        }
    ),
}