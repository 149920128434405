import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { mainActions, saleActions } from "@actions/region.actions";

export const useRegion = ({
    type,
    province_code,
    district_code,
    ward_code,
    setProvince,
    setDistrict,
    setWard
}) => {
    const dispatch = useDispatch()
    const [listProvince, setListProvince] = useState([]);
    const [listDistrict, setListDistrict] = useState([]);
    const [listWard, setListWard] = useState([]);

    const isSale = type === 'sale'
    const actions = isSale ? saleActions : mainActions

    const formatItem = useCallback((item) => {
        return isSale ? { label: item.full_name, value: item.code } : { label: item.name, value: item.id };
    }, [isSale])

    const handleGetProvince = async () => {
        const data = await dispatch(actions.getListProvince());
        setListProvince(
            data?.payload?.data?.map((item) => {
                return formatItem(item)
            }) || []
        );
    }

    const handleGetDistrict = async () => {
        if (province_code) {
            const data = await dispatch(actions.getListDistrict(province_code));
            setListDistrict(
                data?.payload?.data?.map((item) => {
                    return formatItem(item)
                })
            );
        } else setListDistrict([]);
    };

    const handleGetWard = async () => {
        if (district_code) {
            const data = await dispatch(actions.getListWard(district_code));
            setListWard(
                data?.payload?.data?.map((item) => {
                    return formatItem(item)
                })
            );
        } else setListWard([]);
    };

    useEffect(() => {
        handleGetDistrict();
        if (listProvince?.length > 0) {
            setDistrict(null);
        }
    }, [province_code]);

    useEffect(() => {
        handleGetWard();
        if (listDistrict?.length > 0) {
            setWard(null);
        }
    }, [district_code]);

    useEffect(() => {
        if (listProvince?.length > 0) {
            setProvince(province_code);
        }
    }, [listProvince]);

    useEffect(() => {
        if (listDistrict?.length > 0) {
            setDistrict(district_code);
        }
    }, [listDistrict]);

    useEffect(() => {
        if (listWard?.length > 0) {
            setWard(ward_code);
        }
    }, [listWard]);

    return { listProvince, listDistrict, listWard, handleGetProvince };
}