import { createAsyncThunk } from '@reduxjs/toolkit';
import { settingCheckoutServices } from '@services';

export const getEcomInfo = createAsyncThunk(
  'setting-checkout/getEcomInfo',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await settingCheckoutServices.getEcomInfo(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editMerchantInfo = createAsyncThunk(
  'setting-checkout/editMerchantInfo',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await settingCheckoutServices.editMerchantInfo(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editWidgetSetting = createAsyncThunk(
  'setting-checkout/editWidgetSetting',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await settingCheckoutServices.editWidgetSetting(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
