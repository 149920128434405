import React from "react";
import CustomButton from "@components/buttons/CustomButton";
import { sell } from "@assets";

const CardWrapper = ({ onClick, children, backgroundImg = "", ...props }) => {
  return (
    <div

      style={{
        backgroundImage: `url(${backgroundImg})`,
        maxWidth: 380,
        height: "100%",
        padding: 40,
        margin: "auto",
        backgroundSize: "100% 100%"
      }}

      className="flex-1 bg-login-color-1 cursor-pointer transition p-10 relative rounded-xl overflow-hidden"
    >
      {/* <img src={backgroundImg} className="absolute inset-0 z-0" alt="background" /> */}
      <div className="relative z-10 flex flex-col items-center justify-center ">
        {children}
        <CustomButton
          onClick={onClick}
          title="Vào sử dụng ngay"
          leftIcon={<img src={sell.icFire} alt="icon" width={16} height={16} />}
          className={"rounded-lg"}
        />
      </div>
    </div>
  );
};

export default CardWrapper;
