import React, { useEffect, useMemo, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { mapEDC } from "@actions/edc.actions";
import { getListLinkedVa } from "@actions/metadata.actions";
import { selectIsLoading } from "@reducers/edc.reducer";
import { selectListLinkedVa } from "@reducers/metadata.reducer";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";

import CustomButton from "@components/buttons/CustomButton";
import SelectField from "@components/fields/SelectInPaymentField";
import { useTranslation } from "@hooks/useTranslation";
import useClickOutside from "@hooks/useClickOutside";
import { PCHANNEL_TITLE } from "@constants/shop-qr";

const IntergratePChannel = ({ selectedEDC, setShow, getListEDC }) => {
  const { translate, language } = useTranslation('edc');
  const dispatch = useDispatch();
  const { success, error } = CustomToastMessage;
  const [va, setVa] = useState(null);
  const isLoading = useSelector(selectIsLoading);
  const dataListLinkedVa = useSelector(selectListLinkedVa);

  const { nodeRef } = useClickOutside(() => setShow(false))

  const listLinkedVa = useMemo(() => {
    if (!dataListLinkedVa) return [];
    return dataListLinkedVa.map((va) => ({
      // label: va.va_account_no,
      label: (
        <div>
          <p className="">{va.va_account_no}</p>
          <p className="text-blue-2 font-normal italic">{va.store_name}</p>
        </div>
      ),
      value: { accNo: va.va_account_no, name: va.store_name },
    }));
  }, [dataListLinkedVa]);

  const handleMapEDC = async () => {
    const payload = { va_account_no: va.accNo, pos_id: selectedEDC.pos_id };
    const res = await dispatch(mapEDC(payload));
    if (res?.payload?.data) {
      setShow(false);
      success(
        translate("shop_qr:management_at_counter_integrate_success"),
        <div>
          <p>{translate("shop_qr:management_at_counter_integrate")}</p>
          <p>{va.accNo}</p>
          <p className="text-blue-2 font-normal italic">{va.name}</p>
        </div>,
      );
      dispatch(getListEDC());
    } else {
      setShow(false);
      error("Tích hợp thất bại", ERROR_CODE[res?.payload?.error_code][language]);
    }
  };

  useEffect(() => {
    dispatch(getListLinkedVa({ store_id: selectedEDC.store_id, page_size: 1000000 }));
  }, [dispatch, selectedEDC.store_id]);

  return (
    <div className="fixed z-50 inset-0 bg-blur flex items-center justify-center shadow-primary">
      <div
        ref={nodeRef}
        style={{
          width: 550,
        }}
        className="bg-white rounded-2xl p-12 relative"
      >
        <IoCloseSharp
          size={24}
          onClick={() => setShow(false)}
          style={{
            position: "absolute",
            top: 16,
            right: 16,
            cursor: "pointer",
          }}
        />
        <h1 className="text-dark-primary text-center text-2xl leading-8 font-bold mb-6">
          {translate("pos_machine_management_select_shop_qr")}
        </h1>
        <div style={{ color: "#0A203D" }} className="text-sm leading-5 font-medium mt-2">
          <span className="font-semibold">{translate("pos_machine_management_edc_code")}: </span>
          {selectedEDC.serial_number}
        </div>
        <div className="mt-2 flex flex-col gap-2">
          <p className="text-primary-text text-sm leading-5 font-semibold">
            {PCHANNEL_TITLE}
            <span className="text-error">*</span>
          </p>
          <SelectField
            data={listLinkedVa}
            setValue={setVa}
            placeHolder={translate("pos_machine_management_shop_qr")}
          />
        </div>
        <div className="mt-12 grid grid-cols-2 gap-4">
          <CustomButton onClick={() => setShow(false)} title={translate("common:cancel")} outline />
          <CustomButton
            onClick={handleMapEDC}
            title={translate("common:integrate")}
            disabled={isLoading || !va?.accNo}
          />
        </div>
      </div>
    </div>
  );
};

export default IntergratePChannel;
