import React, { useState } from "react";

import InputField from "@components/fields/InputField";
import { useTranslation } from "@hooks/useTranslation";

const Ecom = ({ form, register, setValue, errors, watch }) => {
  const [showAdvance, setShowAdvance] = useState(false);
  const { translate } = useTranslation();

  const handleShowAdvance = () => setShowAdvance(!showAdvance);

  return (
    <div className="w-full flex flex-col gap-4 bg-white">
      <div className="flex items-end gap-4">
        <InputField
          isRequired
          label={translate("payment_successful")}
          setValue={setValue}
          id={form.RETURN_URL}
          name={form.RETURN_URL}
          value={watch(form.RETURN_URL)}
          register={register}
          errors={errors}
          extra={"flex-1"}
        />
        <button
          type="button"
          onClick={handleShowAdvance}
          style={{ padding: "10px 16px", gap: 10, backgroundColor: "rgba(67, 76, 118, 0.10)" }}
          className="flex items-center rounded transition-all"
        >
          <div style={{ color: "#18191B" }} className="font-medium">
            {translate("button_advance")}
          </div>
          <div className={` transform ${showAdvance ? "" : "rotate-180 "}`}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2188 5.33398H12.1662C12.0946 5.33398 12.0272 5.36003 11.9851 5.40273L7.99766 9.4819L4.01023 5.40273C3.96812 5.36003 3.90075 5.33398 3.82917 5.33398H2.77652C2.68529 5.33398 2.63196 5.41107 2.68529 5.46628L7.63415 10.5298C7.8138 10.7132 8.18152 10.7132 8.35977 10.5298L13.3086 5.46628C13.3634 5.41107 13.31 5.33398 13.2188 5.33398Z"
                fill="#18191B"
              />
            </svg>
          </div>
        </button>
      </div>

      <div
        className={`grid grid-cols-2 gap-2 ${showAdvance ? "visible" : "invisible z-0 absolute"}`}
      >
        <InputField
          readOnly
          isRequired
          label={translate("payment_checkout")}
          setValue={setValue}
          id={form.CHECKOUT}
          name={form.CHECKOUT}
          value={watch(form.CHECKOUT)}
          extra={`invisible z-0 absolute`}
          register={register}
          errors={errors}
        />

        <InputField
          isRequired
          label={translate("payment_cancel")}
          setValue={setValue}
          id={form.CANCEL_URL}
          name={form.CANCEL_URL}
          value={watch(form.CANCEL_URL)}
          extra=""
          register={register}
          errors={errors}
        />
        <InputField
          isRequired
          label={translate("payment_receipt")}
          setValue={setValue}
          id={form.IPN}
          name={form.IPN}
          value={watch(form.IPN)}
          extra=""
          register={register}
          errors={errors}
        />
      </div>
    </div>
  );
};

export default Ecom;
