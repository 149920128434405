import { createAsyncThunk } from "@reduxjs/toolkit";
import { metadataServices } from "@services/metadata.service";

export const getListStore = createAsyncThunk(
  "metadata/getListStore",
  async (data, { dispatch }) => {
    const _data = await metadataServices.getListStore(data);
    return _data;
  }
);

export const getListStaff = createAsyncThunk(
  "metadata/getListStaff",
  async (data, { dispatch }) => {
    const _data = await metadataServices.getListStaff(data);
    return _data;
  }
);

export const getListLinkedVa = createAsyncThunk(
  "metadata/getListLinkedVa",
  async (data, { dispatch }) => {
    const _data = await metadataServices.getListLinkedVa(data);
    return _data;
  }
);

export const getListAccountNumber = createAsyncThunk(
  "metadata/getListAccountNumber",
  async (data, { dispatch }) => {
    const _data = await metadataServices.getListAccountNumber(data);
    return _data;
  }
);

export const syncData = createAsyncThunk(
  "metadata/rule/sync",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await metadataServices.syncData(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
