import { createAsyncThunk } from "@reduxjs/toolkit";
import { edcServices } from "@services/edc.service";

export const mapEDC = createAsyncThunk("edc/mapEDC", async (data, { dispatch }) => {
    try {
        const _data = await edcServices.mapEDC(data);
        return _data;
    } catch (error) { return error }
});

export const getListEDC = createAsyncThunk("edc/getListEDC", async (data, { dispatch }) => {
    const _data = await edcServices.getListEDC(data);
    return _data;
});