import React, { useEffect, useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  handleDragDrop,
  validateFile
} from '@utils/lib';
import CustomButton from '@components/buttons/CustomButton';
import { imageService } from '@services/image.service';

const UploadImage = ({
  id = 'uploadImage',
  initValue = null,
  setValue,
  register,
  name,
  isRequired,
}) => {
  const { translate } = useTranslation();
  const [file, setFile] = useState(initValue);
  const [scale, setScale] = useState(1);

  const handleResetValue = async (value) => {
    if (!validateFile(value, 700)) return;

    if (file) URL.revokeObjectURL(file);

    try {
      const res = await imageService.postImage(value)
      setValue(name, res)
    } catch (error) { setValue(name, URL.createObjectURL(value)) }

    setFile(URL.createObjectURL(value));
  };

  const handleFileChange = (e) => {
    handleResetValue(e.target.files[0]);
    e.target.value = null;
  };

  useEffect(() => {
    setFile(initValue);
  }, [initValue]);

  return (
    <div
      style={{
        width: 382,
        height: 160,
        paddingBottom: 22,
        backgroundColor: '#F3F3F3',
        transform: `scale(${scale})`,
      }}
      {...handleDragDrop(handleResetValue, setScale)}
      {...register(name, {
        required: isRequired,
      })}
      className='upload-file relative overflow-hidden rounded cursor-pointer border border-dashed border-grey-6'
    >
      {file && (
        <img
          src={file}
          alt='file'
          style={{ height: 160 }}
          className='object-contain mx-auto'
        />
      )}
      <label
        className={`${file ? 'content' : 'flex'} flex-col h-full items-center justify-center cursor-pointer`}
        htmlFor={id} style={{ color: file ? "#ffffff" : "#595959" }}
      >
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.6634 11.419L16.6634 27.4736" stroke="currentColor" strokeLinecap="round"
            strokeLinejoin="round" />
          <path d="M20.5508 15.3223L16.6628 11.4183L12.7748 15.3223" stroke="currentColor"
            strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M22.8396 21.1631H24.0836C26.7969 21.1631 28.9956 18.9644 28.9956 16.2498L28.9956 9.73775C28.9956 7.03109 26.8023 4.83775 24.0956 4.83775L9.24227 4.83775C6.52894 4.83775 4.32894 7.03775 4.32894 9.75109L4.32894 16.2644C4.32894 18.9698 6.5236 21.1631 9.22894 21.1631H10.4849"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <p className='mt-2 font-semibold leading-5 text-sm'>
          {translate('drag_and_drop_to_upload')}
        </p>
        <p className='mb-2 leading-4 text-xs'>
          ({translate('recommend')} 764x320px)
        </p>
        <input
          id={id}
          type='file'
          className='hidden'
          onChange={(e) => handleFileChange(e)}
          accept='.png, .jpeg, .jpg'
        />
        <CustomButton
          onClick={() => document.getElementById(id).click()}
          title={translate('select_image')}
          className={'h-8 flex items-center justify-center !font-medium'}
        />
      </label>
    </div>
  );
};

export default UploadImage;
