import DEFAULT_VI from '@assets/locales/vi/default.json'
import DEFAULT_EN from '@assets/locales/en/default.json'

import SIDEBAR_VI from '@assets/locales/vi/sidebar.json'
import SIDEBAR_EN from '@assets/locales/en/sidebar.json'
import LOGIN_VI from '@assets/locales/vi/login.json'
import LOGIN_EN from '@assets/locales/en/login.json'
import TXN_VI from '@assets/locales/vi/txn.json'
import TXN_EN from '@assets/locales/en/txn.json'
import BANK_VI from '@assets/locales/vi/bank.json'
import BANK_EN from '@assets/locales/en/bank.json'
import COMMON_VI from '@assets/locales/vi/common.json'
import COMMON_EN from '@assets/locales/en/common.json'
import PAY_BY_LINK_VI from '@assets/locales/vi/pay-by-link.json'
import PAY_BY_LINK_EN from '@assets/locales/en/pay-by-link.json'
import EDC_VI from '@assets/locales/vi/edc.json'
import EDC_EN from '@assets/locales/en/edc.json'
import BUSINESS_VI from '@assets/locales/vi/business.json'
import BUSINESS_EN from '@assets/locales/en/business.json'
import NOTI_VI from '@assets/locales/vi/noti.json'
import NOTI_EN from '@assets/locales/en/noti.json'
import COOKIE_VI from '@assets/locales/vi/cookie.json'
import COOKIE_EN from '@assets/locales/en/cookie.json'
import SALE_CUSTOMER_VI from '@assets/locales/vi/sale-customer.json'
import SALE_CUSTOMER_EN from '@assets/locales/en/sale-customer.json'
import SALE_ORDER_VI from '@assets/locales/vi/sale-order.json'
import SALE_ORDER_EN from '@assets/locales/en/sale-order.json'
import SALE_PRODUCT_VI from '@assets/locales/vi/sale-product.json'
import SALE_PRODUCT_EN from '@assets/locales/en/sale-product.json'
import SALE_TABLE_VI from '@assets/locales/vi/sale-table.json'
import SALE_TABLE_EN from '@assets/locales/en/sale-table.json'
import SALE_SETTINGS_VI from '@assets/locales/vi/sale-settings.json'
import SALE_SETTINGS_EN from '@assets/locales/en/sale-settings.json'
import SHOP_QR_VI from '@assets/locales/vi/shop-qr.json'
import SHOP_QR_EN from '@assets/locales/en/shop-qr.json'
import DASHBOARD_VI from '@assets/locales/vi/dashboard.json'
import DASHBOARD_EN from '@assets/locales/en/dashboard.json'

export const TRANSLATIONS = {
  vi: {
    default: DEFAULT_VI,
    common: COMMON_VI,
    sidebar: SIDEBAR_VI,
    login: LOGIN_VI,
    txn: TXN_VI,
    bank: BANK_VI,
    pay_by_link: PAY_BY_LINK_VI,
    edc: EDC_VI,
    business: BUSINESS_VI,
    noti: NOTI_VI,
    cookie: COOKIE_VI,
    sale_customer: SALE_CUSTOMER_VI,
    sale_order: SALE_ORDER_VI,
    sale_product: SALE_PRODUCT_VI,
    sale_table: SALE_TABLE_VI,
    sale_settings: SALE_SETTINGS_VI,
    shop_qr: SHOP_QR_VI,
    dashboard: DASHBOARD_VI,
  },
  en: {
    default: DEFAULT_EN,
    common: COMMON_EN,
    sidebar: SIDEBAR_EN,
    login: LOGIN_EN,
    txn: TXN_EN,
    bank: BANK_EN,
    pay_by_link: PAY_BY_LINK_EN,
    edc: EDC_EN,
    business: BUSINESS_EN,
    noti: NOTI_EN,
    cookie: COOKIE_EN,
    sale_customer: SALE_CUSTOMER_EN,
    sale_order: SALE_ORDER_EN,
    sale_product: SALE_PRODUCT_EN,
    sale_table: SALE_TABLE_EN,
    sale_settings: SALE_SETTINGS_EN,
    shop_qr: SHOP_QR_EN,
    dashboard: DASHBOARD_EN,
  },
}
