import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePChannel, getListPayment } from "@actions/va.action";
import { selectListPayment } from "@reducers/va.reducer";
import { CustomToastMessage } from "@utils/customToastMsg";
import { ERROR_CODE } from "@constants/errorCode";

import ModalDelete from "@components/modals/ModalDelete";
import SectionTitle from "@components/section-title";
import Datatable from "@components/tsdatatable/datatables";
import CreatePChannel from "./CreatePChannel";
import DetailPChannel from "./DetailPChannel";

import moreInfo from "@assets/images/payment/moreInfo.svg";
import trash from "@assets/images/payment/trash.svg";
import CustomButton from "@components/buttons/CustomButton";
import { useTranslation } from "@hooks/useTranslation";
import { formatChannel, formatDateTxn, formatVaType } from "@utils/lib";
import { PCHANNEL_TITLE } from "@constants/shop-qr";
import useSearchParams from "@pages/sale-management/customer/useSearchParams";

const DataTableComponent = ({
  filters,
  numOfPage,
  data,
  setFilters,
  setSelectedChannel,
  setShowDetail,
  setShowFormDelete,
  translate,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: `${translate("common:numerical_order")}`,
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: `${translate("shop_qr:management_virtual_account")}`,
        accessor: "va_type",
        Cell: ({ value }) => <p>{translate(formatVaType(value))}</p>,
        disableSortBy: true,
      },
      {
        Header: `${translate("virtual_account_code")}`,
        accessor: "va_account_no",
        disableSortBy: true,
      },
      {
        Header: `${translate("shop_qr:management_virtual_account_number")}`,
        accessor: "bank_account_no",
        Cell: ({ value }) => <p>{value ? value : ""}</p>,
        disableSortBy: true,
      },
      {
        Header: `${translate("shop_qr:management_sale_channel")}`,
        accessor: "channel",
        Cell: ({ value }) => <div>{translate(formatChannel(value))}</div>,
        disableSortBy: true,
      },
      {
        Header: `${translate("shop_qr:management_shop_branch")}`,
        accessor: "store_name",
        disableSortBy: true,
      },
      {
        Header: `${translate("shop_qr:management_staff")}`,
        accessor: "staff_id",
        Cell: ({ value }) => <div>{value || ""}</div>,
        disableSortBy: true,
      },
      {
        Header: `${translate("shop_qr:management_created_time")}`,
        accessor: "created_at",
        Cell: ({ value }) => <div>{formatDateTxn(value, false)}</div>,
      },
      {
        Header: `${translate("common:actions")}`,
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <img
                src={moreInfo}
                alt="moreInfo"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setSelectedChannel(row.original);
                  setShowDetail(true);
                }}
              />
              <img
                src={trash}
                alt="trash"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setSelectedChannel(row.original);
                  setShowFormDelete(true);
                }}
              />
            </div>
          );
        },
        disableSortBy: true,
      },
    ],
    [setSelectedChannel, setShowDetail, setShowFormDelete, translate]
  );

  return (
    <Datatable
      columns={columns}
      data={data}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      setFilters={setFilters}
      filters={filters}
    />
  );
};

const PaymentChannel = () => {
  const { translate, language } = useTranslation('bank');
  const [showFormCreate, setShowFormCreate] = useState(false);
  const [showFormDelete, setShowFormDelete] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const dispatch = useDispatch();

  const data = useSelector(selectListPayment);
  const [numOfPage, setNumOfPage] = useState("1");

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);
    return data?.data;
  }, [data]);


  const { updatePagination, filtersParams } = useSearchParams();

  const [filters, setFilters] = useState(filtersParams);

  useEffect(() => {
    updatePagination(new URLSearchParams(filters));
  }, [dispatch, filters, updatePagination]);

  const handleDelete = async (id) => {
    const res = await dispatch(deletePChannel({ data: { channel_id: id } }));
    if (res?.payload?.data) {
      CustomToastMessage.success(
        `${translate('common:delete')} ${PCHANNEL_TITLE} ${translate('shop_qr:management_at_counter_success_title2')}`,
        `${translate('shop_qr:delete_success_desc')} ${PCHANNEL_TITLE} ${selectedChannel.store_name}.`
      );
      dispatch(getListPayment(filters));
    } else {
      CustomToastMessage.error(
        `${translate('common:delete')} ${PCHANNEL_TITLE} ${translate('shop_qr:management_at_counter_fail_title')}`,
        ERROR_CODE[res.payload.error_code][language]
      );
    }
  };

  useEffect(() => {
    dispatch(getListPayment(filters));
  }, [dispatch, filters]);

  return (
    <>
      <div className="flex w-full justify-between items-center mb-8">
        <SectionTitle
          title={PCHANNEL_TITLE}
          subtitle={translate("shop_qr:management")}
        />
        <CustomButton
          type="add"
          outline
          title={translate("common:add")}
          onClick={() => setShowFormCreate(true)}
        />
      </div>

      <DataTableComponent
        filters={filters}
        setFilters={setFilters}
        data={customData}
        numOfPage={numOfPage}
        setSelectedChannel={setSelectedChannel}
        setShowDetail={setShowDetail}
        setShowFormDelete={setShowFormDelete}
        translate={translate}
      />

      {showFormCreate && (
        <CreatePChannel
          setShowForm={setShowFormCreate}
          refectch={() => dispatch(getListPayment(filters))}
        />
      )}
      <ModalDelete
        title={`${translate("common:delete")} ${PCHANNEL_TITLE}`}
        desc={`${translate(
          "shop_qr:management_delete_bank_account_confirm"
        )} ${selectedChannel?.store_name} ${PCHANNEL_TITLE}?`}
        isOpen={showFormDelete}
        onClose={() => setShowFormDelete(false)}
        item={selectedChannel}
        handleAction={handleDelete}
        titleBtnLeft={translate("common:cancel")}
        titleBtnRight={translate("common:delete")}
      />
      <DetailPChannel
        isOpen={showDetail}
        selectedChannel={selectedChannel}
        onClose={() => setShowDetail(false)}
      />
    </>
  );
};

export default PaymentChannel;
