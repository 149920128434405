import axios from 'axios'

const baseURL = process.env.REACT_APP_CHECKOUT_API_ENDPOINT

export const settingCheckoutServices = {
  getEcomInfo: (id) => {
    return axios.get(baseURL + '/merchants/' + id + '?allow_create=True')
  },

  editMerchantInfo: (payload) => {
    return axios.put(baseURL + '/merchants/' + payload.merchant_id, payload)
  },

  editWidgetSetting: ({ id, data }) => {
    return axios.put(baseURL + '/widgets/' + id, data)
  },
}
