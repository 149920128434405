import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import IcWarn from "@assets/images/icon/ic_warn.svg";
import CustomButton from "@components/buttons/CustomButton";
import ModalWrap from "@components/modals/ModalWrap";
import { history } from "@helpers";
import { useTranslation } from "@hooks/useTranslation";

const ModalOffline = ({ isOpen, onClose, table_id }) => {
  const { translate } = useTranslation()

  return (
    <ModalWrap isOpen={isOpen} onClickBackdrop={onClose}>
      <IoCloseSharp
        className="absolute cursor-pointer hover:opacity-75"
        style={{ right: 16, top: 16 }}
        onClick={() => {
          onClose();
        }}
        size={20}
      />

      <div className=" mx-auto flex max-w-md flex-col gap-8 rounded-2xl px-6 py-8">
        <div className="flex flex-col items-center justify-center gap-6">
          <div>
            <img src={IcWarn} alt="ic_warn" />
          </div>
          <div style={{ gap: 10 }} className="flex flex-col  text-center text-black font-normal">
            <h1 className="text-xl font-bold">{translate('sale_management_disconnect')}</h1>
            <p className="text-sm text-grey-9 font-normal">
              {translate('sale_management_contact_help')}
            </p>
            <p className="text-sm text-grey-9 font-normal">“1900 3353”</p>
          </div>
        </div>
        <CustomButton
          title={translate('sale_management_back_to_home')}
          onClick={() => {
            onClose();
            history.push(`${table_id ? '/sale-table' : '/sale-order?page=1&page_size=10'}`);
          }}
        />
      </div>
    </ModalWrap>
  );
};

export default ModalOffline;
